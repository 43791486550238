import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Keyboard, Navigation, Scrollbar, Thumbs } from "swiper";
import data from "./../../assets/json/howToWorks";


const Howtowork = () => {
  return (
    <div className="Howtowork">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="works-part">
              <div className="col-12 col-md-6">
                <div className="works-slider">
                  <Swiper
                    slidesPerView={1}
                    showsPagination={false}
                    slidesPerGroupSkip={1}
                    spaceBetween={40}
                    grabCursor={true}
                    keyboard={{
                      enabled: true
                    }}
                    breakpoints={{
                      769: {
                        slidesPerView: 1.5,
                        slidesPerGroup: 1
                      }
                    }}
                    scrollbar={true}
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                      disabledClass: "swiper-button-disabled"
                    }}
                    dir="ltr"
                    cssMode={true}
                    modules={[Keyboard, Scrollbar, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    {data.map((item) => (
                      <SwiperSlide key={item.id}>
                      <LazyLoadImage
                    src={require(`./../../assets/images/home/${item.img}`)}
                    PlaceholderSrc={`./../../assets/images/home/${item.img}`}
                    alt={item.img}
                    effect="blur"
                    loading="lazy"
                    className="img-fluid"
                  />
                      </SwiperSlide>
                    ))}
                    <div className="slider-pagination-btn">
                   
                      <button className="image-swiper-button-prev focus:outline-none">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M48 24C48 10.7452 37.2548 0 24 0V0C10.7452 0 0 10.7452 0 24V24C0 37.2548 10.7452 48 24 48V48C37.2548 48 48 37.2548 48 24V24Z"
                            fill="white"
                            fillOpacity="0.08"
                          />
                          <path
                            d="M22 17L15 24M15 24L22 31M15 24L33 24"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button className="image-swiper-button-next focus:outline-none">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M48 24C48 10.7452 37.2548 0 24 0V0C10.7452 0 0 10.7452 0 24V24C0 37.2548 10.7452 48 24 48V48C37.2548 48 48 37.2548 48 24V24Z"
                            fill="white"
                            fillOpacity="0.08"
                          />
                          <path
                            d="M26 31L33 24M33 24L26 17M33 24L15 24"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </Swiper>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="works-right">
                  <Swiper
                    slidesPerView={1}
                    showsPagination={false}
                    slidesPerGroupSkip={1}
                    spaceBetween={0}
                    grabCursor={true}
                    freeMode={true}
                    keyboard={{
                      enabled: true
                    }}
                    breakpoints={{
                      769: {
                        slidesPerView: 1,
                        slidesPerGroup: 1
                      }
                    }}
                    scrollbar={false}
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                      disabledClass: "swiper-button-disabled"
                    }}
                    dir="ltr"
                    cssMode={true}
                    modules={[Keyboard, Scrollbar, Navigation, Thumbs]}
                    className="mySwiper"
                  >
                    {data.map((item) => (
                      <SwiperSlide key={item.id} id={item.id}>
                        <div className="heading text-start">
                          <div className="section-name text-primary fs-16">
                            {item.name}
                          </div>
                          <h2 className="fs-40 text-start text-light">
                            {item.title}
                          </h2>
                          <ul>
                            {item.list.map((item) => (
                              <li key={item.id}>{item.desc}</li>
                            ))}
                          </ul>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howtowork;