import React from "react";
import { Link } from "react-router-dom";

const ScheduleCard = ({id, cardTitle, icon, title, desc, link}) => {
  return (
    <>
      <div id={id} className="schedule-card card card-border p-3">
        <div className="schedule-card-heading mb-2 pb-2">
          <h3 className="fs-14 Pb-3 ">{cardTitle}</h3>
        </div>

        <div>
          <div className="img-content text-center mb-2">
            <img
              className="mt-4 img-fluid"
              src={icon}
              alt="error"
            />
          </div>
          <div className="text-content text-center">
            <h2 className="fs-24 fw-700">{title}</h2>
            <p className="fs-14 fw-400">
             {desc}
            </p>
            {link && <Link to={"#"} className="btn btn-light radius-16 mt-3">
              {link}
            </Link>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleCard;
