import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const TrainingComponent = ({ data }) => {
  const navigate = useLocation();
  const filterSlug = navigate.pathname.split("/")[3];
  const [newData, setNewData] = useState(null); // Initial value is null or an empty object/array

  useEffect(() => {
    const filterData = data?.find((item) => item.slug == filterSlug);
    setNewData(filterData == null ? data : filterData.section);
  }, [filterSlug]); // Pass filterSlug as a dependency


  
  return (
    <>
      <div className="training">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Resources</Breadcrumb.Item>

                <Breadcrumb.Item active>Product Training</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {newData?.map((item) => (
              <div className="col-lg-6">
                <div className="question-card">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/ProductTraining/folder.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h4>{item.title}</h4>
                    <ul>
                      <li>
                        <span>{item.article?.length} Articles</span>
                      </li>
                      {item.section && (
                        <li>
                          <span>{item.section?.length} Sections</span>
                        </li>
                      )}
                    </ul>
                    <p>{item.desc}</p>
                    <Link
                      to={`${
                        item.section?.length > 0 ? "sections" : "articles"
                      }/${item.slug}`}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingComponent;