import React from "react";

const CompanyCard2 = ({ title, desc, logo, bgImg }) => {
  return (
    <div className={`company-card-2`}>
      <div className="img-content text-end img-fluid">
        <img
          className="mb-4"
          src={require(`../../assets/images/customer/${logo}`)}
          alt=""
        />
        {/* <img className="mb-4" src={logo} alt="" /> */}
      </div>
      {bgImg ? (
        <img
          className="img-fluid bg-img"
          src={require(`../../assets/images/customer/illiyeen_bg.png`)}
          alt="Illiyeen"
        />
      ) : (
        ""
      )}
      <div className="text-content text-start">
        <h3 className="fs-14 fw-400">{title}</h3>
        <p className="fs-18 fw-700">{desc}</p>
        {/* <Link className="fs-16 fw-400">Learn More {"->"}</Link> */}
      </div>
    </div>
  );
};

export default CompanyCard2;
