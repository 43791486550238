import React from "react";
import BlogCat from "./BlogCat";

const BlogCatCard = () => {
  return (
    <div className="related-segment py-2 px-3 radius-10">
      <h2 className="text-dark fw-700 fs-20 mb-5 ">Categories</h2>
      <BlogCat />
      <BlogCat />
      <BlogCat />
      <BlogCat />
    </div>
  );
};

export default BlogCatCard;
