import React from "react";
import { Link } from "react-router-dom";
import BannerText from "../../Banner/BannerText";

const NoticeBoardHero = () => {
  return (
    <>
      <div className="AttendanceManagement-hero NoticeBoard-hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <BannerText
                sectionTitle="DIGITAL NOTICE BOARD"
                title="Clear and concise communication at once"
                desc="Improve communication and collaboration through the digital notice board powered by SmartPeople, which in-turn leads to an increase in employee engagement and keep your team updated with latest news events and official notice or announcement."
                
              />
              <Link to="http://apps.smartoffice.ai/register" className="btn btn-primary radius-8 w-50 mt-5">
              Free Signup
              </Link>
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/digital-noticeboard/DIGITAL-NOTICE-BOARD.png")}
                  alt="attendance-hero"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBoardHero;
