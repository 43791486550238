const FeaturesCompanyManagementData = [
    {
      id: 0,
      title: "Manage Multiple company under same panel",
      desc: "With tax penalty protection we'll pay up to $25,000 if you receive a penalty.",
      img: require("./../images/feature/company-management/manage-multiple.png"),
    },
    {
      id: 1,
      title: "Switch between companies",
      desc: "With tax penalty protection we'll pay up to $25,000 if you receive a penalty.",
      img: require("./../images/feature/company-management/switch.png"),
    },
    {
      id: 2,
      title: "Ability to create and manage multiple companies",
      desc: "With tax penalty protection we'll pay up to $25,000 if you receive a penalty.",
      img: require("./../images/feature/company-management/ability.png"),
    },
    {
      id: 3,
      title: "Company-specific settings and configurations",
      desc: "With tax penalty protection we'll pay up to $25,000 if you receive a penalty.",
      img: require("./../images/feature/company-management/company-specific.png"),
    },
    {
      id: 4,
      title: "Employee profiles with personal and professional details",
      desc: "With tax penalty protection we'll pay up to $25,000 if you receive a penalty.",
      img: require("./../images/feature/company-management/employee-profile.png"),
    },
    {
      id: 5,
      title: "Real-time tracking of employee attendance of each compan",
      desc: "With tax penalty protection we'll pay up to $25,000 if you receive a penalty.",
      img: require("./../images/feature/company-management/real-time.png"),
    },
  ];
  
  export { FeaturesCompanyManagementData };
  