const DomainCustomizationBearingGiftsData=[
    {
        id: 0,
        icon: require("../images/feature/domain-customization/icon 1 Manage Time with Ease.png"),
        title: "Manage Time with Ease",
        desc: "Access helpful resources or talk to an HR advisor. Stay compliant with help from Mineral, Inc."
      },
      {
        id: 1,
        icon: require("../images/feature/domain-customization/icon 2 Apply Shift Differentials.png"),
        title: "Apply Shift Differentials",
        desc: "Save money and get coverage for on-the-job injuries. Find the right policy for you with AP Intego."
      },
      {
        id: 2,
        icon: require("../images/feature/domain-customization/icon 3 Improve Communication.png"),
        title: "Improve Communication",
        desc: "Access helpful resources or talk to an HR advisor. Stay compliant with help from Mineral, Inc."
      },
      {
        id: 3,
        icon: require("../images/feature/domain-customization/icon 3 Improve Communication.png"),
        title: "Improve Communication",
        desc: "Save money and get coverage for on-the-job injuries. Find the right policy for you with AP Intego."
      }
];

export { DomainCustomizationBearingGiftsData };

