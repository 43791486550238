import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const HomeHero = ({
  className,
  container,
  name,
  title,
  desc,
  img,
  btn,
  btn1,
  data,
}) => {
  const appFeatures = [
    {
      id: 0,
      img: "Attendance Management 1.png",
      alt: "Attendance Management",
      title: "Attendance Management"
    },
    {
      id: 1,
      img: "Block Chain 1.png",
      alt: "Block Chain",
      title: "Block Chain"
    },
    {
      id: 2,
      img: "Digital Noticeboard 1.png",
      alt: "Digital Notice Board",
      title: "Digital Notice Board"
    },
    {
      id: 3,
      img: "Domain Customization 1.png",
      alt: "Domain Customization",
      title: "Domain Customization"
    },
    {
      id: 4,
      img: "Employee Management 1.png",
      alt: "Employee Management",
      title: "Employee Management"
    },
    {
      id: 5,
      img: "Leave Management 1.png",
      alt: "Leave Management",
      title: "Leave Management"
    },
    {
      id: 6,
      img: "Notification Center 1.png",
      alt: "Notification Center",
      title: "Notification"
    },
    {
      id: 7,
      img: "Payroll Management 1.png",
      alt: "Payroll Management",
      title: "Payroll Management"
    },
    {
      id: 8,
      img: "Shift Management 1.png",
      alt: "Shift Management",
      title: "Shift Management"
    },
    {
      id: 9,
      img: "Tax Management 1.png",
      alt: "Tax Management",
      title: "Tax Management"
    },
  ]
  return (
    <div className={`hero-section ${className}`}>
      <div className={` ${container ? container : "container-fluid"}`}>
        <div className={`row align-items-center`}>
          <div className="col-12 order-2 order-lg-1 col-lg-6">
            <div className="hero-title-animate d-flex align-items-center justify-content-end flex-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row">
              <div className="hero-title max-w-546 position-relative -top-1">
                {name && <h6>{name}</h6>}
                <h2 className="fs-64 fw-800 mb-4 CSK-900">{title}</h2>
                <p className="fs-20 fw-500 mb-3">{desc}</p>
                <div
                  className="text hr-list-item"
                  dangerouslySetInnerHTML={{ __html: data }}
                />
                {btn1 && (
                  <Link
                    to="http://apps.smartoffice.ai/register"
                    className="btn btn-light radius-12 radius-8 w-50 mt-5"
                  >
                    {btn1}
                  </Link>
                )}
                {btn && (
                  <Link
                    to="http://apps.smartoffice.ai/register"
                    className="btn btn-primary radius-8 w-50 mt-5"
                  >
                    {btn}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 order-1 order-lg-2 col-lg-6">
            <div className="hero-title hero-animate">
              <div className="logo-slider">
                <div className="slider-bg-img">
                <LazyLoadImage
                    src={require("./../../assets/images/hero/hero-slide-bg.png")}
                    PlaceholderSrc={"./../../assets/images/hero/hero-slide-bg.png"}
                    alt={"slide-bg"}
                    effect="blur"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
                <div className="marquee-wrap">
                  <div className="marquee-block">
                    <div className="marquee-inner to-left">
                    
                      <span>
                      {appFeatures.map((item) => (
                          <div key={item.id} className="marquee-item">
                            <div className="img">
                              <img
                                src={require(`./../../assets/images/megamenu/new-icon/${item.img}`)}
                                alt={item.title}
                                className="img-fluid"
                              />
                            </div>
                            <div className="content">
                              <p>{item.title}</p>
                            </div>
                          </div>
                        ))}
                       
                      </span>
                    </div>
                  </div>
                  <div className="marquee-block">
                    <div className="marquee-inner to-right">
                      <span>
                      {appFeatures.map((item) => (
                          <div key={item.id} className="marquee-item">
                            <div className="img">
                              <img
                                src={require(`./../../assets/images/megamenu/new-icon/${item.img}`)}
                                alt={item.title}
                                className="img-fluid"
                              />
                            </div>
                            <div className="content">
                              <p>{item.title}</p>
                            </div>
                          </div>
                        ))}
                      </span>
                    </div>
                  </div>
                  <div className="marquee-block">
                    <div className="marquee-inner to-left">
                      {/* <span>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-1.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-2.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-3.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-4.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-5.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-6.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-7.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-8.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-9.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/r-10.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </span> */}
                      <span>
                      {appFeatures.map((item) => (
                          <div key={item.id} className="marquee-item">
                            <div className="img">
                              <img
                               src={require(`./../../assets/images/megamenu/new-icon/${item.img}`)}
                                alt={item.title}
                                className="img-fluid"
                              />
                            </div>
                            <div className="content">
                              <p>{item.title}</p>
                            </div>
                          </div>
                        ))}
                       
                      </span>
                    </div>
                  </div>
                  <div className="marquee-block">
                    <div className="marquee-inner to-right">
                      {/* <span>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-1.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-2.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-3.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-4.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-5.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-6.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-7.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-8.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-9.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="marquee-item">
                          <div className="img">
                            <img
                              src={require("./../../assets/images/hero/slide-items/b-10.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </span> */}
                      <span>
                      {appFeatures.map((item) => (
                          <div key={item.id} className="marquee-item">
                            <div className="img">
                              <img
                                src={require(`./../../assets/images/megamenu/new-icon/${item.img}`)}
                                alt={item.title}
                                className="img-fluid"
                              />
                            </div>
                            <div className="content">
                              <p>{item.title}</p>
                            </div>
                          </div>
                        ))}
                       
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
