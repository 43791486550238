import React from "react";
import ProductCard from "./ProductCard";

const ProductSection = ({data}) => {
  return (
    <div className="mt-60">
      <div className="container">
        <div className="row">
          {data.map((item) => (
            <div key={item.id} className="col-12 col-lg-6 mb-4">
              <ProductCard title={item.title} desc={item.desc} imgName={item.imgName}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
