import React from "react";
import Faqs from "../../components/Common/Faqs";
import NoticeBoardAttachfile from "../../components/Feature/NoticeBoard/NoticeBoardAttachfile";
import NoticeBoardBearingGifts from "../../components/Feature/NoticeBoard/NoticeBoardBearingGifts";
import NoticeBoardBlockchain from "../../components/Feature/NoticeBoard/NoticeBoardBlockchain";
import NoticeBoardCreation from "../../components/Feature/NoticeBoard/NoticeBoardCreation";
import NoticeBoardFriendlyInterface from "../../components/Feature/NoticeBoard/NoticeBoardFriendlyInterface";
import NoticeBoardHero from "../../components/Feature/NoticeBoard/NoticeBoardHero";
import NoticeBoardManagement from "../../components/Feature/NoticeBoard/NoticeBoardManagement";
import "./../../assets/css/future.css";

const NoticeBoard = () => {
  return (
    <>
      <NoticeBoardHero />
      <NoticeBoardBearingGifts />
      <NoticeBoardCreation bg={'bg-shadow'}/>
      <NoticeBoardManagement />
      <NoticeBoardBlockchain bg={'bg-shadow'}/>
      <NoticeBoardFriendlyInterface />
      <NoticeBoardAttachfile bg={'bg-shadow'}/>
      <Faqs />
    </>
  );
};

export default NoticeBoard;
