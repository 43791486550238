import React from "react";
import SectionHeading from "../../SectionHeading/SectionHeading";

const LeaveManagementPolicies = () => {
  return (
    <>
      <div className="leaveManagement-teamCalender">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/leave-management/image48.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
            <SectionHeading 
            title={"Custom Leave Policies"}
            desc={"Designed to cater businesses with unique and specific needs. With this flexible tool, you can create leave policies that align perfectly with your company's requirements. Customize leave policies based on your business's industry, location, size, and employee preferences. Define different types of leaves, such as annual leave, sick leave, maternity/paternity leave, and much more. Decide whether unused leaves can be carried forward to the next year, leave encashment or have a reset policy. Give employees the flexibility they need while maintaining operational efficiency."}
            data={'<a class="btn" href="#"> Try it for free </a>'}
            />
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementPolicies;
