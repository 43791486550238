import { useState } from "react";
import FullWidthHeading from "../Device/FullWidthHeading";
import { Button } from "react-bootstrap";
import DemoModal from "../Common/DemoModal";

const AboutPayroll = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className="about-payrollService">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                sectionTitle="FULL-SERVICE PAYROLL SOFTWARE"
                title="Employee Wellbeing & Team Spirit"
                desc="Employee well-being has been one of the key focuses of SmartOffice.ai. It is more than ensuring financial and career growth of the employees, the organization cares about the employees’ spiritual and psychological upliftment as well. A state-of-the-art working environment is ensured to inspire creativity and innovation. Strong team support and fairness of administrative and management policies fulfill the fundamental requirements for employee well-being. Team spirit springs from how efficiently, responsibly, and respectfully the team members treat, hear and cooperate with one another. The organization focuses on building a psychologically safe atmosphere for enhancing team spirit so that teams can innovate faster, achieve higher productivity, manage risks and find better solutions to problems.">
                <div class="btn-group">
                  <Button
                    to={"#"}
                    className="btn btn-primary radius-8"
                    onClick={() => setModalShow(true)}
                  >
                    Get a demo
                  </Button>
                  <a
                    href="/smart-people/payroll-management"
                    class="btn btn-outline-primary radius-8"
                  >
                    Try it now
                    <span class="tryNow">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.1875 7.03125V2.8125H10.9688"
                          stroke="#243757"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.125 7.875L15.1875 2.8125"
                          stroke="#243757"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.9375 10.125V14.625C12.9375 14.7742 12.8782 14.9173 12.7727 15.0227C12.6673 15.1282 12.5242 15.1875 12.375 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V5.625C2.8125 5.47582 2.87176 5.33274 2.97725 5.22725C3.08274 5.12176 3.22582 5.0625 3.375 5.0625H7.875"
                          stroke="#243757"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </FullWidthHeading>
            </div>
            <div className="col-12 d-block d-lg-none">
              <div className="img">
                <img
                  src={require("./../../assets/images/feature/payroll-mob-laptop.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DemoModal modalShow={modalShow} setModalShow={setModalShow} />
    </>
  );
};

export default AboutPayroll;
