import React from "react";
import BlogCard from "./BlogCard";

const BlogItem = ({ data }) => {
  return (
    <>
      <div className="container mt-4">
        <div className="row">
          {data.map((item) => (
            <div key={item.id} className={"col-lg-4 mt-3"}>
              <BlogCard item={item}/>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogItem;
