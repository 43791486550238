import React from "react";
import BannerText from "../../Banner/BannerText";

const PayrollRevolutionize = ({bg}) => {
  return (
    <>
      <div className={`payroll-revolutionize ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
            <BannerText
                title="Revolutionize Your Payroll System with Blockchain Technology
"
                desc="At SmartPeople, we are thrilled to introduce our revolutionary payroll system powered by blockchain technology, offering enhanced security, transparency, and efficiency in managing payrolls. By harnessing the power of blockchain, we have created a payroll management process that ensures utmost trust and reliability.

With blockchain, every activity performed to generate payroll is securely recorded in a decentralized and immutable ledger, leaving no room for tampering or unauthorized modifications. This robust system guarantees a tamper-proof record of all activities providing you with unparalleled peace of mind.
"
                
              />
            
            </div>
            <div className="col-12 col-lg-6">
              <div className="img-content">
                <img
                  className="img-fluid w-100"
                  src={require("../../../assets/images/feature/revolutionize.png")}
                  alt="error"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollRevolutionize;
