import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NotificationEverySituation = () => {
  return (
    <>
      <div className="everySituation">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/notification/Target Group Delivery.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <FullWidthHeading
                title={"Target Group Delivery"}
                data={`<p> In the vast sea of information, relevance is the key to capturing attention. SmartPeople's Customized Notification Alerts hits the bullseye with targeted delivery to specific groups. Our platform ensures that messages resonate with the intended audience, resulting in amplified results and action. Every notification template requires you to specify one to several user groups who will become the target audience of your notification alert.
</p>`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationEverySituation;
