import React from "react";
import productTrainingData from "../assets/json/productTrainingData";
import Banner from "../components/ProductTraining/Banner";
import Questions from "../components/ProductTraining/Questions";
import TrainingComponent from "../components/ProductTraining/TrainingComponent";

const ProductTraining = () => {
  return (
    <>
      <Banner />
      <TrainingComponent data={productTrainingData}/>
      <Questions />
    </>
  );
};

export default ProductTraining;