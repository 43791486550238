import React from "react";
import { Link } from "react-router-dom";

const NewsLetter = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${require("../../assets/images/comp/newsletter.png")})`,
        }}
        className="newsletter text-center d-flex mt-64"
      >
        <div className="newsletter-content mx-auto d-flex flex-column justify-content-center align-items-center">
          <h2 className="text-dark fs-64 fw-800">
            Take advantage of the limited time offer to access all features
          </h2>

          <Link className="btn btn-primary w-50 mt-5" to={"http://apps.smartoffice.ai/register"}>
            Free Signup
          </Link>
        </div>
      </section>
    </>
  );
};

export default NewsLetter;
