import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const EmployeeMonitoringHero = () => {
  return (
    <>
      <div className="employeeMonitoring-hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <FullWidthHeading
                sectionTitle="EMPLOYEE MANAGEMENT"
                title="Smart Human Resource Management Solution"
                desc="SmartPeople is a cloud based HR Management solution with common and futuristic business tools for managing and monitoring your business needs."
                btn="Free Signup"
                btnLink={'http://apps.smartoffice.ai/register'}
              />

              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/employee-monitoring/EMPLOYEE MANAGEMENT.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMonitoringHero;
