import React from "react";
import FullWidthHeading from "../Device/FullWidthHeading";

const AboutHero = () => {
  return (
    <>
      <div className="about-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                gradientText="Develop."
                title="Preview. Grow."
                data={
                  '<div class="btn-group"> <a href="http://apps.smartoffice.ai/register" class="btn btn-primary radius-8">Free Signup</a> <a href="#" class="btn btn-outline-primary radius-8">View All Features</a> </div>'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHero;
