import React from "react";
import { Link, NavLink } from "react-router-dom";

const MobileDropdown = ({
  data,
  handleNavLinkClick,
  windowWidth,
  btnHovered,
  btnId,
  hideMegamenu,
  closeDropdown
}) => {

  const handleclick = (item) => {
    if (item.hasDropdown === undefined) {
      document.querySelector(".btn-close").click();
    }
    handleNavLinkClick(item);
  };
  return (
    <div
      className="offcanvas offcanvas-start header_nav"
      tabindex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <div className=" mob-nav">
          <Link className="navbar-brand" to="/" >
            <img
              className="img-fluid"
              src={require("./../../assets/images/logo/logo.png")}
              alt=""
              onClick={() => document.querySelector(".btn-close").click()}
            />
          </Link>
          <button
            type="button"
            className="btn-close d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <img
              src={require("../../assets/images/comp/cross.png")}
              alt="Error !"
            />
          </button>
        </div>
      </div>
      <div className="offcanvas-body">
        <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
          <li className="nav-item ">
            <NavLink
              type="button"
              className={"nav-link d-flex justify-content-between"}
              to="/"
              onClick={() => document.querySelector(".btn-close").click()}
            >
              <span>Home</span>
            </NavLink>
          </li>
          <li className="nav-item ">
            <NavLink
              type="button"
              className={"nav-link d-flex justify-content-between"}
              to="/about"
              onClick={() => document.querySelector(".btn-close").click()}
            >
              <span>About</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <Link
              onClick={() => handleclick(data)}
              type="button"
              data-bs-toggle={"offcanvas"}
              data-bs-target={"#offcanvasRight1"}
              className={"nav-link d-flex justify-content-between"}
              aria-current="page"
              // to={"!#"}
            >
              <span>Features</span>

              <img
                className=""
                src={require("../../assets/images/comp/arrowright.png")}
                alt="Error!"
              />
            </Link>
          </li>
          <li className="nav-item ">
            <NavLink
              type="button"
              className={"nav-link d-flex justify-content-between"}
              to="/pricing"
              onClick={() => document.querySelector(".btn-close").click()}
            >
              <span>Pricing</span>
            </NavLink>
          </li>
          <li className="nav-item ">
            <NavLink
              type="button"
              className={"nav-link d-flex justify-content-between"}
              to="/customers"
              onClick={() => document.querySelector(".btn-close").click()}
            >
              <span>Customers</span>
            </NavLink>
          </li>
          <li className="nav-item ">
            <NavLink
              type="button"
              className={"nav-link d-flex justify-content-between"}
              to="/blog"
              onClick={() => document.querySelector(".btn-close").click()}
            >
              <span>Blog</span>
            </NavLink>
          </li>
        </ul>
        <div className="d-flex auth-btn">
          <Link
            to="http://apps.smartoffice.ai/"
            className="btn btn-light radius-8"
          >
            Login
          </Link>
          <Link
            to="http://apps.smartoffice.ai/register"
            className="btn btn-primary radius-8"
          >
            Free Signup
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileDropdown;
