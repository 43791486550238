const benifitData = [
  {
    id: 0,
    icon: require("../images/feature/payroll/Icon- HR support.png"),
    title: "HR support",
    desc: "Access helpful resources or talk to an HR advisor. Stay compliant with help from Mineral, Inc."
  },
  {
    id: 1,
    icon: require("../images/feature/payroll/Icon- Worker_s comp.png"),
    title: "Worker's comp",
    desc: "Save money and get coverage for on-the-job injuries. Find the right policy for you with AP Intego."
  },
  {
    id: 2,
    icon: require("../images/feature/payroll/Icon- Health benefits.png"),
    title: "Health benefits",
    desc: "Offer affordable medical, dental, and vision insurance packages by SimplyInsured."
  },
  {
    id: 3,
    icon: require("../images/feature/payroll/Icon- 401(k) plans.png"),
    title: "401(k) plans",
    desc: "Access affordable retirement plans by Guideline that sync with QuickBooks Payroll."
  }
];

export { benifitData };

