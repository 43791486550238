import React from "react";
import { Link } from "react-router-dom";

const InfoCard3 = ({ id, icon, title, desc, link }) => {
  return (
    <>
      <div id={id} className="content-card">
        <div className="img-content text-center mb-2">
          <img className="mb-4 img-fluid" src={icon} alt="error" />
        </div>

        <div className="text-content text-center">
          {title && <h2 className="fs-20 fw-500">{title}</h2>}
          {desc && <p className="fs-16 fw-400 mb-3">{desc}</p>}
          {link && (
            <Link to={link} className="text-primary fs-16 fw-600 ">
              {" "}
              Learn More {"->"}{" "}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default InfoCard3;
