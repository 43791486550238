import { useFormik } from "formik";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const emailJSApiKey = "g_BO247ULOaASCc8k";
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [countryCode, setCoutryCode] = useState();
  const [location, setLocation] = useState();



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const code = await loadGeoLocation();
  //       setCoutryCode(code);
  //     } catch (error) {
  //       console.error("Error loading geo location:", error);
  //     }
  //   };

  //   fetchData();

  // }, []);
  

  const mySchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(70, 'Too Long!')
      .required('Required'),
      lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(70, 'Too Long!')
      .required('Required'),
      
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),

    phoneNumber: Yup.string()
      .required('Required'),
      message: Yup.string()
      .min(2, 'Too Short!')
      .max(70, 'Too Long!')
      .required('Required'),
      // agree: Yup.boolean()
      // .required('Required'),


  });


  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message:""

    },
    validationSchema: mySchema,
    onSubmit: (values, action) => {
      setLoading(true);

      const sendAbleData = {
        user_id: emailJSApiKey,
        service_id: "service_2n2t34i",
        template_id: "template_mhschhb",
        template_params: {
          firstName: values.firstName,
          email_address: values.email,
          lastName: values.lastName,
          phone_number: values.phoneNumber,
          message: values.message,
        },
      };

      console.log(sendAbleData);
      fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        body: JSON.stringify(sendAbleData),
        headers: {
          Authorization: `Bearer ${emailJSApiKey}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            toast.success("Message sent successfully !");
          } else {
            // throw new Error("Oops... Something went wrong");
            toast.error("Oops failed to send message !");
          }
          setLoading(false);
          // formRef.current.reset();
          setPhone(countryCode);
          
          setIsChecked(false);
          action.resetForm();
        })
        .catch((error) => {
          console.log(error.message);
          toast.error("Technically failed to send message");
          setLoading(false);
        });
    },
  });

  
  return (
    <>
    <ToastContainer />
    <div   className="contact-form mt-80 mb-80 mx-auto">
      <div className="container">
        <div className="form-wrapper d-flex  gap-5">
          <img
            className="img-fluid"
            src={require(`../../assets/images/comp/form.png`)}
            alt="Error"
          />

          <form onSubmit={handleSubmit} id="go-to-contact" className="d-flex flex-column justify-content-center">
            <h3 className="fs-36 fw-600">Contact us</h3>
            <p style={{ color: "#667085" }} className="fs-20 fw-400">
              Our friendly team would love to hear from you.
            </p>

            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label>First name</label>
                  <input
                  onBlur={handleBlur}
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName}
                  placeholder="First name" className="form-control" />
                  {errors.firstName && touched.firstName ? (<p className="form-error text-danger fs-12">{errors.firstName}</p>) : null}

                </div>
                <div className="col-6">
                  <label>Last name</label>
                  <input 
                  onBlur={handleBlur}
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName}
                  placeholder="Last name" className="form-control" />
                  {errors.lastName && touched.lastName ? (<p className="form-error text-danger fs-12">{errors.lastName}</p>) : null}
                </div>
              </div>
              <label>Email</label>
              <input
              
                type="email"
                placeholder="you@company.com"
                className="form-control"
                onBlur={handleBlur}
                  name="email"
                  onChange={handleChange}
                  value={values.email}
              />
              {errors.email && touched.email ? (<p className="form-error text-danger fs-12">{errors.email}</p>) : null}

              <label>Phone number</label>
              <PhoneInput
                className="form-control"
                // countrySelectProps={{ unicodeFlags: true }}
                defaultCountry={"BD"}
                international
                value={phone}
                placeholder="+(880) 1XXXXXXXX"
                onBlur={handleBlur}
                  name="phoneNumber"
                  onChange={
                    (phone) => setFieldValue("phoneNumber", phone)
                    // handleFieldChange("phoneNumber", phone)
                  }

              />
            {errors.phoneNumber && touched.phoneNumber ? (<p className="form-error text-danger fs-12">{errors.phoneNumber}</p>) : null}

              <label>Message</label>
              <textarea 
              onBlur={handleBlur}
              name="message"
              onChange={handleChange}
              value={values.message}
              rows={5} className="form-control" />
              {errors.message && touched.message ? (<p className="form-error text-danger fs-12">{errors.message}</p>) : null}

              <div className="checkbox mt-3 ">
                <label className="d-flex">
                  <input  required
                  className="mt-2 me-2"
                  type="checkbox"   />
                  
                  You agree to our friendly{" "}
                  {errors.agree && touched.agree ? (<p className="form-error text-danger fs-12">{errors.agree}</p>) : null}
                  <Link to={"/policy"} className="policy">
                    {" "}
                    privacy policy
                  </Link>
                  .
                </label>
              </div>
              <Button variant="primary" type="submit" className={`w-100 ${loading ? "disabled" : ""}`}>
              {loading ? (
                        <span
                          className="spinner-border spinner-border-sm ms-auto"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                      {loading ? " Sending Message... " : "Send Message"}
              </Button>
              
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactForm;
