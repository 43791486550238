import React from "react";
import BannerText from "../../Banner/BannerText";

const AttendanceManagementTask = ({bg}) => {
  return (
    <>
      <section className={`feture-hero AttendanceManagement-task ${bg}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <BannerText
                title="Attendance Management.
"
                desc="Efficient Attendance Management. Keep track of key dates and tasks with seamless calendar integration.Important dates 
and tasks are effortlessly integrated into your calendar.
"
                btnText="Try it for free"
              />
            </div>
            <div className="col-lg-7">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/attendance-management/product-time.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AttendanceManagementTask;
