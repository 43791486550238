import React from "react";

const Service = () => {
  return (
    <>
      <div className="one-service">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={require("./../../assets/images/comparewithrippling/Overview.png")}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <h2>One service unlimited accounts</h2>
              <p>
                Smart Office allows you to add each service many times. This
                makes Franz the perfect tool to manage multiple business and
                private accounts all at the same time. You could even use five
                different Facebook Messenger accounts at once, if some
                inexplicable reason urges you to do so.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;