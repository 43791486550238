import React from "react";

const NoPage = () => {
  return (
    <div className="container  mx-auto">
      <img
        className="img-fluid"
        src={require("../assets/images/comp/404-Error.jpg")}
        alt="Error"
      />
    </div>
  );
};

export default NoPage;
