import React from "react";
import BannerText from "../Banner/BannerText";

const FetureHero = () => {
  return (
    <>
      <section className="feture-hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <BannerText
              className="feture-hero-title"
                title="Customized features that meet your needs"
                desc="SmartOffice is a cloud based HR Management solution with common
                and futuristic business tools for modeling application."
                btnText="Free Signup"
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="circle">
                <div className="circle-part">
                  <div className="circle-center-img-div">
                    <img
                      className="circle-center-img"
                      src={require("./../../assets/images/herocircle-1/logo.png")}
                      alt="Main Logo"
                    />
                  </div>

                  {/* <!-- Inner --> */}
                  <div className="hw-inner animate-spin-slow anim-8s">
                    <div className="hw-inner-item">
                      <img
                        className="animate-rotate-img anim-8s"
                        src={require("./../../assets/images/herocircle-1/new-icon/Attendance Management 1.png")}
                        alt="Adobe After Effects"
                      />
                    </div>
                    <div className="hw-inner-item">
                      <img
                        className="animate-rotate-img anim-8s"
                        src={require("./../../assets/images/herocircle-1/new-icon/Block Chain 1.png")}
                        alt="Tailwind CSS"
                      />
                    </div>
                    <div className="hw-inner-item">
                      <img
                        className="animate-rotate-img anim-8s"
                        src={require("./../../assets/images/herocircle-1/new-icon/Digital Noticeboard 1.png")}
                        alt="Bootstrap"
                      />
                    </div>
                    <div className="hw-inner-item">
                      <img
                        className="animate-rotate-img anim-8s"
                        src={require("./../../assets/images/herocircle-1/new-icon/Domain Customization 1.png")}
                        alt="Vue.js"
                      />
                    </div>
                  </div>

                  {/* Outer */}
                  <div className="hw-outer rounded-full reverse-animate-spin-slow">
                    <div className="hw-outer-div">
                      <div className="hw-outer-item">
                        <img
                          className="reverse-animate-rotate-img"
                          src={require("./../../assets/images/herocircle-1/new-icon/Employee Management 1.png")}
                          alt="React"
                        />
                      </div>
                      <div className="hw-outer-item">
                        <img
                          className="reverse-animate-rotate-img"
                          src={require("./../../assets/images/herocircle-1/new-icon/Leave Management 1.png")}
                          alt="Figma"
                        />
                      </div>
                      <div className="hw-outer-item">
                        <img
                          className="reverse-animate-rotate-img"
                          src={require("./../../assets/images/herocircle-1/new-icon/Notification Center 1.png")}
                          alt="Adobe XD"
                        />
                      </div>
                      <div className="hw-outer-item">
                        <img
                          className="reverse-animate-rotate-img"
                          src={require("./../../assets/images/herocircle-1/new-icon/Payroll Management 1.png")}
                          alt="Microsoft PowerPoint"
                        />
                      </div>
                      <div className="hw-outer-item">
                        <img
                          className="reverse-animate-rotate-img"
                          src={require("./../../assets/images/herocircle-1/new-icon/Shift Management 1.png")}
                          alt="Material UI"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FetureHero;
