import React from "react";
import Hero from "../../Home/Hero";
import heroIMG1 from "./../../../assets/images/feature/domain-customization/Benefit from reliable services for you.png";

const DomainCustomizationBenifit = ({bg}) => {
  return (
    <>
      <Hero
        img={heroIMG1}
        className={`growing-part domaincustomization-reliableServices ${bg}`}
        title="Benefit from reliable services for you"
        data={`<ul>
                    <li class="fs-16"><span><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="14" fill="#E6EDFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.9457 8.62486L11.5923 16.6865L9.37568 14.3182C8.96734 13.9332 8.32568 13.9099 7.85901 14.2365C7.40401 14.5749 7.27568 15.1699 7.55568 15.6482L10.1807 19.9182C10.4373 20.3149 10.8807 20.5599 11.3823 20.5599C11.8607 20.5599 12.3157 20.3149 12.5723 19.9182C12.9923 19.3699 21.0073 9.81486 21.0073 9.81486C22.0573 8.74153 20.7857 7.79653 19.9457 8.6132V8.62486Z" fill="#013FD7"/>
</svg>

</span>Public API</li>
                    <li class="fs-16"><span><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="14" fill="#E6EDFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.9457 8.62486L11.5923 16.6865L9.37568 14.3182C8.96734 13.9332 8.32568 13.9099 7.85901 14.2365C7.40401 14.5749 7.27568 15.1699 7.55568 15.6482L10.1807 19.9182C10.4373 20.3149 10.8807 20.5599 11.3823 20.5599C11.8607 20.5599 12.3157 20.3149 12.5723 19.9182C12.9923 19.3699 21.0073 9.81486 21.0073 9.81486C22.0573 8.74153 20.7857 7.79653 19.9457 8.6132V8.62486Z" fill="#013FD7"/>
</svg>

</span>Infra-as-Code to pilot your servers</li>
                    <li class="fs-16"><span><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="14" fill="#E6EDFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.9457 8.62486L11.5923 16.6865L9.37568 14.3182C8.96734 13.9332 8.32568 13.9099 7.85901 14.2365C7.40401 14.5749 7.27568 15.1699 7.55568 15.6482L10.1807 19.9182C10.4373 20.3149 10.8807 20.5599 11.3823 20.5599C11.8607 20.5599 12.3157 20.3149 12.5723 19.9182C12.9923 19.3699 21.0073 9.81486 21.0073 9.81486C22.0573 8.74153 20.7857 7.79653 19.9457 8.6132V8.62486Z" fill="#013FD7"/>
</svg>

</span>Anycast DNS, DNSSEC, anti-DDoS solutions</li>
                    <li class="fs-16"><span><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="14" fill="#E6EDFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.9457 8.62486L11.5923 16.6865L9.37568 14.3182C8.96734 13.9332 8.32568 13.9099 7.85901 14.2365C7.40401 14.5749 7.27568 15.1699 7.55568 15.6482L10.1807 19.9182C10.4373 20.3149 10.8807 20.5599 11.3823 20.5599C11.8607 20.5599 12.3157 20.3149 12.5723 19.9182C12.9923 19.3699 21.0073 9.81486 21.0073 9.81486C22.0573 8.74153 20.7857 7.79653 19.9457 8.6132V8.62486Z" fill="#013FD7"/>
</svg>

</span>Protected emails</li>
                    <li class="fs-16"><span><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="14" fill="#E6EDFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.9457 8.62486L11.5923 16.6865L9.37568 14.3182C8.96734 13.9332 8.32568 13.9099 7.85901 14.2365C7.40401 14.5749 7.27568 15.1699 7.55568 15.6482L10.1807 19.9182C10.4373 20.3149 10.8807 20.5599 11.3823 20.5599C11.8607 20.5599 12.3157 20.3149 12.5723 19.9182C12.9923 19.3699 21.0073 9.81486 21.0073 9.81486C22.0573 8.74153 20.7857 7.79653 19.9457 8.6132V8.62486Z" fill="#013FD7"/>
</svg>

</span>Multi-domain SAN, wildcard</li>
                </ul>`}
        btn="Get started"
        // btn1="Learn more"
      />
    </>
  );
};

export default DomainCustomizationBenifit;
