import React from "react";
import { analyticsData } from "../../../assets/json/analyticsData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const ShiftManagementAnalytics = () => {
  return (
    <>
      <div className="shiftManagement-analytics">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle="FEATURES"
                title="Insightful analytics for smarter shift management and continuous improvement."
                desc="Empowering, self-serve shift management analytics to enhance scheduling, engagement, and workforce retention."
              />
            </div>
            {analyticsData.map((item, index) => (
              <div key={index} className="col-lg-3 mb-4">
                <InfoCard2
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShiftManagementAnalytics;
