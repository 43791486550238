import React from "react";
import Marquee from "react-fast-marquee";
import SectionHeading from "../SectionHeading/SectionHeading";

const MarqueSlider = () => {
  return (
    <>
      <div className="integrate">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <SectionHeading
              fs="fs-48"
              title="Lets see what we integrate"
              desc="We recently had to jump on 10+ different plugin across eight
                  different countries to find the right owner and escalation
                  process."
              className="text-center mb-64"
            />
              

              <div className="logo-slider">
                <Marquee className="marque-slider mb-40" direction="left" speed={15} gradient={true}
              gradientColor={[255, 255, 255]}
              autoFill={true}>
                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/google.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/gmail.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/microsoft.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/zoom.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/slack.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/trello.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/click-up.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/brust-sms.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/dropbox.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/slack.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/trello.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Marquee>
                <Marquee className="marque-slider" direction="right" speed={15} gradient={true}
              gradientColor={[255, 255, 255]}
              autoFill={true}>
                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/google.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/gmail.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/microsoft.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/zoom.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/slack.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/trello.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/click-up.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/brust-sms.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/dropbox.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/slack.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="img">
                    <img
                      src={require("./../../assets/images/integrate-slider/trello.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarqueSlider;
