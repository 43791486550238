import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BenefitOfSmartPeople = ({ bg }) => {
  return (
    <>
     
      <div className={`benefit-hero ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <FullWidthHeading
                  sectionTitle="Benefits"
                  title="Benefits of SmartPeople's Company Management"
                  desc="Streamline both your data collection and secure storage with our employee database management system. Set up your organization and maintain all your employee records in one central location."
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/company-management/benefit.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BenefitOfSmartPeople;
