import React from "react";
import { blogData } from "../assets/json/blogData";
import HeroWithSearch from "../components/Blog/HeroWithSearch";
import RecentBlogs from "../components/Blog/RecentBlogs";
import TrendingBlogs from "../components/Blog/TrendingBlogs";

const ViewAllBlog = () => {
  return (
    <div>
      <HeroWithSearch />
      <div className="blog-section">
        <TrendingBlogs title={"Trending Blogs"} data={blogData} />
      </div>
      <RecentBlogs headeing={"Latest blogs"} />
    </div>
  );
};

export default ViewAllBlog;
