import "../assets/css/customer-page.css";
import { blogData } from "../assets/json/blogData";
import companyData from "../assets/json/companyData";
import companyData2 from "../assets/json/companyData2";
import BlogItem from "../components/Blog/BlogItem";
import AllCompany from "../components/Customer/AllCompany";
import CompanyBanner from "../components/Customer/CompanyBanner";
import CustomerHero from "../components/Customer/CustomerHero";
import SuccessStories from "../components/Customer/SuccessStories";

const Customers = () => {
  return (
    <div>
      <CustomerHero />
      <CompanyBanner />
      <AllCompany data={companyData} />
      <SuccessStories />
      <AllCompany data={companyData2} />
      <AllCompany />
      <div className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <div className="section-name text-primary fs-16">BLOG</div>
                <h2 className="fs-40">From the blog</h2>
                <p className="fs-20 mt-3 mb-64">
                  Stay informed with our blog's expert content on standard
                  operating procedures, best practices, cutting-edge
                  technologies, and valuable resources for your organization.
                </p>
              </div>
            </div>
            <BlogItem data={blogData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
