import React from "react";

const Banner = ({ title, desc }) => {
  return (
    <section className="banner">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h2>{title}</h2>
            <p>{desc}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
