import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NotificationOnlineService = () => {
  return (
    <>
      <div className="Notification-online-service">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-6">
              <FullWidthHeading
                title={"Action Customization"}
                desc={
                  "Cookie-cutter approaches are a thing of the past. SmartPeople enables you to personalize every notification, nurturing the unique connection between your organization and its employees. Witness the transformational power of personalization as engagement and productivity soar to unprecedented heights. You can specify user actions to trigger alerts at any moment."
                }
              />
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/notification/Action Customization.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationOnlineService;
