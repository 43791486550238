import React from "react";
import { Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { blogData } from "../../assets/json/blogData";
import BlogCard from "./BlogCard";


const RecentBlogs = ({ headeing }) => {
  const route = useLocation();

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <div className="trending-blogs-section  container">
      <div className="heading text-start d-flex justify-content-between mb-5">
        <h2 className="fs-40 text-start ">{headeing}</h2>
        <div>
          {route.pathname === "/blog" && (
            <Link to="/viewallblog" className="btn btn-light">
              View All
            </Link>
          )}
          {route.pathname === "/viewallblog" && (
            <Form.Select aria-label="Default select example">
              <option value="1">Top Rated</option>
              <option value="2">Lowest</option>
              <option value="3">Three</option>
            </Form.Select>
          )}
        </div>
      </div>
      <Slider {...settings}>
        {blogData.map((item) => (
          <BlogCard item={item}/>
        ))}
      </Slider>
    </div>
  );
};

export default RecentBlogs;
