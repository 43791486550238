const analyticsData = [
  {
    id: 0,
    icon: require("../images/feature/shift-management/Automated Scheduling.png"),
    title: "Automated Scheduling",
    desc: "AI-driven shifts for optimal resource allocation and efficiency.    "
  },
  {
    id: 1,
    icon: require("../images/feature/shift-management/Real-Time Notifications.png"),
    title: "Real-Time Notifications",
    desc: "Instant alerts for schedule changes and updates."
  },
  {
    id: 2,
    icon: require("../images/feature/shift-management/Forecasting and Demand.png"),
    title: "Forecasting and Demand",
    desc: "Predict workforce requirements for efficient shift allocation.    "
  },
  {
    id: 3,
    icon: require("../images/feature/shift-management/Shift Performance Tracking.png"),
    title: "Shift Performance Tracking",
    desc: "Assess employee productivity and performance during shifts.    "
  },
  {
    id: 4,
    icon: require("../images/feature/shift-management/Shift Reminders and Alerts.png"),
    title: "Shift Reminders and Alerts",
    desc: "Ensure timely attendance with automated shift reminders."
  },
  {
    id: 5,
    icon: require("../images/feature/shift-management/Shift Cost Analysis.png"),
    title: "Shift Cost Analysis",
    desc: "Track labor expenses and optimize resource allocation for cost-effectiveness.    "
  },
  {
    id: 6,
    icon: require("../images/feature/shift-management/Shift Performance Rewards.png"),
    title: "Shift Performance Rewards",
    desc: "Recognize top-performing employees and incentivize productivity."
  },
  {
    id: 7,
    icon: require("../images/feature/shift-management/Shift Evaluation Surveys.png"),
    title: "Shift Evaluation Surveys",
    desc: "Gather feedback to improve scheduling and overall workforce management."
  }
];

export { analyticsData };

