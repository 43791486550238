import React from 'react';

const GradientText = ({gradientText}) => {
    return (
        <>
            <span className='gradient-text'>{gradientText}</span>
        </>
    );
};

export default GradientText;