import React from 'react';

const BreadCum = () => {
    return (
        <div className='container breadcum fs-14 py-2'>
            <span>Blog </span>
            <span>Trending Post</span>
            <span>Single Post</span>
        </div>
    );
};

export default BreadCum;