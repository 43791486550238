import React from "react";
import BannerText from "../../Banner/BannerText";

const ShiftManagementSchedule = () => {
  return (
    <>
      <section className="shiftManagement-schedule">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <BannerText
                title="Seamlessly handle your schedule from any
location.
"
                desc="Simply create, copy, and publish schedules, manage shift swaps, and find replacements using our user-friendly web system accessible from any device."
              />
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/shift-management/image8.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShiftManagementSchedule;
