import React from "react";
import SectionHeading from "../../SectionHeading/SectionHeading";

const LeaveManagementBlockchain = () => {
  return (
    <>
      <div className="leaveManagement-blockchain ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <SectionHeading
                title={
                  "Embrace the Power of Blockchain Technology"
                }
                desc={
                  "At SmartOffice.ai, we leverage the revolutionary blockchain technology to provide you with unparalleled transparency and a comprehensive user activity log. With blockchain's tamper-proof records, you can say goodbye to doubts and uncertainties, gaining full visibility into your HR operations. Trust in a system built on the principles of transparency and data integrity with SmartPeople. Our advanced blockchain integration paves the way for more efficient and secure workforce management, allowing you to transparently monitor each of the activities performed to manage the leave approvals and holiday calendar."
                }
                data={'<a class="btn" href="#"> Learn More </a>'}
              />
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/blockchain-image.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementBlockchain;
