import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/faq-page.css";
import GradientText from "../Common/GradientText";

const FullWidthHeading = ({
  sectionTitle,
  sectionTitleClassName,
  gradientText,
  title,
  desc,
  btn,
  btn2,
  btn3,
  data,
  btnLink,
  btn2Link,
  children
}) => {
  return (
    <>
      <div className="fullwidth-heading">
        <div className="text-center">
          {sectionTitle && <p className={`text-primary fs-16 fw-bold section-title text-uppercase ${sectionTitleClassName}`}>
            {sectionTitle}
          </p>}
          {title && <h2 className="text-dark fw-800 fs-48 title">
            <GradientText gradientText={gradientText} />
            {title}
          </h2>}
          {desc && <p className="text-dark mt-2 fs-16 desc">{desc}</p>}
        </div>
        <div className="btn-group mt-5 d-flex align-items-center justify-content-center gap-4">
          {btn && (
            <Link to={btnLink} className="btn btn-primary radius-8 text-light">
              {btn}
            </Link>
          )}
          {btn2 && (
            <Link to={btn2Link} className="btn btn-primary radius-8 text-light">
              {btn2}
            </Link>
          )}
          {btn3 && <div dangerouslySetInnerHTML={{ __html: btn3 }}></div>}
        </div>
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
        {children}
      </div>
    </>
  );
};

export default FullWidthHeading;
