import React from "react";

const AttendanceManagementEmployeeAbsent = () => {
  return (
    <>
      <div className="AttendanceManagement-employeeabsent">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <h2 className="fs-40">
                  Get employee absentee data in Realtime & accurately
                </h2>
              </div>
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/attendance-management/employee-absent.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceManagementEmployeeAbsent;
