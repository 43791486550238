const NoticeBoardBearingGiftsData = [
    {
      id: 0,
      icon: require("../images/feature/digital-noticeboard/Schedule Notices with Ease.png"),
      title: "Schedule Notices with Ease:      ",
      desc: "Stay on schedule with our Notice Scheduling feature that allows you to set customized publish and unpublish dates.      "
    },
    {
      id: 1,
      icon: require("../images/feature/digital-noticeboard/Choose your target Audience.png"),
      title: "Choose your target Audience:      ",
      desc: "Reach the right Audience with targeted Notices through utilizing customized user groups.      "
    },
    {
      id: 2,
      icon: require("../images/feature/digital-noticeboard/improve Communication.png"),
      title: "Improve Communication:      ",
      desc: "Make your Notices count! Get your digital notices publish instantly using SmartPeople.      "
    },
    {
      id: 3,
      icon: require("../images/feature/digital-noticeboard/Further Segregation.png"),
      title: "Further Segregation:      ",
      desc: "A Notice for every occasion! Use the notice type feature to streamline your communication flow.      "
    }
  ];
  
  export { NoticeBoardBearingGiftsData };
  
  