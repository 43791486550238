import React from "react";
import BlogCard2 from "./BlogCard2";

const RelatedSegment = ({ heading, data }) => {
  
  return (
    <div className="related-segment py-2 px-3 radius-10">
      <h2 className="text-dark fw-700 fs-20 mb-5 ">{heading}</h2>
      {data.map((item) => (
        <BlogCard2 item={item}/>
      ))}
    </div>
  );
};

export default RelatedSegment;
