import React from "react";
import SectionHeading from "../../SectionHeading/SectionHeading";

const EmployeeMonitoringService = () => {
  return (
    <>
      <div className="notification-coreHR blockSecurity-EmployeeMonitoring employee">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <SectionHeading
                fs="fs-40"
                title="Employee Onboarding"
                desc="In the dynamic landscape of modern businesses, ensuring a seamless and efficient onboarding process for new employees has become paramount. The success of any organization depends on unlocking the potential of its talent from the very first day. Enter SmartPeople - a cloud-based HR Management solution equipped with futuristic tools, revolutionizing the way you onboard and nurture your workforce. With SmartPeople's innovative employee onboarding with work slot allocation feature, say goodbye to cumbersome procedures and welcome a new era of productivity and engagement."
                data={`<ul>
                    <li class="fs-16">
                      <div>
                      <b>Efficiency Redefined:</b>Time is money, and SmartPeople understands the value of both. By optimizing your onboarding process with Work Slot allocation, we guarantee a boost in productivity and efficiency.
                      </div>
                    </li>


<li class="fs-16"><div><b>The Foundation of Success:</b> The first steps of an employee's journey are critical. SmartPeople recognizes the significance of a strong foundation and presents a game-changing approach to onboarding - Work Slot based Onboarding.</div></li>


<li class="fs-16"><div><b>Seamless Integration:</b> The seamless integration of new hires is not just a dream; it's a reality with SmartPeople. Picture a world where every individual joins your organization with a sense of belonging, purpose, and direction.</div></li>
                    
                </ul>`}
              ></SectionHeading>
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/employee-monitoring/Employee Onboarding.png")}
                  alt="Economies"
                  className="img-fluid w-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMonitoringService;
