import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const DomainCustomizationHero = () => {
  return (
    <>
      <div className="domainHero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <FullWidthHeading
                  sectionTitle="Domain Customization"
                  title="Ending with .com is just the beginning."
                  desc="SmartOffice is a cloud based HR Management solution with
                  common and futuristic business tools for modeling application."
                  btn="Free Signup"
                  btnLink={"http://apps.smartoffice.ai/register"}
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/domain-customization/DOMAIN CUSTOMIZATION.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainCustomizationHero;
