import React from "react";
import "../../assets/css/plan-page.css";
import { planData } from "../../assets/json/plan";
import BtnGroup from "./BtnGroup";
import PlanCriteria from "./PlanCriteria";

const ComparePlan = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="plan-table">

                {planData.map((item) => (

                  <>
                    <h5 className="text-primary fs-20 fw-600 mb-4 mt-4 text-start">
                      {item.criteria}
                    </h5>
                    <table className="table table-bordered">
              <tbody className="text-center">
                    <PlanCriteria data={item} />
                    </tbody>
            </table>
                  </>
                ))}
                {/* <tr className="bottom-tr">
                  <td></td>
                  <td></td>
                  <td data-label="free">
                    <div>
                      <BtnGroup
                        btnclassName={"btn btn-primary w-100"}
                        btnText={"Get Started"}
                      />
                      <BtnGroup
                        btnclassName={"btn btn-dark w-100"}
                        btnText={"Chat to sales"}
                      />
                    </div>
                  </td>

                  <td data-label="standard">
                    <div>
                      <BtnGroup
                        btnclassName={"btn btn-primary w-100"}
                        btnText={"Get Started"}
                      />
                      <BtnGroup
                        btnclassName={"btn btn-dark w-100"}
                        btnText={"Chat to sales"}
                      />
                    </div>
                  </td>
                  <td data-label="premium">
                    <div>
                      <BtnGroup
                        btnclassName={"btn btn-primary w-100"}
                        btnText={"Get Started"}
                      />
                      <BtnGroup
                        btnclassName={"btn btn-dark w-100"}
                        btnText={"Chat to sales"}
                      />
                    </div>
                  </td>
                  <td data-label="enterprise">
                    <div>
                      <BtnGroup
                        btnclassName={"btn btn-primary w-100"}
                        btnText={"Get Started"}
                      />
                      <BtnGroup
                        btnclassName={"btn btn-dark w-100"}
                        btnText={"Chat to sales"}
                      />
                    </div>
                  </td>
                </tr> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePlan;
