import React from "react";

const CompanyCard = ({img, title, desc}) => {
  return (
    <div className="company-card  card h-100">
      <div className="img-content text-center img-fluid mb-3">
        <img className="img-fluid"
          src={require(`../../assets/images/customer/${img}`)}
          alt=""
        />
      </div>
      <div className="text-content text-center">
        <h3 className="fs-20 fw-600">{title}</h3>
        <p className="fs-16 fw-400">
          {desc}
        </p>
      </div>
    </div>
  );
};

export default CompanyCard;
