import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import HeroAnimate from "./HeroAnimate";

const Hero = ({
  className,
  container,
  name,
  title,
  desc,
  img,
  btn,
  btn1,
  data,
  video
}) => {
  const [videoReady, setVideoReady] = useState(false);

  const handleCanPlay = () => {
    setVideoReady(true);
  };

  return (
    <div className={`hero-section ${className}`}>
      <div className={` ${container ? container : "container-fluid"}`}>
        <div className={`row align-items-center`}>
          <div className="col-12">
            <div className="hero-title-animate d-flex align-items-center justify-content-end flex-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row">
              <div className="hero-title max-w-546 position-relative -top-1">
                {name && <h6>{name}</h6>}
                <h2 className="fs-64 fw-800 mb-4 CSK-900">{title}</h2>
                <p className="fs-20 fw-500 mb-3">{desc}</p>
                <div
                  className="text hr-list-item"
                  dangerouslySetInnerHTML={{ __html: data }}
                />
                {btn1 && (
                  <Link
                    to="http://apps.smartoffice.ai/register"
                    className="btn btn-light radius-12 radius-8 w-50 mt-5"
                  >
                    {btn1}
                  </Link>
                )}
                {btn && (
                  <Link
                    to="http://apps.smartoffice.ai/register"
                    className="btn btn-primary radius-8 w-50 mt-5"
                  >
                    {btn}
                  </Link>
                )}
              </div>
              <div className="hero-title hero-animate">
                {img && (
                  <div className="img">
                  <LazyLoadImage
                    src={img}
                    PlaceholderSrc={img}
                    alt={"RECIPE LIBRARY"}
                    effect="blur"
                    loading="lazy"
                    className="img-fluid"
                  />
                    {/* <img src={img} alt="" className="img-fluid" /> */}
                  </div>
                )}
                {video && (
                  <HeroAnimate/>
                )}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Hero;
