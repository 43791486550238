import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NotificationBlockchain = ({bg}) => {
  return (
    <>
      <div className={`notification-blockchain ${bg}`}>
        <div className="container">
          <div className="container">
            <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
                <div className="img-content">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/feature/blockchain-img.png")}
                    alt="error"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
              <FullWidthHeading 
                 title={"Enhance Your Notification System with Blockchain Technology"}
                 desc={"Our customized notification system now incorporates blockchain technology to provide a more secure, reliable, and instant notification process. With blockchain, notifications are securely stored in a decentralized and immutable ledger, ensuring that messages and alerts are delivered securely and cannot be tampered with. The use of distributed ledgers ensures that notifications are always available and cannot be lost, while the use of smart contracts can automate the delivery process."}
                 data={' <a href="/smart-people/blockchain-technology" class="btn btn-primary radius-12 mt-3"> Learn More </a>'}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationBlockchain;
