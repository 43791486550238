const FeatureData =[
  {
    id: 0,
    icon: require("../images/keyfeature/icon-without-back/Payroll Management 1.png"),
    title: "Payroll Management",
    desc: "Efficiently process and manage employee salaries with automated calculations and deductions.",
    link: "/smart-people/payroll-management"
    },
  {
    id: 1,
    icon: require("../images/keyfeature/icon-without-back/Shift Management 1.png"),
    title: "Shift Management",
    desc: "Seamlessly schedule and organize employee shifts for optimized workforce planning.",
    link: "/smart-people/shift-management"
  },
  {
    id: 2,
    icon: require("../images/keyfeature/icon-without-back/Attendance Management 1.png"),
    title: "Attendance Management",
    desc: "Track employee attendance effortlessly and ensure accurate timekeeping for payroll purposes.",
    link: "/smart-people/attendance-management"
  },
  {
    id: 3,
    icon: require("../images/keyfeature/icon-without-back/Leave Management 1.png"),
    title: "Leave Management",
    desc: "Simplify and optimize the process of managing employee leaves and time-off requests with dedicated feature ensuring reliable, secure and fast operation",
    link: "/smart-people/leave-management"
  },
  {
    id: 4,
    icon: require("../images/keyfeature/icon-without-back/Employee Management 1.png"),
    title: "Employee Management",
    desc: "Centralize employee data, profiles, and documents for streamlined HR administration.",
    link: "/smart-people/employee-management"
  },
  {
    id: 5,
    icon: require("../images/keyfeature/icon-without-back/Tax Management 1.png"),
    title: "Tax Management",
    desc: "Effortlessly manage tax calculations and reporting to ensure compliance with regulatory requirements.",
    link: "/smart-people/tax-management"
  },
  {
    id: 6,
    icon: require("../images/keyfeature/icon-without-back/Domain Customization 1.png"),
    title: "Domain Customization",
    desc: "Customize your domain to maximized ownership to align with your company’s unique needs and branding.",
    link: "/smart-people/domain-customization"
  },
  {
    id: 7,
    icon: require("../images/keyfeature/icon-without-back/Block Chain 1.png"),
    title: "Blockchain Technology",
    desc: "keep user activity transparent, unchangeable, and immutable, reinforcing the integrity of your data like never before.",
    link: "/smart-people/blockchain-technology"
  },
  {
    id: 8,
    icon: require("../images/keyfeature/icon-without-back/Digital Noticeboard 1.png"),
    title: "Digital Notice Board",
    desc: "Share important announcements, updates, and company news on a centralized digital platform.",
    link: "/smart-people/digital-notice-board"
  },
  {
    id: 9,
    icon: require("../images/keyfeature/icon-without-back/Notification Center 1.png"),
    title: "Notification",
    desc: "Stay updated with real-time notifications on all HR activities in SmartPeople. Create customized alerts tailored to match your unique preferences.",
    link: "/smart-people/notification"
  },
    
  ]

  export { FeatureData }

