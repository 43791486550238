import React from "react";
import LegalNav from "../components/Legal/LegalNav";
import PolicyDetails from "../components/Legal/PolicyDetails";

const PolicyPage = () => {
  return (
    <div className=" legal-page">
      <LegalNav />
      <PolicyDetails />
    </div>
  );
};

export default PolicyPage;
