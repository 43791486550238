import React from "react";
import SectionHeading from "../../SectionHeading/SectionHeading";

const NotificationCoreHR = () => {
  return (
    <>
      <div className="notification-coreHR">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/notification/Event Source Selection.png")}
                  alt="Economies"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <SectionHeading
                fs="fs-40"
                title="Event Source Selection"
                desc="Communication without action is mere noise. SmartPeople elevates 
communication to a catalyst for progress by incorporating action 
or event source based custom notifications. Your alerts can be 
designed to immediately notify concerns, driving collaboration 
and delivering results in real-time. Each Notification Template allows 
You to choose an event for specific alerts."
                data={`<ul>
                    <li class="fs-16"><span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 3.24219V12.2422L13.5 9.99219L10.5 12.2422V3.24219" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.50002 20.4922C4.49878 20.1964 4.55613 19.9032 4.66876 19.6297C4.7814 19.3562 4.94708 19.1076 5.15626 18.8985C5.36544 18.6893 5.61397 18.5236 5.88752 18.411C6.16106 18.2983 6.4542 18.241 6.75002 18.2422H19.5V3.24221H6.75002C6.4542 3.24097 6.16106 3.29832 5.88752 3.41095C5.61397 3.52359 5.36544 3.68927 5.15626 3.89845C4.94708 4.10763 4.7814 4.35616 4.66876 4.6297C4.55613 4.90325 4.49878 5.19639 4.50002 5.49221V20.4922Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.5 20.4922V21.2422H18" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</span>Generate offer letter & handbook</li>
                    <li class="fs-16"><span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 10.9953V5.49219C3.75 5.29328 3.82902 5.10251 3.96967 4.96186C4.11032 4.82121 4.30109 4.74219 4.5 4.74219H19.5C19.6989 4.74219 19.8897 4.82121 20.0303 4.96186C20.171 5.10251 20.25 5.29328 20.25 5.49219V10.9953C20.25 18.8703 13.5656 21.4766 12.2344 21.9172C12.0831 21.9732 11.9169 21.9732 11.7656 21.9172C10.4344 21.4766 3.75 18.8703 3.75 10.9953Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</span>Run background check & E-Verify</li>
                    <li class="fs-16"><span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.99219V8.49219" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 15.9922V17.4922" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 21.2422C16.9706 21.2422 21 17.2128 21 12.2422C21 7.27162 16.9706 3.24219 12 3.24219C7.02944 3.24219 3 7.27162 3 12.2422C3 17.2128 7.02944 21.2422 12 21.2422Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.75 15.9922H13.125C13.6223 15.9922 14.0992 15.7946 14.4508 15.443C14.8025 15.0914 15 14.6145 15 14.1172C15 13.6199 14.8025 13.143 14.4508 12.7914C14.0992 12.4397 13.6223 12.2422 13.125 12.2422H10.875C10.3777 12.2422 9.90081 12.0446 9.54917 11.693C9.19754 11.3414 9 10.8645 9 10.3672C9 9.86991 9.19754 9.39299 9.54917 9.04136C9.90081 8.68973 10.3777 8.49219 10.875 8.49219H14.25" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</span>Add to payroll & prorate first check</li>
                    <li class="fs-16"><span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.63135 9.1081C4.11427 8.53743 3.83645 7.78977 3.8554 7.01991C3.87436 6.25005 4.18864 5.51697 4.73317 4.97243C5.27771 4.42789 6.01079 4.11361 6.78065 4.09466C7.55051 4.07571 8.29817 4.35353 8.86885 4.87061L19.3688 15.3706C19.8859 15.9413 20.1637 16.6889 20.1448 17.4588C20.1258 18.2287 19.8116 18.9617 19.267 19.5063C18.7225 20.0508 17.9894 20.3651 17.2195 20.3841C16.4497 20.403 15.702 20.1252 15.1313 19.6081L4.63135 9.1081Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.1313 4.87061C15.702 4.35353 16.4497 4.07571 17.2195 4.09466C17.9894 4.11361 18.7225 4.42789 19.267 4.97243C19.8116 5.51697 20.1258 6.25005 20.1448 7.01991C20.1637 7.78977 19.8859 8.53743 19.3688 9.1081L8.86885 19.6081C8.29817 20.1252 7.55051 20.403 6.78065 20.3841C6.01079 20.3651 5.27771 20.0508 4.73317 19.5063C4.18864 18.9617 3.87436 18.2287 3.8554 17.4588C3.83645 16.6889 4.11427 15.9413 4.63135 15.3706L15.1313 4.87061Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path opacity="0.5" d="M12 12.6172C12.2071 12.6172 12.375 12.4493 12.375 12.2422C12.375 12.0351 12.2071 11.8672 12 11.8672C11.7929 11.8672 11.625 12.0351 11.625 12.2422C11.625 12.4493 11.7929 12.6172 12 12.6172Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 13.3672C12.6213 13.3672 13.125 12.8635 13.125 12.2422C13.125 11.6209 12.6213 11.1172 12 11.1172C11.3787 11.1172 10.875 11.6209 10.875 12.2422C10.875 12.8635 11.3787 13.3672 12 13.3672Z" fill="#243757"/>
</svg>
</span>Enroll in health insurance & 401(K)</li>
                    <li class="fs-16"><span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.75 17C11.4069 17 12.75 15.6569 12.75 14C12.75 12.3431 11.4069 11 9.75 11C8.09315 11 6.75 12.3431 6.75 14C6.75 15.6569 8.09315 17 9.75 17Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.00625 20C5.43196 19.1022 6.1037 18.3436 6.94343 17.8124C7.78315 17.2813 8.75638 16.9993 9.75 16.9993C10.7436 16.9993 11.7168 17.2813 12.5566 17.8124C13.3963 18.3436 14.068 19.1022 14.4938 20H20.25C20.4489 20 20.6397 19.921 20.7803 19.7803C20.921 19.6397 21 19.4489 21 19.25V5.75C21 5.55109 20.921 5.36032 20.7803 5.21967C20.6397 5.07902 20.4489 5 20.25 5H3.75C3.55109 5 3.36032 5.07902 3.21967 5.21967C3.07902 5.36032 3 5.55109 3 5.75V19.25C3 19.4489 3.07902 19.6397 3.21967 19.7803C3.36032 19.921 3.55109 20 3.75 20H5.00625Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.5 17H18V8H6V9.5" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</span>Assign anti-sexual harassment training</li>
                    <li class="fs-16"><span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.125 11C8.98896 11 10.5 9.48896 10.5 7.625C10.5 5.76104 8.98896 4.25 7.125 4.25C5.26104 4.25 3.75 5.76104 3.75 7.625C3.75 9.48896 5.26104 11 7.125 11Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.875 11C18.739 11 20.25 9.48896 20.25 7.625C20.25 5.76104 18.739 4.25 16.875 4.25C15.011 4.25 13.5 5.76104 13.5 7.625C13.5 9.48896 15.011 11 16.875 11Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.125 20.75C8.98896 20.75 10.5 19.239 10.5 17.375C10.5 15.511 8.98896 14 7.125 14C5.26104 14 3.75 15.511 3.75 17.375C3.75 19.239 5.26104 20.75 7.125 20.75Z" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.875 14.75V20" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.5 17.375H14.25" stroke="#243757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</span>Set up apps (Slack, Zoom, etc.)</li>
                </ul>`}
              ></SectionHeading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationCoreHR;
