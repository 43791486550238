import React from "react";
import { useLocation } from "react-router-dom";

const PolicyDetails = () => {
  const location = useLocation();
  const reload = location.pathname;
  return (
    <div className="container mb-64 policy-desc">
      {reload === "/terms-condition" && <h4 className="title fs-20 text-dark fw-400 mb-4">
      <>
  <h1 style={{ textAlign: "center" }}>
    <strong>
      <span style={{ color: "#111111", fontSize: "23pt" }}>
        SmartOffice Terms and Conditions
      </span>
    </strong>
  </h1>
  <p style={{ textAlign: "center" }}>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        Effective from: December 1, 2023
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Welcome to the&nbsp;
    </span>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        SmartOffice,&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      hereby also referred to as ‘SmartOffice Platform’, ‘our’, ‘us’, ‘we’ or
      ‘SmartOffice’. These&nbsp;
    </span>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        Terms and Conditions&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      outline your rights and responsibilities as a valued user of our
      cloud-based SaaS services. Whether you’re an individual or representing an
      organization, please read these terms carefully.
    </span>
  </p>
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        1. Acceptance and Authority
      </span>
    </strong>
  </h2>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      By clicking “I agree” (or a similar button or checkbox) during sign-up or
      by accessing and using the SmartOffice Platform, you signify your
      acceptance of these terms. If you’re acting on behalf of your employer or
      another entity, ensure that you have the legal authority to bind them to
      these terms. If not, please refrain from accepting.
    </span>
  </p>
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>2. Your Role</span>
    </strong>
  </h2>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        2.1 Individual Users
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      : If you’re an individual user, these terms apply directly to you.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        2.2 Representatives
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      : If you’re accepting on behalf of an organization, you represent and
      warrant that you have the necessary authority to bind your employer or the
      relevant entity.
    </span>
  </p>
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        3. Cloud Services Access
      </span>
    </strong>
  </h2>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        3.1 Invitations
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      : If you’re invited or added to the SmartOffice Platform by another user,
      the User Notice governs your access.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        3.2 SmartOffice Entity
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      : These terms are between you and SmartOffice, the entity operating the
      SmartOffice Platform.
    </span>
  </p>
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        4. Binding effect
      </span>
    </strong>
  </h2>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        4.1 Email Addresses
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      : If you sign up using an email address associated with your employer or
      another entity:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          You represent that entity.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Your acceptance binds them to these terms.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          The term “you” refers to your employer or that entity.
        </span>
      </p>
    </li>
  </ul>
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        5. Effective Date
      </span>
    </strong>
  </h2>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      These terms take effect on the earlier of:
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>5.1&nbsp;</span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Your first click of “I agree” or a similar action.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>5.2&nbsp;</span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Your use or access of the SmartOffice Platform or any of its products.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Remember, no physical signature is required for these terms to be legally
      binding.
    </span>
  </p>
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        6. What These Terms Cover
      </span>
    </strong>
  </h2>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        6.1 Cloud Products
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      These Terms govern our Cloud Products, including SmartPeople HRMS, related
      Support, and Additional Services. By accessing or using any SaaS product
      provided by SmartOffice, you agree to comply with these Terms. Our
      Policies (including our Privacy Policy), the Product-Specific Terms, and
      your Orders are all part of these governing Terms.
    </span>
  </p>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        6.2 Product-Specific Terms
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Some Cloud Products may have additional terms specific to that product, as
      outlined in the Product-Specific Terms. When accessing or using a product
      covered by these specific terms, you also implicitly agree to those
      Product-Specific Terms.
    </span>
  </p>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        6.3 Expanded Coverage Addendum
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Certain qualifying Cloud Products fall under the Expanded Coverage
      Addendum, as specified in the relevant terms. If applicable, these
      additional provisions enhance the scope of coverage for specific services.
    </span>
  </p>
   <br />
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        7. How Cloud Products Are Administered
      </span>
    </strong>
  </h2>
   <br />
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        7.1 &nbsp;Administrators
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Through the SmartOffice Platform, you may specify certain End Users as
      Administrators, who will have important rights and controls over your use
      of the SmartOffice Platform and End User Accounts. This may include:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Making Orders for SmartOffice Platform or enabling Apps (which may
          incur fees).
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Creating, de-provisioning, monitoring, or modifying End User Accounts,
          and setting End User usage permissions.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Managing access to Your Data by End Users or others.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Taking over management of accounts previously registered using an
          email address belonging to your domain (which become “managed
          accounts”, as described in our Documentation).
        </span>
      </p>
    </li>
  </ul>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Without limiting Section 7.4 (Responsibility for End Users), which fully
      applies to Administrators, you are responsible for whom you allow to
      become Administrators and any actions they take, including as described
      above. You agree that our responsibilities do not extend to the internal
      management or administration of the SmartOffice Platform for you.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        7.2 &nbsp;Administrators
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      If you order SmartOffice Platform through a Reseller, then you are
      responsible for determining whether the Reseller may serve as an
      Administrator and for any related rights or obligations in your applicable
      agreement with the Reseller. As between you and SmartOffice, you are
      solely responsible for any access by Reseller to your accounts or your
      other End User Accounts.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        7.3 &nbsp;End User Consent
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You will provide all required disclosures to and will obtain and maintain
      all required consents from End Users to allow:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Administrators to have the access described in these Terms and the
          Privacy Policy.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          SmartOffice’s provision of the SmartOffice Platform to Administrators
          and End Users.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You will provide evidence of such consents upon our reasonable request.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        7.4 &nbsp;End User Consent
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      SmartOffice has various user onboarding flows. Some require users to be
      designated by Administrators, while others allow users to sign up for
      individual accounts that can later become associated with teams or
      organizations. You are responsible for understanding the settings and
      controls for each SmartOffice product you use and for controlling whom you
      allow to become an End User.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      If payment is required for End Users to use or access a SmartOffice
      product, then we are only required to provide the SmartOffice product to
      those End Users for whom you have paid the applicable fees. Only such End
      Users are permitted to access and use the SmartOffice products. Some
      SmartOffice products may allow you to designate different types of End
      Users, in which case pricing and functionality may vary according to the
      type of End User.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You are responsible for compliance with these Terms by all End Users,
      including for any payment obligations. Please note that you are
      responsible for the activities of all your End Users, including Orders
      they may place and how End Users use Your Data, even if those End Users
      are not from your organization or domain.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We may display our SaaS Agreement to End Users at sign-up, account
      creation, SmartOffice product registration. If you use single sign-on
      (SSO) for identity management of your SmartOffice product(s) such that End
      Users will bypass these screens and our SaaS Agreement, you are
      responsible for displaying our SaaS Agreement to End Users and for any
      damages resulting from your failure to do so.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        7.5 &nbsp;Credentials
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You must require that all End Users keep their user IDs and passwords for
      the SmartOffice products strictly confidential and do not share such
      information with any unauthorized person. User IDs are granted to
      individual, named persons and may not be shared. You are responsible for
      any and all actions taken using End User Accounts and passwords, and you
      agree to immediately notify us of any unauthorized use of which you become
      aware.
    </span>
  </p>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        7.6 Domain Name Ownership
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Where you are required to specify a domain for the operation of the
      SmartOffice product(s) or certain SmartOffice product features, we may
      verify that you own or control that domain. If you do not own or control
      the domain you specify, then we will have no obligation to provide you
      with the SmartOffice products or SmartOffice product features.
    </span>
  </p>
   <br />
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        8. &nbsp;What is Covered (Including the Limitations) by Your SmartOffice
        Subscription
      </span>
    </strong>
  </h2>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        8.1 Access to SmartOffice Product(s)
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      During the applicable Subscription Term, you may access and use the
      SmartOffice product(s) for your own business purposes or personal use, as
      applicable, all in accordance with these Terms, the applicable Order, and
      the Documentation. This includes the right, as part of your authorized use
      of the SmartOffice product, to download and use the client software
      associated with the SmartOffice product. The rights granted to you in this
      Section 8.1 are non-exclusive, non-sublicensable, and non-transferable.
    </span>
  </p>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        8.2 Restrictions
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      a. Reproduce, modify, adapt, or create derivative works of the SmartOffice
      product.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      b. Rent, lease, distribute, sell, sublicense, transfer, or provide access
      to the SmartOffice Platform to a third party.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      c. Use the SmartOffice product for the benefit of any third party.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      d. Incorporate any SmartOffice product into a product or service you
      provide to a third party.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      e. Interfere with or otherwise circumvent mechanisms in the SmartOffice
      product intended to limit your use.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      f. Reverse engineer, disassemble, decompile, translate, or otherwise seek
      to obtain or derive the source code, underlying ideas, algorithms, file
      formats, or non-public APIs to any SmartOffice product, except to the
      extent expressly permitted by applicable law (and then only upon advance
      notice to us).
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      g. Remove or obscure any proprietary or other notices contained in any
      SmartOffice product.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      h. Use the SmartOffice product for competitive analysis or to build
      competitive products.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      i. Encourage or assist any third party to do any of the foregoing.
    </span>
  </p>
   <br />
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        9. &nbsp;Our Policies Regarding Security and Data Privacy
      </span>
    </strong>
  </h2>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        9.1 Security and Certifications
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      At SmartOffice, we employ comprehensive physical, technical, and
      administrative security measures to safeguard Your Data from any
      unauthorized access, destruction, use, modification, or disclosure. Our
      commitment to security is evident through our compliance program, which
      involves rigorous third-party audits and certifications. For more insights
      into our security practices and certifications, please refer to our Trust
      Center.
    </span>
  </p>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>9.2 Privacy</span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      In the course of your and your End Users' interaction with the SmartOffice
      Platform, we gather specific data and information. This information is
      handled in strict accordance with our Privacy Policy, a document that you
      acknowledge and accept.
    </span>
  </p>
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        9.3 Improving SmartOffice Platform
      </span>
    </strong>
  </h3>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      To enhance user experience, we leverage advanced analytics techniques to
      gain deeper insights into how the SmartOffice Platform is utilized. For a
      detailed overview of these techniques and the types of data collected,
      please consult our Privacy Policy.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      &nbsp; &nbsp;&nbsp;
    </span>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        9.4 Compliance with Legal Obligations
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      While nothing in these Terms impedes us from disclosing Your Data as
      required by law, subpoenas, or court orders, we are committed to notifying
      you, to the extent permitted. We diligently balance your privacy rights
      with legal requirements. For detailed information on our policies
      regarding law enforcement requests for customer data, please refer to our
      Guidelines for Law Enforcement Requests.
    </span>
  </p>
   <br />
   <br />
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        10. &nbsp;Terms Applicable for Your Data
      </span>
    </strong>
  </h2>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        10.1 &nbsp;Utilizing Your Data for SmartOffice Products and Services
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You maintain full ownership of Your Data in the format submitted to the
      SmartOffice and its products. In accordance with these Terms and solely to
      facilitate the provision of SmartOffice products to you, you provide us
      with a global, limited-term license to access, utilize, process,
      replicate, distribute, perform, export, and exhibit Your Data. This
      license extends to the creation of modifications and derivative works, but
      only to the extent that reformatting Your Data for presentation in a Cloud
      Product constitutes such modifications. Additionally, we may access your
      accounts, End User Accounts, and your SmartOffice Products with End User
      permission to address your support requests.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        10.2 &nbsp;Compliance Responsibilities for Your Data
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You and your usage of SmartOffice Products (including usage by your End
      Users) must consistently adhere to these Terms and all applicable laws.
      You confirm and assure that: (i) you have acquired all necessary rights,
      releases, and permissions to submit all Your Data to the Cloud Products
      and grant the specified rights in these Terms; and (ii) Your Data, along
      with its submission and use as authorized in these Terms, will not
      infringe upon (1) any laws, (2) any third-party intellectual property,
      privacy, publicity, or other rights, or (3) any of your or third-party
      policies or terms governing Your Data. Apart from our explicit obligations
      under Section 4 (Our security and data privacy policies), we bear no
      responsibility or liability for Your Data, and you are solely accountable
      for Your Data and the consequences of its submission and use with the
      Cloud Products.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        10.3 &nbsp; Exclusion of Prohibited Sensitive Health Information
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You agree not to provide to the SmartOffie products (or utilize the
      products to process) any patient, medical, or other protected health
      information regulated by the Health Insurance Portability and
      Accountability Act or any similar health data protected under law, unless
      expressly authorized by SmartOffice in writing.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        10.4 &nbsp; Your Indemnification
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You will protect, indemnify, and exempt us (along with our Affiliates,
      officers, directors, agents, and employees) from any and all claims,
      expenses, damages, losses, liabilities, and costs (including reasonable
      attorneys' fees and costs) arising from claims related to (i) your
      violation of Section 7.3 (End User Consent) or any claims or disputes
      initiated by your End Users due to their use of Cloud Products, (ii) your
      violation (or alleged violation) of Sections 10.2 (Compliance
      Responsibilities for Your Data) or 10.3 (Exclusion of Prohibited Sensitive
      Health Information); or (iii) Your Materials. This obligation to indemnify
      is contingent upon you receiving (a) timely written notice of such claims
      (ensuring adequate time for you to respond without prejudice); (b) the
      exclusive right to manage and direct the investigation, defense, or
      settlement of such claims; and (c) all necessary cooperation from us at
      your expense.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        10.5 &nbsp; &nbsp;Removals and Suspension
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      While we are not obligated to actively monitor the content uploaded to
      SmartOffice products, we reserve the right to take action if we determine
      it is necessary due to your violation of these Terms, including Our
      Policies, or in response to legitimate takedown requests adhering to our
      guidelines for Reporting Copyright and Trademark Violations. This action
      may involve (1) the removal of Your Data from SmartOffice Products or (2)
      the suspension of your access to the cloud product(s). We will make
      reasonable efforts to provide you with advance notice of removals and
      suspensions when feasible. However, if we find that your actions pose a
      threat to the operation of SmartOffice products or the well-being of other
      users, we reserve the right to suspend your access or remove Your Data
      immediately without prior notice. We bear no liability to you for the
      removal or deletion of Your Data from, or the suspension of your access
      to, any cloud products as outlined in this Section 10.5.
    </span>
  </p>
   <br />
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        11. &nbsp;Using SmartOffice Developer Assets
      </span>
    </strong>
  </h2>
  <h2>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Usage of our developer assets, in any form, is subject to mutual agreement
      and costs and terms are subject to the same mutual agreement.&nbsp;
    </span>
  </h2>
   <br />
  <h2>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        12. &nbsp;Subscription Plans, Renewals, and Payment&nbsp;
      </span>
    </strong>
  </h2>
   <br />
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        12.1 &nbsp; &nbsp;Monthly and Annual Subscription Options
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      With the exception of No-Charge Products, all Cloud Products by
      SmartOffice are available on either a monthly or annual subscription
      basis.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        12.2 &nbsp; Subscription Renewals
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Unless otherwise specified in your Order, if neither party cancels your
      subscription before the current Subscription Term expires, it will
      automatically renew for another term equivalent to the initial
      Subscription Term. To cancel the subscription, you must follow the
      cancellation process specified in the account settings of the cloud
      products or by reaching out to our support team. Cancellation ensures that
      you won't be billed for the next billing cycle, but no refunds or credits
      will be issued for charges already incurred. All renewals are contingent
      on the continued availability of the relevant SmartOffice product and will
      be billed at the prevailing rates.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        12.3 &nbsp; Additional Users and Usage Adjustments
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You have the option to add users, increase storage limits, or expand your
      usage of SmartOffice products (if applicable) by placing a new Order or
      adjusting an existing one. Unless stated otherwise in the applicable
      Order, any such increases will be billed at our current rates, prorated
      for the remaining portion of the current subscription term.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        12.4 &nbsp;Payment Terms
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      All fees must be paid according to the terms specified in each Order, on
      the due dates, and in the currency outlined in the Order. Specific payment
      terms, including payment methods and due dates, are detailed in each Order
      document. By agreeing to these terms, you authorize us to charge your
      credit card or other designated payment method for renewals, additional
      users, overages, expenses, and any unpaid fees.
    </span>
  </p>
   <br />
  <h3>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        12.5 Delivery of SmartOffice Products
      </span>
    </strong>
  </h3>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The login instructions for SmartOffice products will be delivered to your
      account or through other reasonable means promptly upon our receipt of
      payment for the applicable fees. It is your responsibility to access your
      account to confirm that payment has been received and that your Order has
      been successfully processed. All deliveries specified in these Terms will
      be conducted electronically.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        13. Our Refund Policy
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      In line with our commitment to customer satisfaction and without limiting
      the warranty terms outlined in Section 18 (Warranty and Disclaimer), you
      have the option to terminate your initial Order for a SmartOffice cloud
      product under these Terms, for any reason or no reason, by notifying us
      within thirty (30) days of the order date for that specific cloud product.
      If you choose to terminate your initial Order as per Section 13, upon the
      approval of your request (which can be submitted through your account with
      us), we will refund the amount paid under that Order. It's important to
      note that this termination and refund option is applicable only to your
      first Order of the SmartOffice product and only if you exercise your
      termination right within the specified timeframe. This provision does not
      extend to additional services. Please be aware that we may modify this
      policy in the future in accordance with Section 23 (Amendments to the
      Terms and Conditions).
    </span>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span
        style={{
          color: "#111111",
          
          fontSize: "17pt"
        }}
      >
        14. Exclusion of Tax
      </span>
    </strong>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        &nbsp; &nbsp;&nbsp;
      </span>
    </strong>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        14.1 Applicability of Taxes and Duties
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The fees outlined in these Terms do not cover any taxes or duties
      associated with the SmartOffice cloud products. If applicable taxes or
      duties are our responsibility, you are obligated to pay the specified
      amount in addition to the fees stipulated in these Terms. If you have a
      tax exemption, provide the necessary details, and we will make reasonable
      efforts to facilitate invoicing for potential refunds or credits.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        14.2 Obligations for Applicable Taxes or Duties
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      If there are applicable taxes or duties related to the SmartOffice
      products and they are our responsibility, you, the client, are obligated
      to pay the specified amount in addition to the fees stipulated in these
      Terms.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        14.3 Tax Exemption: Tax Adjustment (Example)
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      In the event that you have a valid tax exemption, you would provide the
      necessary documentation to our billing department. Upon verification, we
      would then adjust the invoices accordingly, either providing a refund for
      taxes already paid or applying credits to future invoices.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        14.4 Payment Method
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Payment for SmartOffice products shall be made through the&nbsp;
    </span>
    <span
      style={{ color: "#111111",  fontSize: "12pt" }}
    >
      Stripe&nbsp;
    </span>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      payment gateway unless otherwise agreed upon in writing.
    </span>
  </p>
   <br />
  <ol>
    <li>
      <strong>
        <ul>
          <li
            style={{
              listStyleType: "lower-alpha",
              color: "#111111",
              fontSize: "12pt"
            }}
          >
            <p>
              <span style={{ color: "#111111", fontSize: "12pt" }}>
                Service Charges
              </span>
            </p>
          </li>
        </ul>
      </strong>
    </li>
  </ol>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The client acknowledges that Stripe may impose service charges for
      processing payments. These service charges are the responsibility of the
      client, and they will be in addition to the fees specified in these Terms.
    </span>
  </p>
   <br />
  <ol start={2}>
    <li>
      <strong>
        <ul>
          <li
            style={{
              listStyleType: "lower-alpha",
              color: "#111111",
              fontSize: "12pt"
            }}
          >
            <p>
              <span style={{ color: "#111111", fontSize: "12pt" }}>
                Adjustment for Service Charges
              </span>
            </p>
          </li>
        </ul>
      </strong>
    </li>
  </ol>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      If applicable taxes or duties are our responsibility (as per Section 14),
      and they are paid through Stripe, the client is obligated to pay the
      specified amount for both taxes/duties and any associated Stripe service
      charges.
    </span>
  </p>
   <br />
  <ol start={3}>
    <li>
      <strong>
        <ul>
          <li
            style={{
              listStyleType: "lower-alpha",
              color: "#111111",
              fontSize: "12pt"
            }}
          >
            <p>
              <span style={{ color: "#111111", fontSize: "12pt" }}>
                Example: Processing fees &amp; Charges
              </span>
            </p>
          </li>
        </ul>
      </strong>
    </li>
  </ol>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Suppose a monthly subscription fee of $100 is due, and the applicable
      taxes are $10. If the client pays through Stripe, and the Stripe service
      charge is 2%, the total payment due would be:
    </span>
  </p>
   <br />
  <ul>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Subscription Fee: $100
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>Taxes: $10</span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
      <p>
        <span style={{ color: "#111111", fontSize: "12pt" }}>
          Stripe Service Charge (2% of $110): $2.20
        </span>
      </p>
    </li>
  </ul>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The total payment through Stripe would be $112.20, with $100 going toward
      the subscription fee, $10 for taxes, and $2.20 for the Stripe service
      charge.
    </span>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        15. Intellectual Property Rights in SmartOffice Products and Feedback
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      SmartOffice products are provided on a limited access basis, and no
      ownership rights are transferred to you, regardless of the use of terms
      such as "purchase" or "sale." We and our licensors retain all rights,
      title, and interest, including intellectual property rights, in Our
      Technology (including the cloud products). If you choose to provide us
      with Feedback from time to time, we reserve the right to freely use, copy,
      disclose, license, distribute, and exploit the Feedback in any manner
      without any obligation, royalty, or restriction based on intellectual
      property rights or otherwise. Your Feedback will not be treated as your
      Confidential Information, and these Terms do not limit our right to
      independently use, develop, evaluate, or market products or services,
      whether incorporating Feedback or not.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        16. Confidentiality
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Unless otherwise specified in these Terms, both parties acknowledge that
      any code, inventions, know-how, and business, technical, and financial
      information disclosed by one party ("Disclosing Party") to the other party
      ("Receiving Party") constitute the confidential property of the Disclosing
      Party, hereinafter referred to as "Confidential Information." This
      acknowledgment holds true when the information is identified as
      confidential at the time of disclosure or should reasonably be known by
      the Receiving Party to be confidential or proprietary based on the nature
      of the information and the circumstances surrounding its disclosure. Our
      Technology and any performance-related information regarding the Cloud
      Products will be automatically deemed our Confidential Information without
      additional marking or designation. Unless expressly authorized in these
      Terms, the Receiving Party agrees to (1) maintain the confidentiality of
      and not disclose any Confidential Information to third parties and (2) use
      Confidential Information solely for fulfilling its obligations and
      exercising its rights under these Terms.&nbsp;
    </span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The Receiving Party may share Confidential Information with its employees,
      agents, contractors, and other representatives with a legitimate need to
      know, provided they are bound by confidentiality obligations at least as
      protective as those outlined in this Section 16, and the Receiving Party
      remains accountable for their compliance with the terms of this section.
      The Receiving Party's confidentiality obligations do not apply to
      information that:&nbsp;
    </span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      (i) was rightfully in its possession or known to it before receiving the
      Confidential Information;&nbsp;
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      (ii) becomes public knowledge without the Receiving Party's fault;&nbsp;
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      (iii) is rightfully obtained from a third party without violating any
      confidentiality obligation; or&nbsp;
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      (iv) is independently developed by the Receiving Party's employees with no
      access to the disclosed information.&nbsp;
    </span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The Receiving Party may disclose Confidential Information if required by
      regulation, law, or court order, but only to the minimum extent necessary
      to comply, and with advance notice to the Disclosing Party. The Receiving
      Party acknowledges that disclosing Confidential Information would cause
      significant harm for which damages alone would be insufficient, and
      therefore, upon any such disclosure, the Disclosing Party is entitled to
      appropriate equitable relief in addition to any other remedies available
      at law.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        17. Term and Termination
      </span>
    </strong>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>17.1 Duration</span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>&nbsp;</span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      These Terms become effective on the Effective Date and remain in force
      until the expiration or termination of all Subscription Terms.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        17.2 Termination for Cause
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Either party has the right to terminate these Terms (including all related
      Orders) under the following conditions: (a) failure to remedy any material
      breach of these Terms within thirty (30) days after notice; (b) cessation
      of operation without a successor; or (c) initiation of any bankruptcy,
      receivership, trust deed, creditors’ arrangement, composition, or similar
      proceeding, or if such a proceeding is brought against that party (and not
      dismissed within sixty (60) days thereafter).
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        17.3 Termination at Your Discretion
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You have the option to discontinue using the SmartOffice cloud products
      and terminate these Terms (including all Orders) at any time for any
      reason by providing written notice to us. However, unless you are
      exercising your right to terminate early under f the return policy
      section, upon such termination: (i) no refund of prepaid fees is
      applicable, and (ii) any outstanding fees for the current Subscription
      Term or related services period (as applicable) become immediately due and
      payable.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "13pt" }}>
        17.4. Consequences of Termination
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>&nbsp;</span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Following the expiration or termination of these Terms, you must cease
      using all SmartOffice cloud products and promptly delete (or, at our
      request, return) all Confidential Information or other materials of ours
      in your possession, including on third-party systems operated on your
      behalf. Certification of such deletion may be required upon our request.
      Your access to Your Data will cease (and we may delete it unless legally
      prohibited) after the expiration or termination of these Terms (or its
      applicable Subscription Term). Therefore, it is crucial to export Your
      Data using the products' functionality. If you terminate these Terms under
      Section 16.2 (Termination for Cause), we will refund any prepaid fees
      covering the remainder of the then-current Subscription Term after the
      effective date of termination. In the event we terminate these Terms under
      Section 16.2 (Termination for Cause), you are obligated to pay any unpaid
      fees (if any) covering the remainder of the then-current Subscription Term
      after the effective date of termination. Termination does not relieve you
      of the obligation to pay fees due to us for the period preceding the
      effective date of termination. Except where an exclusive remedy is
      specified in these Terms, the termination by either party, including
      termination for cause, will not prejudice any other remedies available
      under these Terms, by law, or otherwise.
    </span>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        17.5 Continuation
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>&nbsp;</span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The subsequent provisions will persist beyond any termination or
      expiration of these Terms: Sections 8.2 (Restrictions), 10.4 (Your
      Indemnification), 11.4 (Payment Terms), 14 (Exclusion of Tax), 15
      (Evaluations, trials, and betas) (pertaining to disclaimers and use
      restrictions only), 15 ( Intellectual Property Rights in SmartOffice
      Products and Feedback), 16 (Confidentiality), 17.4 (Consequences of
      Termination), 18 (Warranty and Disclaimer), 19 (Liability Limitations), 20
      (Indemnification for Intellectual Property) (limited to claims arising
      from your use of SmartOffice products during the Subscription Term), 22
      (Resolution of Dispute), and 25 (Miscellaneous Terms and Conditions).
    </span>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        18. &nbsp;Warranty and Disclaimer
      </span>
    </strong>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        18.1 Mutual Assurances
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Each party affirms and guarantees that it possesses the legal authority
      and capacity to enter into these Terms.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        18.2 Our Pledges
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We warrant, exclusively for your benefit, that we exert commercially
      reasonable efforts to prevent the introduction of viruses, Trojan horses,
      or similar harmful materials into the Cloud Products (though we are not
      liable for harmful materials submitted by you or End Users) (referred to
      as the “Performance Warranty”). This Performance Warranty is subject to
      specific criteria detailed in the product documentation (if available).
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        18.3 Remedy for Warranty
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We will make commercially reasonable efforts, at no cost to you, to
      rectify reported non-conformities with the Performance Warranty. If
      corrections are deemed impracticable, either party may terminate the
      applicable Subscription Term. In such instances, you will be refunded any
      pre-paid fees for the terminated portion of the applicable Subscription
      Term. The Performance Warranty is not applicable: (i) unless a claim is
      made within thirty (30) days of the date of first noticing the
      non-conformity, (ii) if the non-conformity results from misuse,
      unauthorized modifications, or third-party products, software, services,
      or equipment, or (iii) to No-Charge Products. Our singular liability, and
      your only remedy, for any breach of the Performance Warranty is outlined
      in this Section 18.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        18.4 Disclaimer of Warranty&nbsp;
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Except as expressly stated in this Section 17, all SmartOffice Cloud
      Products, support, and any additional services are provided "as is," and
      we and our suppliers specifically disclaim all warranties and
      representations of any kind, including any warranty of non-infringement,
      title, fitness for a particular purpose, functionality, or
      merchantability, whether express, implied, or statutory. Without limiting
      our specified obligations in these terms, we provide no guarantee that
      your use of SmartOffice Products will be uninterrupted or error-free, that
      we will review your data for accuracy, or that we will preserve or
      maintain your data without loss. You understand that the use of the
      SmartOffice Products involves the transmission of your data over networks
      that we do not own, operate, or control, and we are not responsible for
      any loss, alteration, interception, or storage of your data across such
      networks. We cannot guarantee that our security procedures will be
      error-free, that transmissions of your data will always be secure, or that
      unauthorized third parties will never defeat our security measures or
      those of our third-party service providers. We will not be liable for
      delays, interruptions, service failures, or other issues inherent in the
      use of the internet and electronic communications or other systems outside
      our reasonable control. You may have other statutory rights, but the
      duration of statutorily required warranties, if any, will be limited to
      the shortest period permitted by law.Except as expressly stated in this
      Section 18, all SmartOffice Cloud Products, support, and any additional
      services are provided "as is," and we and our suppliers specifically
      disclaim all warranties and representations of any kind, including any
      warranty of non-infringement, title, fitness for a particular purpose,
      functionality, or merchantability, whether express, implied, or statutory.
      Without limiting our specified obligations in these terms, we make no
      guarantee that your use of SmartOffice Products will be uninterrupted or
      error-free, that we will review your data for accuracy, or that we will
      preserve or maintain your data without loss. You understand that the use
      of the SmartOffice Products involves the transmission of your data over
      networks that we do not own, operate, or control, and we are not
      responsible for any loss, alteration, interception, or storage of your
      data across such networks. We cannot guarantee that our security
      procedures will be error-free, that transmissions of your data will always
      be secure, or that unauthorized third parties will never defeat our
      security measures or those of our third-party service providers. We will
      not be liable for delays, interruptions, service failures, or other issues
      inherent in the use of the internet and electronic communications or other
      systems outside our reasonable control. You may have other statutory
      rights, but the duration of statutorily required warranties, if any, will
      be limited to the shortest period permitted by law.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        19. &nbsp;Liability Limitations
      </span>
    </strong>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        19.1 Waiver of Consequential Damages
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      With the exception of excluded claims (as defined below in section 19.3),
      neither party (nor its suppliers) will be held liable under these terms
      for any loss of use, loss or inaccuracy of data, lost profits, failure of
      security mechanisms, business interruption, costs of delay, or any
      indirect, special, incidental, reliance, or consequential damages of any
      nature, even if advised of the possibility of such damages in advance
    </span>
    <span style={{ fontSize: "11pt" }}>.</span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        19.2 Capping of Liability
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Except for excluded claims, the combined liability of each party and its
      suppliers arising from or related to these terms will not surpass the
      amount actually paid by you to us for SmartOffice products and additional
      services that gave rise to the liability in the twelve (12) months
      immediately preceding the claim.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        19.3 Excluded Claims Definition
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      “Excluded Claims” refers to (1) amounts owed by you under any Orders, (2)
      the explicit indemnification obligations of either party in these Terms,
      and (3) your violation of Section 8.2 (Restrictions) or combining the
      products with Open Source Software of any third-party code in SmartOffice
      products.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        19.4 Failure of Primary Purpose and Nature of Claims
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The parties acknowledge that the waivers and restrictions outlined in this
      Section 18 are applicable regardless of the form of action, whether in
      contract, tort (including negligence), strict liability, or otherwise.
      These provisions will persist and be effective even if any limited remedy
      specified in these Terms is found to have failed in achieving its
      fundamental purpose.
    </span>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        20. &nbsp;Indemnification for Intellectual Property
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We undertake to defend you against any third-party claim asserting that
      SmartOffice Products, when used in accordance with these Terms, violate
      any third-party patent, copyright, or trademark, or unlawfully appropriate
      any third-party trade secret enforceable in any jurisdiction that is a
      signatory to the Berne Convention (referred to as a "Claim"). We will
      indemnify you and bear the costs and damages ultimately awarded in the
      Claim by a competent court or agreed upon in a settlement executed by us,
      including reasonable attorneys' fees. To activate this indemnification,
      you must provide us with: (a) prompt written notice of the Claim; (b)
      reasonable assistance in the defense and investigation of the Claim,
      including providing us with a copy of the Claim and all relevant evidence
      in your possession, custody, or control, and cooperating with evidentiary
      discovery, litigation, and trial, including making witnesses within your
      employ or control available for testimony; and (c) the exclusive right to
      control and direct the investigation, defense, and settlement (if
      applicable) of the Claim. If your use of the SmartOffice Products is (or
      is likely to be) restricted, either by court order or settlement, or if we
      reasonably determine such actions are necessary to avoid significant
      liability, we may, at our discretion: (i) secure the right for your
      continued use of the Cloud Product under these Terms; (ii) provide a
      substantially functionally similar Cloud Product; or (iii) terminate your
      right to continue using the Cloud Product and refund any prepaid amounts
      for the terminated portion of the Subscription Term. Our indemnification
      obligations do not apply in certain situations, including but not limited
      to: (1) when the total aggregate fees we receive for your subscription to
      a SmartOffice product in the twelve (12) months immediately preceding the
      Claim are less than USD 80,000; (2) if the SmartOffice cloud product is
      modified by any party other than us, but only to the extent the alleged
      infringement is caused by such modification; (3) if the Cloud Product is
      used in combination with any non-SmartOffice product, software, service,
      or equipment, but only to the extent the alleged infringement is caused by
      such combination; (4) to unauthorized use of SmartOffice Products; (5) to
      any Claim arising as a result of (y) Your Data or circumstances covered by
      your indemnification obligations in Section 10.4 (Your Indemnification) or
      (z) any third-party deliverables or components contained within the
      SmartOffice Products; or (6) if you settle or make any admissions with
      respect to a Claim without our prior written consent. This Section 19
      (Indemnification for Intellectual Property) constitutes our sole liability
      and your exclusive remedy for any infringement of intellectual property
      rights in connection with any SmartOffice product or other items we
      provide under these Terms.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        21. &nbsp;Right to Publicity
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We reserve the right to identify you as a SmartOffice customer in our
      promotional materials. We will promptly cease such identification upon
      receiving your request sent to us via our official email provided at the
      Contact Us page of this website.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        22. &nbsp;Resolution of Dispute&nbsp;
      </span>
    </strong>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        22.1 Informal Resolution
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      If any dispute or claim arises from or relates to these Terms, the parties
      will engage in consultations and negotiations, acknowledging their shared
      interests, with the aim of reaching a mutually satisfactory solution. If a
      settlement is not reached within sixty (60) days, either party may seek
      relief as provided under these Terms in accordance with Section 22.2
      (Governing Law and Jurisdiction). All discussions under this Section 22.1
      will be confidential and treated as negotiations for settlement, subject
      to the rules and codes of evidence of applicable legislation and
      jurisdictions.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        22.2 Governing Law and Jurisdiction
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      These Terms shall be governed by and construed in accordance with the laws
      of the jurisdiction where each party has its residence, domicile, or
      citizenship at the time of filing a petition or dispute, without regard to
      its conflict of laws principles. Each party irrevocably agrees that any
      legal action, suit, or proceeding arising from or related to these Terms
      must be brought exclusively in the competent courts of the jurisdiction
      where each party has its residence, domicile, or citizenship at the time
      of filing a petition or dispute and submits unconditionally to the
      personal jurisdiction of those courts.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        22.3 Injunctive Relief and Enforcement
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Despite the provisions of Section 22.1 (Informal Resolution) and 22.2
      (Governing Law and Jurisdiction), nothing in these Terms will prevent us
      from seeking injunctive relief for violations of intellectual property
      rights, confidentiality obligations, or enforcement or recognition of any
      award or order in any appropriate jurisdiction.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        22.4 Exclusion of UN Convention and UCITA
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The United Nations Convention on Contracts for the Sale of Goods does not
      apply to these Terms. The Uniform Computer Information Transactions Act
      (UCITA) will not apply to these Terms, regardless of when or where
      adopted.
    </span>
  </p>
   <br />
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        23. &nbsp;Amendments to the Terms and Conditions
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We reserve the right to make modifications to the terms and conditions of
      these Terms, including Our Policies, periodically. Notice of such
      modifications will be provided to you in accordance with Section 25.1
      (Communication) or by posting the revised Terms on our website.
      Concurrently with the notice, we will explicitly state the effective date
      of the proposed modifications. Modifications to these Terms will become
      effective upon the subsequent renewal of your Subscription Term, unless
      otherwise specified. If the effective date falls within your existing
      Subscription Term and you object to the changes, your exclusive remedy is
      to terminate the affected Orders within thirty (30) days of our
      notification.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        23.1 No-Charge Products
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      For continued use of the No-Charge Products, you are required to accept
      any modifications. In the event that you object to the proposed
      modifications, your sole recourse is to discontinue the use of the
      No-Charge Products.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        23.2 Paid Subscriptions
      </span>
    </strong>
  </p>

    <br />

  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Unless otherwise stipulated below, modifications to these Terms will
      become effective upon the subsequent renewal of your Subscription Term and
      will automatically apply from the renewal date, unless you choose not to
      renew in accordance with Section 12.2 (Subscription Renewals).
      Notwithstanding the foregoing, in certain instances (e.g., for compliance
      with Laws or the introduction of new features), we may specify that such
      modifications take effect during your ongoing Subscription Term.&nbsp;
    </span>
    <span
      style={{ color: "#111111",  fontSize: "12pt" }}
    >
      If the effective date of these modifications falls within your existing
      Subscription Term and you object to the changes, your exclusive remedy is
      to terminate the affected Orders upon notifying us. In such cases, we will
      reimburse any fees you have prepaid for the usage of the affected
      SmartOffice Products for the terminated portion of the applicable
      Subscription Term.
    </span>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      &nbsp;To exercise this right, you must notify us of your objection and
      termination within thirty (30) days of our notification of the
      modifications. It is clarified that any Order is subject to the version of
      these Terms that is in effect at the time of the Order.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        23.3 Policy Adjustments During Subscription Term
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      We reserve the right to modify Our Policies to become effective during
      your ongoing Subscription Term in response to alterations in our products,
      business operations, or Laws. In this context, unless mandated by Laws, we
      commit not to implement modifications to Our Policies that, when
      considered collectively, would significantly reduce our obligations during
      your current Subscription Term. Changes to Our Policies will automatically
      take effect on the specified effective date for the updated policies.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        24. &nbsp;Change or Modifications to the SmartOffice Products
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You recognize that the SmartOffice products are online, subscription-based
      services, and to enhance the customer experience, we may modify the Cloud
      Products and update the associated documentation. While we are committed
      to fulfilling SmartOffice Products under existing orders, please be aware
      that we reserve the right to cease any cloud products by SmartOffice,
      additional services, or specific components or features of SmartOffice
      products at any time and for any reason without incurring any liability to
      you.
    </span>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        25. &nbsp;Miscellaneous Terms and Conditions
      </span>
    </strong>
  </p>
   <br />
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.1 Communication
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Any communication under these Terms must be conveyed in writing. We
      reserve the right to notify you through your Notification Email Address,
      your account, or in-product notifications. You acknowledge that electronic
      communication meets the legal requirements for written communication.
      Notices to you will be considered delivered on the first business day
      after we send them. If you need to notify us, you can do so by our
      official email shared at the ‘Contact Us’ page.&nbsp;
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.2 Force Majeure
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Neither party will be held responsible for any delay or failure to fulfill
      obligations under these Terms (excluding a failure to pay fees) if the
      delay or failure is caused by events beyond the reasonable control of that
      party, such as strikes, blockades, war, acts of terrorism, riots, natural
      disasters, power or telecommunication failures, or government agency
      license refusals.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.3 Transfer of Rights
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>&nbsp;</span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      You cannot assign or transfer these Terms without our prior written
      consent. However, you can assign these Terms, including all Orders, to
      your successor resulting from a merger, acquisition, or sale of all or
      substantially all of your assets, provided you promptly notify us, and the
      assignee agrees in writing to assume all your obligations under these
      Terms. Any unauthorized attempt to transfer or assign these Terms will be
      void. We can assign our rights and obligations under these Terms without
      your consent.&nbsp;
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.4 Govt. End Users
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>&nbsp;</span>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Government entities, including but not limited to federal, state, or local
      governments, are subject to specific terms and conditions in accordance
      with applicable laws and regulations in their respective jurisdictions.
      These terms are in addition to and may supplement the standard terms
      outlined in this agreement.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.5 Comprehensiveness of Agreement
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      These Terms constitute the entire agreement between you and us regarding
      the SmartOffice products and any other subject matter covered by these
      Terms, superseding all prior or contemporaneous oral or written
      communications, proposals, and representations between you and us
      concerning the Smartoffice products or any other subject matter covered by
      these Terms. No provision of any purchase order or other business form
      used by you will override or supplement the terms and conditions of these
      Terms. Such documents related to these Terms are for administrative
      purposes only and have no legal effect.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.6 Resolution of Conflicts
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      In case of any conflict between the main body of these Terms and Our
      Policies or Product-Specific Terms, Our Policies or Product-Specific Terms
      (as applicable) will take precedence concerning their subject matter.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.7 Rights Preservation and Modifications&nbsp;
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The failure or delay of a party to exercise any right, power, or privilege
      under these Terms will not waive that right. A single or partial exercise
      of any right, power, or privilege will not prevent further exercises of
      that right, power, or privilege under these Terms, whether in law or
      equity. Any amendments or modifications to these Terms must be in writing
      and executed by an authorized representative of each party, except as
      specified in Section 23 (Amendments to the Terms and Conditions).
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.8 Inclusive Terminology and Interpretation
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      For the purposes herein, "including" (and its variations) means "including
      without limitation" (and its variations). Headings are provided for
      convenience only. If any provision of these Terms is found to be void,
      invalid, unenforceable, or illegal, the other provisions will remain in
      full force and effect.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        25.9 Legal Autonomy of the Parties
      </span>
    </strong>
  </p>
   <br />
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The parties are independent contractors. These Terms will not be construed
      to establish either party as a partner of the other or to create any other
      form of legal association that would grant either party the express or
      implied right, power, or authority to establish any duty or obligation of
      the other party.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "17pt" }}>
        26. &nbsp;Terminologies
      </span>
    </strong>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        SmartOffice Cloud Products/SmartOffice Products:&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Online, subscription-based services offered by SmartOffice, potentially
      including software, applications, and associated documentation provided
      through cloud technology.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        Subscription Term:&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The duration for which a user subscribes to and has access to SmartOffice
      Cloud Products, either on a monthly or annual basis.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        No-Charge Products:&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Products provided by SmartOffice without direct subscription fees,
      possibly with limitations on features or usage.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>Orders:&nbsp;</span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Formal requests or agreements made by users to purchase or subscribe to
      SmartOffice products or services.
    </span>
  </p>
  <p>
    <span style={{ color: "#111111", fontSize: "12pt" }}>Performance.</span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        Injunctive Relief:&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Legal remedy sought by a party to prevent violations of intellectual
      property rights or enforce specific actions.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        Force Majeure:&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      Unforeseeable circumstances or events beyond the control of the parties
      that may excuse non-performance of contractual obligations.
    </span>
  </p>
   <br />
  <p>
    <strong>
      <span style={{ color: "#111111", fontSize: "12pt" }}>
        Communication:&nbsp;
      </span>
    </strong>
    <span style={{ color: "#111111", fontSize: "12pt" }}>
      The exchange of written information between the parties, which may include
      notifications, updates, or other official correspondence.
    </span>
  </p>
   <br />
   <br />
   <br />
   <br />
   <br />
   <br />
   <br />
   <br />
   <br />
   <br />
</>

      </h4>}
     {reload === "/privacy-policy" && 
     <>
     <p>
       <span style={{ fontSize: "26pt" }}>SmartOffice Privacy Statement</span>
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "13.999999999999998pt" }}>
           Effective: November 20, 2023
         </span>
       </strong>
     </p>
     <p>
       <br />
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         At SmartOffice, we are committed to upholding the privacy and security of
         information as a fundamental right, irrespective of your nationality and
         geographic location. When you are using our services, we believe that you
         are entrusting us to handle your personal information safely. That endows
         us with the commitment to maintain transparency in regards to our
         collecting, using and sharing that information.&nbsp;
       </span>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         This SmartOffice Privacy Statement (“Privacy Statement”) describes how we
         collect, use, disclose, transfer, and store your personal data for the
         activities described below, including when you visit SmartOffice website
         that links to this Privacy Statement (“Website”), when you attend our
         marketing and learning events both online and offline (“Events”), and for
         our business account management. This Privacy Statement describes your
         choices and rights related to your personal data. A reference to
         “SmartOffice,” “we,” “us,”or the “Company” is a reference to SmartOffice.
         SmartOffice is the controller for the personal data discussed in this
         Privacy Statement, except as noted in the “SmartOffice as a Service
         Provider” section below.
       </span>
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "13.999999999999998pt" }}>
           SmartOffice as a Service Provider
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         SmartOffice customers are organizations such as businesses, NGOs or
         government offices, who use our services to help them manage personnel,
         students, and applicants. SmartOffice processes personal data in these
         services only according to our customers’ instructions. If you have
         questions about personal data you have entered into a SmartOffice service
         used by one of our customers, or want to exercise any of your rights
         regarding your personal data, our customer contract requires that we
         redirect your inquiry back to that SmartOffice customer.
       </span>
     </p>
     <p>
       <br />
     </p>
     
     <p>
       <br />
     </p>
     <p>
       <strong>
         <u>
           <span style={{ fontSize: "13.999999999999998pt" }}>
             Privacy Statement
           </span>
         </u>
       </strong>
     </p>
     <p>
       <br />
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>1.&nbsp;</span>
       <strong>
         <span style={{ color: "#111111", fontSize: "13pt" }}>
           Information we collect
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         At SmartOffice, we collect information to provide better services to our
         users. This allows us to deliver customized services like personalized
         advertising, which helps keep many of our services free, and analyze and
         understand how our services are used. As part of our ongoing efforts to
         ensure the best possible user experience, we may use this information to
         identify and fix problems with our services, and to improve the services
         we offer you.
       </span>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         We collect information in the following ways:
       </span>
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           1.1 Information shared by you
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         Our services require you to sign up for a SmartOffice account. When you
         do, we’ll ask for personal information, including but not limited to:
       </span>
     </p>
     <ul>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             Contact information (e.g., name, email address, phone number)
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             Employment-related information (e.g., job title, department, work
             history)
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             Account credentials
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             Demographic information
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             Any other information you voluntarily provide
           </span>
         </p>
       </li>
     </ul>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           1.2 Information we get from your use of our services
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         We collect data about how you interact with our services. This information
         helps us understand user behavior, enabling us to enhance the user
         experience and develop features that meet our users’ needs. Here’s what
         this entails:
       </span>
     </p>
     <ul>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Usage Information:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We collect data about your interactions with our services, such as
             features used, the settings you select, and the buttons you click.
             This helps us understand which aspects of our service are most
             valuable to our users.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Device Information:
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             &nbsp;We may collect specific information about the device you use to
             access our services, such as the hardware model, operating system, and
             version. This helps us optimize our services for common devices and
             operating systems.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Log Information:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             When you use our services, we automatically collect and store certain
             information in server logs. This includes details of how you used our
             service, IP address, and device event information such as crashes,
             system activity, hardware settings, browser type, browser language,
             the date and time of your request and referral URL.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Location Information:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may collect and process information about your geographical
             location. We use various technologies to determine location, including
             IP address, GPS, and other sensors that may provide information on
             nearby devices, Wi-Fi access points, and cell towers.
           </span>
         </p>
       </li>
     </ul>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           1.3 Information from third parties
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         In addition to the information you provide and the data we collect from
         your use of our services, we may also obtain information about you from
         third parties. Here’s how we use this information:
       </span>
     </p>
     <ul>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Third-Party Verification Services:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may use third-party verification services to authenticate the
             information you provide to us.&nbsp;
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Business Partners:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may receive information about you from our business partners, such
             as joint marketing partners and resellers, who help us in delivering
             our services.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Service Providers:
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             &nbsp;We may receive information from third-party service providers
             that help us with the provision of our services, such as payment
             processors, customer support providers, and analytics providers.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Publicly Available Sources:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may collect information about you that is publicly available. For
             example, if you post a review about our services on a public forum, we
             may collect and use this information to improve our services and
             better understand user feedback.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Social Media Platforms:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             If you choose to link, create, or log in to your SmartOffice account
             with a social media service (e.g., LinkedIn, Facebook, Twitter), the
             social media service may send us information such as your registration
             and profile information from that service. This information varies and
             is controlled by that service or as authorized by you via your privacy
             settings at that service.
           </span>
         </p>
       </li>
     </ul>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         Please note that we respect all applicable laws and regulations in our use
         of third-party data. We also require that these third parties comply with
         relevant laws and regulations and have appropriate safeguards in place to
         protect any information we obtain.
       </span>
     </p>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           1.4 Cookies and Similar Technologies
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         SmartOffice may use website tracking technologies to observe your
         activities, interactions, preferences, transactional information, and
         other computer and connection information (such as an IP address) relating
         to your use of our websites and services. We may also use log files,
         cookies, and similar technologies to collect information about the pages
         you view, links you click, and other actions you take when accessing our
         website or emails.
       </span>
     </p>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           2. How We Use Your Information
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         At SmartOffice, we use the information we collect from you in several
         ways:
       </span>
     </p>
     <ul>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Providing and Improving Our Services:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We use your information to provide the services you've requested,
             create and manage your account, execute transactions, respond to
             customer service requests, and monitor and improve our services. For
             example, we may use your information to understand which features of
             our SaaS services are most popular and useful to our users, and use
             this insight to guide our future development efforts.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Personalizing Your Experience:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We use your information to personalize the SmartOffice experience for
             you. This could include presenting tailored content or recommendations
             that we believe will be of interest to you, based on the information
             we have about your usage patterns and preferences.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Communicating with You:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We use your information to communicate with you about your account and
             our services. This could include responding to your inquiries,
             notifying you of any changes to our services, and sending you
             technical notices, updates, security alerts, and administrative
             messages. We may also send you marketing communications about
             products, services, offers, programs, and promotions of SmartOffice
             and our partners.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Conducting Research and Analysis:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We use your information for research and analysis purposes. We may use
             third-party service providers to help us with our research and
             analysis, but we only allow them to use your information to perform
             these services on our behalf. Our research and analysis activities are
             designed to help us understand our users, improve our services, and
             develop new features and services.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Complying with Legal Obligations:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We use your information to comply with legal obligations to which we
             are subject. This could include complying with legal process,
             responding to requests from public and government authorities,
             enforcing our terms and conditions, protecting our operations, and
             protecting our rights, privacy, safety, or property, and/or that of
             you or others.
           </span>
         </p>
       </li>
     </ul>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         Please note that we may use your information for other purposes that are
         compatible with the purposes we have disclosed to you, and that are
         related to the services we provide.
       </span>
     </p>
     <p>
       <br />
     </p>
     <p>
       <br />
     </p>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           3. How We Share Your Information
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         At SmartOffice, we value your privacy and only share your information
         under certain circumstances:
       </span>
     </p>
     <ul>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Third-Party Service Providers:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may share your information with third-party service providers that
             perform services on our behalf. These services could include data
             analysis, payment processing, information technology and related
             infrastructure provision, customer service, email delivery, auditing,
             and other similar services. These third-party service providers are
             only allowed to use your information to the extent necessary to
             provide the requested services.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Business Partners:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may share your information with our business partners in the
             context of our joint marketing efforts or other joint ventures. This
             could include partners who offer products or services that complement
             our own, or partners with whom we collaborate on the development of
             new products or services.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Affiliates:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may share your information with our affiliates, which are companies
             under common ownership or control with SmartOffice. Our affiliates may
             use this information for their everyday business purposes.
           </span>
         </p>
       </li>
       <li style={{ listStyleType: "disc", color: "#111111", fontSize: "12pt" }}>
         <p>
           <strong>
             <span style={{ color: "#111111", fontSize: "12pt" }}>
               Legal Authorities:&nbsp;
             </span>
           </strong>
           <span style={{ color: "#111111", fontSize: "12pt" }}>
             We may disclose your information to legal authorities when required by
             law or in response to a legal process, such as a court order or
             subpoena. We may also disclose your information if we believe it’s
             necessary to prevent, investigate, or take action regarding illegal
             activities, suspected fraud, situations involving potential threats to
             the safety of any person, or as evidence in litigation in which
             SmartOffice is involved.
           </span>
         </p>
       </li>
     </ul>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         Please note that we take care to allow your information to be accessed
         only by those who really need to in order to perform their tasks and
         duties, and to share with third parties who have a legitimate purpose for
         accessing it. We will never sell or rent your information.
       </span>
     </p>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>4. Security</span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         We implement reasonable security measures to protect your information from
         unauthorized access, disclosure, alteration, and destruction. However, no
         method of transmission over the internet or electronic storage is 100%
         secure. Therefore, we cannot guarantee its absolute security.
       </span>
     </p>
     <p>
       <br />
     </p>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           5. Your Choices
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         You have choices regarding the collection and use of your information. You
         can:
       </span>
     </p>
     <ul>
       <li>
         <strong>
           <ul>
             <li
               style={{
                 listStyleType: "disc",
                 color: "#111111",
                 fontSize: "12pt"
               }}
             >
               <p>
                 <span style={{ color: "#111111", fontSize: "12pt" }}>
                   Opt out of certain data collection:&nbsp;
                 </span>
                 <span style={{ color: "#111111", fontSize: "12pt" }}>
                   : If you prefer that we do not collect certain types of
                   information, you have the option to opt out. Please note that
                   some types of data are necessary for us to provide you with our
                   services, and if you choose to opt out, it may affect the
                   quality of your experience.
                 </span>
               </p>
             </li>
           </ul>
         </strong>
         <strong>
           <ul>
             <li
               style={{
                 listStyleType: "disc",
                 color: "#111111",
                 fontSize: "12pt"
               }}
             >
               <p>
                 <span style={{ color: "#111111", fontSize: "12pt" }}>
                   Update or delete your account information:&nbsp;
                 </span>
                 <span style={{ color: "#111111", fontSize: "12pt" }}>
                   : You can review, update, or delete the personal information
                   associated with your account at any time. To do this, simply log
                   in to your account, go to your account settings, and make the
                   necessary changes. If you wish to delete your account entirely,
                   you can do so in the account settings. Please note that deleting
                   your account will result in the loss of all data and content
                   associated with that account, and we may not be able to recover
                   it.
                 </span>
               </p>
             </li>
           </ul>
         </strong>
       </li>
     </ul>
     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>
           6. Changes to this Privacy Statement
         </span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         SmartOffice reserves the right to modify this Privacy Statement at any
         time in accordance with this provision. If we make changes to this Privacy
         Statement, we will post the revised Privacy Statement on the SmartOffice
         platform and update the “Last Updated” date at the top of this Privacy
         Statement. We will also provide you with notice of the modification by
         email at least thirty&nbsp;
       </span>
       <span
         style={{ color: "#111111", fontSize: "12pt" }}
       >
         (30)&nbsp;
       </span>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         days before the date the changes become effective. If you disagree with
         the revised Privacy Statement, you may cancel your account. If you do not
         cancel your account before the revised Privacy Statement becomes
         effective, your continued access to or use of the SmartOffice platform
         will constitute acceptance of the revised Privacy Statement.
       </span>
     </p>
     <p>
       <br />
     </p>


     <p>
       <strong>
         <span style={{ color: "#111111", fontSize: "12pt" }}>7. Consent</span>
       </strong>
     </p>
     <p>
       <span style={{ color: "#111111", fontSize: "12pt" }}>
         By choosing to visit or use SmartOffice website or any of its services,
         you consent to the terms of this Privacy Statement.
       </span>
     </p>
     <p>
       <br />
     </p>


     <p>
       <u>
         <span style={{ color: "#1155cc", fontSize: "12pt" }}>
           Download or print a copy of the SmartOffice Privacy Statement
         </span>
       </u>
     </p>
   </>
   
   
     }
      
    </div>
  );
};

export default PolicyDetails;
