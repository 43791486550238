const BlockSecurityIntegrateData = [
    {
        id: 0,
        icon: require("./../images/feature/blockchain/Unprecedented Visibility.png"),
        title: "Unprecedented Visibility        ",
        desc: "Through the implementation of blockchain technology, SmartPeople offers authorities and administrators unprecedented visibility into user activity. The transparent nature of blockchain allows every action to be easily traced back to its origin, granting a clear and comprehensive audit trail.        ",
        link: "#"
    },
    {
        id: 1,
        icon: require("./../images/feature/blockchain/Enhanced Accountability.png"),
        title: "Enhanced Accountability        ",
        desc: "With the help of blockchain, SmartPeople ensures enhanced accountability throughout the platform. Administrators can track and verify each user action, leaving no room for ambiguity. This heightened level of accountability promotes a secure and trustworthy environment for all users.        ",
        link: "#"
    },
    {
        id: 2,
        icon: require("./../images/feature/blockchain/Swift Identification of Unusual Activity.png"),
        title: "Swift Identification of Unusual Activity",
        desc: "The transparency provided by blockchain enables quick identification of any unusual or unwanted activity within SmartPeople. Administrators can swiftly detect and address any potential security breaches or unauthorized actions, ensuring the platform remains safe and reliable for everyone involved.        ",
        link: "#"
    },
]

export { BlockSecurityIntegrateData };

