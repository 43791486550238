import React from "react";
import BannerText from "../../Banner/BannerText";

const AttendanceManagementHero = () => {
  return (
    <>
      <div className="AttendanceManagement-hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <BannerText
                sectionTitle="ATTENDANCE MANAGEMENT"
                title="Revamp 
attendance 
tracking Today
"
                desc="SmartOffice is a cloud based HR Management solution with common and futuristic business tools for modeling applications."
                btnText="Free Signup"
              />
            </div>
            <div className="col-lg-7">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/attendance-management/attendance-hero.png")}
                  alt="attendance-hero"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceManagementHero;
