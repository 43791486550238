import React from "react";
import SectionHeading from "../../SectionHeading/SectionHeading";

const EmployeeMonitoringTrackingReport = () => {
  return (
    <>
      <div className="notification-coreHR blockSecurity-TrackingReport employee">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <SectionHeading
                fs="fs-40"
                title="Employee Groups
"
                desc="Breaking free from traditional departmental silos, 
this cutting-edge tool empowers your organization 
to build synergistic teams that transcend boundaries. 
Join us as we explore the art of strategic employee 
grouping with SmartPeople, redefining the way you  
foster collaboration and innovation.
"
                data={`<ul>
                    <li class="fs-16"><div><b>Empower Your Organization: </b>
In the quest for excellence, SmartPeople 
recognizes the significance of fostering 
diverse and agile teams. 
Gone are the days of restricting collaboration 
within departmental boundaries. 
Our Customized Employee Grouping feature 
empowers you to assemble teams that transcend 
traditional structures, unleashing the potential of 
cross-functional brilliance.</div>
</li>
                    <li class="fs-16"><div><b>Breaking Barriers, Forging Bonds: </b>
Customized Employee Grouping is more than 
just a feature; it's a philosophy. By breaking 
barriers and forging bonds between employees 
across departments, SmartPeople fosters a 
culture of inclusivity and collaboration. 
Experience the camaraderie that transcends 
artificial boundaries, as your teams unite to 
conquer challenges together.</div>
</li>
                    
                </ul>`}
              ></SectionHeading>
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/employee-monitoring/Employee Groups.png")}
                  alt="Economies"
                  className="img-fluid w-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMonitoringTrackingReport;
