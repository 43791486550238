const VisualizationAndNavigationData = [
  {
    id: 0,
    icon: require("../images/feature/organogram-management/MapTrifold.png"),
    title: "Real-Time Visualization",
    desc: "Keep track of organizational changes as they occur.",
  },
  {
    id: 1,
    icon: require("../images/feature/organogram-management/ChartBar.png"),
    title: "Zoom In and Out",
    desc: "Effortlessly adjust the level of detail for an overarching or detailed view.",
  },
  {
    id: 2,
    icon: require("../images/feature/organogram-management/pan-across-chart.png"),
    title: "Pan Across the Chart",
    desc: "Navigate through the organizational chart seamlessly.",
  },
];

export { VisualizationAndNavigationData };
