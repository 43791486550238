import React from "react";

const RacipeLibrarySlider = () => {
  const line1 = [
    {
      id: 0,
      img: "uae.png",
      alt: "Tax Template (UAE)",
      title: "Tax Template (UAE)",
      desc: "As there is no tax applicable in the UAE SmartPeople is completely ready for Business for the UAE. If there are other deduction it can be managed per your organizations requirement.",
      cat: "Automation"
    },
    {
      id: 1,
      img: "canada.png",
      alt: "Tax Template (Canada)",
      title: "Tax Template (Canada)",
      desc: "We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for Canada. Ensure payroll precision, and comply with Canadian regulations using our upcoming templates.",
      cat: "Automation"
    },
    {
      id: 2,
      img: "usa.png",
      alt: "Tax Template (USA)",
      title: "Tax Template (USA)",
      desc: "We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for USA. Ensure payroll precision, and comply with USA regulations using our upcoming templates.",
      cat: "Automation"
    },
    {
      id: 3,
      img: "australia.png",
      alt: "Tax Template (Australia)",
      title: "Tax Template (Australia)",
      desc: `We believe in providing the best
      HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for Australia. Ensure payroll precision, and
      comply with Australia regulations using our upcoming templates.`,
      cat: "Automation"
    },
    {
      id: 4,
      img: "uk.png",
      alt: "Tax Template (UK)",
      title: "Tax Template (UK)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for UK. Ensure payroll
      precision, and comply with UK regulations using our upcoming templates`,
      cat: "Automation"
    },
    {
      id: 5,
      img: "bangladesh.png",
      alt: "Tax Template (Bangladesh)",
      title: "Tax Template (Bangladesh)",
      desc: `SmartPeople offers a tailored
      Tax Calculation Template specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    }
  ];
  const line2 = [
    {
      id: 0,
      img: "bangladesh.png",
      alt: "Tax Template (Bangladesh)",
      title: "Tax Template (Bangladesh)",
      desc: `SmartPeople offers a tailored Tax Calculation Template specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    },
    {
      id: 1,
      img: "Notification Center 1.png",
      alt: "Custom Notification",
      title: "Custom Notification",
      desc: `With our advanced notification template feature, SmartPeople empowers you to create personalized and impactful communications effortlessly.`,
      cat: "Automation"
    },
    {
      id: 2,
      img: "Tax Management 1.png",
      alt: "Tax Calculation",
      title: "Tax Calculation",
      desc: `Template
      SmartPeople offers a tailored Tax Calculation Template
      specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    },
    {
      id: 3,
      img: "Notification Center 1.png",
      alt: "Custom Notification",
      title: "Custom Notification",
      desc: `Say goodbye to generic messages and embrace tailor-made notifications that suit your organization's`,
      cat: "Automation"
    },
    {
      id: 4,
      img: "uae.png",
      alt: "Tax Template (UAE)",
      title: "Tax Template (UAE)",
      desc: `As there is no tax applicable in the UAE SmartPeople is completely ready for Business for the UAE. If there are other deduction it can be managed per your organizations requirement.`,
      cat: "Automation"
    },
    {
      id: 5,
      img: "canada.png",
      alt: "Tax Template (Canada)",
      title: "Tax Template (Canada)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for Canada. Ensure payroll precision, and comply with Canadian regulations using our upcoming templates.`,
      cat: "Automation"
    }
  ];
  const line3 = [
    {
      id: 0,
      img: "Tax Management 1.png",
      alt: "Tax Calculation Template",
      title: "Tax Calculation Template",
      desc: `SmartPeople offers a tailored Tax Calculation Template specifically designed for businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    },
    {
      id: 1,
      img: "Notification Center 1.png",
      alt: "Custom Notification",
      title: "Custom Notification",
      desc: `Say goodbye to generic messages and embrace tailor-made notifications
      that suit your organization's`,
      cat: "Automation"
    },
    {
      id: 2,
      img: "bangladesh.png",
      alt: "Tax Template (Bangladesh)",
      title: "Tax Template (Bangladesh)",
      desc: `SmartPeople offers a tailored Tax Calculation Template
      specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    },
    {
      id: 3,
      img: "uk.png",
      alt: "Tax Template (UK)",
      title: "Tax Template (UK)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for UK. Ensure payroll precision, and comply with UK regulations using our upcoming templates`,
      cat: "Automation"
    },
    {
      id: 4,
      img: "australia.png",
      alt: "Tax Template (Australia)",
      title: "Tax Template (Australia)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for Australia. Ensure payroll precision, and
      comply with Australia regulations using our upcoming templates.`,
      cat: "Automation"
    },
    {
      id: 5,
      img: "usa.png",
      alt: "Tax Template (USA)",
      title: "Tax Template (USA)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for USA. Ensure
      payroll precision, and comply with USA regulations using our upcoming templates.`,
      cat: "Automation"
    }
  ];
  const line4 = [
    {
      id: 0,
      img: "canada.png",
      alt: "Tax Template (Canada)",
      title: "Tax Template (Canada)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for Canada.
      Ensure payroll precision, and
      comply with Canadian
      regulations using our upcoming templates.`,
      cat: "Automation"
    },
    {
      id: 1,
      img: "uae.png",
      alt: "Tax Template (UAE)",
      title: "Tax Template (UAE)",
      desc: `As there is no tax applicable in the UAE SmartPeople is completely ready for Business for the UAE. If there are other deduction it can be managed per your organizations requirement.`,
      cat: "Automation"
    },
    {
      id: 2,
      img: "Tax Management 1.png",
      alt: "Tax Calculation",
      title: "Tax Calculation",
      desc: `Template
      SmartPeople offers a tailored
      Tax Calculation Template
      specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.
      `,
      cat: "Automation"
    },
    {
      id: 3,
      img: "Notification Center 1.png",
      alt: "Custom Notification",
      title: "Custom Notification",
      desc: `Say goodbye to generic messages and embrace tailor-made notifications that suit your organization's`,
      cat: "Automation"
    },
    {
      id: 4,
      img: "bangladesh.png",
      alt: "Tax Template (Bangladesh)",
      title: "Tax Template (Bangladesh)",
      desc: `SmartPeople offers a tailored
      Tax Calculation Template specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    },
    {
      id: 5,
      img: "Notification Center 1.png",
      alt: "Custom Notification",
      title: "Custom Notification",
      desc: `Say goodbye to generic messages and embrace tailor-made notifications that suit your organization's`,
      cat: "Automation"
    }
  ];
  const line5 = [
    {
      id: 0,
      img: "usa.png",
      alt: "Tax Template (USA)",
      title: "Tax Template (USA)",
      desc: "We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for USA. Ensure payroll precision, and comply with USA regulations using our upcoming templates.",
      cat: "Automation"
    },
    {
      id: 1,
      img: "australia.png",
      alt: "Tax Template (Australia)",
      title: "Tax Template (Australia)",
      desc: `We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for Australia. Ensure payroll precision, and
      comply with Australia regulations using our upcoming templates.`,
      cat: "Automation"
    },
    {
      id: 2,
      img: "uk.png",
      alt: "Tax Template (UK)",
      title: "Tax Template (UK)",
      desc: "We believe in providing the best HR solutions for our customers, and that's why we're currently crafting prebuilt templates exclusively for UK. Ensure payroll precision, and comply with UK regulations using our upcoming templates.",
      cat: "Automation"
    },
    {
      id: 3,
      img: "bangladesh.png",
      alt: "Tax Template (Bangladesh)",
      title: "Tax Template (Bangladesh)",
      desc: `SmartPeople offers a tailored Tax Calculation Template
      specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    },
    {
      id: 4,
      img: "Notification Center 1.png",
      alt: "Custom Notification",
      title: "Custom Notification",
      desc: `With our advanced notification template feature, SmartPeople empowers you to create
      personalized and impactful communications effortlessly.`,
      cat: "Automation"
    },
    {
      id: 5,
      img: "Tax Management 1.png",
      alt: "Tax Calculation",
      title: "Tax Calculation",
      desc: `Template SmartPeople offers a tailored
      Tax Calculation Template specifically designed for
      businesses that accurately
      calculates taxes and deductions
      according to local regulations.`,
      cat: "Automation"
    }
  ];
  return (
    <>
      <div className="hero-title hero-animate">
        <div className="logo-slider">
          <div className="marquee-wrap">
            <div className="marquee-block">
              <div className="marquee-inner to-left">
                <span className="mb-5 d-block">
                  {line1.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
                <span>
                  {line1.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
              </div>
            </div>
            <div className="marquee-block">
              <div className="marquee-inner to-right">
                <span className="mb-5 d-block">
                {line2.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
                <span>
                {line2.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
              </div>
            </div>
            <div className="marquee-block">
              <div className="marquee-inner to-left">
                <span className="mb-5 d-block">
                {line3.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
                <span>
                {line3.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
              </div>
            </div>
            <div className="marquee-block">
              <div className="marquee-inner to-right">
                <span className="mb-5 d-block">
                {line4.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
                <span>
                {line4.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
              </div>
            </div>
            <div className="marquee-block">
              <div className="marquee-inner to-left">
                <span className="mb-5 d-block">
                {line5.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
                <span>
                {line5.map((item) => (
                    <div key={item.id} className="marquee-item">
                      <div className="icon">
                        <img
                          src={require(`./../../assets/images/recipe-library/${item.img}`)}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="content">
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                        <span>{item.cat}</span>
                      </div>
                    </div>
                  ))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="marquee-block">
        <div className="marquee-inner to-left">
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide1.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide1.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="marquee-block">
        <div className="marquee-inner to-right">
          <span>
            <div className="marquee-item">
              <div className="img w-100">
                <img
                  src={require("./../../assets/images/recipe-library/slide2.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide2.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="marquee-block">
        <div className="marquee-inner to-left">
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide3.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide3.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="marquee-block">
        <div className="marquee-inner to-right">
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide4.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide4.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="marquee-block">
        <div className="marquee-inner to-left">
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide5.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
          <span>
            <div className="marquee-item">
              <div className="img">
                <img
                  src={require("./../../assets/images/recipe-library/slide5.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </span>
        </div>
      </div> */}
    </>
  );
};

export default RacipeLibrarySlider;
