import React from "react";
import AboutCoreHR from "../components/About/AboutCoreHR";
import AboutDriven from "../components/About/AboutDriven";
import AboutHero from "../components/About/AboutHero";
import AboutPayroll from "../components/About/AboutPayroll";
import AboutUs from "../components/About/AboutUs";
import AboutWorks from "../components/About/AboutWorks";
import "./../assets/css/about.css";

const About = () => {
  return (
    <>
      <AboutHero />
      <AboutUs />
      <AboutDriven />
      <AboutPayroll />
      <AboutWorks />
      <AboutCoreHR />
    </>
  );
};

export default About;
