const AttendanceBiometricData = [
  {
    id: 0,
    icon: require("../images/feature/biometric1.png"),
    title: "Face Recognition"
  },
  {
    id: 1,
    icon: require("../images/feature/biometric2.png"),
    title: "Biometric"
  },
  {
    id: 2,
    icon: require("../images/feature/biometric3.png"),
    title: "Smartcard Tag"
  }
];

export { AttendanceBiometricData };

