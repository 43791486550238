import React from "react";
import BannerText from "../../Banner/BannerText";

const Hero = () => {
  return (
    <>
      <div className="shiftManagement-hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg-6">
              <BannerText
                sectionTitle="SMARTOFFICE SHIFT MANAGEMENT"
                title="Your Scheduling 
Chaos ends here"
                desc="SmartOffice is an innovative cloud-based HR Management solution equipped with a wide range of common and futuristic business tools for seamless application and enhanced performance."
                btnText="Get started"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/shift-management/Shift Management.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
