import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { Link, useHref } from "react-router-dom";

const SingleProductTrainingArticle = () => {
  const slug = useHref();
  const newSlug = slug.split("/");
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");

  const copyLinkToClipboard = () => {
    const currentURL = window.location.href;

    // Create a temporary input element
    const input = document.createElement("input");
    input.value = currentURL;

    // Append the input element to the document
    // document.body.appendChild(input);

    // Select the input's value
    // input.select();

    // Copy the selected URL to the clipboard
    document.execCommand("copy");
    setShow(true);

    // Remove the temporary input element
    // document.body.removeChild(input);
    // Optionally, provide user feedback
    // alert("Link copied to clipboard: " + currentURL);
    setUrl(currentURL);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const contentToPrint = document.getElementById("divToPrint");

    if (printWindow) {
      printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
      </head>
      <body>
        ${contentToPrint.outerHTML}
      </body>
    </html>
  `);

      printWindow.document.close();

      // Delay the print function to ensure the print dialog is shown
      setTimeout(function () {
        printWindow.print();
        printWindow.close();
      }, 100); // Adjust the delay time as needed
    }
  };

  // pdf
  const generatePdf = (item) => {
    // Get the reference to the div you want to convert to PDF

    try {
      const element = document.getElementById("divToPrint");
      var opt = {
        margin: 1,
        filename: "myfile.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
      };
      // New Promise-based usage:
      html2pdf().from(element).set(opt).save();
      // Old monolithic-style usage:
      html2pdf(element, opt);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  
  return (
    <>
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Body>Copied</Toast.Body>
      </Toast>
      <div className="single-product-training">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Resources</Breadcrumb.Item>
                <Breadcrumb.Item href="/product-training">Product Training</Breadcrumb.Item>
                <Breadcrumb.Item active>{newSlug[2]}</Breadcrumb.Item>
                <Breadcrumb.Item active>{decodeURIComponent(newSlug[3])}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="col-lg-8 col-12 position-relative border-design">
              <div className="copy-print-btn">
                <div>
                  <button onClick={() => generatePdf(newSlug[2])}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 12V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V12"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.25 3V8.25H19.5"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.5 18.75H6C6.39782 18.75 6.77936 18.592 7.06066 18.3107C7.34196 18.0294 7.5 17.6478 7.5 17.25C7.5 16.8522 7.34196 16.4706 7.06066 16.1893C6.77936 15.908 6.39782 15.75 6 15.75H4.5V20.25"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.25 15.75H17.625V20.25"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.875 18.375H17.625"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 20.25C12.5967 20.25 13.169 20.0129 13.591 19.591C14.0129 19.169 14.25 18.5967 14.25 18C14.25 17.4033 14.0129 16.831 13.591 16.409C13.169 15.9871 12.5967 15.75 12 15.75H10.6875V20.25H12Z"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div>
                  <button onClick={handlePrint}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M19.7531 7.5H4.24687C3.35625 7.5 2.625 8.175 2.625 9V16.5H6V14.25H18V16.5H21.375V9C21.375 8.175 20.6437 7.5 19.7531 7.5Z"
                        fill="#243757"
                      />
                      <path
                        d="M18 3.75H6V7.5H18V3.75Z"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 14.25H6V20.625H18V14.25Z"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 16.5H2.625V9C2.625 8.175 3.35625 7.5 4.24687 7.5H19.7531C20.6437 7.5 21.375 8.175 21.375 9V16.5H18"
                        stroke="#243757"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.625 12C18.2463 12 18.75 11.4963 18.75 10.875C18.75 10.2537 18.2463 9.75 17.625 9.75C17.0037 9.75 16.5 10.2537 16.5 10.875C16.5 11.4963 17.0037 12 17.625 12Z"
                        fill="#243757"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                className="single-product-training-content scrollspy-example"
                id="divToPrint"
                data-bs-spy="scroll"
                data-bs-target="#list-example"
                data-bs-smooth-scroll="true"
                tabindex="0"
              >
                <div id="Profile">
                  <h1 className="text-capitalize">
                    {decodeURIComponent(newSlug[3].split("-").join(" "))}
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h1>
                  <p>
                    Employee Self-Service is a space where the employee can
                    access t heir personal information, related services, and
                    quick actions.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img1.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <p>
                    The following is a list of basic tabs that they can view and
                    edit from their self-service page:
                  </p>
                </div>
                <div id="Team">
                  <h4>Team</h4>
                  <p>
                    An employee will be able to view their complete profile,
                    including personal details, work details, skill set
                    information, reportees, and related information.
                    <br />
                    <br />
                    To initiate a quick chat with any teammate, simply click the
                    chat icon beside that employee's name. An employee can also
                    update their profile picture by clicking on the image and
                    then clicking Change Photo.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img2.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div id="Calendar">
                  <h4>Calendar</h4>
                  <p>
                    In this tab, the employee will be able to see the members of
                    your team. All the members of their team will be listed. For
                    a more detailed view, click on the name of the teammate to
                    view their self-service page.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img3.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div id="Leave">
                  <h4>Leave</h4>
                  <p>
                    In this tab, the employee will be able to see the members of
                    your team. All the members of their team will be listed. For
                    a more detailed view, click on the name of the teammate to
                    view their self-service page.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img4.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div id="Timesheet">
                  <h4>Timesheet</h4>
                  <p>
                    In this tab, the employee will be able to see the members of
                    your team. All the members of their team will be listed. For
                    a more detailed view, click on the name of the teammate to
                    view their self-service page.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img5.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div id="Attendance">
                  <h4>Attendance</h4>
                  <p>
                    In this tab, the employee will be able to see the members of
                    your team. All the members of their team will be listed. For
                    a more detailed view, click on the name of the teammate to
                    view their self-service page.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img5.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div id="Delegation">
                  <h4>Delegation</h4>
                  <p>
                    In this tab, the employee will be able to see the members of
                    your team. All the members of their team will be listed. For
                    a more detailed view, click on the name of the teammate to
                    view their self-service page.
                  </p>
                  <img
                    src={require("./../../assets/images/ProductTraining/img5.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="share">
                <ul>
                  <li>
                    <h5>Share</h5>
                  </li>
                  <li>
                    <button onClick={copyLinkToClipboard}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.9999 7.66008V8.00008C21.0007 9.06616 20.576 10.0885 19.8199 10.84L16.9999 13.67C16.4738 14.1911 15.6261 14.1911 15.1 13.67L15 13.56C14.8094 13.3656 14.8094 13.0544 15 12.86L18.4399 9.42006C18.807 9.03938 19.0083 8.52883 18.9999 8.00008V7.66008C19.0003 7.12705 18.788 6.61589 18.4099 6.2401L17.7599 5.59011C17.3841 5.21207 16.873 4.99969 16.3399 5.00011H15.9999C15.4669 4.99969 14.9558 5.21207 14.58 5.59011L11.14 9.00007C10.9456 9.19064 10.6344 9.19064 10.44 9.00007L10.33 8.89007C9.8089 8.36394 9.8089 7.51623 10.33 6.99009L13.16 4.15012C13.9165 3.40505 14.9382 2.99133 15.9999 3.00014H16.3399C17.4011 2.9993 18.4191 3.42018 19.1699 4.17012L19.8299 4.83012C20.5798 5.5809 21.0007 6.59891 20.9999 7.66008ZM8.64993 13.94L13.9399 8.65008C14.0338 8.55543 14.1616 8.50218 14.2949 8.50218C14.4282 8.50218 14.556 8.55543 14.6499 8.65008L15.3499 9.35007C15.4445 9.44395 15.4978 9.57175 15.4978 9.70507C15.4978 9.83839 15.4445 9.96618 15.3499 10.0601L10.0599 15.35C9.96604 15.4447 9.83824 15.4979 9.70492 15.4979C9.57161 15.4979 9.44381 15.4447 9.34993 15.35L8.64993 14.65C8.55528 14.5561 8.50204 14.4283 8.50204 14.295C8.50204 14.1617 8.55528 14.0339 8.64993 13.94ZM13.5599 15C13.3655 14.8094 13.0543 14.8094 12.8599 15L9.42993 18.41C9.0517 18.7905 8.53645 19.003 7.99995 18.9999H7.65995C7.12691 19.0004 6.61576 18.788 6.23997 18.41L5.58997 17.76C5.21194 17.3842 4.99956 16.873 4.99998 16.34V16C4.99956 15.4669 5.21194 14.9558 5.58997 14.58L9.00993 11.14C9.2005 10.9456 9.2005 10.6345 9.00993 10.44L8.89993 10.33C8.3738 9.80894 7.52609 9.80894 6.99996 10.33L4.17999 13.16C3.42392 13.9116 2.99916 14.9339 3 16V16.35C3.00182 17.4077 3.42249 18.4216 4.16999 19.1699L4.82998 19.8299C5.58076 20.5799 6.59878 21.0008 7.65995 20.9999H7.99995C9.05338 21.0061 10.0667 20.5964 10.8199 19.8599L13.6699 17.01C14.191 16.4838 14.191 15.6361 13.6699 15.11L13.5599 15Z"
                          fill="#236BB4"
                        />
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                          fill="#2231BC"
                        />
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.9737 6.7174C20.5093 7.33692 19.9479 7.87733 19.3112 8.31776C19.3112 8.47959 19.3112 8.64142 19.3112 8.81225C19.3163 11.7511 18.1424 14.5691 16.0527 16.6345C13.9629 18.6999 11.1321 19.8399 8.19491 19.7989C6.49685 19.8046 4.82053 19.4169 3.29728 18.6661C3.21514 18.6302 3.16217 18.549 3.16248 18.4593V18.3604C3.16248 18.2313 3.26709 18.1267 3.39613 18.1267C5.06528 18.0716 6.67488 17.4929 7.9972 16.4724C6.48639 16.4419 5.12705 15.5469 4.50146 14.1707C4.46987 14.0956 4.4797 14.0093 4.52743 13.9432C4.57515 13.8771 4.65386 13.8407 4.73511 13.8471C5.19428 13.8932 5.65804 13.8505 6.10106 13.7212C4.43325 13.375 3.18007 11.9904 3.00072 10.2957C2.99435 10.2144 3.03078 10.1357 3.09686 10.0879C3.16293 10.0402 3.2491 10.0303 3.32424 10.062C3.7718 10.2595 4.25495 10.3635 4.7441 10.3676C3.2827 9.40846 2.65147 7.58405 3.20741 5.92622C3.2648 5.76513 3.40267 5.64612 3.57036 5.61294C3.73804 5.57975 3.91082 5.63728 4.02518 5.76439C5.99725 7.86325 8.7069 9.11396 11.5828 9.25279C11.5092 8.95885 11.473 8.65676 11.475 8.35372C11.5019 6.76472 12.4851 5.34921 13.9643 4.76987C15.4434 4.19054 17.1258 4.56203 18.2239 5.71044C18.9723 5.56785 19.6959 5.31645 20.3716 4.96421C20.4211 4.93331 20.4839 4.93331 20.5334 4.96421C20.5643 5.01373 20.5643 5.07652 20.5334 5.12604C20.2061 5.87552 19.6532 6.50412 18.9518 6.92419C19.566 6.85296 20.1694 6.70807 20.7491 6.49264C20.7979 6.45942 20.862 6.45942 20.9108 6.49264C20.9517 6.51134 20.9823 6.54711 20.9944 6.59042C21.0065 6.63373 20.9989 6.68018 20.9737 6.7174Z"
                          fill="#1DA1F2"
                        />
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 6H13.5C12.9477 6 12.5 6.44772 12.5 7V10H16.5C16.6137 9.99748 16.7216 10.0504 16.7892 10.1419C16.8568 10.2334 16.8758 10.352 16.84 10.46L16.1 12.66C16.0318 12.8619 15.8431 12.9984 15.63 13H12.5V20.5C12.5 20.7761 12.2761 21 12 21H9.5C9.22386 21 9 20.7761 9 20.5V13H7.5C7.22386 13 7 12.7761 7 12.5V10.5C7 10.2239 7.22386 10 7.5 10H9V7C9 4.79086 10.7909 3 13 3H16.5C16.7761 3 17 3.22386 17 3.5V5.5C17 5.77614 16.7761 6 16.5 6Z"
                          fill="#2058CD"
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="position-sticky">
                <div className="onThisPage mob-hidden">
                  <h2>On this page</h2>
                  <div id="list-example" className="list-group">
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Profile"
                    >
                      Profile
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Team"
                    >
                      Team
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Calendar"
                    >
                      Calendar
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Leave"
                    >
                      Leave
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Timesheet"
                    >
                      Timesheet
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Attendance"
                    >
                      Attendance
                    </Link>
                    <Link
                      className="list-group-item list-group-item-action"
                      to="#Delegation"
                    >
                      Delegation
                    </Link>
                  </div>
                </div>
                <div className="article-section onThisPage">
                  <h2>Articles in this section</h2>
                  <ul>
                    <li>
                      <Link to={""}>Employee Self-Service</Link>
                    </li>
                    <li>
                      <Link to={""}>
                        Set up your team’s Workspace from scratch
                      </Link>
                    </li>
                    <li>
                      <Link to={""}>Set up personal workspace</Link>
                    </li>
                    <li>
                      <Link to={""}>Set up personal workspace</Link>
                    </li>
                    <li>
                      <Link to={""}>The hierarchy overview</Link>
                    </li>
                    <li>
                      <Link to={""}>Universal search</Link>
                    </li>
                  </ul>
                  <Link to="" className="seeAll">
                    See all 46 articles
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProductTrainingArticle;