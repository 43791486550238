import React, { useState } from "react";
import Faqs from "../components/Common/Faqs";
import GetInTouchCard from "../components/Common/GetInTouchCard";
import ComparePlan from "../components/Pricing/ComparePlan";
import PricingHero from "../components/Pricing/PricingHero";
import Pricings from "../components/Pricing/Pricings";

const Pricing = () => {
  const [multiplyer, setMultiplyer] = useState(1);

  const checkBtn = (id) => {
    id === 1 ? setMultiplyer(1) : setMultiplyer(12);
  };

  return (
    <div className=" pricing-page">
      <PricingHero checkBtn={checkBtn} />
      <Pricings multiplyer={multiplyer} />
      <ComparePlan />
      <Faqs />
      <GetInTouchCard />
    </div>
  );
};

export default Pricing;
