import React, { useEffect, useState } from "react";

const ToTopBtn = () => {
  const [top, setTop] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setTop(window.pageYOffset < 100 ? false : true);
      return () => (window.onscroll = null);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <>
      <button
        onClick={toTop}
        type="button"
        className={`btn btn-primary radius-8 btn-floating btn-lg ${
          top ? "visible" : "hidden"
        }`}
        id="btn-back-to-top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-caret-up-fill"
          viewBox="0 0 16 16"
        >
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        </svg>
      </button>
    </>
  );
};

export default ToTopBtn;
