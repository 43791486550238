import React from "react";
import BannerText from "../../Banner/BannerText";

const AttendanceManagementEmployee = () => {
  return (
    <>
      <section className="feture-hero AttendanceManagement-employee">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <BannerText
                title="Employee Attendance with Time 
Tracking Software"
                desc="Experience Seamless Attendance Management with SmartOffice. Empower your workforce to mark attendance conveniently. Leadership can access and review attendance records instantly, from any location and at any time.
"
                btnText="Try it for free"
              />
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/attendance-management/attendance-employee.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AttendanceManagementEmployee;
