import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";
import { Link } from "react-router-dom";

const ComprehensiveInformation = ({ bg }) => {
  return (
    <>
      <div
        className={`notification-blockchain domaincustomization-blockchain comprehensive-information ${bg}`}
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <FullWidthHeading
                title={"Comprehensive Information at Your Fingertips"}
                desc={
                  "Lorem ipsum dolor sit amet consectetur. Nisi tellus interdum felis risus. Suspendisse ut feugiat arcu eu enim eget. Quis interdum vitae eu faucibus at ultrices. Nullam ac feugiat ornare in dictum imperdiet."
                }
              />

              <div className="d-flex flex-sm-row flex-column justify-content-lg-start justify-content-center  gap-3">
                <Link to="#" className="btn btn-light radius-8 fw-600">
                  Learn more
                </Link>
                <Link
                  to="http://apps.smartoffice.ai/register"
                  className="btn btn-primary radius-8"
                >
                  Get started
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="img-content">
                <img
                  className="img-fluid w-100"
                  src={require("../../../assets/images/feature/organogram-management/comprehensive.png")}
                  alt="error"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComprehensiveInformation;


