const cancellationsRefunds = [
    {
      id: 0,
      title: "How do i view my renewal date ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 1,
      title: "How do i view my renewal date ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 2,
      title: "How do i view my renewal date ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 3,
      title: "How do i view my renewal date ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    }
    
  ];

  
const memberGuestSeatsData = [
    {
      id: 0,
      title: "How can I add new member & guests ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 1,
      title: "How can I see my current seat count ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 2,
      title: "How can I add new member & guests ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 3,
      title: "How can I see my current seat count ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    }
    
  ];

const apiData = [
    {
      id: 0,
      title: "How can I get started with the API ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 1,
      title: "How can I get started with the API ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 2,
      title: "How can I get started with the API ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    },
    {
      id: 3,
      title: "How can I get started with the API ?",
      desc: `<p>Start working with the Smartoffice API in just a few clicks by downloading the Postman collection!
      You can download and import all of the Smartoffice API endpoints into Postman, which is an app you can use to test and work with APIs.</p>
      <ul>
      <li>Download the latest version of our Postman collection.</li>
      <li>Import the collection into your Postman workspace.</li>
      <li>Make your first request to the Smartoffice API!</li>
      </ul>`
    }
    
  ];
  
  export { apiData, cancellationsRefunds, memberGuestSeatsData };
  