import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h6>About Us</h6>
              <p>
              Welcome to SmartOffice, where innovation meets productivity. We are a leading provider of comprehensive business solutions designed to revolutionize the future of work. Our platform is robust, dynamic, and focused on empowering organizations to streamline operations and achieve their goals.
              </p>
              <p>
              At SmartOffice, we understand the importance of data security in today's digital landscape. That's why our platform is backed by cutting-edge blockchain technology, ensuring the highest level of data security and integrity. With blockchain, you can trust that your sensitive information is protected, providing peace of mind and compliance with privacy regulations.

              </p>
              <p>
              Our platform is built with scalability in mind, allowing businesses to grow and adapt without limitations. With our microservices architecture, we provide a flexible and modular framework that enables seamless integration and customization. Whether you need our SmartPeople solution for advanced HR management, SmartBooks for efficient accounting, SmartDesk for streamlined help desk operations, SmartAsset for effective asset management, SmartSCMS, SmartProduction, or SmartAssistance platform offers a range of tailored applications to meet your specific needs.
              </p>
              <p>
              To enhance user experience and simplify access to our suite of applications, we have implemented Single Sign-On (SSO) functionality. With SSO, you can seamlessly navigate between our SmartOffice apps, eliminating the need for multiple logins and enhancing overall productivity.
              </p>
              <p>
              Our dedicated team of experts is passionate about driving success for our clients. We work closely with businesses to understand their unique challenges and provide personalized support every step of the way. We believe in long-lasting partnerships and strive to deliver excellence in all aspects of our services.

              </p>
              <p>
              Join us on the journey towards a smarter and more efficient future of work. Explore the SmartOffice platform and experience the power of innovation, security, scalability, and seamless integration. Empower your organization with SmartOffice and unlock the full potential of your business.

              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
