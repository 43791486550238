import { useState } from "react";
import Loader from "../Common/Loader";
import InfoCard from "../InfoCard/InfoCard";
import SectionHeading from "../SectionHeading/SectionHeading";
import { KeyFeatureData } from "./../../assets/json/KeyFeatureData";

const KeyFeature = () => {
  const [appFeatures, setAppFeatures] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   api
  //     .get(ENDPOINTS.APP_FEATURES)
  //     .then((res) => {
  //       setAppFeatures(res.data)
  //       setLoading(false)
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // console.log(appFeatures);

  return (
    <div className="keyfeature">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <SectionHeading
              name="KEY FEATURES"
              title="Reinvent your Core HR with SmartPeople!"
              desc="Step into the future of HR! Immerse yourself in a world of advanced features that will revolutionize your operations and drive unmatched efficiency."
            />
            
            {
              loading ? <Loader/> : 
              <div className="animate-key">
                <svg
                  width="1116"
                  height="1127"
                  viewBox="0 0 1116 1127"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="558"
                    cy="563"
                    r="140"
                    fill="#013FD7"
                    fillOpacity="0.08"
                  />
                  <rect
                    x="459.5"
                    y="464.5"
                    width="197"
                    height="197"
                    rx="98.5"
                    fill="white"
                  />
                  <path
                    d="M574.522 531.606C576.151 531.604 577.754 532.024 579.173 532.825C578.348 531.361 577.148 530.143 575.697 529.295C574.247 528.447 572.596 528 570.916 528H545.082C543.401 528 541.751 528.447 540.3 529.295C538.849 530.143 537.65 531.361 536.825 532.825C538.244 532.024 539.846 531.604 541.476 531.606H574.522Z"
                    fill="#117FEA"
                  />
                  <path
                    d="M541.476 576.393C539.847 576.396 538.244 575.976 536.825 575.176C537.65 576.64 538.849 577.858 540.3 578.706C541.751 579.554 543.401 580.001 545.082 580.001H570.916C572.596 580.001 574.247 579.554 575.697 578.706C577.148 577.858 578.348 576.64 579.173 575.176C577.754 575.977 576.151 576.397 574.522 576.395L541.476 576.393Z"
                    fill="#117FEA"
                  />
                  <path
                    d="M579.172 532.824C579.974 534.244 580.394 535.847 580.391 537.477V570.521C580.394 572.151 579.974 573.754 579.172 575.174C580.636 574.349 581.854 573.149 582.702 571.699C583.55 570.248 583.997 568.598 583.997 566.917V541.081C583.997 539.4 583.55 537.75 582.702 536.299C581.854 534.849 580.636 533.649 579.172 532.824V532.824Z"
                    fill="#117FEA"
                  />
                  <path
                    d="M535.606 537.479C535.604 535.849 536.024 534.247 536.825 532.828C535.361 533.653 534.143 534.852 533.295 536.303C532.447 537.754 532 539.404 532 541.085V566.917C532 568.597 532.446 570.248 533.294 571.699C534.142 573.151 535.361 574.35 536.825 575.175C536.024 573.757 535.604 572.154 535.606 570.525V537.479Z"
                    fill="#117FEA"
                  />
                  <path
                    d="M580.39 537.475C580.392 535.846 579.972 534.244 579.171 532.825C577.752 532.023 576.15 531.603 574.52 531.605H541.474C539.845 531.603 538.243 532.023 536.824 532.825C536.022 534.244 535.602 535.846 535.605 537.475V570.519C535.602 572.149 536.022 573.753 536.824 575.172C538.243 575.973 539.845 576.393 541.474 576.391H574.52C576.15 576.393 577.752 575.973 579.171 575.172C579.972 573.753 580.392 572.151 580.39 570.521V537.475Z"
                    fill="#013FD7"
                  />
                  <path
                    d="M558.154 537.512L555.744 539.922C555.09 540.576 555.09 541.635 555.744 542.289L564.28 550.825C564.934 551.478 565.993 551.478 566.647 550.825L569.057 548.415C569.71 547.762 569.71 546.702 569.057 546.048L560.521 537.512C559.867 536.859 558.807 536.859 558.154 537.512Z"
                    fill="white"
                  />
                  <path
                    d="M548.302 542.028L545.892 544.438C545.239 545.091 545.239 546.151 545.892 546.805L565.331 566.243C565.985 566.897 567.045 566.897 567.698 566.243L570.108 563.834C570.762 563.18 570.762 562.12 570.108 561.467L550.669 542.028C550.016 541.374 548.956 541.374 548.302 542.028Z"
                    fill="white"
                  />
                  <path
                    d="M549.349 557.172L546.939 559.582C546.286 560.236 546.286 561.295 546.939 561.949L555.475 570.485C556.129 571.139 557.189 571.139 557.842 570.485L560.252 568.075C560.906 567.422 560.906 566.362 560.252 565.708L551.716 557.172C551.062 556.519 550.003 556.519 549.349 557.172Z"
                    fill="white"
                  />
                  <path
                    d="M516.267 595.121C516.218 594.66 516.011 594.301 515.648 594.045C515.283 593.788 514.812 593.66 514.231 593.66C513.822 593.66 513.473 593.721 513.18 593.845C512.889 593.968 512.665 594.135 512.51 594.346C512.355 594.557 512.277 594.799 512.272 595.07C512.272 595.295 512.324 595.49 512.428 595.655C512.532 595.82 512.673 595.961 512.852 596.074C513.031 596.189 513.231 596.284 513.448 596.361C513.666 596.439 513.887 596.504 514.107 596.557L515.12 596.809C515.529 596.905 515.922 597.032 516.299 597.194C516.678 597.356 517.018 597.56 517.318 597.806C517.619 598.053 517.857 598.35 518.033 598.698C518.208 599.046 518.296 599.455 518.296 599.922C518.296 600.556 518.134 601.112 517.811 601.592C517.487 602.072 517.02 602.447 516.41 602.717C515.8 602.986 515.061 603.121 514.195 603.121C513.355 603.121 512.625 602.99 512.008 602.73C511.39 602.47 510.908 602.091 510.562 601.59C510.215 601.09 510.028 600.482 510 599.764H511.926C511.954 600.14 512.071 600.453 512.274 600.702C512.478 600.952 512.745 601.139 513.074 601.262C513.403 601.385 513.772 601.446 514.179 601.446C514.605 601.446 514.979 601.382 515.3 601.253C515.622 601.125 515.875 600.946 516.058 600.715C516.241 600.485 516.335 600.215 516.338 599.905C516.335 599.623 516.251 599.391 516.089 599.206C515.927 599.022 515.701 598.866 515.411 598.742C515.12 598.618 514.782 598.505 514.395 598.407L513.165 598.09C512.276 597.862 511.572 597.515 511.057 597.048C510.541 596.582 510.284 595.963 510.284 595.188C510.284 594.551 510.458 593.994 510.804 593.515C511.15 593.036 511.623 592.663 512.221 592.399C512.819 592.133 513.496 592 514.253 592C515.021 592 515.693 592.133 516.271 592.399C516.85 592.665 517.305 593.032 517.636 593.502C517.967 593.971 518.138 594.51 518.148 595.119H516.267V595.121Z"
                    fill="#243757"
                  />
                  <path
                    d="M520.326 602.959V594.852H522.153V596.23H522.248C522.417 595.765 522.697 595.402 523.087 595.139C523.477 594.877 523.944 594.746 524.486 594.746C525.035 594.746 525.499 594.879 525.876 595.145C526.255 595.411 526.52 595.772 526.676 596.23H526.761C526.94 595.779 527.243 595.419 527.671 595.15C528.098 594.881 528.605 594.746 529.194 594.746C529.94 594.746 530.549 594.982 531.02 595.454C531.492 595.925 531.728 596.613 531.728 597.517V602.959H529.812V597.813C529.812 597.31 529.678 596.941 529.411 596.707C529.143 596.473 528.816 596.355 528.429 596.355C527.969 596.355 527.608 596.499 527.35 596.785C527.092 597.072 526.962 597.444 526.962 597.901V602.957H525.088V597.731C525.088 597.312 524.963 596.979 524.711 596.728C524.459 596.477 524.131 596.353 523.727 596.353C523.453 596.353 523.203 596.422 522.978 596.562C522.753 596.701 522.574 596.896 522.44 597.148C522.306 597.4 522.239 597.693 522.239 598.027V602.957H520.326V602.959Z"
                    fill="#243757"
                  />
                  <path
                    d="M536.371 603.121C535.858 603.121 535.396 603.029 534.985 602.843C534.575 602.659 534.252 602.386 534.014 602.022C533.776 601.66 533.658 601.213 533.658 600.681C533.658 600.224 533.743 599.845 533.911 599.546C534.081 599.247 534.31 599.008 534.602 598.828C534.894 598.649 535.224 598.512 535.592 598.419C535.959 598.325 536.34 598.258 536.734 598.215C537.209 598.166 537.595 598.121 537.89 598.08C538.186 598.039 538.401 597.976 538.536 597.89C538.671 597.805 538.74 597.669 538.74 597.486V597.454C538.74 597.056 538.622 596.749 538.386 596.53C538.15 596.312 537.811 596.203 537.368 596.203C536.9 596.203 536.53 596.305 536.257 596.51C535.985 596.713 535.801 596.955 535.705 597.233L533.922 596.98C534.062 596.487 534.295 596.076 534.619 595.742C534.942 595.409 535.338 595.16 535.806 594.993C536.275 594.826 536.791 594.742 537.358 594.742C537.748 594.742 538.138 594.788 538.524 594.879C538.911 594.97 539.265 595.122 539.586 595.331C539.906 595.54 540.163 595.824 540.358 596.183C540.554 596.542 540.651 596.99 540.651 597.529V602.955H538.814V601.841H538.751C538.635 602.066 538.472 602.276 538.262 602.471C538.053 602.666 537.79 602.823 537.475 602.941C537.162 603.062 536.793 603.121 536.371 603.121ZM536.867 601.717C537.251 601.717 537.583 601.641 537.865 601.487C538.146 601.334 538.364 601.132 538.517 600.878C538.67 600.625 538.746 600.348 538.746 600.049V599.094C538.686 599.143 538.586 599.189 538.443 599.231C538.3 599.273 538.142 599.31 537.965 599.342C537.789 599.373 537.614 599.402 537.443 599.426C537.27 599.451 537.121 599.472 536.995 599.489C536.71 599.528 536.455 599.591 536.23 599.679C536.005 599.767 535.827 599.889 535.697 600.046C535.566 600.202 535.502 600.404 535.502 600.65C535.502 601.002 535.63 601.268 535.887 601.448C536.143 601.628 536.47 601.717 536.867 601.717Z"
                    fill="#243757"
                  />
                  <path
                    d="M542.953 602.958V594.851H544.805V596.202H544.89C545.038 595.733 545.292 595.372 545.652 595.117C546.012 594.862 546.425 594.734 546.89 594.734C546.995 594.734 547.114 594.739 547.246 594.747C547.378 594.756 547.488 594.77 547.576 594.787V596.545C547.495 596.517 547.368 596.491 547.193 596.469C547.02 596.446 546.851 596.434 546.69 596.434C546.341 596.434 546.029 596.509 545.753 596.658C545.476 596.808 545.26 597.014 545.101 597.279C544.943 597.543 544.864 597.847 544.864 598.192V602.958H542.953Z"
                    fill="#243757"
                  />
                  <path
                    d="M553.703 594.853V596.332H549.043V594.853H553.703ZM550.194 592.91H552.105V600.52C552.105 600.778 552.145 600.974 552.224 601.109C552.304 601.244 552.409 601.337 552.538 601.385C552.669 601.435 552.813 601.459 552.971 601.459C553.091 601.459 553.201 601.451 553.301 601.433C553.402 601.415 553.478 601.399 553.531 601.385L553.852 602.879C553.751 602.914 553.605 602.952 553.417 602.994C553.229 603.036 552.999 603.061 552.729 603.068C552.251 603.082 551.819 603.009 551.436 602.85C551.053 602.69 550.749 602.44 550.526 602.102C550.302 601.765 550.193 601.343 550.196 600.836V592.91H550.194Z"
                    fill="#243757"
                  />
                  <path
                    d="M555.93 602.962V592.152H559.982C560.813 592.152 561.511 592.308 562.075 592.616C562.638 592.925 563.067 593.351 563.358 593.891C563.648 594.431 563.793 595.044 563.793 595.73C563.793 596.424 563.647 597.039 563.355 597.577C563.062 598.115 562.632 598.539 562.062 598.846C561.492 599.154 560.79 599.308 559.956 599.308H557.27V597.698H559.692C560.177 597.698 560.575 597.613 560.885 597.445C561.195 597.276 561.424 597.044 561.574 596.749C561.723 596.453 561.798 596.113 561.798 595.73C561.798 595.348 561.723 595.009 561.574 594.717C561.424 594.426 561.194 594.197 560.883 594.033C560.572 593.869 560.171 593.788 559.682 593.788H557.888V602.961H555.93V602.962Z"
                    fill="#243757"
                  />
                  <path
                    d="M569.251 603.117C568.439 603.117 567.738 602.948 567.148 602.608C566.559 602.268 566.106 601.787 565.789 601.162C565.473 600.537 565.314 599.802 565.314 598.953C565.314 598.119 565.473 597.386 565.793 596.755C566.111 596.123 566.557 595.63 567.13 595.277C567.704 594.923 568.378 594.746 569.152 594.746C569.651 594.746 570.124 594.826 570.569 594.986C571.014 595.146 571.408 595.393 571.751 595.728C572.094 596.062 572.364 596.487 572.561 597.002C572.759 597.518 572.857 598.131 572.857 598.842V599.427H566.213V598.141H571.026C571.023 597.775 570.944 597.448 570.788 597.162C570.634 596.875 570.419 596.65 570.142 596.484C569.866 596.319 569.545 596.235 569.179 596.235C568.788 596.235 568.445 596.329 568.15 596.517C567.854 596.705 567.624 596.951 567.46 597.255C567.296 597.56 567.213 597.893 567.21 598.255V599.38C567.21 599.851 567.295 600.255 567.468 600.591C567.64 600.926 567.882 601.184 568.191 601.362C568.501 601.54 568.863 601.629 569.278 601.629C569.556 601.629 569.808 601.589 570.033 601.51C570.258 601.43 570.453 601.313 570.619 601.159C570.784 601.005 570.91 600.813 570.994 600.583L572.778 600.784C572.665 601.256 572.451 601.666 572.137 602.017C571.822 602.367 571.42 602.637 570.931 602.829C570.44 603.022 569.881 603.117 569.251 603.117Z"
                    fill="#243757"
                  />
                  <path
                    d="M578.377 603.115C577.585 603.115 576.899 602.942 576.319 602.593C575.739 602.245 575.289 601.757 574.97 601.131C574.652 600.505 574.492 599.773 574.492 598.936C574.492 598.099 574.651 597.365 574.97 596.735C575.289 596.105 575.738 595.616 576.319 595.268C576.9 594.92 577.586 594.746 578.377 594.746C579.169 594.746 579.855 594.92 580.435 595.268C581.014 595.616 581.465 596.105 581.783 596.735C582.101 597.366 582.261 598.099 582.261 598.936C582.261 599.773 582.101 600.505 581.783 601.131C581.465 601.757 581.016 602.245 580.435 602.593C579.854 602.942 579.169 603.115 578.377 603.115ZM578.387 601.585C578.816 601.585 579.175 601.466 579.463 601.229C579.752 600.991 579.967 600.671 580.11 600.27C580.252 599.87 580.323 599.423 580.323 598.93C580.323 598.433 580.251 597.984 580.11 597.581C579.967 597.178 579.752 596.858 579.463 596.618C579.175 596.379 578.816 596.258 578.387 596.258C577.948 596.258 577.582 596.379 577.292 596.618C577.001 596.857 576.785 597.178 576.642 597.581C576.5 597.984 576.429 598.433 576.429 598.93C576.429 599.423 576.501 599.87 576.642 600.27C576.785 600.671 577.001 600.991 577.292 601.229C577.582 601.467 577.947 601.585 578.387 601.585Z"
                    fill="#243757"
                  />
                  <path
                    d="M584.25 605.997V594.851H586.129V596.192H586.239C586.338 595.995 586.477 595.785 586.656 595.562C586.835 595.339 587.079 595.147 587.384 594.986C587.691 594.826 588.081 594.746 588.556 594.746C589.182 594.746 589.748 594.906 590.252 595.224C590.757 595.542 591.158 596.012 591.456 596.634C591.753 597.254 591.902 598.015 591.902 598.917C591.902 599.806 591.756 600.563 591.464 601.186C591.171 601.809 590.774 602.283 590.271 602.61C589.767 602.937 589.198 603.101 588.561 603.101C588.097 603.101 587.711 603.024 587.406 602.869C587.099 602.714 586.854 602.527 586.669 602.307C586.485 602.087 586.341 601.878 586.239 601.682H586.16V606H584.25V605.997ZM586.124 598.904C586.124 599.428 586.199 599.888 586.348 600.281C586.498 600.676 586.713 600.982 586.997 601.202C587.28 601.422 587.624 601.532 588.029 601.532C588.452 601.532 588.805 601.418 589.091 601.191C589.376 600.964 589.591 600.653 589.737 600.257C589.883 599.861 589.956 599.41 589.956 598.903C589.956 598.4 589.884 597.953 589.739 597.562C589.594 597.172 589.38 596.865 589.095 596.643C588.81 596.421 588.455 596.311 588.028 596.311C587.62 596.311 587.274 596.418 586.989 596.633C586.704 596.847 586.488 597.148 586.342 597.535C586.197 597.924 586.124 598.38 586.124 598.904Z"
                    fill="#243757"
                  />
                  <path
                    d="M595.815 592.152V602.961H593.904V592.152H595.815Z"
                    fill="#243757"
                  />
                  <path
                    d="M601.738 603.117C600.925 603.117 600.224 602.948 599.635 602.608C599.045 602.268 598.592 601.787 598.276 601.162C597.959 600.537 597.801 599.802 597.801 598.953C597.801 598.119 597.959 597.386 598.279 596.755C598.597 596.123 599.043 595.63 599.617 595.277C600.19 594.923 600.864 594.746 601.638 594.746C602.138 594.746 602.61 594.826 603.055 594.986C603.5 595.146 603.894 595.393 604.237 595.728C604.58 596.062 604.85 596.487 605.048 597.002C605.245 597.518 605.344 598.131 605.344 598.842V599.427H598.699V598.141H603.513C603.51 597.775 603.43 597.448 603.275 597.162C603.12 596.875 602.905 596.65 602.628 596.484C602.352 596.319 602.031 596.235 601.665 596.235C601.275 596.235 600.932 596.329 600.636 596.517C600.34 596.705 600.111 596.951 599.947 597.255C599.783 597.56 599.699 597.893 599.696 598.255V599.38C599.696 599.851 599.782 600.255 599.954 600.591C600.127 600.926 600.368 601.184 600.678 601.362C600.987 601.54 601.35 601.629 601.765 601.629C602.042 601.629 602.294 601.589 602.519 601.51C602.744 601.43 602.939 601.313 603.105 601.159C603.271 601.005 603.396 600.813 603.481 600.583L605.264 600.784C605.152 601.256 604.937 601.666 604.623 602.017C604.308 602.367 603.906 602.637 603.417 602.829C602.926 603.022 602.367 603.117 601.738 603.117Z"
                    fill="#243757"
                  />
                  <rect
                    x="459.5"
                    y="464.5"
                    width="197"
                    height="197"
                    rx="98.5"
                    stroke="#243757"
                    strokeWidth="3"
                  />
                  <path
                    opacity="0.2"
                    d="M858 875V839C858 833.477 853.523 829 848 829H268C262.477 829 258 833.477 258 839V875"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    d="M558 828.975V699"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M855 873H861L858 876L855 873Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M255 873H261L258 876L255 873Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    d="M557.5 837.16V874.846"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeLinecap="square"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M557 878L554 875H560L557 878Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    d="M274 432H343C348.523 432 353 436.477 353 442V672C353 677.523 348.523 682 343 682H274"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    d="M352.481 557H413.004"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M276 435V429L273 432L276 435Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M276 685V679L273 682L276 685Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    d="M841 432H773C767.477 432 763 436.477 763 442V672C763 677.523 767.477 682 773 682H841"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    d="M763.519 557H702.996"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M839 435V429L842 432L839 435Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M839 685V679L842 682L839 685Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    d="M859 239V275C859 280.523 854.523 285 849 285L269 285C263.477 285 259 280.523 259 275V239"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    d="M559 285.025V415"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M856 241H862L859 238L856 241Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M256 241H262L259 238L256 241Z"
                    fill="#121212"
                  />
                  <path
                    opacity="0.2"
                    d="M558.5 276.84V239.154"
                    stroke="#121212"
                    strokeWidth="2.5"
                    strokeLinecap="square"
                    strokeDasharray="7 7"
                  />
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M558 236L555 239H561L558 236Z"
                    fill="#121212"
                  />
                </svg>

                
                <div className="animate-key-card">
                  {KeyFeatureData.map(({ id, icon, title, desc, link }) => (
                    <InfoCard
                      key={id}
                      className="max-w-270"
                      cardLogo="mb-3"
                      icon={icon}
                      title={title}
                      desc={desc}
                      link={link}
                    />
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeature;
