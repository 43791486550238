const howtoworks = [
    {
        "id": 0,
        "name": "HOW IT WORKS",
        "title": "Payroll made easier with SmartPeople",
        "img": "payroll-made-easier-with-smartpeople.png",
        "list": [
            { "id": 0, "desc": "Automated Payroll Processing: Auto calculate and process employee salaries, deductions, and taxes with accuracy in just one click." },

            { "id": 1, "desc": "Compliance and Tax Management: Ensure regulatory compliance by automating tax calculations, deductions, and generating tax during payroll processing." },

            { "id": 2, "desc": "Self-Service Payslips: Empower workforce with secure access to digital payslips, enabling them to conveniently view and download their payment and deduction details whenever needed." },

            { "id": 3, "desc": "Integration with Time and Attendance Device: Seamlessly sync attendance data to automate payroll calculations, reducing manual data entry and minimizing potential errors." },

            { "id": 4, "desc": "Customized Payroll Reports: Generate comprehensive payroll reports with customizable parameters, offering valuable insights to support informed decision-making."}
        ]
    },
    {
        "id": 1,
        "name": "HOW IT WORKS",
        "title": "People management made easier with SmartPeople",
        "img": "people-management-made-easier-with-SmartPeople.png",
        "list": [
            { "id": 0, "desc": "Employee Profile Management: Create and maintain employee profiles with necessary information, contact details, and employment history." },

            { "id": 1, "desc": "Performance Tracking and Reviews: Streamline performance management by setting goals, conducting regular performance reviews, and tracking employee progress." },

            { "id": 2, "desc": "Training and Development: Facilitate employee growth and skill enhancement through centralized training management, including scheduling, tracking, and reporting." },

            { "id": 3, "desc": "Leave and Attendance Tracking: Simplify leave requests, approvals, and tracking, while seamlessly integrating with attendance records for accurate time management." },

            { "id": 4, "desc": "Document Management: Store and manage employee documents, such as contracts, certifications, and appraisal records, in a secure digital repository."}
        ]
    },
    {
        "id": 2,
        "name": "HOW IT WORKS",
        "title": "Blockchain Technology to ensure Transparency, Security & Reliability",
        "img": "blockchain-technology-to-ensure-transparency-security-reliability.png",
        "list": [
            { "id": 0, "desc": "Immutable Data Storage: Utilizing blockchain technology, SmartOffice ensures secure and tamper-proof storage of user activity, preventing unauthorized modifications and enhancing data integrity." },

            { "id": 1, "desc": "Decentralized Verification: The decentralized nature of blockchain allows for transparent verification of stored activity log for centralized authorities." },

            { "id": 2, "desc": "Enhanced Data Privacy: Blockchain provides robust encryption and decentralized storage, safeguarding your activity log report and mitigating the risks of data breaches." },

            { "id": 3, "desc": "Auditable and Traceable Transactions: Every transaction recorded on the blockchain is transparent, traceable, and auditable, providing a comprehensive audit trail for Top Management for improving accountability." }
        ]
    },
    {
        "id": 3,
        "name": "HOW IT WORKS",
        "title": "Dynamic Workflow Automation",
        "img": "dynamic-workflow-automation.png",
        "list": [
            { "id": 0, "desc": "SmartPeople revolutionizes workflow management with its dynamic approach, adapting to your organization's ever-changing needs effortlessly." },

            { "id": 1, "desc": "With SmartPeople's dynamic workflow management, you can easily design, modify, and optimize processes in real-time, ensuring maximum efficiency and productivity." },

            { "id": 2, "desc": "Experience unparalleled agility in your HR operations as SmartPeople's dynamic workflow management empowers you to respond quickly to evolving business requirements." },

            { "id": 3, "desc": "SmartPeople's dynamic workflow management adapts to varying complexities, streamlining tasks and approvals, resulting in a seamless and error-free workflow." },

            { "id": 4, "desc": "Embrace the future of HR with SmartPeople's intelligent dynamic workflow management, making your processes flexible, responsive, and tailored to your organization's unique demands."}
        ]
    }
]

export default howtoworks;