import React from "react";
import RacipeLibrarySlider from "../Home/RacipeLibrarySlider";

const RacipeLibrary = () => {
  return (
    <div className="racipeLibrary">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading centered-heading  text-center">
              <div className="section-name text-primary fs-16">
                RECIPE LIBRARY
              </div>
              <h2 className="fs-48 text-center">
              Hit the ground running with hundreds of templates

              </h2>
              <p className="fs-12 text-center mt-3">
              Choose from hundreds of pre-built templates. Use them out-of-the-box or tweak them to meet your exact needs.

              </p>
            </div>

            <div className="recipe-library">
              <div className="img">
                <RacipeLibrarySlider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RacipeLibrary;
