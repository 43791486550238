import React from 'react';
import { useHref } from 'react-router-dom';
import Article from '../components/ProductTraining/Article';
import Banner from '../components/ProductTraining/Banner';
import Questions from '../components/ProductTraining/Questions';

const ProductTrainingArticle = () => {
    const slug = useHref();
    const newSlug = slug.split("/")

    return (
        <>
            <Banner/>
            <Article slug={newSlug[3]}/>
            <Questions/>
        </>
    );
};

export default ProductTrainingArticle;