import React from "react";
import Address from "./Address";

// const data = [
//   {
//     id: "0",
//     title: "UAE",
//     desc: "ASPIN COMMERCIAL TOWER, SHEIKH ZAYED ROAD, DUBAI, UAE"
//   },
//   {
//     id: "1",
//     title: "USA",
//     desc: "401 RYLAND ST, STE 200-A, RENO, NV 89502, USA"
//   },
//   {
//     id: "2",
//     title: "SINGAPORE",
//     desc: "LEVEL 17-01, MARINA BAY FINANCIAL TOWER, 12 MARINA BOULEVARD, SINGAPORE 018982, SINGAPORE"
//   },
//   {
//     id: "3",
//     title: "BANGLADESH",
//     desc: "PLOT 39/2, KALACHANDPUR (NORTH BARIDHARA), GULSHAN, DHAKA 1212, BANGLADESH"
//   },
// ]
const data = [
  {
    id: "0",
    title: "USA",
    desc: "401 RYLAND ST, STE 200-A, RENO, NV 89502, USA"
  },
  {
    id: "1",
    title: "UAE",
    desc: "ASPIN COMMERCIAL TOWER, SHEIKH ZAYED ROAD, DUBAI, UAE"
  }
]

const VisitSection = () => {
  return (
    <div className="visitsection p-4">
      <div className="container ">
        <div className="row">
          <div className="col-12 col-md-4">
            <p className="text-primary">Our locations</p>
            {/* <h2 className="text-dark">Visit our Office</h2> */}
            <h2 className="text-dark">Find us at <br /> these locations.</h2>
            {/* <p className="text-primary">Find us at these locations.</p> */}
          </div>
          <div className="col-12 col-md-8">
            <div className="row">
              {data.map((item) => (
                <div className="col-12 col-md-6">
                  <Address
                    title={item.title}
                    desc={item.desc}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitSection;
