import React from "react";

const PlanCriteria = ({ data }) => {
  return (
    <>
      <tr className="desktop-th">
        <th scope="col" colSpan={2}></th>
        <th scope="col">Free</th>
        <th scope="col">Baseline</th>
        <th scope="col">Standard</th>
        <th scope="col">Enterprise</th>
      </tr>

      {data.included.map((item) => (
        <>
          <tr>
          <td data-label="" className="text-start" colSpan={2}>
            {item.featureName}

          </td>
          <td data-label="Free">
            {item.dataType === "string" ? (
              item.free
            ) : item.free === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          <td data-label="standard">
            {item.dataType === "string" ? (
              item.standard
            ) : item.standard === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          <td data-label="premium">
            {item.dataType === "string" ? (
              item.premium
            ) : item.premium === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          <td data-label="enterprise">
            {item.dataType === "string" ? (
              item.enterprise
            ) : item.enterprise === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          </tr>

          {item.subFeatures && 
           item.subFeatures.map(subItem =>
            <tr>
              <td className="text-start ps-4" colspan="2"><span className="">- {subItem}</span></td>
              <td data-label="Free">
            {item.dataType === "string" ? (
              item.free
            ) : item.free === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          <td data-label="standard">
            {item.dataType === "string" ? (
              item.standard
            ) : item.standard === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          <td data-label="premium">
            {item.dataType === "string" ? (
              item.premium
            ) : item.premium === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
          <td data-label="enterprise">
            {item.dataType === "string" ? (
              item.enterprise
            ) : item.enterprise === "yes" ? (
              <img
                src={require("../../assets/images/comp/tick-circle.png")}
                alt="error"
              />
            ) : "" ? (
              ""
            ) : (
              ""
            )}
          </td>
            </tr>
            )
          }
        </>

        
        
      )
      )}
    </>
  );
};

export default PlanCriteria;
