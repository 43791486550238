import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const VerifyPassword = () => {
  useEffect(() => {
    const inputElements = [...document.querySelectorAll("input.code-input")];
    inputElements.forEach((ele, index) => {
      ele.addEventListener("keydown", (e) => {
        if (e.keyCode === 8 && e.target.value === "")
          inputElements[Math.max(0, index - 1)].focus();
      });
      ele.addEventListener("input", (e) => {
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? "";
        const lastInputBox = index === inputElements.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join("");
          inputElements[index + 1].dispatchEvent(new Event("input"));
        }
      });
    });

    function onSubmit(e) {
      e.preventDefault();
      const code = inputElements.map(({ value }) => value).join("");
    }
  }, []);
  return (
    <div className="container">
      <form className="radius-16 auth-form">
        <div className="row">
          <h2 className="text-center">Request sent successfully!</h2>
          <p className="text-center">
            An email containing a 6-digit confirmation code has been sent to
            your email address. Please enter the code in the box below to verify
            your email.
          </p>

          <div className="col-12 mb-3">
            <div className="d-flex gap-3 input_item">
              <input
                type={"text"}
                className="form-control code-input text-center"
                required placeholder="-"
              />
              <input
                type={"text"}
                className="form-control code-input text-center"
                required placeholder="-"
              />
              <input
                type={"text"}
                className="form-control code-input text-center"
                required placeholder="-"
              />
              <input
                type={"text"}
                className="form-control code-input text-center"
                required placeholder="-"
              />
              <input
                type={"text"}
                className="form-control code-input text-center"
                required placeholder="-"
              />
              <input
                type={"text"}
                className="form-control code-input text-center"
                required placeholder="-"
              />
            </div>
          </div>

          <div className="col-12 mb-3">
            <Link to="/auth/change-password" className="btn btn-primary w-100" type="submit">
              Verify
            </Link>
          </div>

          <div className="col-12 mb-3">
            <p className="text-center">
              Don’t have a code?{" "}
              <button className="btn p-0">Resend Code</button>
            </p>
          </div>

          <div className="col-12">
            <Link
              className="d-flex align-items-center justify-content-center gap-2 returnToSignIn"
              to="http://apps.smartoffice.ai/"
            >
              <span className="">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 16.5L1 9L8.5 1.5"
                    stroke="#000F33"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Return to sign in
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyPassword;
