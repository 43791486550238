import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const LeaveManagementWellbeing = ({bg}) => {
  return (
    <>
      <div className={`payroll-action leaveManagement-wellbeing ${bg}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <FullWidthHeading
                title={"Refocus on Employee wellbeing"}
                desc={
                  "By implementing efficient leave management systems, you can prioritize the health and happiness of your employees, leading to reduced burnout and fostering a healthier work-life balance for your team. Ensuring that employees have the opportunity to take time off when needed allows them to recharge, rejuvenate, and return to work with renewed energy and motivation. A well-managed leave system also promotes a positive work culture where employees feel valued and supported, leading to increased productivity and overall satisfaction within the organization. Take the step towards creating a more thriving and engaged workforce by placing employee wellbeing at the forefront of your HR practices."
                }
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="img-content">
                <img
                  className="img-fluid w-100"
                  src={require("../../../assets/images/feature/leave-management/Refocus on Employee wellbeing.png")}
                  alt="error"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementWellbeing;
