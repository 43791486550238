const companyData = [
    {
      id: 0,
      img: "holiday-island.png",
      title: "Holiday Island",
      desc: "SmartOffice has transformed our HR processes completely! The user-friendly interface and customizable features make managing our team effortless.      ",
    },
    {
      id: 1,
      img: "marketplace.png",
      title: "Marketplace",
      desc: "With SmartPeople's payroll management, we save hours of manual work. It's accurate, efficient, and the support team is fantastic!",
    },
    {
      id: 2,
      img: "KX-Transport.png",
      title: "KX Transport",
      desc: "SmartOffice's analytics feature has provided valuable insights into our employee performance, allowing us to make data-driven decisions for growth.      ",
    },
    {
      id: 3,
      img: "knitexpert.png",
      title: "knitexpert",
      desc: "SmartPeople's leave management feature has streamlined our employee time-off requests. It's easy to use and ensures better team coordination.      ",
    },
    {
      id: 4,
      img: "my-generation.png",
      title: "My Generation",
      desc: "The self-service portal in SmartOffice empowers our employees, giving them control over their information and making HR tasks more efficient      ",
    },
    {
      id: 5,
      img: "blvck-group.png",
      title: "Blvck Group",
      desc: "SmartPeople's tax configuration templates are a lifesaver! It simplifies payroll tax calculation, ensuring compliance with local regulations.",
    },
  ];

  export default companyData;