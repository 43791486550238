import React from "react";
import ContactCard from "./ContactCard";

const data = [
  {
    id: "0",
    title: "Support",
    desc: "Our friendly team is here to help.",
    email: "support@untitledui.com",
    link:"/technical-support"
  },
  {
    id: "1",
    title: "Sales",
    desc: "Questions or queries? Get in touch!",
    email: "sales@untitledui.com",
    link:"/sales"
  },
  {
    id: "2",
    title: "Billing",
    desc: "Mon-Fri from 8am to 5pm.",
    phone: "+1 (555) 000-0000",
    link:"/billing"
  }
]

const ContactSection = () => {
  return (
    <div  className="container-fluid-md container-lg  mt-64 mb-80">
      <div className="row">
        {data.map((item) => (
          <div key={item.id} className="col-12 col-md-4">
            <ContactCard title={item.title} desc={item.desc} mail={item.email} phone={item.phone} link={item.link}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactSection;
