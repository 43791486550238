import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const PayrollHero = () => {
  return (
    <>
      <div className="payroll-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                sectionTitle="FULL-SERVICE PAYROLL SOFTWARE"
                gradientText="Payroll"
                title=" services tailored for your business"
                desc="Streamline your payroll operations and empower your HR team to manage employee compensation with ease and accuracy. Our comprehensive payroll services are designed to adapt to your organization's specific needs, ensuring a seamless and error-free payroll process."
                btn3={`<div class="btn-group">
                  <a href="http://apps.smartoffice.ai/register" class="btn btn-outline-primary radius-8 text-light">Try it now <span class="tryNow"> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M15.1875 7.03125V2.8125H10.9688" stroke="#243757" strokeLinecap="round" strokeLinejoin="round"/> <path d="M10.125 7.875L15.1875 2.8125" stroke="#243757" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12.9375 10.125V14.625C12.9375 14.7742 12.8782 14.9173 12.7727 15.0227C12.6673 15.1282 12.5242 15.1875 12.375 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V5.625C2.8125 5.47582 2.87176 5.33274 2.97725 5.22725C3.08274 5.12176 3.22582 5.0625 3.375 5.0625H7.875" stroke="#243757" strokeLinecap="round" strokeLinejoin="round"/> </svg> </span></a>
                  </div>`}
              />

              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/payroll/Payroll Management Page.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </>
  );
};

export default PayrollHero;
