import React from "react";
import { Outlet } from "react-router-dom";
import AuthBanner from "../AuthBanner/AuthBanner";
import AuthHeader from "../AuthHeader/AuthHeader";

const Auth = () => {
  return (
    <div>
      <AuthHeader />
      <AuthBanner/>
      <Outlet />
    </div>
  );
};

export default Auth;
