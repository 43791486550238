const StructureUnderstandAndAccessibilityData = [
  {
    id: 0,
    title: "Clear Hierarchy Display",
    desc: "Visual indicators for quick comprehension of reporting relationships.",
    img: require("./../images/feature/organogram-management/clear-hierarchy.png"),
  },
  {
    id: 1,
    title: "Expand and Collapse Nodes",
    desc: "Easily explore or simplify the organizational structure.",
    img: require("./../images/feature/organogram-management/expand-and-collapse.png"),
  },
  {
    id: 2,
    title: "Position, Designation, and Employee Details",
    desc: "Quick access to pertinent information for effective decision-making.",
    img: require("./../images/feature/organogram-management/position.png"),
  },
];

export { StructureUnderstandAndAccessibilityData };
