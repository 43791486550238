import React from "react";
import { benifitData } from "../../../assets/json/benifitData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const PayrollBenifits = () => {
  return (
    <>
      <div className="payroll-benifits">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                title="Big business benefits on a small business budget"
                desc=" Explore all the employee services you can get with QuickBooks
                  Payroll."
              />
            </div>
            {benifitData.map((item, index) => (
              <div key={index} className="col-lg-3 mb-4">
                <InfoCard2
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollBenifits;
