const blogData = [
  {
    id: 0,
    badgecat: "HRMS",
    img: "img1.png",
    readTime: "4 min read",
    title:
      "Why the Human Resource Management System (HRMS) is essential for managing a modern workforce.",
    desc: "Managing a modern workforce can be challenging, especially with the ever-changing HR trends and compliance requirements. To stay organized and efficient, HR professionals need reliable HR software that can handle employee management, HR automation, talent management, and other HR-related tasks.",
    publishDate: "31 July 2023 ",
    like: "8",
    tag: [
      { id: 0, title: "HRMSEssentials" },
      { id: 1, title: "ModernWorkforceManagement" },
      { id: 2, title: "StreamlineHRProcesses" },
      { id: 3, title: "DataDrivenHR" },
      { id: 4, title: "HRMSAutomation" },
      { id: 5, title: "ComplianceManagement" },
      { id: 6, title: "RemoteWorkforceHRMS" },
      { id: 7, title: "EmployeeEngagement" },
      { id: 8, title: "HRAnalytics" },
      { id: 9, title: "SmartOffice.ai" },
      { id: 10, title: "SmartPeople" }
    ],
    singleBody: `
    <p>Managing a modern workforce can be challenging, especially with the ever-changing HR trends and compliance requirements. To stay organized and efficient, HR professionals need reliable HR software that can handle employee management, HR automation, talent management, and other HR-related tasks. This is where the HRMS or Human Resource Management System comes into play.</p>
    <img class="img-fluid rounded-4" src={require("./../../assets/images/blog/preview_img.png")} alt="" />
    <p>HRMS, also known as Human Resource Management System, is a cloud-based HR technology that enables organizations to streamline their HR processes, making it easier for HR professionals to manage their workforce. SmartOffice HRMS is a powerful tool that can help digitize your entire HR department, including recruitment, onboarding, performance management, compensation and benefits, compliance management, employee engagement, organizational culture, productivity, remote work, flexibility, diversity and inclusion, succession planning, career development, and learning and development.</p>
    </br>
    <p>Here are some reasons why HRMS is essential for managing a modern workforce:    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">HR automation</h4>
    <p>HRMS automates various HR processes, including payroll management, time and attendance tracking, applicant tracking system, employee self-service, benefits administration, performance reviews, HR compliance, workforce analytics, and succession planning. This automation reduces manual errors and saves time, allowing HR professionals to focus on strategic tasks.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Data-driven insights</h4>
    <p>HRMS provides workforce analytics, allowing HR professionals to make data-driven decisions. With HR analytics, organizations can identify workforce trends, track employee performance, and make informed decisions about talent acquisition, employee retention, training and development, and workforce planning.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Compliance management</h4>
    <p>HRMS ensures compliance with legal requirements and company policies. HR professionals can set up compliance rules, track compliance-related tasks, and generate compliance reports, ensuring that the organization is always in line with legal and regulatory requirements.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Remote workforce management    </h4>
    <p>HRMS supports remote work arrangements, making it easier for HR professionals to manage a remote workforce. With employee self-service and mobile access, employees can access their HR information from anywhere, while HR professionals can manage their workforce from a centralized system.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Employee engagement and retention
    </h4>
    <p>HRMS supports employee engagement and retention by providing tools for employee recognition, team building, and career development. With HRMS, HR professionals can track employee performance, identify top performers, and provide targeted training and development opportunities.
    <br />
    In conclusion, HRMS is an essential tool for managing a modern workforce. It helps HR professionals stay organized and efficient, while also providing data-driven insights, compliance management, and remote workforce management. With SmartOffice HRMS, you can streamline your HR processes and take your HR department to the next level.
    </p>
    `
  },
  {
    id: 1,
    badgecat: "SmartPeople's",
    img: "img2.png",
    readTime: "12 min read    ",
    title:
      "How SmartPeople can revolutionize your human resource management Processes.",
    desc: "Are you tired of managing HR-related tasks manually, feeling like you're drowning in paperwork and dire need of Human Resource Software? Then, say hello to SmartPeople - a cloud HRMS system to revolutionize how you manage your resources and HR processes.",
    publishDate: "30 July 2023 ",
    like: "6",
    tag: [
      {id: 0,title:"SmartPeopleHRMSRevolution"},
      {id: 1,title:"StreamlineHRProcesses"},
      {id: 2,title:"DataDrivenDecisions"},
      {id: 3,title:"BoostProductivity"},
      {id: 4,title:"AllInOneHRSoftware"},
      {id: 5,title:"SmartPeopleHRMS"},
      {id: 6,title:"CloudBasedHRSolution"},
      {id: 7,title:"AutomateHRTasks"},
      {id: 8,title:"EnhanceEmployeeExperiences"},
      {id: 9,title:"RealTimeInsights"}
      
    ],
    singleBody: `
    <p>Are you tired of managing HR-related tasks manually, feeling like you're drowning in paperwork and dire need of Human Resource Software? Then, say hello to SmartPeople - a cloud HRMS system to revolutionize how you manage your resources and HR processes. Our cloud-based HUman  Resource Management System (HRMS) offers a comprehensive solution to streamline and automate HR tasks, providing real-time insights and analytics to help make data-driven decisions. Incorporating this advanced system can enhance the efficiency and accuracy of HR processes, improve employee experiences, and boost productivity. In this blog, we will explore the various features of Smart Office HRMS in detail and understand how they can benefit any company, institution, or organization. 
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Profile Management    </h4>
    <p>Profile management is one of the most crucial features of any HRMS (Human Resource Management System) software. Smart Office HRMS provides extensive profile management features to ensure data security and streamlined HR processes.
    </p>
    <ul>
    <li>User Registration: Smart Office HRMS allows users to register with their details, such as name, email address, and phone number. This feature enables the HR team to track employee information and manage employee profiles effectively.
    </li>
    <li>User Login: Users can log in to the system using their credentials, such as username and password, once registered. This feature ensures data security and access control by restricting unauthorized access to sensitive employee information.</li>
    <li>User Profile Management: The software allows users to manage their profiles by updating their personal and professional details, such as contact information, job title, department, etc. This feature ensures that employee information is up-to-date and accurate.
    </li>
    <li>Company Profile Management: Smart Office HRMS enables the HR team to manage the company's profile by updating its information, such as company name, address, logo, and more. This feature ensures that the company's information is current and up-to-date.
    </li>
    <li>User Permission: Smart Office HRMS provides user permission features enabling the HR team to assign different access levels to users based on their roles and responsibilities. This feature ensures that employees can only access the information they need to perform their duties.
    </li>
    <li>User Role: The software also provides user role features that allow the HR team to assign specific roles to employees based on their job functions and responsibilities. This feature ensures that employees can only perform tasks relevant to their roles, increasing productivity and efficiency.
    </li>
    </ul>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Employee Management</h4>
    <p>Smart Office HRMS provides advanced employee management features to streamline HR processes and enhance employee satisfaction. 
    </p>
    <ul>
    <li>Promotion: Smart Office HRMS allows the HR team to manage employee promotions by updating their job titles, salary, and benefits based on performance and achievements. This feature ensures that employees are rewarded for their hard work and motivates them to perform better.
    </li>
    <li>Increment: The software provides an increment management feature that enables the HR team to manage employee salary increments based on their performance, experience, and job responsibilities. This feature ensures that employees are compensated fairly for their work and motivates them to stay with the company.
    </li>
    <li>Transfer: Smart Office HRMS provides a transfer management feature that enables the HR team to manage employee transfers from one department to another or from one location to another. This feature ensures that employees are deployed in areas where they can utilize their skills and knowledge effectively.
    </li>
    <li>Conveyance: The software provides a conveyance management feature that enables the HR team to manage employee travel and transportation expenses, such as fuel reimbursement, vehicle maintenance, and more. This feature ensures that employees are compensated for their travel expenses and motivates them to travel for work.
    </li>
    <li>Plugin Management: Smart Office HRMS allows the HR team to manage plugin integration with other HR software or applications. This feature ensures the HR team can seamlessly customize and integrate the software with other HR tools according to their needs.
    </li>
    <li>Shift Management: The software provides features that enable the HR team to manage employee shifts effectively. This feature ensures that employees are assigned the most suitable shifts for their job roles and schedules.
    </li>
    <li>Device Management: Smart Office HRMS allows the HR team to manage employee devices, such as laptops, mobile phones, and tablets, ensuring employees can access the tools they need to perform their duties effectively.
    </li>
    </ul>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Attendance and Holiday
    </h4>
    <p>Smart Office HRMS's attendance and holiday management features ensure that the HR team can keep track of employee attendance, manage leaves effectively, and plan for holidays.
    </p>
    <ul>
    <li>Late Management: Smart Office HRMS provides a late management feature that enables the HR team to track employee attendance and manage late arrivals effectively. This feature ensures that employees are held accountable for their attendance and motivates them to arrive on time.</li>
    <li>Holiday Management: The software provides a holiday management feature that enables the HR team to manage holidays effectively. This feature ensures that employees know about the holidays and can plan their work accordingly.
    </li>
    <li>Weekly Holiday Management: Smart Office HRMS allows the HR team to manage weekly holidays effectively. This feature ensures employees have enough time to rest and rejuvenate before the next work week.</li>
    <li>Holiday Calendar: The software provides a calendar feature enabling the HR team to plan for holidays in advance and communicate the holiday schedule to employees. This feature ensures that employees can plan their work and personal schedules accordingly.</li>
    </ul>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Leave Management    </h4>
    <p>Smart Office HRMS provides a comprehensive leave management feature that enables the HR team to manage employee leaves effectively
    </p>
    <ul>
    <li>Leave Type: Smart Office HRMS provides a leave type management feature that enables the HR team to create and manage different types of leaves, such as sick leave, annual leave, maternity leave, and more. This feature ensures that employees can request the right type of leave and that the HR team can manage leaves effectively.</li>
    <li>Leave Allocation: The software provides a leave allocation feature that enables the HR team to allocate leaves to employees based on their job roles, seniority, and other criteria. This feature ensures that employees have enough leaves to take time off when needed and that the HR team can manage leaves effectively.
    </li>
    <li>Leave Summary: Smart Office HRMS allows employees to view their leave summary, including the number of leaves taken, remaining, and pending leaves. This feature ensures that employees can plan their leaves effectively and that the HR team can manage leaves effectively.
    </li>
    <li> Leave Application: The software provides a leave application feature that enables employees to request leave online. Employees can select the type of leave, the date range, and the reason for the leave. The HR team can review and approve or reject the leave request online. This feature ensures that employees can request leaves easily and that the HR team can manage leaves effectively.
    </li>
    </ul>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Pay Grade Management
    </h4>
    <p>A complete pay grade management feature enables the HR team to effectively manage employee compensation and benefits.
    </p>
    <ul>
    <li>Bonus: Smart Office HRMS allows the HR team to set up and manage employee bonuses, such as performance bonuses, annual bonuses, and more. This feature ensures that employees are rewarded for their hard work and that the HR team can manage bonuses effectively.</li>
    <li>Provident Fund: The software provides a provident fund management feature that enables the HR team to manage employee contributions and withdrawals from the provident fund. This feature ensures that employees can save for retirement and that the HR team can manage provident funds effectively.
    </li>
    <li>Gratuity: Smart Office HRMS allows the HR team to manage employee gratuity payments, such as end-of-service gratuity, death gratuity, and more. This feature ensures that employees are compensated appropriately when they leave the company.
    </li>
    <li>Tax Calculation: The software provides a tax calculation feature enabling the HR team to calculate employee taxes based on income, deductions, and other factors. This feature ensures that employees pay the proper taxes and that the HR team can manage taxes effectively.
    </li>
    <li>Leave Encashment: Smart Office HRMS allows the HR team to manage employee leave encashment, such as cashing out unused leaves or compensating employees for leaves they are not entitled to. This feature ensures employees are compensated for unused leaves, and the HR team can manage leave encashment effectively.
    </li>
    <li>Advance Salary: The software provides an advanced salary management feature that enables the HR team to manage employee salary advances, such as emergency loans or salary advances for special occasions. This feature ensures that employees can get the financial support they need, and the HR team can manage salary advances effectively.
    </li>
    <li>Loan: Smart Office HRMS allows the HR team to manage employee loans, such as personal loans, car loans, or house loans. This feature ensures that employees can get the financial support they need and that the HR team can manage loans effectively.
    </li>
    </ul>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Notification Center:
    </h4>
    <p>This feature enables the HR team to set and manage notifications for various HR-related tasks. It ensures that employees are informed about important HR-related events, such as leave approval, salary processing, etc. The notification settings feature allows the HR team to customize the notification frequency, recipients, and channels.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Organogram Template:
    </h4>
    <p>The software provides an organogram template feature that enables the HR team to create and manage an organizational chart. This feature ensures that employees understand their roles and responsibilities in the company and can visualize the company hierarchy. The organogram template feature allows the HR team to customize the chart's design, add or remove roles, and update the chart in real time.</p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Notice Board:
    </h4>
    <p>The notice board feature enables the HR team to post company-wide announcements, such as upcoming events, policy changes, or HR-related news. This feature ensures that employees are informed about important company updates and can refer to the notice board anytime. The notice board feature allows the HR team to customize the announcements' content, format, and visibility.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Widget:
    </h4>
    <p>The software provides a widget feature enabling the HR team to display important HR-related information on the company intranet, such as employee attendance, leave balance, or upcoming holidays. This feature ensures that employees can easily access important HR-related information and plan their work accordingly. The widget feature allows the HR team to customize the widget's content, design, and placement.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Log Management:
    </h4>
    <p>Smart Office HRMS provides a log management feature that enables the HR team to track and manage user activities, such as login attempts, data access, or system modifications. This feature ensures that user actions are logged and can be audited for security and compliance purposes. The log management feature allows the HR team to customize the logging settings, view logs in real-time, and generate reports.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Workflow Management:
    </h4>
    <p>It enables the HR team to set up and manage HR-related workflows, such as leave approval, employee onboarding, or performance review. This feature ensures that HR-related tasks are streamlined and efficient, reducing manual errors and delays. The workflow management feature allows the HR team to customize the workflow's design, participants, and triggers.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Report Generation:
    </h4>
    <p>Smart Office HRMS provides a report generation feature that enables the HR team to generate various HR-related reports, such as attendance reports, payroll reports, or employee performance reports. This feature ensures that HR-related data is analyzed and presented in a structured and meaningful way. The report generation feature allows the HR team to customize the report's format, filters, and frequency.
    </p>
    <h4 style="color: #243757" class="fs-24 fw-800 mt-5">Group:
    </h4>
    <p>The group feature enables the HR team to create and manage employee groups based on various criteria, such as department, role, or location. This feature ensures that employees can be grouped for communication, collaboration, or task allocation. The group feature allows the HR team to customize the group's membership, permissions, and visibility.

</br>
    Smart Office HRMS is an all-in-one cloud-based HR software that can revolutionize HR processes. With features such as profile management, employee management, attendance, holiday management, leave management, pay grade management, and more, this software can streamline and automate various HR tasks, saving time and reducing errors. It also provides real-time insights and analytics to help in making data-driven decisions. Implementing Smart Office HRMS can create a more efficient and effective HR system, leading to better employee experiences and higher productivity.
    </p>
    `
  },
  {
    id: 2,
    badgecat: "Top Features",
    img: "img3.png",
    readTime: "4 min read",
    title:
      "Top features to look for in a Human Resource Management System (HRMS).",
    desc: "Human Resource Management System (HRMS) is essential for any organization that wants to manage its human resources efficiently. The software helps manage the HR department's day-to-day activities, such as employee data management, attendance, payroll, benefits, performance management, and other related tasks.",
    publishDate: "1 Aug 2023 ",
    like: "3",
    tag: [
      {id: 0,title:"HRMSSolutions"},
      {id: 1,title:"SmartPeople"},
      {id: 2,title:"HRMS"},
      {id: 3,title:"DataDrivenDecisions"},
      {id: 4,title:"EfficientHRManagement"},
      {id: 5,title:"EmployeeEngagement"},
      {id: 6,title:"StreamlineHRProcesses"},
      {id: 7,title:"ProductivityBoost"},
      {id: 8,title:"CloudBasedHRMS"},
      {id: 9,title:"UserFriendlyInterface"},
      {id: 10,title:"CustomizableHRSoftware"},
    ],
    singleBody: `
    <p>Human Resource Management System (HRMS) is essential for any organization that wants to manage its human resources efficiently. The software helps manage the HR department's day-to-day activities, such as employee data management, attendance, payroll, benefits, performance management, and other related tasks. The use of HRMS not only streamlines the HR processes but also enables organizations to make data-driven decisions that can improve employee engagement and productivity. Below mentioned are the top features to look for in an HRMS.
    </p>
    <p>
    <b>User-Friendly Interface:</b> The HRMS should have a user-friendly interface that makes it easy for HR managers to navigate and use the system. The interface should be intuitive, simple, and easy to understand.
</br>
<b>Customizable:</b> HRMS should be customizable according to the organization's specific requirements. The system should be flexible enough to accommodate changes in company HR policies and procedures.
</br>
<b>Data Security:</b> HRMS should have robust security measures to protect employee data. The system should have access controls and encryption protocols to safeguard employee data from unauthorized access.
</br>
<b>Employee Self-Service Portal:</b> The HRMS should have an employee self-service portal that allows employees to access their personal information, update their profiles, request leave, and view their payroll and benefits information.
</br>
<b>Time and Attendance Tracking:</b> HRMS should have a time and attendance tracking feature that automates attendance management. It should allow managers to track employee attendance, manage schedules, and approve or deny time off requests.
</br>
<b>Payroll Management:</b> HRMS should have a payroll management feature that automates payroll processing. It should calculate employee salaries, taxes, and other deductions, generate pay slips, and comply with local tax laws.
</br>
<b>Performance Management:</b> HRMS should have a feature that allows managers to set performance goals, track progress, and provide employee feedback. The system should also enable employees to access performance reports and track progress.
</br>
<b>Analytics and Reporting:</b> HRMS should have an analytics and reporting feature that gives HR managers insights into employee performance, productivity, and engagement. The system should generate reports that help in making data-driven decisions.
</br>
Smart Office HRMS is a cloud-based platform offering all the essential features required for efficient human resource management. It offers a user-friendly interface, making it easy for HR managers to navigate and access essential functions. It's highly customizable, allowing organizations to configure it according to their needs. The system is designed with robust security measures to protect employee data from unauthorized access using encryption protocols and access controls.
</br>
Smart Office has an employee self-service portal allowing employees to access their personal information, update their profiles, request leave, and view payroll and benefits information. It also features a time and attendance tracking feature that automates attendance management, enabling managers to track employee attendance, manage schedules, and approve or deny time off requests.
</br>
The system's payroll management feature automates payroll processing, including calculating employee salaries, taxes, and other deductions. It generates pay slips and ensures compliance with local tax laws. Smart Office's performance management feature allows managers to set performance goals, track progress, and provide employee feedback. The system also enables employees to access performance reports and track their progress.
</br>
Lastly, Smart Office's analytics and reporting feature provides HR managers with insights into employee performance, productivity, and engagement. The system generates reports that aid in data-driven decision-making. With these features, Smart Office provides organizations with a comprehensive HR management solution that streamlines HR operations and enhances employee experience.
</br>
Smart Office is 360 degrees cloud-based HRMS platform that offers all the essential features required for efficient human resource management. Organizations can use Smart Office HRMS to improve employee engagement and productivity, streamline HR processes, and make data-driven decisions.

    </p>
   
    `
  }
];

export { blogData };

