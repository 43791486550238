import React from "react";
import Faqs from "../../components/Common/Faqs";
import DomainCustomizationBearingGifts from "../../components/Feature/DomainCustomization/DomainCustomizationBearingGifts";
import DomainCustomizationBenifit from "../../components/Feature/DomainCustomization/DomainCustomizationBenifit";
import DomainCustomizationBlockchain from "../../components/Feature/DomainCustomization/DomainCustomizationBlockchain";
import DomainCustomizationFeature from "../../components/Feature/DomainCustomization/DomainCustomizationFeature";
import DomainCustomizationHero from "../../components/Feature/DomainCustomization/DomainCustomizationHero";
import DomainCustomizationPersonalConsultant from "../../components/Feature/DomainCustomization/DomainCustomizationPersonalConsultant";
import DomainCustomizationSecurity from "../../components/Feature/DomainCustomization/DomainCustomizationSecurity";
import "./../../assets/css/future.css";

const DomainCustomization = () => {
  return (
    <>
      <DomainCustomizationHero />
      <DomainCustomizationBearingGifts bg={'bg-shadow'}/>
      <DomainCustomizationFeature />
      <DomainCustomizationBenifit bg={'bg-shadow'}/>
      <DomainCustomizationPersonalConsultant />
      <DomainCustomizationBlockchain bg={'bg-shadow'}/>
      <DomainCustomizationSecurity />
      <Faqs />
    </>
  );
};

export default DomainCustomization;
