import React from "react";
import { Link } from "react-router-dom";
import { BlockSecurityManagementData } from "../../../assets/json/BlockSecurityManagementData";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecurityManagement = ({bg}) => {
  return (
    <>
      <div className={`payroll-benifits blockSecurity-management trusted-platform ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle={"EXPERIENCE THE POWER OF BLOCKCHAIN"}
                title={"Blockchain for Transparency User Activity Tracking"}
                desc={
                  "Connect your existing apps and workflows with pre-made integrations available for 400+ popular apps, plus custom integrations for more advanced users."
                }
              />
            </div>

            {BlockSecurityManagementData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-4">
                <div className="content-card p-3">
                  <div className="img-content text-center mb-2">
                    <img
                      className="mt-4 img-fluid"
                      src={item.icon}
                      alt="error"
                    />
                  </div>

                  <div className="text-content text-center">
                    <h2 className="fs-20 fw-500">{item.title}</h2>
                    <p className="fs-16 fw-400 mb-3">
                    {item.desc}
                    </p>
                    {/* <Link to={item.link} className="text-primary fs-16 fw-600 ">
                      
                      Learn More {"->"}
                    </Link> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityManagement;
