import React from "react";
import ApiDocsContent from "../components/ApiDocs/ApiDocsContent";
import Banner from "../components/ApiDocs/Banner";

const ApiDocs = () => {
  return (
    <>
      <Banner />
      <ApiDocsContent/>
    </>
  );
};

export default ApiDocs;