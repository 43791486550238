import React from "react";
import Faqs from "../../components/Common/Faqs";
import AttendanceManagementBenifits from "../../components/Feature/AttendanceManagement/AttendanceManagementBenifits";
import AttendanceManagementBiometric from "../../components/Feature/AttendanceManagement/AttendanceManagementBiometric";
import AttendanceManagementEmployee from "../../components/Feature/AttendanceManagement/AttendanceManagementEmployee";
import AttendanceManagementEmployeeAbsent from "../../components/Feature/AttendanceManagement/AttendanceManagementEmployeeAbsent";
import AttendanceManagementHero from "../../components/Feature/AttendanceManagement/AttendanceManagementHero";
import AttendanceManagementTask from "../../components/Feature/AttendanceManagement/AttendanceManagementTask";
import AttendanceManagementTimeBlockchain from "../../components/Feature/AttendanceManagement/AttendanceManagementTimeBlockchain";
import AttendanceManagementTimeTracking from "../../components/Feature/AttendanceManagement/AttendanceManagementTimeTracking";
import "./../../assets/css/future.css";

const AttendanceManagement = () => {
  return (
    <>
      <AttendanceManagementHero />
      <AttendanceManagementBenifits />
      <AttendanceManagementEmployee />
      <AttendanceManagementBiometric bg={"bg-shadow"}/>
      <AttendanceManagementEmployeeAbsent />
      <AttendanceManagementTask bg={"bg-shadow"}/>
      <AttendanceManagementTimeTracking />
      <AttendanceManagementTimeBlockchain bg={"bg-shadow"}/>
      <Faqs />
    </>
  );
};

export default AttendanceManagement;
