import React from "react";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";
import { RealTimeAttendanceTrackingData } from "../../../assets/json/RealTimeAttendanceTrackingData";


const RealTimeAttendanceTracking = () => {
  return (
    <>
     
      <div className="visualization-and-navigation">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                title={"Real-Time Attendance Tracking"}
                data={
                  ' <p class="fs-16 text-center "> Create public announcements, bulletin board management, and more with the best HR <br /> management software. </p>'
                }
              />
            </div>
          
            {RealTimeAttendanceTrackingData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-4">
                <InfoCard2
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RealTimeAttendanceTracking;
