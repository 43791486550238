import React from "react";
import { DomainCustomizationBearingGiftsData } from "../../../assets/json/DomainCustomizationBearingGiftsData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const DomainCustomizationBearingGifts = ({bg}) => {
  return (
    <>
      <div className={`payroll-benifits domaincustomization-bearingGifts ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                title={"Your domain comes bearing gifts"}
                data={
                  ' <p class="fs-16 text-center mt-3"> Powerful, self-serve product and growth analytics to help you convert, engage, and <br/> retain more users. </p>'
                }
              />
            </div>
            {DomainCustomizationBearingGiftsData.map((item, index) => (
              <div key={index} className="col-lg-3 mb-4">
                <InfoCard2
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainCustomizationBearingGifts;
