import React from "react";
import { Link } from "react-router-dom";
import SectionHeading from "../SectionHeading/SectionHeading";

const HrProcess = ({ data, loader }) => {
  
  return (
    <div className="hrprocess">
      <div className="container">
        {loader === true ? (
          "loading"
        ) : (
          <div className="row justify-content-center">
            <div className="col-12">
              
              <SectionHeading
                fs="fs-48"
                name="KEY FEATURES"
                title={'Streamline your HR processes'}
                desc={'Bring all your HR activities under one roof. Say goodbye to mundane spreadsheets or rigid systems to manage HR tasks. Get smarter and more efficient software with features designed to free you from administrative work.'}
                className="text-center mb-5"
              />
            </div>
            {data.map(
              ({ id, icon, title, desc, link }) => (
                <div key={id} className="col-6 col-lg-4 mb-5">
                  <div className="info-card text-center card-border radius-16 card-shadow card mx-4">
                    <div className="img-content">
                      <img src={icon} alt="" className="img-fluid card-logo" />
                    </div>
                    <div className="text-content">
                      <h3 className="fs-16">
                        <Link to={link}>{title}</Link>
                      </h3>
                      <p className="fs-14 w-100 mx-auto">{desc}</p>
                      <div className="mt-5 learnMoreBtn">
                        <Link
                          to={link}
                          className="text-primary learnMore"
                          style={{ fontWeight: "bold" }}
                        >
                          Learn More
                        </Link>
                        <Link
                          to={link}
                          className="text-end d-block loading-spin"
                          href=""
                        >
                          <img
                            className=" img-fluid"
                            src={require("../../assets/images/comp/icons/ArrowUpRight.png")}
                            alt="error"
                          />
                          <div className="circle-loading"></div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HrProcess;
