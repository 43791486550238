import React from "react";
import Marquee from "react-fast-marquee";


const Integrate = () => {

  return (
    <div className="integrate">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading centered-heading text-center mb-64">
              <h2 className="fs-48 text-center">Let's see what we integrate</h2>
              <p className="fs-12 text-center mt-3 ">
                We recently had to jump on 10+ different plugin across eight
                different countries to find the right owner and escalation
                process.
              </p>
            </div>
            <Marquee
              direction="left"
              gradient={true}
              gradientColor={[255, 255, 255]}
              autoFill={true}
              speed={15}
            >
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/google.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/gmail.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/microsoft.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/zoom.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/slack.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/trello.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/click-up.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/brust-sms.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/dropbox.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            </Marquee>
            {/* Add some spacing or separation here */}
            <div style={{ marginBottom: "50px" }} />
            <Marquee
              direction="rigth"
              gradient={true}
              gradientColor={[255, 255, 255]}
              autoFill={true}
              speed={15}
            >
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/google.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/gmail.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/microsoft.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/zoom.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/slack.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/trello.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/click-up.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/brust-sms.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../assets/images/integrate-slider/dropbox.png")}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrate;
