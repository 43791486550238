const EmployeeMonitoringIntegrateData = [
    {
      id: 0,
      icon: require("../images/feature/employee-monitoring/Embrace Efficiency.png"),
      title: "Embrace Efficiency      ",
      desc: "Streamline onboarding with work slot allocation - The perfect fit for every new hire!",
      link: "#"
    },
    {
      id: 1,
      icon: require("../images/feature/employee-monitoring/Promotions, Perfected.png"),
      title: "Promotions, Perfected      ",
      desc: "Celebrate your stars with seamless employee movement - Your talent, recognized!      ",
      link: "#"
    },
    {
      id: 2,
      icon: require("../images/feature/employee-monitoring/Power in Unity.png"),
      title: "Power in Unity",
      desc: "Group employees effortlessly, empowering you with unmatched access controls - Secure the right way!",
      link: "#"
    }
  ];
  
  export { EmployeeMonitoringIntegrateData };
  
  