import React from "react";
import Faqs from "../../components/Common/Faqs";
import NotificationBlockchain from "../../components/Feature/Notification/NotificationBlockchain";
import NotificationCoreHR from "../../components/Feature/Notification/NotificationCoreHR";
import NotificationEverySituation from "../../components/Feature/Notification/NotificationEverySituation";
import NotificationFeature from "../../components/Feature/Notification/NotificationFeature";
// import NotificationGrowing from "../../components/Feature/Notification/NotificationGrowing";
import NotificationHero from "../../components/Feature/Notification/NotificationHero";
import NotificationNotificationTask from "../../components/Feature/Notification/NotificationNotificationTask";
import NotificationOnlineService from "../../components/Feature/Notification/NotificationOnlineService";
import "./../../assets/css/future.css";

const Notification = () => {
  return (
    <>
    <NotificationHero/>
    <NotificationFeature/>
    <NotificationCoreHR/>
    <NotificationOnlineService/>
    <NotificationEverySituation/>
    <NotificationNotificationTask/>
    {/* <NotificationGrowing/> */}
    <NotificationBlockchain bg={"bg-shadow"}/>
    <Faqs/>
     
    </>
  );
};

export default Notification;
