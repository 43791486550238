import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecuritySecureIdentity = () => {
  return (
    <>
      <div className="blockSecurity-secureIdentity blockSecurity-accessManagement">
        <div className="container">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-lg-6">
              <FullWidthHeading
              sectionTitle={"Safeguarding Against Data Manipulation"}
              sectionTitleClassName={'text-start'}
               title={"Safeguarding Against Data Manipulation"}
                desc={"Blockchain's immutability feature safeguards SmartPeople's critical data from manipulation attempts. Once a user activity is recorded in a block, it becomes practically impossible to alter or erase it without consensus from the entire network. This immutability establishes trust in the integrity of the data, creating a secure environment for all of our clients and end users."}
              />
              </div>
              <div className="col-12 col-lg-6">
                <div className="img-content">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/feature/security-identity.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecuritySecureIdentity;
