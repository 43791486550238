import Faqs from "../../components/Common/Faqs";
import ComprehensiveInformation from "../../components/Feature/OrganogramManagement/ComprehensiveInformation";
import OrganogramManagementHero from "../../components/Feature/OrganogramManagement/OrganogramManagementHero";
import StructureUnderstandAndAccessibility from "../../components/Feature/OrganogramManagement/StructureUnderstandAndAccessibility";
import UserInterfaceAndCollaboration from "../../components/Feature/OrganogramManagement/UserInterfaceAndCollaboration";
import VisualizationAndNavigation from "../../components/Feature/OrganogramManagement/VisualizationAndNavigation";
 

const OrganogramManagement = () => {
  return (
    <div className="organogram-management-page">
      <OrganogramManagementHero />
      <VisualizationAndNavigation />
      <StructureUnderstandAndAccessibility />
      <UserInterfaceAndCollaboration />
      <ComprehensiveInformation bg={'bg-shadow'} />
      <Faqs />
    </div>
  );
};

export default OrganogramManagement;
