import React from "react";

const FaqHero = () => {
  return (
    <div className="faq-hero mb-64">
      <div className="fullwidth-heading px-5">
        <div className="text-center">
          <p className="text-primary fs-16 fw-bold">FAQs</p>
          <h2 className="text-dark fw-800 fs-48">Ask us anything</h2>
          <p className="text-dark mt-2 fs-16 desc">
            Need something cleared up? Here are our most frequently asked
            questions.
          </p>
          <div className="input-wrapper">
            <input
              placeholder="Search"
              className="form-control mt-4 px-5 py-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqHero;
