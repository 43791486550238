import { Link } from "react-router-dom";

const InfoCard = ({ className, cardLogo, icon, title, desc, link, arrowShow }) => {
  // const [moreless, setMoreless] = useState(true);
  return (
    <div
      className={`info-card text-center card-border radius-16 card-shadow card ${className} p-3 pointer`}
    >
      {link ? (
        <Link
          to={`${link}`}
          className={`text-end d-block loading-spin ${arrowShow}`}
          href=""
        >
          {/* <img
          className=" img-fluid"
          src={require("../../assets/images/comp/icons/ArrowUpRight.png")}
          alt="error"
        />
        <div className="circle-loading"></div> */}

          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15L15 5"
              stroke="#013FD7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.875 5H15V13.125"
              stroke="#013FD7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      ) : (
        <Link to={`#`} className={`text-end d-block loading-spin ${arrowShow}`} href="">
          {/* <img
          className=" img-fluid"
          src={require("../../assets/images/comp/icons/ArrowUpRight.png")}
          alt="error"
        />
        <div className="circle-loading"></div> */}

          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15L15 5"
              stroke="#013FD7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.875 5H15V13.125"
              stroke="#013FD7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      )}

      <div>
        <div className="img-content">
          <img className={`img-fluid ${cardLogo}`} src={icon} alt={icon} />
        </div>
        <div className="text-content">
          <h3 className="fs-16">
            <Link to={link}>{title}</Link>
          </h3>
          <p className="fs-13 w-100 mx-auto">
            {desc}
           
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
