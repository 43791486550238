import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const ShiftManagementEmployeeFocused = () => {
  return (
    <>
      <div className="shiftManagement-employee-focused">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle="SHIFT MANAGEMENT WITH AI"
                title="Shaping the Next Generation of Shift Management Solutions"
                desc="Discover how SmartOffice AI is revolutionizing shift management. By leveraging cutting-edge artificial intelligence, it optimizes employee scheduling, improves workforce efficiency, and adapts to changing business demands. Experience the next generation of seamless and intelligent shift management solutions with SmartOffice AI"
              />
              
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/api-graphics.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShiftManagementEmployeeFocused;
