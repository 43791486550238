import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const FilterFaqs = ({data}) => {
  const [activeKey, setActiveKey] = useState(0);

  const handleAccordionToggle = (itemKey) => {
    setActiveKey(itemKey === activeKey ? null : itemKey);
  };
  return (
    <>
      <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
        {data.map((item) => (
          <Accordion.Item key={item.id} eventKey={item.id} id={item.id}>
            <Accordion.Header>{item.title}</Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: item.desc}}></div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default FilterFaqs;
