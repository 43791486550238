import React from "react";

const CustomerHero = () => {
  return (
    <div className="text-center customer-hero mt-80">
      <div className="text-content">
        <h1 className="fs-62 fw-700 text-black">
          Trusted by workplaces globally
        </h1>
        <p className="fs-24 fw-500 mt-40 px-2">
          We’re proud to help so many businesses save time and thrive.
        </p>
      </div>
    </div>
  );
};

export default CustomerHero;
