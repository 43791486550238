import React from "react";
import { Link } from "react-router-dom";

const Section6x6 = ({
  hasSectionname,
  title,
  desc,
  list,
  btnText,
  direction,
  btnName,
  btnWidth,
  imgName,btnLink, imgLink

}) => {
  return (
    <div className="details-section-6x6 mb-80">
      <div className="container">
        <div
          className={`row d-flex align-items-center ${
            direction % 2 === 0 ? "" : "flex-row-reverse"
          }`}
        >
          <div className="col-12 col-lg-6">
            <div className="img-content">
              {imgName ? <img
                className="img-fluid"
                src={require(`../../assets/images/comp/${imgName}`)}
                alt="error"
              /> : ""}
              {imgLink ? <img
                className="img-fluid"
                src={require(`../../assets/images/${imgLink}`)}
                alt="error"
              /> : ""}

            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text-content">
              {hasSectionname && (
                <p className="text-primary fs-16 fw-700 text-uppercase">
                  Decentralization
                </p>
              )}
              <h2 className="text-dark fw-800 fs-40">{title}</h2>
              <p className="details fs-16 fw-400 mt-4" dangerouslySetInnerHTML={{__html: desc}}></p>
              {list && <div className="details fs-16 fw-400 mt-4" dangerouslySetInnerHTML={{__html: list}}></div>}
              <Link
                to={btnLink ? btnLink : "#"}
                className={`btn ${btnWidth} ${
                  btnName === "" ? "btn-light" : btnName
                } radius-8 mt-3`}
              >
                {btnText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6x6;
