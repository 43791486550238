import React from "react";
import { Link } from "react-router-dom";

const AuthHeader = () => {
  return (
    <>
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={require('./../../assets/images/logo/logo.png')}
              alt="Bootstrap"
            />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default AuthHeader;
