import React, { useState } from "react";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const [passView, setPassView] = useState(false);
  const [passView1, setPassView1] = useState(false);

  return (
    <div className="container">
      <form className="radius-16 auth-form">
        <div className="row">
          <h2 className="text-center">Create a New Password!</h2>
          <div className="col-12 mb-3 position-relative">
            <input
                type={passView ? "text" : "password"}
                className="form-control"
                name=""
                id=""
                placeholder="Password"
              />
            <span className="eye" onClick={() => setPassView(!passView)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5001 12.0004C13.5001 12.8288 12.8285 13.5004 12.0001 13.5004C11.1716 13.5004 10.5001 12.8288 10.5001 12.0004C10.5001 11.1719 11.1716 10.5004 12.0001 10.5004C12.8285 10.5004 13.5001 11.1719 13.5001 12.0004Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.7301 5.00036C17.7101 4.82036 21.2301 10.3904 21.8701 11.5004C22.0487 11.8098 22.0487 12.191 21.8701 12.5004C21.0101 14.0004 17.8001 18.8604 12.2701 19.0004H12.0201C6.13007 19.0004 2.76007 13.5904 2.13007 12.5004C1.95144 12.191 1.95144 11.8098 2.13007 11.5004C3.00007 10.0004 6.20007 5.14036 11.7301 5.00036ZM8.50007 12.0004C8.50007 13.9334 10.0671 15.5004 12.0001 15.5004C13.9331 15.5004 15.5001 13.9334 15.5001 12.0004C15.5001 10.0674 13.9331 8.50036 12.0001 8.50036C10.0671 8.50036 8.50007 10.0674 8.50007 12.0004Z"
                  fill="#637381"
                />
              </svg>
            </span>
          </div>
          <div className="col-12 mb-3 position-relative">
            <input
                type={passView1 ? "text" : "password"}
                className="form-control"
                name=""
                id=""
                placeholder="Confirm New Password"
              />
            <span className="eye" onClick={() => setPassView1(!passView1)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5001 12.0004C13.5001 12.8288 12.8285 13.5004 12.0001 13.5004C11.1716 13.5004 10.5001 12.8288 10.5001 12.0004C10.5001 11.1719 11.1716 10.5004 12.0001 10.5004C12.8285 10.5004 13.5001 11.1719 13.5001 12.0004Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.7301 5.00036C17.7101 4.82036 21.2301 10.3904 21.8701 11.5004C22.0487 11.8098 22.0487 12.191 21.8701 12.5004C21.0101 14.0004 17.8001 18.8604 12.2701 19.0004H12.0201C6.13007 19.0004 2.76007 13.5904 2.13007 12.5004C1.95144 12.191 1.95144 11.8098 2.13007 11.5004C3.00007 10.0004 6.20007 5.14036 11.7301 5.00036ZM8.50007 12.0004C8.50007 13.9334 10.0671 15.5004 12.0001 15.5004C13.9331 15.5004 15.5001 13.9334 15.5001 12.0004C15.5001 10.0674 13.9331 8.50036 12.0001 8.50036C10.0671 8.50036 8.50007 10.0674 8.50007 12.0004Z"
                  fill="#637381"
                />
              </svg>
            </span>
          </div>

          <div className="col-12 mb-3">
            <Link to={"/"} className="btn btn-primary w-100" type="submit">
              Update Password
            </Link>
          </div>
          <div className="col-12">
            <Link
              className="d-flex align-items-center justify-content-center gap-2 returnToSignIn"
              to="http://apps.smartoffice.ai/"
            >
              <span className="">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 16.5L1 9L8.5 1.5"
                    stroke="#000F33"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Return to sign in
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
