import React from "react";
import Faqs from "../../components/Common/Faqs";
import BlockSecurityCommunicateEasilly from "../../components/Feature/BlockchainSecurity/BlockSecurityCommunicateEasilly";
import BlockSecurityDataProtection from "../../components/Feature/BlockchainSecurity/BlockSecurityDataProtection";
import BlockSecurityDecentraliazation from "../../components/Feature/BlockchainSecurity/BlockSecurityDecentraliazation";
import BlockSecurityFeature from "../../components/Feature/BlockchainSecurity/BlockSecurityFeature";
import BlockSecurityHero from "../../components/Feature/BlockchainSecurity/BlockSecurityHero";
import BlockSecurityIntegrate from "../../components/Feature/BlockchainSecurity/BlockSecurityIntegrate";
import BlockSecurityManagement from "../../components/Feature/BlockchainSecurity/BlockSecurityManagement";
import BlockSecuritySecureIdentity from "../../components/Feature/BlockchainSecurity/BlockSecuritySecureIdentity";
import "./../../assets/css/future.css";

const BlockchainSecurity = () => {
  return (
    <>
      <BlockSecurityHero />
      <BlockSecurityManagement bg={"bg-shadow"} />
      <BlockSecurityIntegrate />
      <BlockSecurityDecentraliazation bg={"bg-shadow"} />
      <BlockSecuritySecureIdentity />
      <BlockSecurityDataProtection bg={"bg-shadow"} />
      <BlockSecurityCommunicateEasilly />
      <BlockSecurityFeature bg={"bg-shadow"} />
      <Faqs />
    </>
  );
};

export default BlockchainSecurity;
