import React from "react";
import { Form } from "react-bootstrap";

const Banner = () => {
  return (
    <>
      <div className="training-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h2>Product Training</h2>
              <Form>
                <Form.Group>
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#667085"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <Form.Control type="text" placeholder="Search article..." />
                </Form.Group>
              </Form>
              <p>
                Lorem ipsum dolor sit amet consectetur. Pharetra phasellus
                mauris a blandit eu egestas facilisis ut.{" "}
              </p>
            </div>
            <div className="col-lg-5">
              <img className="img-fluid"
                src={require("./../../assets/images/ProductTraining/banner.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;