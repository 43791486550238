import React from "react";
import { NoticeBoardBearingGiftsData } from "../../../assets/json/NoticeBoardBearingGiftsData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NoticeBoardBearingGifts = () => {
  return (
    <>
      <div className="payroll-benifits NoticeBoard-bearingGifts">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
            <FullWidthHeading title={"Smarter Notices, Easier Management"} 
            data={'<p class="fs-16 text-center mt-3"> Smart and convenient way to display announcements, news, and information to your employees.</p>'}/>
            </div>
            {NoticeBoardBearingGiftsData.map((item, index) => 
                <div key={index} className="col-lg-3 mb-4">
                    <InfoCard2 id={item.id} icon={item.icon} title={item.title} desc={item.desc}/>
                </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBoardBearingGifts;
