import React from "react";
import InfoCard from "../InfoCard/InfoCard";
import SectionHeading from "../SectionHeading/SectionHeading";
import { aboutDriven } from "./../../assets/json/aboutDriven";

const AboutDriven = () => {
  
  return (
    <>
      <div className="about-driven">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionHeading
                className="text-center"
                fs="fs-48"
                title="We’re driven by our values."
                desc={`SmartOffice stands on four core values regarded as the fundamental ideological constants to govern the organization. The chronology of these values has been arranged on the basis of priority.`}
              />
            </div>
            {aboutDriven.map(({ id, icon, title, desc }) => (
              <div key={id} className="col-12 col-lg-4 mb-4 card-info-wrapper ">
                <InfoCard
                  className={"h-100 d-flex flex-column justify-content-start"}
                  cardLogo="card-logo"
                  icon={icon}
                  title={title}
                  desc={desc}
                  arrowShow={"d-none hidden opacity-0"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutDriven;
