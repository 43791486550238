import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const BlogCard = ({ item }) => {
  return (
    <div className="blog-card radius-12 overflow-hidden card h-100">
      <div className="img-content">
        <Link to={`/singleblog/${item.id}`}>
          <LazyLoadImage
            src={require(`../../assets/images/comp/${item.img}`)}
            PlaceholderSrc={item.img}
            alt={"Dashboard"}
            effect="blur"
            loading="lazy"
            className="img-fluid w-100 thumb_nail_img"
          />
          {/* <img
            className="img-fluid w-100 thumb_nail_img"
            src={require(`../../assets/images/comp/${item.img}`)}
            alt={item.img}
          /> */}
        </Link>

        <div className="text-content h-auto">
          <div className="card-badge d-flex radius-16 bg-primary-thin ">
            <span className="badge-cat text-primary bg-light radius-16 fs-16">
              {item.badgecat}
            </span>
            <span className=" text-primary fs-16"> {item.readTime}</span>
          </div>
          <Link to={`/singleblog/${item.id}`}>
            <h3 className="fs-20 mt-3">{item.title.slice(0, 80)}...</h3>
          </Link>
          <p className="fs-12 mt-2 d-inline-block">
            {item.desc.slice(0, 140)}...
          </p>

          <div className="published-info text-primary d-flex align-items-center justify-content-between">
            <span className="fs-14">
              {item.publishDate} • <span>7 Views</span>
            </span>
            {/* <span className="fs-14 d-flex align-items-center justify-content-start gap-1">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 7.99969V15.4997M6 7.99969L3 7.99968V15.4997L6 15.4997M6 7.99969L9.89668 3.45355C10.2664 3.02217 10.8482 2.83673 11.3994 2.97452L11.435 2.98344C12.4414 3.23502 12.8947 4.40762 12.3193 5.27071L10.5 7.99968H13.9203C14.8669 7.99968 15.5768 8.86567 15.3912 9.79386L14.4912 14.2939C14.3509 14.995 13.7353 15.4997 13.0203 15.4997L6 15.4997"
                  stroke="#013FD7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>8</span>
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
