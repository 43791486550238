import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "../../assets/json/blogData";
import BlogCatCard from "./BlogCatCard";
import RelatedSegment from "./RelatedSegment";

const DynamicPost = ({ singleData }) => {
  return (
    <div className="container post">
      <div className="row">
        <div className="col-12 col-lg-8">
          <h2
            style={{ color: "#243757", lineHeight: "50px" }}
            className="fs-32 fw-600 "
          >
            {singleData.title}
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: singleData.singleBody }}
          ></div>
          <div>
            {/* <h2
              style={{ color: "#243757", lineHeight: "50px" }}
              className="fs-32 fw-600 "
            >
              Having experience with imaginative studio and it's a definitive
              innovative industrial facility variant of this planet
            </h2>
            <p style={{ color: "#585C7B" }} className="mt-4 text-dark">
              Quisque dictum felis eu pede mollis pretium. Integer tincidunt.
              Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate
              eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
              eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
              feugiat a, tellus. Phasellus viverra nulla ut metus varius
              laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi
              vel augue.
            </p>
            <article>
              Qodem modo typi, qui nunc nobis videntur parum clari, fiant
              sollemnes in futurum quam nunc putamus claram est usus autem vel.
            </article>
            <address>
              <i>by Esther Howard</i>
            </address>

            <p style={{ color: "#585C7B" }} className="mt-4 text-dark">
              Quisque dictum felis eu pede mollis pretium. Integer tincidunt.
              Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate
              eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
              eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
              feugiat a, tellus. Phasellus viverra nulla ut metus varius
              laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi
              vel augue.
            </p>
            <img
              className="img-fluid rounded-4"
              src={require("./../../assets/images/blog/preview_img.png")}
              alt=""
            />
            <p style={{ color: "#585C7B" }} className="mt-4 text-dark">
              Quisque dictum felis eu pede mollis pretium. Integer tincidunt.
              Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate
              eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
              eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
              feugiat a, tellus. Phasellus viverra nulla ut metus varius
              laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi
              vel augue.
            </p>
            <h2 style={{ color: "#243757" }} className="fs-24 fw-800 mt-5">
              Paragraph Title 2
            </h2>
            <p className="mt-2">
              Praesent sed pulvinar posuere nisl tincidunt. Iaculis sit quam
              magna congue. Amet vel non aliquet habitasse. Egestas erat odio
              et, eleifend turpis etiam blandit interdum. Nec augue ut senectus
              quisque diam quis. At augue accumsan, in bibendum. A eget et, eget
              quisque egestas netus vel. Velit, aliquet turpis convallis
              ullamcorper. Scelerisque sagittis condimentum pretium in vitae
              etiam lacinia quis amet. Porttitor consequat, sollicitudin vivamus
              pharetra nibh faucibus neque, viverra. Praesent amet sed lacus
              vitae.
            </p>

            <div className="lists">
              <h3
                className="fw-800 fs-16 d-flex align-items-center justify-content-start gap-1"
                style={{ color: "#243757" }}
              >
                <span>
                  <img
                    className="img-fluid rounded-4"
                    src={require("./../../assets/images/blog/icon/plus-circle.png")}
                    alt=""
                  />
                </span>
                Pros
              </h3>
              <ul>
                <li>
                  A eu, ac nunc, volutpat. Augue enim ac justo, at elementum,
                  arcu.
                </li>
                <li>
                  At sodales quam felis ullamcorper iaculis tristique. Felis,
                  etiam felis pellentesque sit neque.
                </li>
                <li>
                  Porta ipsum quis lacus eu ipsum mattis sit quis. Massa, massa
                  lectus porttitor laoreet ultricies odio fermentum arcu quam.
                </li>
                <li>
                  Accumsan arcu neque, nisl, pellentesque fames justo consequat
                  blandit lacus. Eget odio vel nulla vel.
                </li>
                <li>
                  Diam ac phasellus est, eu urna purus blandit aliquam. Vitae
                  accumsan et pellentesque diam in.
                </li>
                <li>
                  Tellus arcu, lectus tincidunt neque nunc. Bibendum lacus,
                  molestie ultrices sed arcu.
                </li>
              </ul>

              <h3
                className="fw-800 fs-16 d-flex align-items-center justify-content-start gap-1"
                style={{ color: "#243757" }}
              >
                <span>
                  <img
                    className="img-fluid rounded-4"
                    src={require("./../../assets/images/blog/icon/minus-circle.png")}
                    alt=""
                  />
                </span>
                Cons
              </h3>
              <ul>
                <li>
                  A eu, ac nunc, volutpat. Augue enim ac justo, at elementum,
                  arcu.
                </li>
                <li>
                  At sodales quam felis ullamcorper iaculis tristique. Felis,
                  etiam felis pellentesque sit neque.
                </li>
                <li>
                  Porta ipsum quis lacus eu ipsum mattis sit quis. Massa, massa
                  lectus porttitor laoreet ultricies odio fermentum arcu quam.
                </li>
                <li>
                  Accumsan arcu neque, nisl, pellentesque fames justo consequat
                  blandit lacus. Eget odio vel nulla vel.
                </li>
                <li>
                  Diam ac phasellus est, eu urna purus blandit aliquam. Vitae
                  accumsan et pellentesque diam in.
                </li>
                <li>
                  Tellus arcu, lectus tincidunt neque nunc. Bibendum lacus,
                  molestie ultrices sed arcu.
                </li>
              </ul>
            </div> */}
            {/* 
            <div className="mt-4">
              <h3 className="fw-800 fs-24" style={{ color: "#243757" }}>
                Conclusion
              </h3>
              <p>
                Venenatis faucibus platea gravida amet sed sed urna volutpat.
                Aliquam neque, mi, justo odio semper. Egestas potentiquis neque
                nunc, rhoncus hendrerit. Viverra gravida pretium dolor eget
                placerat morbi proin eget. Vestibulum vitae ultrices vel sed sit
                quis. Ac quis diam at nulla libero. Turpis duis magna tellus
                condimentum.{" "}
              </p>

              <p>
                Velit parturient tellus tellus, congue pulvinar tellus viverra.
                In cum massa mattis ac. Amet vitae massa ut mi etiam. Auctor
                aliquam tristique felis donec eu sit nisi. Accumsan mauris eget
                convallis mattis sed etiam scelerisque.
              </p>
            </div> */}

            <div className="relatedTags d-flex align-items-start gap-3">
              <h3 className="fs-16 fw-800" style={{ color: "#243757" }}>
                Related Tags:
              </h3>
              <div className="d-flex gap-3 flex-wrap ">
                {singleData.tag.map((item) => (
                  <Link key={item.id} to={"#"}>
                    #{item.title}
                  </Link>
                ))}
              </div>
            </div>

            <div className="pagination d-flex justify-content-between mt-5">
              <div className="prev">
                <div className="d-flex gap-3">
                  <div>
                    <Link>
                      <img
                        src={require("../../assets/images/blog/icon/arrow_2.png")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <h4 className="fs-14">PREVIOUS</h4>
                    <h3 className="fs-16 text-dark">How does screen work</h3>
                  </div>
                </div>
              </div>

              <div className="next">
                <div className="d-flex gap-3 flex-row-reverse ">
                  <div>
                    <Link>
                      <img
                        className="next-btn"
                        src={require("../../assets/images/blog/icon/arrow_2.png")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="text-end">
                    <h4 className="fs-14">NEXT</h4>
                    <h3 className="fs-16 text-dark">How does screen work</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/* <CommentSystem/> */}
          {/* <BlogForm /> */}
        </div>
        <div className="col-12 col-lg-4">
          <RelatedSegment heading={"Trending Posts"} data={blogData} />
          <RelatedSegment heading={"Popular Posts"} data={blogData} />
          <RelatedSegment heading={"Recent Posts"} data={blogData} />
          <BlogCatCard />
        </div>
      </div>
    </div>
  );
};

export default DynamicPost;
