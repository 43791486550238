import React from "react";

const Address = ({ title, desc }) => {
  return (
    <div className="address-info mb-64">
      <p className="text-uppercase fs-20 fw-600">{title}</p>
      <p className="text-uppercase fs-16 fw-500">{desc}</p>
    </div>
  );
};

export default Address;
