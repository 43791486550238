import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const UserInterfaceAndCollaboration = () => {
  return (
    <>
      <div className="payroll-feature">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                
                title="User Interface, System Dynamics, and Collaboration"
                desc="Streamline both your data collection and secure storage with our employee database management system. Set up your organization and maintain all your employee records in one central location."
              />
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                  Responsive Design
                  </h3>
                  <p className="fs-14">
                  Optimal user experience across various devices.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/organogram-management/responsive-design.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                  Interactive User Interface
                  </h3>
                  <p className="fs-14">
                  User-friendly interface for intuitive navigation and interaction.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/organogram-management/interactive.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row h-100">
                <div className="col-12 mb-3">
                  <div className="card stat-card card-shadow card-border p-3 d-flex flex-row gap-3 radius-20">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24">
                      Dynamic Updates
                      </h3>
                      <p className="fs-14">
                      Stay informed with real-time updates on organizational changes.
                      </p>
                    </div>
                    <div className="img-content text-center ">
                      <img
                        className="mt-3 fs-14 img-fluid"
                        src={require("./../../../assets/images/feature/organogram-management/dynamic.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="card stat-card card-shadow card-border p-3 d-flex flex-row gap-3 radius-20">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24">
                      Collaborative Features
                      </h3>
                      <p className="fs-14">
                      Experience an interactive user interface that goes beyond the ordinary. Our system ensures effortless navigation
                      </p>
                    </div>
                    <div className="img-content text-center ">
                      <img
                        className="mt-3 fs-14 img-fluid"
                        src={require("./../../../assets/images/feature/organogram-management/statistic.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="card stat-card card-shadow card-border p-3 radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                  Scalability
                  </h3>
                  <p className="fs-14">
                  Accommodates organizations of all sizes, including those with complex structures.
                  </p>
                </div>
                <div className="img-content text-center">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/organogram-management/scalability.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInterfaceAndCollaboration;
