import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NoticeBoardAttachfile = ({bg}) => {
  return (
    <>
      <div className={`online-service NoticeBoard-attachfile ${bg}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/digital-noticeboard/image11.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <FullWidthHeading
                title={"Easier to attach file with every notice"}
                desc={
                  "No more hassles of printing and manually posting notices on physical boards. The Digital Notice Board allows HR administrators and authorized personnel to post official announcements, policy updates, other important information through notice attachments in a centralized digital space."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBoardAttachfile;
