import React from "react";
import { NotificationFeatureData } from "../../../assets/json/NotificationFeatureData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NotificationFeature = () => {
  return (
    <>
      <div className="notification-feature">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <FullWidthHeading
                sectionTitle={"KEY FEATURES"}
                title={"All your occasions covered by specialized alerts"}
                desc={
                  "Business clients can effortlessly draft notifications tailored to specific events, ensuring that the right message reaches the right audience at the right time."
                }
              />
            </div>
            {NotificationFeatureData.map((item, index) => (
              <div key={index} className="col-lg-3 mb-3">
                <InfoCard2
                  id={item.id}
                  className={'h-100'}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                  data={item.data}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationFeature;
