import React from "react";

const SectionHeading = ({ children, className,titleClass, name, title, desc, data, fs }) => {
  return (
    <div className={`heading centered-heading  ${className}`}>
      <div className={`section-name ${titleClass ? titleClass :'text-primary'} fw-bold fs-16 text-uppercase`}>{name}</div>
      <h2 className={`${fs}`}>{title}</h2>
      <p className="section-desc fs-12 mt-3">{desc}</p>
      <div className="text hr-list-item" dangerouslySetInnerHTML={{__html: data}}/>
    </div>
  );
};

export default SectionHeading;
