import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecurityDecentraliazation = ({bg}) => {
  return (
    <>
      <div className={`details-section-6x6 blockSecurity-decentraliazation ${bg}`}>
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div className="img-content">
                <img
                  className="img-fluid"
                  src={require("../../../assets/images/comp/layer.png")}
                  alt="error"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
            <FullWidthHeading sectionTitle={"ENSURING 100% ACCURACY"}
                title={"Ensuring Reliability Through Consensus Mechanism and Unanimous Agreement for Data Accuracy"}
                desc={"SmartPeople's implementation of blockchain utilizes a consensus mechanism, where multiple nodes in the network validate and agree on the accuracy of user activity data. This decentralized validation process ensures that all records are consistent, removing any single point of failure and guaranteeing 100% accuracy in tracking user actions."}
                
            />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityDecentraliazation;
