import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";

const AboutWorks = () => {
  return (
    <>
      <div className="payroll-works about-experience">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            {/* Section Header */}
              <SectionHeading
              name={'PURPOSE'}
              titleClass={'text-center text-primary'}
                title="Our purpose is to explore, innovate and invest 
for a better tomorrow."
                desc="Our purpose can be defined by our continuous exploration of new ideas, leading to a stream of continuous innovation, which is followed by continuous investment. It drives everything we do, providing a sense of meaning and direction for our journey. We envision creating a better tomorrow for the world and beyond.
"
                fs="fs-48"
              />

              {/* Tab */}
              <div className="tab-section mt-5">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Continuous Exploration
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      Continuous Innovation
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    >
                      Continuous Investment
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="better-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#better-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="better-tab-pane"
                      aria-selected="false"
                    >
                      For A Better Tomorrow
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="heading text-start">
                          <div className="section-name text-primary fs-16">
                            START WITH US
                          </div>
                          <h2 className="fs-48 text-start">
                          Continuous Exploration
                          </h2>
                          <p className="fs-12 mt-3">
                          An unending exploration of new ideas, designs, concepts, models, and technological advancements is how it starts at SmartOffice.ai. Accumulation of knowledge through continuous research, experimentation, and testing comes prior to innovation in our journey. Following the rhythm of time and space, we reimagine the possibilities of doing things differently and in a better form. It is a relentless journey of exploration that continues even after we meet our current objectives. We nurture a culture of seeking out the most advanced and revolutionary ideas to work with and invest in.

                          </p>
                          {/* <Link
                            to={"#"}
                            className="btn btn-outline-primary radius-8"
                          >
                            Learn more
                          </Link> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../assets/images/about/about-tab-img.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="heading text-start">
                          <div className="section-name text-primary fs-16">
                            START WITH US
                          </div>
                          <h2 className="fs-48 text-start">
                          Continuous Innovation

                          </h2>
                          <p className="fs-12 mt-3">
                          Innovation is compared to the engine of SmartOffice.ai. From innovation emerge exclusive models, systems, or mechanisms that help us generate exceptional products and services for the world. We employ and excel in incremental and disruptive innovations to usher breakthroughs consistently. Innovative ideas validated by exploration are executed through methods inspired by ‘agile’ development principles. Our commitment to the process of creating value through innovation is reinforced by our purpose strategically and methodically. In essence, our purpose cultivates innovation in a more holistic way, engaging employees to accomplish objectives with unrestrained passion and creativity.

                          </p>
                          {/* <Link
                            to={"#"}
                            className="btn btn-outline-primary radius-8"
                          >
                            Learn more
                          </Link> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../assets/images/about/continuous-innovation.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="heading text-start">
                          <div className="section-name text-primary fs-16">
                            START WITH US
                          </div>
                          <h2 className="fs-48 text-start">
                          Continuous Investment

                          </h2>
                          <p className="fs-12 mt-3">
                          Investment at SmartOffice.ai follows the steps of exploration and innovation. The validation and refinement of ideas and innovation in any field must pass a certain bench mark before getting selected for capital investment. We only make long-term investments where we can create an impact at scale. What differentiates SmartOffice.ai from any other business organization is its purpose of investments. The aim of our investment goes beyond profit-making. Our investments are guided by our vision to establish a better future for everyone. We invest in meaningful ventures through which we can make socio-economic contributions.

                          </p>
                          {/* <Link
                            to={"#"}
                            className="btn btn-outline-primary radius-8"
                          >
                            Learn more
                          </Link> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../assets/images/about/continuous-investment.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="better-tab-pane"
                    role="tabpanel"
                    aria-labelledby="better-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="heading text-start">
                          <div className="section-name text-primary fs-16">
                            START WITH US
                          </div>
                          <h2 className="fs-48 text-start">
                          For A Better Tomorrow

                          </h2>
                          <p className="fs-12 mt-3">
                          We are building an autonomous institution for fulfilling our purpose — an institution through which we can explore, innovate and invest for a better tomorrow. Here, a better tomorrow is a broad and open-ended term where tomorrow means the next day, the next week, the next month, the next year, and most importantly, the next life.

SmartOffice.ai is a responsible and visionary organization investing for a better tomorrow. The organization moves with an explicit purpose to create a better world and a better future for humanity, providing them with exceptional products and services. Harvesting the power of innovation, knowledge, technology, higher moral standards, and integrity, we are resolute in shaping a better tomorrow for all we can influence.

                          </p>
                          {/* <Link
                            to={"#"}
                            className="btn btn-outline-primary radius-8"
                          >
                            Learn more
                          </Link> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../assets/images/about/for-a-better-tomorrow.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutWorks;
