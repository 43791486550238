import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";
import { Breadcrumb, Button, Col, Form, Row } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Banner from "../components/HelpCenter/Banner";

const fileTypes = ["JPEG", "PNG", "GIF"];
const Sales = () => {
  const editorRef = useRef(null);
  const [file, setFile] = useState(null);
  const [phone, setPhone] = useState("");
  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <>
      <Banner
        title={`Sales`}
        desc={`Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. `}
      />
      <div className="container billing">
        <div className="row">
          <div className="col-12">
            <Breadcrumb>
              <Breadcrumb.Item href="/contact">Contact Us</Breadcrumb.Item>
              <Breadcrumb.Item active>Sales</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-12">
            <Form className="form">
              <Row>
                <Col xl={12} className="mb-3">
                  <Form.Label>Choose your issue below</Form.Label>
                  <Form.Select defaultValue="Sales" disabled className="default-select-option">
                    <option>Sales</option>
                  </Form.Select>
                </Col>
                <Col xl={6} className="mb-3">
                  <Form.Label>Email address*</Form.Label>
                  <Form.Control placeholder="Enter email" />
                </Col>
                <Col xl={6} className="mb-3">
                  <Form.Label>Mobile Number *</Form.Label>
                  <PhoneInput
                    className="form-control"
                    defaultCountry="BD"
                    value={phone}
                    onChange={(phone) => setPhone(phone)} 
                    placeholder="Enter Number"
                  />
                </Col>
                <Col xl={12} className="mb-3">
                  <Form.Label>Enter your query *</Form.Label>
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    init={{
                      selector: "textarea",
                      placeholder: "Write here....",
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount"
                      ],

                      toolbar:
                        "bold italic underline | alignleft aligncenter | ",
                      table_toolbar:
                        "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                      toolbar_mode: "wrap" | "scrolling",
                      toolbar_sticky: true,
                      toolbar_sticky_offset: 100
                    }}
                  />
                </Col>
                <Col xl={12} className="mb-3">
                  <Form.Label>Attachments</Form.Label>
                  <FileUploader  classes="file-uploading"
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    label={"Add file or drop files here"}
                    hoverTitle={"Drop here"}
                  />
                  <p className="upload-status">
                    {file
                      ? `File name: ${file[0].name}`
                      : "no files uploaded yet"}
                  </p>
                </Col>
                <Col xl={12}>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sales;
