import React from "react";
import heroIMG from "../../../assets/images/feature/notification/CUSTOMIZED NOTIFICATION.png";
import Hero from "../../Home/Hero";

const NotificationHero = () => {
  return (
    <>
      <Hero
        img={heroIMG}
        className="notification-hero"
        name="CUSTOMIZED NOTIFICATION"
        title="Customized notifications for all your needs"
        desc="SmartPeople's Customized Notification Management is a powerful tool that enhances internal communication within organizations. With the ability to create tailored notification templates, target specific groups, and utilize multiple communication channels, businesses can ensure that vital information reaches employees promptly and accurately. "
        btn="Free Signup"
      />
    </>
  );
};

export default NotificationHero;
