import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const TaxManagementTax = () => {
  return (
    <>
      <div className="payroll-works TaxManagement-tax">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <FullWidthHeading
                sectionTitle={"WHY SmartPeople FOR PAYROLL TAX AUTOMATION "}
                title={
                  "Unlock the power of Payroll Tax Automation with SmartPeople"
                }
                desc={
                  "Managing payroll taxes can be a tedious and error-prone task, but with SmartPeople's Payroll Tax Automation, you can simplify the process and focus on what matters most for your business."
                }
              />

              <div className="tab-section row">
                <div className="col-lg-4">
                  <h3 className="tab-title">Save with Potential Tax Credits</h3>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="item1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#item1"
                        type="button"
                        role="tab"
                        aria-controls="item1"
                        aria-selected="true"
                      >
                        Real-time Updates
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="item2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#item2"
                        type="button"
                        role="tab"
                        aria-controls="item2"
                        aria-selected="false"
                      >
                        Time Savings
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="item3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#item3"
                        type="button"
                        role="tab"
                        aria-controls="item3"
                        aria-selected="false"
                      >
                        Enhanced Accuracy
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="item4-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#item4"
                        type="button"
                        role="tab"
                        aria-controls="item4"
                        aria-selected="false"
                      >
                        Scalable Solution
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="item5-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#item5"
                        type="button"
                        role="tab"
                        aria-controls="item5"
                        aria-selected="false"
                      >
                        Expert Support
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="item1"
                      role="tabpanel"
                      aria-labelledby="item1"
                      tabIndex="0"
                    >
                      <div className="row align-items-center">
                        <div className="img mb-5">
                          <img
                            src={require("./../../../assets/images/feature/tax-management/Save-with-Potential-Tax-Credits/Real-time Updates.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h6>Real-time Updates</h6>
                        <p>
                          Tax laws are subject to change, and staying updated is
                          crucial to avoid penalties. SmartPeople keeps you
                          informed with real-time updates, ensuring your payroll
                          tax processes remain compliant.
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="item2"
                      role="tabpanel"
                      aria-labelledby="item2"
                      tabIndex="0"
                    >
                      <div className="row align-items-center">
                        <div className="img mb-5">
                          <img
                            src={require("./../../../assets/images/feature/tax-management/Save-with-Potential-Tax-Credits/Time-Savings-01.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h6>Time Savings</h6>
                        <p>
                          Manual tax management can be time-consuming and prone
                          to errors. By automating the process with SmartPeople,
                          you free up valuable time for your HR and finance
                          teams to focus on strategic initiatives.
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="item3"
                      role="tabpanel"
                      aria-labelledby="item3"
                      tabIndex="0"
                    >
                      <div className="row align-items-center">
                        <div className="img mb-5">
                          <img
                            src={require("./../../../assets/images/feature/tax-management/Save-with-Potential-Tax-Credits/Enhanced Accuracy.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h6>Enhanced Accuracy</h6>
                        <p>
                          Mistakes in payroll tax calculations can lead to
                          costly consequences. SmartPeople's automated system
                          guarantees precise and error-free tax computations,
                          minimizing the risk of penalties.
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="item4"
                      role="tabpanel"
                      aria-labelledby="item4"
                      tabIndex="0"
                    >
                      <div className="row align-items-center">
                        <div className="img mb-5">
                          <img
                            src={require("./../../../assets/images/feature/tax-management/Save-with-Potential-Tax-Credits/Scalable Solution.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h6>Scalable Solution</h6>
                        <p>
                          Whether you're a small business or a large enterprise,
                          SmartPeople's Payroll Tax Automation is designed to
                          grow with your needs. From handling a few employees to
                          thousands, we've got you covered.
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="item5"
                      role="tabpanel"
                      aria-labelledby="item5"
                      tabIndex="0"
                    >
                      <div className="row align-items-center">
                        <div className="img mb-5">
                          <img
                            src={require("./../../../assets/images/feature/tax-management/Save-with-Potential-Tax-Credits/Expert Support.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h6>Expert Support</h6>
                        <p>
                          Our dedicated support team is available to assist you
                          every step of the way. From implementation to ongoing
                          assistance, we are committed to ensuring a smooth and
                          successful payroll tax automation experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxManagementTax;
