import React from "react";

const SingleBlogHero = ({ singleData }) => {
  console.log(singleData);
  return (
    <div className="container mt-4">
      <img
        className="img-fluid radius-12 w-100"
        src={require(`../../assets/images/comp/${singleData.img}`)}
        alt="error"
      />
      <div className="post-info d-flex align-items-center gap-3 flex-wrap">
        <img
          className="img-fluid user-img"
          src={require(`../../assets/images/customer/item4.png`)}
          alt="error"
        />
        <p>By SmartOffice</p>
        <div className="d-flex align-items-center gap-3">
          <p>{singleData.publishDate}</p>
          {/* <div className='post-like d-flex align-items-center'>
                        <Link href='#'><img src='' alt='' /></Link>
                        <span className='fs-16'><img src={require('../../assets/images/blog/icon/like.png')}  alt='' /> 8</span>
                    </div>
                    <div className='post-comment'>
                        <Link><img src='' alt='' /></Link>
                        <span className='fs-16'><img src={require('../../assets/images/blog/icon/message.png')}  alt='' /> 8</span>
                    </div>
                    <div className='post-share'>
                        <Link><img src='' alt='' /></Link>
                        <span className='fs-16'><img src={require('../../assets/images/blog/icon/share.png')}  alt='' /> 8</span>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleBlogHero;
