import React from "react";
import { Breadcrumb } from "react-bootstrap";

const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Breadcrumb>
              <Breadcrumb.Item href="/contact">Compare</Breadcrumb.Item>
              <Breadcrumb.Item active>SmartOffice vs Rippling</Breadcrumb.Item>
            </Breadcrumb>

            <h2>What’s the difference?</h2>
            <p>
              Below, you can directly compare Gusto with BambooHR. By placing
              both options next to each other, you can better decide which
              platform is best for your payroll and HR needs.
            </p>
            <div className="appvs">
                <div className="logo">
                    <img className="img-fluid" src={require("./../../assets/images/comparewithrippling/smartOffice.png")} alt=""/>
                </div>
                <h4>Vs</h4>
                <div className="logo">
                    <img className="img-fluid" src={require("./../../assets/images/comparewithrippling/rippling.png")} alt=""/>
                </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-img">
                <img src={require("./../../assets/images/comparewithrippling/heroCompare.png")} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;