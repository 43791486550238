import React from "react";
import Faqs from "../../components/Common/Faqs";
import LeaveManagementBlockchain from "../../components/Feature/LeaveManagement/LeaveManagementBlockchain";
import LeaveManagementControl from "../../components/Feature/LeaveManagement/LeaveManagementControl";
import LeaveManagementHero from "../../components/Feature/LeaveManagement/LeaveManagementHero";
import LeaveManagementPolicies from "../../components/Feature/LeaveManagement/LeaveManagementPolicies";
import LeaveManagementSystem from "../../components/Feature/LeaveManagement/LeaveManagementSystem";
import LeaveManagementTakeContol from "../../components/Feature/LeaveManagement/LeaveManagementTakeContol";
// import LeaveManagementTeamCalender from "../../components/Feature/LeaveManagement/LeaveManagementTeamCalender";
import LeaveManagementWellbeing from "../../components/Feature/LeaveManagement/LeaveManagementWellbeing";
import LeaveManagementWorkweeks from "../../components/Feature/LeaveManagement/LeaveManagementWorkweeks";
import "./../../assets/css/future.css";

const LeaveManagement = () => {
  return (
    <>
      <LeaveManagementHero />
      <LeaveManagementSystem />
      <LeaveManagementControl />
      <LeaveManagementWellbeing bg={"bg-shadow"}/>
      <LeaveManagementTakeContol />
      <LeaveManagementWorkweeks />
      <LeaveManagementBlockchain />
      {/* <LeaveManagementTeamCalender /> */}
      <LeaveManagementPolicies />
      <Faqs />
    </>
  );
};

export default LeaveManagement;
