import React from "react";
import { Link } from "react-router-dom";
import CompareTable from "./CompareTable";

const Compare = ({columns, data}) => {
  return (
    <div className="compare">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4>Compare</h4>
            <h2>Why choose <Link to="https://smartoffice.ai/">SmartOffice</Link> over Rippling?</h2>
            <p>
              Use project management software that scales with you. See why
              SmartOffice's Tasks, Docs, and Goals make it the #1 Rippling
              alternative.
            </p>
            <CompareTable columns={columns} data={data}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compare;