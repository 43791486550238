import React from "react";

const MiniCard = ({ icon, title }) => {
  return (
    <div className="mini-card  text-center radius-16 mb-3 mx-auto">
      <div className="img-content ">
        <img
          src={require(`./../../assets/images/comp/${icon}`)}
          alt="error"
        />
      </div>
      <div className="text-content ">
        <h4 className="fs-14 fw-600">{title}</h4>
      </div>
    </div>
  );
};

export default MiniCard;
