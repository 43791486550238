import React from "react";
import BannerText from "../Banner/BannerText";

const DeviceHero = ({ title, desc, btnText, bigImage, sectionTitle }) => {
  return (
    <>
      <div className="container ">
        <div className="row align-items-center device-hero ">
          <div className="col-12 col-md-6 d-flex flex-column align-item-center justify-content-center">
            <BannerText
              title={title}
              desc={desc}
              btnText={btnText}
              sectionTitle={sectionTitle}
            />
          </div>
          <div className="col-12 col-md-6">
            <img
              className="img-fluid"
              src={require(`../../assets/images/hero/${bigImage}`)}
              alt="Error"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceHero;
