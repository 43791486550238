import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const PricingCard = ({ state, multiplyer, data }) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
  return (
    <div
      className={`pricing-card ${state} mx-auto ${
        data.isPopular ? "most-populer" : ""
      }`}
    >
      <div className="text-end position-absolute popular">
        <h5 className="badge bg-light radius-20 text-primary ">
          {data.isPopular && "Most populer"}
        </h5>
      </div>
      <div className="package-price ">
        {data.packageName === "Enterprise" ? (
          <div>
            <span className="text-dark "></span>
            <span> </span>
          </div>
        ) : (
          <div>
            <span className="text-dark ">${multiplyer * data.price}</span>
            <span> /{multiplyer > 1 ? " year" : "month"}</span>
          </div>
        )}
      </div>
      <h3 className="package-name mt-3 fw-700"> {data.packageName} </h3>
      <div className="d-flex flex-column" style={{ minHeight: "280px" }}>
        <p className="package-desc ">
          For most businesses that want to otpimize web queries
        </p>
        <div className="package-features">
          <ul>
            {data.packageLists.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="text-center">
        {data.packageName === "Enterprise" ? (
          <HashLink className="fs-16 fw-600 btn bg-primary-thin radius-20 w-100 mx-auto text-primary" to={`/contact/#go-to-contact`} smooth={true} scroll={scrollWithOffset}>
            {data.btnLabel}
          </HashLink>
        ) : (
          <Link
            to={"https://apps.smartoffice.ai/register"}
            className="fs-16 fw-600 btn bg-primary-thin radius-20 w-100 mx-auto text-primary"
          >
            {data.btnLabel}
          </Link>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
