import React from 'react';

const Resources = () => {
    return (
        <div>
            Resources
        </div>
    );
};

export default Resources;