import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const AttendanceManagementTimeTracking = () => {
  return (
    <>
      <section className="AttendanceManagement-timeTracking">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/attendance-management/time-tracking.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <FullWidthHeading
                sectionTitle="TIME TRACKING"
                title="Maximize Efficiency with Smart Time Tracking"
                data='<div class="time-tracking-part"> <div class="time-tracking-item"> <p> Our Attendance Management System is equipped with intelligent time tracking features to maximize efficiency in your workforce. Seamlessly record employee attendance, track working hours, and generate accurate reports, empowering you to optimize productivity and streamline workforce management. 
Say goodbye to manual tracking and embrace the simplicity and precision of our smart time tracking solution.
</p></div></div>'
              />
             
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default AttendanceManagementTimeTracking;
