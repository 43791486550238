const BlockSecurityFeatureData=[
    {
        id: 0,
        icon: require("./../images/feature/blockchain/empowering-users-to-take-ownership.png"),
        title: "Empowering Users to Take Ownership",
        desc:"Blockchain technology not only enhances accountability for authorities but also empowers individual users to take ownership of their actions. Through transparent user activity tracking, employees within organizations using SmartPeople gain valuable insights into their own actions. This enables them to review their contributions, understand their impact, and take proactive steps to improve their performance."
    },
    {
        id: 1,
        icon: require("./../images/feature/blockchain/Transparency & Accountability.png"),
        title: "Transparency & Accountability        ",
        desc:"With SmartPeople's blockchain-powered user activity tracking, each action taken by users is securely recorded and visible in real-time. This transparent system fosters a culture of personal accountability, where employees can track their own activities and evaluate their performance objectively. By having access to this data, users can make informed decisions, align their actions with organizational goals, and contribute more effectively to the overall success of the organization."
    },
    {
        id: 2,
        icon: require("./../images/feature/blockchain/Staying Proactive with SmartPeople.png"),
        title: "Staying Proactive with SmartPeople        ",
        desc:"With SmartPeople's blockchain-powered user activity tracking, administrators and managers have access to real-time monitoring capabilities. This enables them to stay proactive in ensuring the security of the system and its data. In case of any suspicious activities or anomalies, SmartPeople sends instant notifications to the relevant authorities, allowing them to promptly investigate and take necessary actions. This proactive approach helps to mitigate potential risks and maintain a secure environment for all users.        "
    },
    {
        id: 3,
        icon: require("./../images/feature/blockchain/Ensuring Prompt Response to Suspicious Activities.png"),
        title: "Ensuring Prompt Response to Suspicious Activities",
        desc:"Through blockchain technology, SmartPeople ensures that any unusual user activity is promptly detected and reported. The system's ability to monitor activities in real-time empowers administrators and managers to stay vigilant and respond swiftly to any potential threats or unauthorized actions. By receiving instant notifications, the team can take immediate action to safeguard sensitive information and maintain the integrity of the payroll process.        "
    },
    {
        id: 4,
        icon: require("./../images/feature/blockchain/aligning-with-global-standards.png"),
        title: "Aligning with Global Standards        ",
        desc:"At SmartPeople, we prioritize data protection and compliance with global regulations, including the General Data Protection Regulation (GDPR). By leveraging blockchain technology, we ensure transparent user activity tracking while maintaining robust data security measures. This alignment with GDPR standards offers organizations and their employees peace of mind, knowing that their data is handled with utmost care and in accordance with international data protection regulations"
    },
    {
        id: 5,
        icon: require("./../images/feature/blockchain/Ensuring Peace of Mind.png"),
        title: "Ensuring Peace of Mind        ",
        desc:"SmartPeople's commitment to GDPR compliance and data protection ensures that sensitive information remains secure and tamper-proof. With blockchain-powered transparency and data integrity, our platform provides a safe environment for organizations and their employees. Rest assured, your data is handled responsibly, meeting the highest global standards for data protection."
    },
];
export { BlockSecurityFeatureData };

