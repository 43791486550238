import React from "react";
import Faqs from "../../components/Common/Faqs";
import Hero from "../../components/Feature/ShiftManagement.js/Hero";
import ShiftManagementAnalytics from "../../components/Feature/ShiftManagement.js/ShiftManagementAnalytics";
import ShiftManagementBlockchain from "../../components/Feature/ShiftManagement.js/ShiftManagementBlockchain";
import ShiftManagementEmployeeFocused from "../../components/Feature/ShiftManagement.js/ShiftManagementEmployeeFocused";
// import ShiftManagementGlobe from "../../components/Feature/ShiftManagement.js/ShiftManagementGlobe";
import ShiftManagementReduce from "../../components/Feature/ShiftManagement.js/ShiftManagementReduce";
import ShiftManagementSchedule from "../../components/Feature/ShiftManagement.js/ShiftManagementSchedule";
// import ShiftManagementSolution from "../../components/Feature/ShiftManagement.js/ShiftManagementSolution";
import "./../../assets/css/future.css";

const ShiftManagement = () => {
  return (
    <>
      <Hero />
      <ShiftManagementReduce />
      <ShiftManagementAnalytics />
      {/* <ShiftManagementSolution /> */}
      {/* <ShiftManagementGlobe /> */}
      <ShiftManagementEmployeeFocused />
      <ShiftManagementBlockchain bg={"bg-shadow"}/>
      <ShiftManagementSchedule />
      <Faqs />
    </>
  );
};

export default ShiftManagement;
