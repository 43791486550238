const NoticeBoardManagementData =[
    {
        id: 0,
        title: "Easy Scheduling:",
        img: "Easy-Scheduling.png",
        desc: "Schedule your notices within seconds with SmartPeople. Your workforce is only presented with relevant and latest notices.",
        btn: "Explore Scheduling",
        btnLink: "#"
    },
    {
        id: 1,
        title: "Publish Instantly:        ",
        img: "Publish-Instantly.png",
        desc: "SmartPeople ensures 0% communication gaps through Notices. Everyone is aligned with the latest announcements.        ",
        btn: "Explore Attendance",
        btnLink: "#"
    },
    {
        id: 2,
        title: "Manage Segregation:",
        img: "Manage-Segregation.png",
        desc: "SmartPeople allows you to publish notices for customized user groups, efficiently reducing time to read through everything.",
        btn: "Explore Messaging",
        btnLink: "#"
    },
];

export { NoticeBoardManagementData };

