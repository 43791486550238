import React from "react";
import { BlockSecurityIntegrateData } from "../../../assets/json/BlockSecurityIntegrateData";
import InfoCard3 from "../../Common/InfoCard3";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecurityIntegrate = () => {
  return (
    <>
      <div className="payroll-benifits blockSecurity-integrate">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle={"ENHANCED USER VISIBILITY"}
                title={"Empowering Authorities to Track User Activity with Precision"}
                desc={
                  "With blockchain technology, authorities and administrators have unprecedented visibility into user activity across SmartPeople. The transparent nature of blockchain allows for easy tracing of each action back to its origin, providing a comprehensive audit trail. This level of visibility ensures accountability and enables swift identification of any unusual or unwanted activity."
                }
              />

              <div className="img mb-5">
                <img
                  src={require("../../../assets/images/feature/blockchain/ENHANCED USER VISIBILITY.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>

            {BlockSecurityIntegrateData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-4">
                <InfoCard3 id={item.id} icon={item.icon} title={item.title} desc={item.desc} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityIntegrate;
