import React from "react";
import { ScheduleData } from "../../../assets/json/ScheduleData";
import FullWidthHeading from "../../Device/FullWidthHeading";
import ScheduleCard from "../../ScheduleCard/ScheduleCard";

const ShiftManagementReduce = () => {
  return (
    <>
      <div className="shiftManagement-reduce">
        <div className="container">
          <div className="row">
            {ScheduleData.map((item, index) => (
              <div key={index} className="col-lg-4">
                <ScheduleCard
                id={item.id}
                  cardTitle={item.cardTitle}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                />
              </div>
            ))}
            <div className="col-12">
              <FullWidthHeading
                sectionTitle="STAFF SCHEDULING SOFTWARE"
                title="Reduce chaos, costs, and turnover"
                desc="Employee scheduling and time tracking software for the modern workforce.
Desktop gives everyone the access they need."
              />

              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/shift-management/image3.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShiftManagementReduce;
