const BlockSecurityManagementData = [
  {
    id: 0,
    icon: require("./../images/feature/secureStorage.png"),
    title: "Decentralized and Immutable Ledger    ",
    desc: "At SmartPeople, blockchain technology serves as the backbone of our user activity tracking system. Through a decentralized and immutable ledger, every action within the application is securely recorded in a series of blocks. This robust architecture ensures that user activity data remains tamper-proof and resistant to any unauthorized modifications.    ",
    link: '#'
  },
  {
    id: 1,
    icon: require("./../images/feature/protect-insurance.png"),
    title: "Unalterable Chain of Activities",
    desc: "The use of blockchain forms an unalterable chain of activities within SmartPeople. Once recorded, each action becomes a permanent part of the blockchain, creating an unbroken sequence of events. This unchangeable chain provides a transparent and reliable history of user interactions, bolstering accountability and trust.    ",
    link: '#'
  },
  {
    id: 2,
    icon: require("./../images/feature/industry-practise.png"),
    title: "Unalterable Chain of Activities    ",
    desc: "The use of blockchain forms an unalterable chain of activities within SmartPeople. Once recorded, each action becomes a permanent part of the blockchain, creating an unbroken sequence of events. This unchangeable chain provides a transparent and reliable history of user interactions, bolstering accountability and trust.    ",
    link: '#'
  }
];

export { BlockSecurityManagementData };

