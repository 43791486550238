import React from "react";
import { Link } from "react-router-dom";
import BlogItem from "./BlogItem";

const TrendingBlogs = ({ title, data, btn }) => {
  return (
    <div className="trending-blogs-section  container mb-3">
      <div className="heading text-start d-flex justify-content-between align-items-center">
        <h2 className="fs-40 text-start">{title}</h2>
        <div>
          {btn && (
            <Link to="/viewallblog" className="btn btn-light">
              View All
            </Link>
          )}
        </div>
      </div>

      <BlogItem data={data} />
    </div>
  );
};

export default TrendingBlogs;
