const optimizeData =[
    {
      id: 0,
      icon: require("../images/overview/item-1.png"),
      title: "Unlock the Power of Data",
      desc: "Experience the future of HR analytics with our AI-powered dashboard. Allowing you to monitor key performance indicators, track trends, and identify areas for improvement."
    },
    {
      id: 1,
      icon: require("../images/overview/item-2.png"),
      title: "Real-Time Analytics",
      desc: "Gain instant access to critical HR data. Monitor attendance trends, analyze performance metrics, and visualize employee engagement levels in real-time. Make informed decisions on the spot, driving efficiency and productivity."
    },
    {
      id: 2,
      icon: require("../images/overview/item-3.png"),
      title: "Customizable Widgets",
      desc: "Tailor your dashboard to suit your unique HR needs. Our Dynamic AI Dashboard allows you to add, remove, and rearrange widgets effortlessly. Customize your view to focus on what matters most to your organization, ensuring you stay in control."
    },
    {
      id: 3,
      icon: require("../images/overview/item-4.png"),
      title: "Data Visualization Made Easy      ",
      desc: "Transform complex data into easily digestible visualizations. Our Dynamic AI Dashboard presents HR metrics in clear and interactive charts, graphs, and tables, enabling you to grasp information at a glance."
    }
  ]

  export { optimizeData }

