import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const PayrollManage = () => {
  return (
    <>
      <div className="payroll-manage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <FullWidthHeading title="Effortless Payroll and Benefits Management" desc="SmartPeople brings you the ultimate solution for payroll and people management. Pay your team on time, efficiently track their hours, and provide attractive benefits with ease. Our reliable payroll tax service guarantees precise calculations, leaving you worry-free and fully focused on your business's success.
" />
              
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/payroll/overlay.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollManage;
