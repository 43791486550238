import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";
import '../../../assets/css/feature.css'
const OrganogramManagementHero = () => {
  return (
    <>
      <div className="organogram-management-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <FullWidthHeading
                  sectionTitle="Organogram Management"
                  title="Revolutionizing Organizational Structure Management"
                  desc="Welcome to SmartPeople's Organogram Management System, where organizational structure visualization and management reach new heights."
                  btn="Free Signup"
                  btnLink="https://apps.smartoffice.ai/register"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/organogram-management/organogram-hero.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganogramManagementHero;
