import React from "react";
import { DomainCustomizationFeatureData } from "../../../assets/json/DomainCustomizationFeatureData";
import FullWidthHeading from "../../Device/FullWidthHeading";

const DomainCustomizationFeature = () => {
  return (
    <>
      <div className="payroll-feature domaincustomization-feature">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                sectionTitle={"FEATURES"}
                title={"Here, your domain is more than a name."}
                desc={
                  "For decades, SmartOffice has helped businesses like yours capture billions of dollars in federal and state-based tax credits, including"
                }
              />
            </div>
            {DomainCustomizationFeatureData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-3">
                <div className="card stat-card card-shadow card-border p-3 box-content  radius-20">
                  <div className="text-content">
                    <h3 className="text-dark fw-700 fs-24">{item.title}</h3>
                    <p className="fs-14">{item.desc}</p>
                  </div>
                  <div className="img-content text-center">
                    <img className="img-fluid fs-14" src={item.img} alt="error" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainCustomizationFeature;
