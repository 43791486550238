const pricing = [
  {
    id: 1,
    price: 0,
    packageName: "Free",
    packageLists:[
      "100 Custom Notification",
      "Storage (1GB/User)",
      "Data Migration Support",
      "24/7 Chat Support"
      
    ],
    btnLabel:"Try Now",
  },
  {
    id: 2,
    price: 3,
    packageName: "Baseline",
    packageLists:[
      "100 Custom Notification",
      "Storage (1GB/User) additional 1GB will Cost 0.25$ per month ",
      "Data Migration Support",
      "24/7 Chat Support"
    ],
    btnLabel:"Get Started"


  },
  {
    id: 1,
    price: 5,
    packageName: "Standard",
    isPopular: "yes",
    packageLists:[
      "1000 Custom Notification",
      "Storage (3GB/User) additional 1GB will Cost  0.25$ per month ",
      "Data Migration Support",
      "24/7 Chat Support"
    ],
    btnLabel:"Get Started"


  },
  {
    id: 1,
    price: NaN,
    packageName: "Enterprise",
    packageLists:[],
    btnLabel:"Contact Sales"
  },
];

const planData = [
  {
    id: 3,
    criteria: "Company Management",
    included: [
      {
        featureName: "Switch between companies",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Ability to create and manage multiple companies",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Company-specific settings and configurations",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Comprehensive employee profiles with personal and professional details",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Real-time tracking of employee attendance of each company",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
    ],
  },

    {
    id: 2,
    criteria: "Employee Management",
    included: [
      {
        featureName: "Employee Onboarding",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Manage user",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "User Search and Filtering",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "User Activation/Deactivation",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Password Management",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "User Profile Management",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Delete user accounts with role-based access",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Supervisor setting",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Role Permissions",
        subFeatures:[
          "Role Creation",
          "Permission Definition",
          "Assign meaningful names to permission",
          "Assign one or more permissions to each role",
          "Assign roles to individual users or multiple user",
          "Ability to create custom roles tailored to the specific needs of the application",
          "Ensure that users can only access features or data associated with their assigned permissions.",
          "Enable permissions to be assigned or revoked in real-time based on changing conditions or user attributes"
        ],
        
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },

      {
        featureName: "Supervisor setting",
        subFeatures:[
          "Assign supervisor to Single/Multiple Branches/unit/department/division/team"
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Work Slot",
        subFeatures:[
          "Define  Flexible Working Hour",
          "Manage Overtime",
          "Office  Time Setting"
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee Movement",
        subFeatures:[
          "Recognition and Growth",
          "Agility Personified",
          "Promotion",
          "Transfer",
          "Increment",
          "Suspend",
          "Employment Close"
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee Groups",
        subFeatures:[
          "Empower Your Organization",
          "Breaking Barriers, Forging Bonds"

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee ID Card Printing",
        subFeatures:[
          "Employee ID Card Printing",
          "ID card settings"

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee Loan/Advance salary",
        subFeatures:[
          "Loan/Advance Salary settings",
          "Employee Loan/Advance Salary Management"

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Dynamic Branch/Unit Management",
        subFeatures:[
          " Create Branches/Department/Division /Units",
          "Organizational Structure Customization",
          "Hierarchical Department and Division Structure",
          "Weekly Holidays and Leave Allocation for  Branches/Department/Division /Units",
          "Drag-and-Drop Functionality"
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
    ],

  },
  {
    id: 4,
    criteria: " Dynamic Dashboard",
    included: [
      {
        featureName: "Employee  dashboard",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Supervisor dashboard",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Company owner dashboard",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Role/Permission wise dashboard",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Check In Employee heat map",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave summary",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee summary",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Event summary",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Attendance List",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },

    ]
  },
  {
    id: 5,
    criteria: "Domain Customization",
    included: [
      {
        featureName: "Sub-domain for your company using your company name",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Customize your sub-domain",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Customize the platform to match your organization's unique domain and branding.",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      }
    ]
  },
  {
    id: 5,
    criteria: "Shift Management",
    included: [
      {
        featureName: "Employee wise shift",
        subFeatures:[
          "Flexible date range picker",
          "Approval system",
          "Lock/Unlock calendar"
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Unit wise shift",
        subFeatures:[
          "Flexible date range picker",
          "Approval system"
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Schedule Faster",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Shift Swapping",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Communicate Better",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Automatic Scheduling",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Real-time Notification",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Shift reminders alert",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
    ]
  },
  {
    id: 6,
    criteria: "Attendance Management",
    included: [
      {
        featureName: "Seamless Attendance Tracking",
        subFeatures:[

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Efficient Time Management",
        subFeatures:[

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Enhanced Communication",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Flexible Attendance Solutions",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee Attendance with Time Tracking Software",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Get employee absentee data in Realtime & accurately",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Maximize Efficiency with Smart Time Tracking",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Attendance device configuration",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Authorized individuals are allowed to make modifications to attendance records..",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Synchronize employee to attendance device",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Online attendance with approval system",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
    ]
  },
  {
    id: 7,
    criteria: "Payroll Automation",
    included: [
      {
        featureName: "Financial year setting",
        subFeatures:[

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Tax customization",
        subFeatures:[

        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Pre built TAX template",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Pay head setting",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Pay grade settings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Pay scale settings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Earning and Deduction settings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Flexibility to generate salary with or without employee attendance",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Salary sheet download",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Salary re-generate",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Salary disbursement",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Salary  approval through dynamic workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Bonus setting",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Bonus generate and regenerate",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Bonus  approval through dynamic workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Bonus disbursement",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave Encashment setting",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave Encashment generate and regenerate",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave Encashment approval through dynamic workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave Encashment disbursement.",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Tax Management",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Pre-built Tax Templates",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Local Regulatory Compliance",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Payroll Tax Calculation",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Time Savings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Accuracy Guaranteed",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Scalable Solution",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
    ]
  },
  {
    id: 8,
    criteria: "Custom Notification",
    included: [
      {
        featureName: "Notification Template Management",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Group Notification",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Multiple action name",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Email Notification",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "System Notification",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Notification with Attachment",
        subFeatures:["SMS Notification"],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      }

    ]
  },
  {
    id: 9,
    criteria: "Digital Notice Board",
    included: [
      {
        featureName: "Schedule notice",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Publish notice to specific group",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Unpublish notice",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Supporting notice types:",
        subFeatures:["Text", "Images", " Documents"],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Organize notice into categories or topics",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      }

    ]
  },
  {
    id: 10,
    criteria: "Dynamic Workflow",
    included: [
      {
        featureName: "Multi-level approval chains",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Configurable approval workflows for document review and approval.",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: " Leave Approval workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Shift Approval workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Salary Approval workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Bonus Approval workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Loan Approval workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave Encashment Approval workflow",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      }

    ]
  },
  {
    id: 11,
    criteria: "Organogram Management",
    included: [
      {
        featureName: "Real-Time Visualization",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Zoom In and Out",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Pan Across the Chart",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Clear Hierarchy Display",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Expand and Collapse Nodes",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Position, designation and Employee Details",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Interactive User Interface",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Responsive Design",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Dynamic Updates",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Scalability",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Collaborative Features",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      }

    ]
  },
  {
    id: 12,
    criteria: "Settings",
    included: [
      {
        featureName: "Company Settings",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Late Deduction Settings",
        subFeatures:[
        ],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Weekly Holidays Settings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Leave Allocation Settings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      },
      {
        featureName: "Employee ID Prefix Settings",
        subFeatures:[],
        dataType: "",
        free: "yes",
        standard: "yes",
        premium: "yes",
        enterprise: "yes",
      }


    ]
  }
  
];

export { planData, pricing };

