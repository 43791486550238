import React from "react";
import { BlockSecurityFeatureData } from "../../../assets/json/BlockSecurityFeatureData";
import InfoCard3 from "../../Common/InfoCard3";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecurityFeature = ({bg}) => {
  return (
    <>
      <div className={`payroll-benifits blockSecurity-management ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle={"REAL-TIME MONITORING AND NOTIFICATIONS"}
                title={"Staying Proactive with SmartPeople"}
                desc={
                  "SmartPeople's blockchain-powered user activity tracking provides real-time monitoring capabilities. Administrators and managers receive instant notifications in case of any suspicious activities, enabling them to promptly investigate and take necessary actions. "
                }
              />
            </div>

            {BlockSecurityFeatureData.map((item) => (
              <div className="col-lg-4 mb-3">
                <InfoCard3
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityFeature;
