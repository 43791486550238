import React, { useState } from "react";

const AuthBanner = ({ col }) => {
  const [videoReady, setVideoReady] = useState(false);
  

  const handleCanPlay = () => {
    setVideoReady(true);
  };

 

  return (
    <div className="container-fluid">
      <div className="hero-animation">
        <div className="marquee-wrap">
          <div className="marquee-block">
            <div className="marquee-inner to-left">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-right">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-left">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-right">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-left">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-right">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/g-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-left">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/r-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-right">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/b-10.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="marquee-block">
            <div className="marquee-inner to-left">
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
              <span>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-6.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-7.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-8.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="marquee-item">
                  <div className="img">
                    <img
                      src={require("./../../assets/images/hero/slide-items/p-9.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </div>
    </div>
  );
};

export default AuthBanner;
