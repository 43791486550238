import React from "react";
import { LeaveManagementSystemData } from "../../../assets/json/LeaveManagementSystemData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const LeaveManagementSystem = () => {
  return (
    <>
      <div className="payroll-benifits leaveManagement-system">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle={"FEATURES"}
                title={"Flexible Leave Management System"}
                desc={
                  " Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users."
                }
              />
            </div>
            {LeaveManagementSystemData.map((item, index) => (
              <div key={index} className="col-lg-3">
                <InfoCard2 id={item.id} icon={item.icon} title={item.title} desc={item.desc}/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementSystem;
