const LeaveManagementSystemData = [
    {
      id: 0,
      icon: require("../images/feature/leave-management/Streamline Leave Requests.png"),
      title: "Streamline Leave Requests:       ",
      desc: "Say goodbye to paperwork and manual processes. SmartPeople's Leave Management feature allows employees to submit leave requests seamlessly, while managers can easily review and approve them in just a click.      "
    },
    {
      id: 1,
      icon: require("../images/feature/leave-management/Customizable Leave Types.png"),
      title: "Customizable Leave Types",
      desc: "Tailor leave types to match your organization's policies. Whether it's annual leave, sick leave, or any other category, our flexible system lets you set up custom leave types that fit your organization's unique requirements.      "
    },
    {
      id: 2,
      icon: require("../images/feature/leave-management/Seamless Attendance Integration.png"),
      title: "Seamless Attendance Integration      ",
      desc: "Integrate leave data with attendance records for accurate time management. SmartPeople's seamless integration ensures leave is accurately reflected in timesheets and payroll processing.      "
    },
    {
      id: 3,
      icon: require("../images/feature/leave-management/Leave Analytics and Reports.png"),
      title: "Leave Analytics and Reports      ",
      desc: "Make informed decisions with our comprehensive leave analytics. Monitor leave trends, track employee absence patterns, and generate insightful reports to optimize workforce planning.      "
    }
  ];
  
  export { LeaveManagementSystemData };
  
  