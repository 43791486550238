import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const DomainCustomizationSecurity = () => {
  return (
    <>
      <div className="domaincustomization-security">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <FullWidthHeading title={'Built for speed and security.'}
                desc={'Simplifying your sickness and absence management with Breathe lets you focus instead on your teams wellness'}
            />
              
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/domain-customization/Built for speed and security..png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainCustomizationSecurity;
