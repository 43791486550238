import React from "react";
import "../assets/css/device-page.css";
import DeviceHero from "../components/Device/DeviceHero";
import Feature from "../components/Device/Feature";
import Section6x6 from "../components/Device/Section6x6";
const data = [
  {
    id: "0",
    title: "Powered by the ZKTeco F18 fingerprint machine",
    desc: "ZKTeco F18 is an access control biometric time Attendance Machine that is the most popular innovative fingerprint attendance machine in Bangladesh.",
    list:`<ul>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>This is more reliable and durable than any other device.</div></li>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>It also has high-performance firmware functions.</div></li>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>It has a user-friendly UI and flexible user privilege settings for multi-level management.</div></li>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>It is able to verify fingerprints with higher efficiency.</div></li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>It is well known for easy installation and Connectivity.</div></li>
</ul>`,
    imgName: "combo_product.png"
  },
  {
    id: "1",
    title: "ZKTeco F18 Access Control with Card & Finger Print",
    desc: "Three different kinds of interface style, including common, matrix and magic styles.",
    list:`<ul>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Capacity to store 30,000 transactions and 3000 fingerprint templates</div></li>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>User recognition is less than 1 second</div></li>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Uses advanced 10.1 algorithms for precision and reliability</div></li>
    <li class="fs-16 d-flex align-items-start gap-2 mb-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Built-in Ethernet and serial ports</div>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Tamper switch activates the alarm</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Three interface styles – matrix, magic, and common</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Fingerprints accepted or rejected with audio-visual indication</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Access control interface for third-party alarm, exit button, electric lock</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Does not require a computer for functioning</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Wiegand output and input</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>TCP/IP or RS485 network interface</div></li>
</li>
    <li class="fs-16 d-flex align-items-start gap-2"><span>
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.21625 10.9995C4.98528 10.9987 4.76498 10.9021 4.60792 10.7328L0.557919 6.42446C0.242656 6.08849 0.259446 5.56056 0.595419 5.24529C0.931392 4.93003 1.45932 4.94682 1.77459 5.28279L5.20792 8.94113L12.2163 1.27446C12.41 1.03314 12.7216 0.9191 13.0254 0.978301C13.3292 1.0375 13.5751 1.26019 13.6641 1.55662C13.7531 1.85305 13.6705 2.17439 13.4496 2.39113L5.83292 10.7245C5.67732 10.8969 5.45683 10.9966 5.22459 10.9995H5.21625Z" fill="#346DFE"/>
    </svg>
    
</span><div>Built-in USB for manual data transfer</div></li>
</ul>`,
    imgName: "combo_product.png"
  },
  {
    id: "2",
    title: "Powered by the ZKTeco F18 Intelligence System™",
    desc: `ZKTeco F18 Fingerprint Attendance Machine is a biometric machine for access control applications. The machine uses an advanced algorithm to provide precise and reliable performance. F18 uses fast fingerprint matching technology and justifies the high performance of the ZKTeco brand. The machine features a user-friendly interface and flexible settings to provide multi-level management.
<br/>
<br/>
  F18 does not require a computer to do its job, which makes it an extremely portable machine. Along with that, the 3.5 inch TFT display provides a rich user experience. The device can either be set up as standalone or connected to any third-party control panel that supports the Wiegand signal.
  <br/>
<br/>
  The operations can be done easily on the TFT-LCD color screen. Indication of the scanned fingerprint is given in audio-visual mode. F18 can store 3000 transactions and 1500 templates. The data can be backed up using a USB flash drive. Dual-factor authentication for access control is allowed due to the Mi-fare Card reader or Internal Proximity Card. Alarm trigger feature available, which occurs with the activation of the tamper switch.`,
    imgName: "combo_product.png"
  }
];

const DeviceDetails = () => {
  return (
    <div className="device-page  device-details-page container">
      <DeviceHero
        sectionTitle="ZKTeco F18"
        title="ZKTeco F18 Access Control with Card & Finger Print"
        desc="Fingerprint attendance machine helps to track employee attendance more efficiently way."
        bigImage="combo_product.png"
        btnText="Order Now"
      />
      <Feature />
      {data.map((item, index) => (
        <Section6x6
          key={item.id}
          direction={index}
          hasSectionname={""}
          title={item.title}
          desc={item.desc}
          list={item.list}
          btnText="Contact to Buy"
          btnWidth="w-auto"
          btnName=""
          imgName={item.imgName}
        />
      ))}
    </div>
  );
};

export default DeviceDetails;
