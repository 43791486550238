const overviewData =[
    {
      id: 0,
      link:"/smart-people/employee-management",
      icon: require("../images/overview/item-1.png"),
      title: "Employee Management ",
      desc: "Effortlessly manage employee profile, leaves, benefits, tax and ensure accurate payroll with just a click."
    },
    {
      id: 1,
      link:"/smart-people/attendance-management",
      icon: require("../images/overview/item-2.png"),
      title: "Attendance & Shift Management       ",
      desc: "Stay ahead of your schedule with SmartPeople. Effortlessly track attendance, manage employee shifts, and avoid roster overlaps, empowering your team to work efficiently and maximizing productivity."
    },
    {
      id: 2,
      link:"/smart-people/payroll-management",
      icon: require("../images/overview/item-3.png"),
      title: "Payroll Management",
      desc: "Empower your payroll process with SmartPeople! Effortlessly set up salary structures, bonuses, incentives, deductions, and generate Payroll in just a click "
    },
    {
      id: 3,
      link:"/smart-people/blockchain-technology",
      icon: require("../images/overview/item-4.png"),
      title: "Security & Access Control       ",
      desc: "Data security is our top priority. Experience peace of mind with our secure user registration, login, and access control features, ensuring access to authorized users only."
    }
  ]

  export { overviewData }

