import React from "react";
import Faqs from "../../components/Common/Faqs";
import EmployeeMonitoringBlockchain from "../../components/Feature/EmployeeMonitoring/EmployeeMonitoringBlockchain";
import EmployeeMonitoringHero from "../../components/Feature/EmployeeMonitoring/EmployeeMonitoringHero";
import EmployeeMonitoringIntegrate from "../../components/Feature/EmployeeMonitoring/EmployeeMonitoringIntegrate";
import EmployeeMonitoringScheduleTracking from "../../components/Feature/EmployeeMonitoring/EmployeeMonitoringScheduleTracking";
import EmployeeMonitoringService from "../../components/Feature/EmployeeMonitoring/EmployeeMonitoringService";
import EmployeeMonitoringTrackingReport from "../../components/Feature/EmployeeMonitoring/EmployeeMonitoringTrackingReport";
import "./../../assets/css/future.css";

const EmployeeMonitoring = () => {
  return (
    <>
      <EmployeeMonitoringHero />
      <EmployeeMonitoringIntegrate  bg={'bg-shadow'}/>
      <EmployeeMonitoringService />
      <EmployeeMonitoringScheduleTracking bg={'bg-shadow'}/>
      <EmployeeMonitoringTrackingReport />
      <EmployeeMonitoringBlockchain bg={'bg-shadow'}/>
      <Faqs />
    </>
  );
};

export default EmployeeMonitoring;
