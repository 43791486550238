import React from "react";
import { Link, NavLink } from "react-router-dom";

const OffCanvus = ({ level1MenuData, handleSubMenuClick }) => {
  const handleclick = (id) => {
    // const filteredData = level1MenuData.find((item) => {
    //   return item.id === id;
    // });
    // if (!filteredData.options === undefined) {
    //   document.querySelector(".dismiss").click();
    // }
    // console.log(id);
    handleSubMenuClick(id);
  };

  return (
    <div
      className="offcanvas offcanvas1 offcanvas-end"
      tabIndex="-1"
      id="offcanvasRight1"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title fs-14 fw-500" id="offcanvasRightLabel">
          <Link
            className="text-dark"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <img
              className="me-2"
              src={require("../../assets/images/comp/leftArrow.png")}
              alt="error"
            />
            Back
          </Link>
        </h5>
        <button data-bs-dismiss="offcanvas">
          <img
            src={require("../../assets/images/comp/cross.png")}
            alt="error"
          />
        </button>
      </div>
      <div className="offcanvas-body p-0">
        <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
          {level1MenuData.map((item) => (
            <li key={item.id} className="nav-item ">
              <NavLink
                onClick={() => handleclick(item.id)}
                type="button"
                className={"nav-link d-flex justify-content-between"}
                aria-current="page"
                to={item.link}
              >
                <span data-bs-dismiss="offcanvas">{item.label}</span>

                <img
                  data-bs-toggle={"offcanvas"}
                  data-bs-target={"#offcanvasRight2"}
                  className="me-2"
                  src={require("../../assets/images/comp/arrowright2.png")}
                  alt="error"
                />
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OffCanvus;
