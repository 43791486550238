import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const PayrollFeature = () => {
  return (
    <>
      <div className="payroll-feature">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                sectionTitle={"WHY SmartPeople FOR  PAYROLL PROCESSING "}
                title="Experience the power of payroll with auto deduction of taxes and benefit calculation in just a few clicks"
                desc="Payroll processing involves more than just calculating paychecks. From employee onboarding to compliance management and payroll record maintenance, there are numerous repetitive tasks to handle every month. SmartPeople's Payroll Software simplifies these processes, allowing HR and payroll professionals to effortlessly manage all payroll activities from one centralized location."
              />
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                    Automated Payroll Processing
                  </h3>
                  <p className="fs-14">
                    Say goodbye to manual calculations! Our automated system
                    effortlessly calculates and processes employee salaries,
                    ensuring accurate and timely payments.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/payroll/Automated Payroll Processing.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                    Error Free Salary Calculation
                  </h3>
                  <p className="fs-14">
                    Eliminate human errors in payroll calculations,ensuring
                    precise and error-free payments. Say goodbye to payroll
                    discrepancies and foster trust among your employees.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/payroll/Error Free Salary Calculation.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                    Compliance and Tax Management
                  </h3>
                  <p className="fs-14">
                    Ensure regulatory compliance by automating tax calculations,
                    deductions, and generating tax during payroll processing.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/payroll/Compliance and Tax Management.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                    Tax Compliance with Confidence
                  </h3>
                  <p className="fs-14">
                    Our tax configuration feature guarantees compliance with
                    local tax regulations. Stay up-to-date with tax changes and
                    configure tax and deductions with ease.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/payroll/Tax Compliance with Confidence.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
           
            {/* <div className="col-lg-6">
              <div className="row h-100">
                <div className="col-12 mb-3">
                  <div className="card stat-card card-shadow card-border p-3 d-flex flex-row gap-3 radius-20">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24">Self-Service Payslips</h3>
                      <p className="fs-14">
                      Empower workforce with secure access to digital payslips, enabling them to conveniently view and download their payment and deduction details whenever needed.

                      </p>
                    </div>
                    <div className="img-content text-center ">
                      <img
                        className="mt-3 fs-14 img-fluid"
                        src={require("./../../../assets/images/feature/payroll/stat2.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card stat-card card-shadow card-border p-3 d-flex flex-row gap-3 radius-20">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24">Payroll Reports
</h3>
                      <p className="fs-14">
                      Generate comprehensive payroll reports with customizable parameters, offering valuable insights to support informed decision-making.

                      </p>
                    </div>
                    <div className="img-content text-center ">
                      <img
                        className="mt-3 fs-14 img-fluid"
                        src={require("./../../../assets/images/feature/payroll/support.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-6 mb-4">
              <div className="card stat-card card-shadow card-border p-3 radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">
                    Self-Service Payslips
                  </h3>
                  <p className="fs-14">
                    Empower workforce with secure access to digital payslips,
                    enabling them to conveniently view and download their
                    payment and deduction details whenever needed.
                  </p>
                </div>
                <div className="img-content text-center">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/payroll/stat2.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card stat-card card-shadow card-border p-3 radius-20">
                <div className="text-content">
                  <h3 className="text-dark fw-700 fs-24">Payroll Reports</h3>
                  <p className="fs-14">
                    Generate comprehensive payroll reports with customizable
                    parameters, offering valuable insights to support informed
                    decision-making.
                  </p>
                </div>
                <div className="img-content text-center ">
                  <img
                    className="mt-3 fs-14 img-fluid"
                    src={require("./../../../assets/images/feature/payroll/support.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollFeature;
