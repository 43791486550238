import React from "react";
import successStories from "../../assets/json/successStories";
import CompanyCard2 from "./CompanyCard2";

const SuccessStories = () => {
  return (
    <>
      <div className="success-section pt-64 pb-64 ">
        <div className="container">
          <div className="title">
            <h3 className="fs-32 fw-700 text-light text-center mb-lg-5">
              See how our customers succeed with SmartOffice
            </h3>
            {/* <p className="fs-28 fw-400">Success Stories</p> */}
          </div>

          <div className="custom-grid">
            {successStories.map((item) => (
              <div key={item.id}>
                <CompanyCard2 title={item.title} desc={item.desc} logo={item.logo} bgImg={item.img} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessStories;
