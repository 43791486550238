import React from "react";
import faqData from "../assets/json/faqData";
import Faqs from "../components/Common/Faqs";
import Compare from "../components/CompareWithRippling/Compare";
import Hero from "../components/CompareWithRippling/Hero";
import Service from "../components/CompareWithRippling/Service";

const CompareWithRippling = () => {
  return (
    <div className="Compare-Page">
      <Hero />
      <Compare/>
      <Service />
      <Faqs data={faqData}/>
    </div>
  );
};

export default CompareWithRippling;