import React, { useState } from "react";
import { Link } from "react-router-dom";

const Signup = () => {
  const [passView, setPassView] = useState(false);
  return (
    <div className="container">
      <form className="radius-16 auth-form">
        <div className="row">
          <h2 className="text-center">
            Set up your <br /> SmartOffice in <br /> under two minutes
          </h2>
          <div className="col-12 mb-3">
            <input
              type="text"
              className="form-control"
              name=""
              id=""
              placeholder="Full Name"
            />
          </div>
          <div className="col-12 mb-3">
            <input
              type="email"
              className="form-control"
              name=""
              id=""
              placeholder="Email address"
            />
          </div>
          <div className="col-12 mb-3 position-relative">
            <input
              type={passView ? "text" : "password"}
              className="form-control"
              name=""
              id=""
              placeholder="Password"
            />
            <span className="eye" onClick={() => setPassView(!passView)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5001 12.0004C13.5001 12.8288 12.8285 13.5004 12.0001 13.5004C11.1716 13.5004 10.5001 12.8288 10.5001 12.0004C10.5001 11.1719 11.1716 10.5004 12.0001 10.5004C12.8285 10.5004 13.5001 11.1719 13.5001 12.0004Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.7301 5.00036C17.7101 4.82036 21.2301 10.3904 21.8701 11.5004C22.0487 11.8098 22.0487 12.191 21.8701 12.5004C21.0101 14.0004 17.8001 18.8604 12.2701 19.0004H12.0201C6.13007 19.0004 2.76007 13.5904 2.13007 12.5004C1.95144 12.191 1.95144 11.8098 2.13007 11.5004C3.00007 10.0004 6.20007 5.14036 11.7301 5.00036ZM8.50007 12.0004C8.50007 13.9334 10.0671 15.5004 12.0001 15.5004C13.9331 15.5004 15.5001 13.9334 15.5001 12.0004C15.5001 10.0674 13.9331 8.50036 12.0001 8.50036C10.0671 8.50036 8.50007 10.0674 8.50007 12.0004Z"
                  fill="#637381"
                />
              </svg>
            </span>
          </div>
          <div className="col-12 mb-3">
          <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
              By signing up, I agree to <Link to="/">Terms of Use</Link> and <Link to="/">Privacy Policy</Link>.
              </label>
            </div>
          </div>
          <div className="col-12 mb-3">
            <button className="btn btn-primary w-100" type="submit">
            Free Sign up
            </button>
          </div>
          <div className="col-12 mb-3">
            <p className="or Signin text-center">or <Link to="http://apps.smartoffice.ai/" className="or Signin text-center">sign in</Link> using</p>
          </div>
          <div className="col-12">
            <ul className="d-flex align-items-center justify-content-center social-btn">
              <li>
                <button className="btn">
                  <img
                    src={require("./../assets/images/auth/google.png")}
                    alt=""
                    className="img-fluid"
                  />
                </button>
              </li>
              <li>
                <button className="btn">
                  <img
                    src={require("./../assets/images/auth/twitter.png")}
                    alt=""
                    className="img-fluid"
                  />
                </button>
              </li>
              <li>
                <button className="btn">
                  <img
                    src={require("./../assets/images/auth/linkdin.png")}
                    alt=""
                    className="img-fluid"
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Signup;
