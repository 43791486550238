const successStories = [
    {
        id: 0,
        title:"kx Electronics",
        desc:"SmartOffice's pre-built tax templates ensure we stay compliant with local regulations, eliminating tax-related headaches.",
        logo: "kx-electronics.png",
        
    },
    {
        id: 1,
        title:"ILLIYEEN",
        desc:"SmartPeople's real-time monitoring helps us proactively identify and address any unusual user activity, boosting security.",
        logo: "illiyeen.png",
        img:"illiyeen_bg.png"
    },
    {
        id: 2,
        title:"kX Sourcing",
        desc:"SmartOffice's user activity tracking with blockchain technology is a game-changer! We now have full visibility into HR operations.",
        logo: "kx-sourcing.png",
       
    },
    {
        id: 3,
        title:"Legends",
        desc:"SmartPeople's exceptional customer experience and support make them a standout HRMS provider.",
        logo: "legends.png",
        
    },
    {
        id: 4,
        title:"FLOW",
        desc:"SmartPeople's team spirit-focused environment has created a positive and collaborative work atmosphere for our company.",
        logo: "flow.png",
        
    },
];

export default successStories;