import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";
import { Breadcrumb, Button, Col, Form, Row } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { apiData } from "../assets/json/helpCenterData";
import Banner from "../components/HelpCenter/Banner";
import FilterFaqs from "../components/HelpCenter/FilterFaqs";

const fileTypes = ["JPEG", "PNG", "GIF"];

const TechnicalSupport = () => {
  const editorRef = useRef(null);
  const [file, setFile] = useState(null);
  const topicRequestRef = useRef(null);
  const [dropDownValue, setDropDownValue] = useState(null);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setDropDownValue(selectedValue);
    console.log(`Selected value: ${selectedValue}`);
  };

  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <>
      <Banner
        title={`Technical Support`}
        desc={`Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. `}
      />
      <div className="container billing">
        <div className="row">
          <div className="col-12">
            <Breadcrumb>
              <Breadcrumb.Item href="/contact">Contact Us</Breadcrumb.Item>
              <Breadcrumb.Item active>Technical Support</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-12">
            <Form className="form">
              <Row>
                <Col xl={6} className="mb-3">
                  <Form.Label>Choose your issue below</Form.Label>
                  <Form.Select
                    defaultValue="Technical-Support"
                    disabled
                    className="default-select-option"
                  >
                    <option>Technical Support</option>
                  </Form.Select>
                </Col>
                <Col xl={6} className="mb-3">
                  <Form.Label>Topic of Request *</Form.Label>
                  <Form.Select
                    defaultValue="-"
                    ref={topicRequestRef}
                    onChange={handleSelectChange}
                  >
                    <option>-</option>
                    <option value={"api"}>API</option>
                    <option value={"bugReport"}>Bug report</option>
                    <option value={"smartOfficeStatus"}>
                      Smartoffice Status
                    </option>
                  </Form.Select>
                </Col>
                {dropDownValue === "api" ? (
                  <>
                    <Col xl={12}>
                      <h4 className="faq-title">Frequently Asked Questions</h4>
                      <FilterFaqs data={apiData} />
                    </Col>
                    <Col xl={12}>
                      <h4 className="faq-title">
                        Still need help? No problem, reach out to our Support
                        team below.
                      </h4>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {dropDownValue === "bugReport" ? (
                  <>
                    <Col xl={12}>
                      <h4 className="faq-title">Frequently Asked Questions</h4>
                      <FilterFaqs data={apiData} />
                    </Col>
                    <Col xl={12}>
                      <h4 className="faq-title">
                        Still need help? No problem, reach out to our Support
                        team below.
                      </h4>
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>Platform *</Form.Label>
                      <Form.Select
                        defaultValue="-"
                      >
                        <option>-</option>
                        <option>Browser</option>
                        <option>Desktop application</option>
                        <option>Mobile application</option>
                      </Form.Select>
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>Browser List *</Form.Label>
                      <Form.Select
                        defaultValue="-"
                      >
                        <option>-</option>
                        <option>Chrome</option>
                        <option>Firefox</option>
                        <option>Edge</option>
                        <option>Opera</option>
                        <option>Other</option>
                      </Form.Select>
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>What feature is being impacted?</Form.Label>
                      <Form.Control placeholder="Enter email" type="email" />
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>What Space/List/View is impacted?</Form.Label>
                      <Form.Control placeholder="Enter Email" type="email" />
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>What feature is being impacted?</Form.Label>
                      <Form.Control placeholder="Enter subject" />
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>What Space/List/View is impacted?</Form.Label>
                      <Form.Control placeholder="Enter subject" />
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>Link to affected area</Form.Label>
                      <Form.Control placeholder="Enter email" type="email" />
                    </Col>
                    <Col xl={6} className="mb-3">
                      <Form.Label>Steps to reproduce issue?</Form.Label>
                      <Form.Control placeholder="Enter subject" />
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col xl={6} className="mb-3">
                  <Form.Label>Your email address *</Form.Label>
                  <Form.Control placeholder="Enter email" type="email" />
                </Col>
                <Col xl={6} className="mb-3">
                  <Form.Label>Subject *</Form.Label>
                  <Form.Control placeholder="Enter subject" />
                </Col>
                <Col xl={12} className="mb-3">
                  <Form.Label>Description *</Form.Label>
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    init={{
                      selector: "textarea",
                      placeholder: "Write here....",
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount"
                      ],

                      toolbar:
                        "bold italic underline | alignleft aligncenter | ",
                      table_toolbar:
                        "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                      toolbar_mode: "wrap" | "scrolling",
                      toolbar_sticky: true,
                      toolbar_sticky_offset: 100
                    }}
                  />
                  <Form.Label>
                    Please enter the details of your request. A member of our
                    support staff will respond as soon as possible.
                  </Form.Label>
                </Col>
                <Col xl={12} className="mb-3">
                  <Form.Label>Attachments</Form.Label>
                  <FileUploader
                    classes="file-uploading"
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    label={"Add file or drop files here"}
                    hoverTitle={"Drop here"}
                  />
                  <p className="upload-status">
                    {file
                      ? `File name: ${file[0].name}`
                      : "no files uploaded yet"}
                  </p>
                </Col>
                <Col xl={12}>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicalSupport;
