import React from "react";
import Faqs from "../../components/Common/Faqs";
import PayrollAction from "../../components/Feature/Payroll/PayrollAction";
import PayrollBenifits from "../../components/Feature/Payroll/PayrollBenifits";
import PayrollFeature from "../../components/Feature/Payroll/PayrollFeature";
import PayrollHero from "../../components/Feature/Payroll/PayrollHero";
import PayrollManage from "../../components/Feature/Payroll/PayrollManage";
import PayrollRevolutionize from "../../components/Feature/Payroll/PayrollRevolutionize";
import PayrollWorks from "../../components/Feature/Payroll/PayrollWorks";
import "./../../assets/css/future.css";

const Payroll = () => {
  return (
    <>
      <PayrollHero />
      <PayrollFeature />
      <PayrollWorks />
      <PayrollManage />
      <PayrollRevolutionize bg={"bg-shadow"}/>
      <PayrollBenifits />
      <PayrollAction bg={"bg-shadow"}/>
      <Faqs />
    </>
  );
};

export default Payroll;
