import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const LeaveManagementTakeContol = () => {
  return (
    <>
      <div className="payroll-revolutionize leaveManagement-takeContol">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5">
              <FullWidthHeading
                title={"Take control of Employee leave"}
                data={`<p class="details fs-16 fw-400 mt-4"> Revolutionize your leave management with SmartPeople's intuitive and  feature-rich Leave Management and Holiday Calendar. Schedule a demo today and witness the efficiency and transparency that SmartPeople brings to your HR operations. Empower your organization with streamlined time-off tracking, and say hello to HR excellence with SmartPeople. </p><a href="/smart-people/leave-management" class="btn btn-primary radius-8 mt-3"> Learn More </a>`}
              />
             
            </div>
            <div className="col-12 col-lg-7">
              <div className="img-content">
                <img
                  className="img-fluid w-100"
                  src={require("../../../assets/images/feature/leave-management/image7.png")}
                  alt="error"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementTakeContol;
