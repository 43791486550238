import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";
import { StructureUnderstandAndAccessibilityData } from "../../../assets/json/StructureUnderstandAndAccessibilityData";

const StructureUnderstandAndAccessibility = () => {
  return (
    <>
      {/* <div className="payroll-feature domaincustomization-feature structure-understand-accessibility" > */}
      <div className="structure-understand-accessibility" >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                title={"Structure Understanding and Accessibility"}
                desc={
                  "Streamline both your data collection and secure storage with our employee database management system. Set up your organization and maintain all your employee records in one central location."
                }
              />
            </div>
            {StructureUnderstandAndAccessibilityData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-3">
                <div className="card stat-card card-shadow card-border p-3 box-content  radius-20">
                  <div className="text-content">
                    <h3 className="text-dark fw-700 fs-24">{item.title}</h3>
                    <p className="fs-14">{item.desc}</p>
                  </div>
                  <div className="img-content text-center">
                    <img
                      className="img-fluid fs-14"
                      src={item.img}
                      alt="error"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StructureUnderstandAndAccessibility;
