import React from "react";
import { NoticeBoardManagementData } from "../../../assets/json/NoticeBoardManagementData";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NoticeBoardManagement = () => {
  return (
    <>
      <div className="payroll-benifits NoticeBoard-management">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle={"NOTICE MANAGEMENT"}
                title={"Advanced Notice Board Management"}
                desc={
                  "With our cutting-edge Digital Notice Board, say goodbye to traditional bulletin boards and embrace a more efficient, eco-friendly, and organized way to communicate official notices to your entire workforce or specific team."
                }
              />
            </div>

            {NoticeBoardManagementData?.map((item, index) => (
              <div key={index} className="col-lg-4 mb-4">
                <div className="schedule-card card card-border p-3 pb-5 h-100">
                  <div className="img-content text-center mb-2">
                    <img
                      className="img-fluid"
                      src={require(`../../../assets/images/feature/digital-noticeboard/${item.img}`)}
                      alt="error"
                    />
                  </div>

                  <div className="text-content text-center">
                    <h2 className="fs-24 fw-700">{item.title}</h2>
                    <p className="fs-14 fw-400">{item.desc}</p>
                    {/* <Link
                      to={item.btnLink}
                      className="btn btn-light radius-20 mt-3"
                    >
                      {item.btn}
                    </Link> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBoardManagement;
