import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "./assets/css/style.css";
// Import Swiper styles
import 'react-phone-number-input/style.css';
import "swiper/css";
import "../src/assets/css/common.css";
import AllComponent from "./components/All/AllComponent";
import Auth from "./components/Auth/Auth";
import Layout from "./components/Layout/Layout";
import SingleProductTrainingArticle from "./components/ProductTraining/SingleProductTrainingArticle";
import About from "./pages/About";
import ApiDocs from "./pages/ApiDocs";
import Billing from "./pages/Billing";
import Blog from "./pages/Blog";
import ChangePassword from "./pages/ChangePassword";
import CompareWithRippling from "./pages/CompareWithRippling";
import Contact from "./pages/Contact";
import Customers from "./pages/Customers";
import DeviceDetails from "./pages/DeviceDetails";
import Devices from "./pages/Devices";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import LegalPage from "./pages/LegalPage";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import PolicyPage from "./pages/PolicyPage";
import Pricing from "./pages/Pricing";
import ProductTraining from "./pages/ProductTraining";
import ProductTrainingArticle from "./pages/ProductTrainingArticle";
import ProductTrainingSection from "./pages/ProductTrainingSection";
import ResetPassword from "./pages/ResetPassword";
import Resources from "./pages/Resources";
import Sales from "./pages/Sales";
import Signup from "./pages/Signup";
import SingleBlog from "./pages/SingleBlog";
import AttendanceManagement from "./pages/SmartPeople/AttendanceManagement";
import BlockchainSecurity from "./pages/SmartPeople/BlockchainSecurity";
import CompanyManagement from "./pages/SmartPeople/CompanyManagement";
import DomainCustomization from "./pages/SmartPeople/DomainCustomization";
import EmployeeMonitoring from "./pages/SmartPeople/EmployeeMonitoring";
import Features from "./pages/SmartPeople/Index";
import LeaveManagement from "./pages/SmartPeople/LeaveManagement";
import NoticeBoard from "./pages/SmartPeople/NoticeBoard";
import Notification from "./pages/SmartPeople/Notification";
import OrganogramManagement from "./pages/SmartPeople/OrganogramManagement";
import Payroll from "./pages/SmartPeople/Payroll";
import ShiftManagement from "./pages/SmartPeople/ShiftManagement";
import TaxManagement from "./pages/SmartPeople/TaxManagement";
import TechnicalSupport from "./pages/TechnicalSupport";
import VerifyEmail from "./pages/VerifyEmail";
import VerifyPassword from "./pages/VerifyPassword";
import ViewAllBlog from "./pages/ViewAllBlog";




function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/smart-people" element={<Features />} />
          <Route path="/smart-people/payroll-management" element={<Payroll />} />
          <Route
            path="/smart-people/domain-customization"
            element={<DomainCustomization />}
          />
          <Route
            path="/smart-people/shift-management"
            element={<ShiftManagement />}
          />
          <Route
            path="/smart-people/attendance-management"
            element={<AttendanceManagement />}
          />
          <Route path="/smart-people/tax-management" element={<TaxManagement />} />
          <Route
            path="/smart-people/leave-management"
            element={<LeaveManagement />}
          />
          <Route
            path="/smart-people/employee-management"
            element={<EmployeeMonitoring />}
          />
          <Route
            path="/smart-people/digital-notice-board"
            element={<NoticeBoard />}
          />
          <Route path="/smart-people/notification" element={<Notification />} />
          <Route
            path="/smart-people/blockchain-technology"
            element={<BlockchainSecurity />}
          />
          <Route
           path="/smart-people/organogram-management"
           element={<OrganogramManagement />}
            />
          <Route
           path="/smart-people/company-management"
           element={<CompanyManagement />}
            />
          <Route path="pricing" element={<Pricing />} />
          <Route path="customers" element={<Customers />} />
          <Route path="resources" element={<Resources />} />
          <Route path="devices" element={<Devices />} />
          <Route path="blog" element={<Blog />} />
          <Route path="contact" element={<Contact />} />
          <Route path="com" element={<AllComponent />} />
          <Route path="viewallblog" element={<ViewAllBlog />} />
          <Route path="singleblog/:id" element={<SingleBlog />} />
          <Route path="devicepage" element={<Devices />} />
          <Route path="devicedetails" element={<DeviceDetails />} />
          <Route path="terms-condition" element={<LegalPage />} />
          <Route path="privacy-policy" element={<PolicyPage />} />
          <Route path="faq" element={<Faq />} />
          <Route path="technical-support" element={<TechnicalSupport />} />
          <Route path="billing" element={<Billing />} />
          <Route path="sales" element={<Sales />} />
          <Route path="compare-with-rippling" element={<CompareWithRippling />} />
          <Route path="product-training" element={<ProductTraining />} />
          <Route path="product-training/sections/*" element={<ProductTrainingSection />} />
          <Route path="product-training/articles/*" element={<ProductTrainingArticle />} />
          <Route path="product-training/single-articles/*" element={<SingleProductTrainingArticle />} />
          <Route path="api-documentation" element={<ApiDocs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
       
        <Route path="/auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="verify-password" element={<VerifyPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="verify-email" element={<VerifyEmail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
