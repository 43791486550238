import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-number-input";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import "react-phone-number-input/style.css";
import { useRef, useState } from "react";

const DemoModal = ({ modalShow, setModalShow }) => {
  const [phone, setPhone] = useState("");
  const [passView, setPassView] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [activeLabelFullName, setActiveLabelFullName] = useState(false);
  const [activeLabelEmail, setActiveLabelEmail] = useState(false);
  const [activeLabelCompanyName, setActiveLabelCompanyName] = useState(false);
  const [activeLabelNumberOfEmployees, setActiveLabelNumberOfEmployees] =
    useState(false);
  const [activeLabelPhoneNumber, setActiveLabelPhoneNumber] = useState(false);
  const [activeLabelHowDidYouHear, setActiveLabelHowDidYouHear] =
    useState(false);

  // Create refs for input fields
  const fullNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const companyNameInputRef = useRef(null);
  const numberOfEmployeesInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  const howDidYouHearInputRef = useRef(null);

  // Function to handle focus and blur events
  const handleFocus = (inputRef, setActiveLabel) => {
    if (inputRef.current) {
      setActiveLabel(true);
    }
  };

  const handleBlur = (inputRef, setActiveLabel) => {
    if (inputRef.current.value.length > 0) {
      setActiveLabel(true);
    } else {
      setActiveLabel(false);
    }
  };

  return (
    <Modal
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="request-demo-modal request-demo-modal"
    >
      <Modal.Body>
        <span className="closeBtn" onClick={() => setModalShow(false)}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="48" height="48" rx="24" fill="#CCDBFF" />
            <path
              d="M25.4042 23.9928L29.6985 19.7081C30.0902 19.3164 30.0902 18.6815 29.6985 18.2898C29.3069 17.8982 28.672 17.8982 28.2804 18.2898L23.996 22.5845L19.7116 18.2898C19.32 17.8982 18.6851 17.8982 18.2935 18.2898C17.9018 18.6815 17.9018 19.3164 18.2935 19.7081L22.5878 23.9928L18.2935 28.2775C18.1044 28.465 17.998 28.7203 17.998 28.9866C17.998 29.2529 18.1044 29.5082 18.2935 29.6957C18.481 29.8848 18.7362 29.9912 19.0025 29.9912C19.2688 29.9912 19.5241 29.8848 19.7116 29.6957L23.996 25.401L28.2804 29.6957C28.4679 29.8848 28.7232 29.9912 28.9895 29.9912C29.2558 29.9912 29.511 29.8848 29.6985 29.6957C29.8876 29.5082 29.994 29.2529 29.994 28.9866C29.994 28.7203 29.8876 28.465 29.6985 28.2775L25.4042 23.9928Z"
              fill="#212B36"
            />
          </svg>
        </span>
        <div className="img text-center mb-3">
          <img
            src={require("./../../assets/images/herocircle-1/logo.png")}
            alt=""
          />
        </div>
        <Form className="auth-form">
          <h2>Request for Demo</h2>
          <p>
            Share a few details with us and our Enterprise Sales Representative
            will promptly arrange a personalized demo for you.
          </p>
          <Row>
            <Col lg={12}>
              <Form.Group
                controlId="formGridFullName"
                className="mb-3 form-group"
              >
                <FormLabel
                  className={activeLabelFullName ? "active" : "deactive"}
                >
                  Full name
                </FormLabel>
                <Form.Control
                  type="text"
                  ref={fullNameInputRef}
                  onFocus={() =>
                    handleFocus(fullNameInputRef, setActiveLabelFullName)
                  }
                  onBlur={() =>
                    handleBlur(fullNameInputRef, setActiveLabelFullName)
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="mb-3 form-group"
              >
                <FormLabel className={activeLabelEmail ? "active" : "deactive"}>
                  Email address
                </FormLabel>
                <Form.Control
                  type="email"
                  ref={emailInputRef}
                  onFocus={() =>
                    handleFocus(emailInputRef, setActiveLabelEmail)
                  }
                  onBlur={() => handleBlur(emailInputRef, setActiveLabelEmail)}
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group
                as={Col}
                controlId="formGridCompanyName"
                className="mb-3 form-group"
              >
                <FormLabel
                  className={activeLabelCompanyName ? "active" : "deactive"}
                >
                  Company name
                </FormLabel>
                <Form.Control
                  type="text"
                  ref={companyNameInputRef}
                  onFocus={() =>
                    handleFocus(companyNameInputRef, setActiveLabelCompanyName)
                  }
                  onBlur={() =>
                    handleBlur(companyNameInputRef, setActiveLabelCompanyName)
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group
                as={Col}
                controlId="formGridNumberOfEmployees"
                className="mb-3 form-group"
              >
                <Form.Select
                  defaultValue="Number of employee"
                  className="form-control"
                  ref={numberOfEmployeesInputRef}
                  onFocus={() =>
                    handleFocus(
                      numberOfEmployeesInputRef,
                      setActiveLabelNumberOfEmployees
                    )
                  }
                  onBlur={() =>
                    handleBlur(
                      numberOfEmployeesInputRef,
                      setActiveLabelNumberOfEmployees
                    )
                  }
                >
                  <option>Number of employees</option>
                  <option value="1-25">1-25</option>
                  <option value="25-50">25-50</option>
                  <option value="100-200">100-200</option>
                  <option value="200-500">200-500</option>
                  <option value="500+">500+</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group
                as={Col}
                controlId="formGridPhoneNumber"
                className="mb-3 form-group phoneNumber"
              >
                <FormLabel
                  className={activeLabelPhoneNumber ? "active" : "deactive"}
                >
                  Phone Number
                </FormLabel>
                <PhoneInput
                  className="form-control"
                  defaultCountry="BD"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  ref={phoneNumberInputRef}
                  onFocus={() =>
                    handleFocus(phoneNumberInputRef, setActiveLabelPhoneNumber)
                  }
                  onBlur={() =>
                    handleBlur(phoneNumberInputRef, setActiveLabelPhoneNumber)
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group
                as={Col}
                controlId="formGridHowDidYouHear"
                className="mb-3 form-group"
              >
                {/* <FormLabel
                    className={activeLabelHowDidYouHear ? "active" : "deactive"}
                  >
                    How did you hear about us?
                  </FormLabel> */}
                <Form.Select
                  defaultValue="How did you hear about us?"
                  className="form-control"
                  ref={howDidYouHearInputRef}
                  onFocus={() =>
                    handleFocus(
                      howDidYouHearInputRef,
                      setActiveLabelHowDidYouHear
                    )
                  }
                  onBlur={() =>
                    handleBlur(
                      howDidYouHearInputRef,
                      setActiveLabelHowDidYouHear
                    )
                  }
                >
                  <option>How did you hear about us?</option>
                  <option value="Podcast">Podcast</option>
                  <option value="Other">Other</option>
                  <option value="Review Site">Review Site</option>
                  <option value="Insurance Broker">Insurance Broker</option>
                  <option value="Accountant">Accountant</option>
                  <option value="Google Search">Google Search</option>
                  <option value="Outdoor Ad">Outdoor Ad</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Youtube">Youtube</option>
                  <option value="Referral">Referral</option>
                  <option value="Radio Ad">Radio Ad</option>
                  <option value="Accelerator">Accelerator</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Button variant="primary" type="submit" className="w-100">
                Schedule Demo
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DemoModal;
