import React from "react";
import Faqs from "../../components/Common/Faqs";
import TaxManagementBlockchain from "../../components/Feature/TaxManagement/TaxManagementBlockchain";
import TaxManagementFeature from "../../components/Feature/TaxManagement/TaxManagementFeature";
import TaxManagementHero from "../../components/Feature/TaxManagement/TaxManagementHero";
import TaxManagementSalaryCalculation from "../../components/Feature/TaxManagement/TaxManagementSalaryCalculation";
import TaxManagementTax from "../../components/Feature/TaxManagement/TaxManagementTax";
import TaxManagementTaxCalculation from "../../components/Feature/TaxManagement/TaxManagementTaxCalculation";
// import TaxManagementTaxFactor from "../../components/Feature/TaxManagement/TaxManagementTaxFactor";
// import TaxManagementTaxRules from "../../components/Feature/TaxManagement/TaxManagementTaxRules";
import "./../../assets/css/future.css";

const TaxManagement = () => {
  return (
    <>
      <TaxManagementHero />
      <TaxManagementFeature />
      <TaxManagementTax />
      <TaxManagementBlockchain />
      <TaxManagementTaxCalculation />
      <TaxManagementSalaryCalculation />
      {/* <TaxManagementTaxFactor /> */}
      {/* <TaxManagementTaxRules /> */}
      <Faqs />
    </>
  );
};

export default TaxManagement;
