import React from "react";
import { Link, useLocation } from "react-router-dom";

const LegalNav = () => {
  const location = useLocation();
  const reload = location.pathname;
  return (
    <div className=" ligal-nav py-3 mb-40 ">
      <div className="container d-flex justify-content-between align-items-center">
        <h4 className="fs-18 fw-500">Legal policies</h4>
        <div className="d-flex gap-3 align-items-center">
          <Link
            className={`btn text-primary fs-16 fw-600 ${
              reload === "/terms-condition" ? "active" : "text-dark"
            }`}
            to={"/terms-condition"}
          >
            Terms and Conditions
          </Link>
          <Link
            className={`btn text-primary fs-16 fw-600 ${
              reload === "/privacy-policy" ? "active" : "text-dark"
            }`}
            to={"/privacy-policy"}
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LegalNav;
