import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const PayrollAction = ({bg}) => {
  return (
    <>
      <div className={`payroll-action ${bg}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-4">
              <FullWidthHeading
                title="Payroll in action"
                desc="Learn how to get set up, pay your team, find HR support and
                  benefits, and sync with accounting so you can manage
                  everything in one place."
              />
            </div>
            <div className="col-12 col-lg-8">
              <div className="img-content">
                <img
                  className="img-fluid w-100"
                  src={require("../../../assets/images/feature/payroll/payroll-action.png")}
                  alt="error"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollAction;
