import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

const StartWithUs = () => {
  const [phone, setPhone] = useState("");
  const [passView, setPassView] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [activeLabelFullName, setActiveLabelFullName] = useState(false);
  const [activeLabelEmail, setActiveLabelEmail] = useState(false);
  const [activeLabelCompanyName, setActiveLabelCompanyName] = useState(false);
  const [activeLabelRemarks, setActiveLabelRemarks] = useState(false);
  const [activeLabelNumberOfEmployees, setActiveLabelNumberOfEmployees] =
    useState(false);
  const [activeLabelPhoneNumber, setActiveLabelPhoneNumber] = useState(true);
  const [activeLabelHowDidYouHear, setActiveLabelHowDidYouHear] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const emailJSApiKey = "g_BO247ULOaASCc8k";

  // Create refs for input fields
  const fullNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const companyNameInputRef = useRef(null);
  const remarks = useRef(null);
  const numberOfEmployeesInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  const howDidYouHearInputRef = useRef(null);


  // Function to handle focus and blur events
  const handleFocus = (inputRef, setActiveLabel) => {
    if (inputRef.current) {
      setActiveLabel(true);
    }
  };

  // const handleBlur = (inputRef, setActiveLabel) => {
  //   if (inputRef.current.value.length > 0) {
  //     setActiveLabel(true);
  //   } else {
  //     setActiveLabel(false);
  //   }
  // };

  const mySchema = Yup.object().shape({
    fullname: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    companyName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    numberOfEmployees: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required")
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      companyName: "",
      phoneNumber: "",
      numberOfEmployees: "",
      remarks: ""
    },
    validationSchema: mySchema,
    onSubmit: (values, action) => {
      setLoading(true);

      const sendAbleData = {
        user_id: emailJSApiKey,
        service_id: "service_eebjgej",
        template_id: "template_axg38dr",
        template_params: {
          sender_name: values.fullname,
          email_address: values.email,
          company_name: values.companyName,
          phone_number: values.phoneNumber,
          numberOfEmployees: values.numberOfEmployees,
          remarks: values.remarks
        }
      };

      console.log(sendAbleData);
      fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        body: JSON.stringify(sendAbleData),
        headers: {
          Authorization: `Bearer ${emailJSApiKey}`,
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          if (response.ok) {
            toast.success("Message sent successfully !");
          } else {
            // throw new Error("Oops... Something went wrong");
            toast.error("Oops failed to send message !");
          }
          setLoading(false);
          // formRef.current.reset();
          setPhone("-");
          action.resetForm();
        })
        .catch((error) => {
          console.log(error.message);
          toast.error("Technically failed to send message");
          setLoading(false);
        });
    }
  });
  // const handleBlur = (inputRef, setActiveLabel) => {
  //   if (inputRef.current.value.length > 0) {
  //     setActiveLabel(true);
  //   } else {
  //     setActiveLabel(false);
  //   }
  // };

  let isFromSocialPlatform = window.location.href.includes("#socialShare");
  const [modalShow, setModalShow] = useState(isFromSocialPlatform);

  return (
    <>
      <div className="StartWithUs">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="heading left-heading  text-start">
                <div className="section-name text-primary fs-16">
                  NOTIFICATION
                </div>
                <h2 className="fs-48 text-start">
                  Experience the power of Custom Notification
                </h2>
                <p className="fs-12 text-start mt-3 mb-4">
                  With SmartPeople's custom notification feature, you have the
                  freedom to tailor system notification to your organization's
                  specific needs. Stay connected and informed by sending
                  relevant notifications to authorized users, ensuring they
                  receive the right information at the right time. Experience
                  seamless communication and enhanced engagement with our
                  user-friendly custom notification feature.
                </p>

                <div className="btn-group mt-5 d-flex justify-content-between gap-5 w-75">
                  <Button
                    to={"#"}
                    className="btn btn-light radius-12"
                    onClick={() => setModalShow(true)}
                  >
                    Request Demo
                  </Button>
                  <Link
                    to={"http://apps.smartoffice.ai/register"}
                    className="btn btn-primary radius-12"
                  >
                    Free Signup
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7 text-end">
              <div className="img">
                <img
                  src={require("./../../assets/images/start-with-us/dashboard.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="request-demo-modal request-demo-modal"
      >
        <ToastContainer />

        <Modal.Body>
          <span className="closeBtn" onClick={() => setModalShow(false)}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#CCDBFF" />
              <path
                d="M25.4042 23.9928L29.6985 19.7081C30.0902 19.3164 30.0902 18.6815 29.6985 18.2898C29.3069 17.8982 28.672 17.8982 28.2804 18.2898L23.996 22.5845L19.7116 18.2898C19.32 17.8982 18.6851 17.8982 18.2935 18.2898C17.9018 18.6815 17.9018 19.3164 18.2935 19.7081L22.5878 23.9928L18.2935 28.2775C18.1044 28.465 17.998 28.7203 17.998 28.9866C17.998 29.2529 18.1044 29.5082 18.2935 29.6957C18.481 29.8848 18.7362 29.9912 19.0025 29.9912C19.2688 29.9912 19.5241 29.8848 19.7116 29.6957L23.996 25.401L28.2804 29.6957C28.4679 29.8848 28.7232 29.9912 28.9895 29.9912C29.2558 29.9912 29.511 29.8848 29.6985 29.6957C29.8876 29.5082 29.994 29.2529 29.994 28.9866C29.994 28.7203 29.8876 28.465 29.6985 28.2775L25.4042 23.9928Z"
                fill="#212B36"
              />
            </svg>
          </span>
          <div className="img text-center mb-3">
            <img
              src={require("./../../assets/images/herocircle-1/logo.png")}
              alt=""
            />
          </div>
          <Form className="auth-form" onSubmit={handleSubmit}>
            <h2>Request for Demo</h2>
            <p>
              Share a few details with us and our Enterprise Sales
              Representative will promptly arrange a personalized demo for you.
            </p>
            <Row>
              <Col lg={12}>
                <Form.Group
                  controlId="formGridFullName"
                  className="mb-3 form-group"
                >
                  <FormLabel
                    className={activeLabelFullName ? "active" : "deactive"}
                  >
                    Full name
                  </FormLabel>
                  <Form.Control
                    type="text"
                    ref={fullNameInputRef}
                    onFocus={() =>
                      handleFocus(fullNameInputRef, setActiveLabelFullName)
                    }
                    onBlur={handleBlur}
                    name="fullname"
                    onChange={handleChange}
                    value={values.name}
                  />
                  {errors.fullname && touched.fullname ? (
                    <span className="form-error text-danger fs-12">
                      {errors.fullname}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="mb-3 form-group"
                >
                  <FormLabel
                    className={activeLabelEmail ? "active" : "deactive"}
                  >
                    Email address
                  </FormLabel>
                  <Form.Control
                    type="email"
                    ref={emailInputRef}
                    onFocus={() =>
                      handleFocus(emailInputRef, setActiveLabelEmail)
                    }
                    onBlur={handleBlur}
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <span className="form-error text-danger fs-12">
                      {errors.email}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group
                  as={Col}
                  controlId="formGridCompanyName"
                  className="mb-3 form-group"
                >
                  <FormLabel
                    className={activeLabelCompanyName ? "active" : "deactive"}
                  >
                    Company name
                  </FormLabel>
                  <Form.Control
                    type="text"
                    ref={companyNameInputRef}
                    onFocus={() =>
                      handleFocus(
                        companyNameInputRef,
                        setActiveLabelCompanyName
                      )
                    }
                    onBlur={handleBlur}
                    name="companyName"
                    onChange={handleChange}
                    value={values.companyName}
                  />
                  {errors.companyName && touched.companyName ? (
                    <span className="form-error text-danger fs-12">
                      {errors.companyName}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group
                  as={Col}
                  controlId="formGridNumberOfEmployees"
                  className="mb-3 form-group"
                >
                  <Form.Select
                    defaultValue="Number of employee"
                    className="form-control"
                    ref={numberOfEmployeesInputRef}
                    onFocus={() =>
                      handleFocus(
                        numberOfEmployeesInputRef,
                        setActiveLabelNumberOfEmployees
                      )
                    }
                    onBlur={handleBlur}
                    name="numberOfEmployees"
                    onChange={handleChange}
                    value={values.numberOfEmployees}
                    style={{fontSize: "13px"}}
                  >
                    <option>Number of employees</option>
                    <option value="1-25">1-25</option>
                    <option value="25-50">25-50</option>
                    <option value="100-200">100-200</option>
                    <option value="200-500">200-500</option>
                    <option value="500+">500+</option>
                  </Form.Select>
                  {errors.numberOfEmployees && touched.numberOfEmployees ? (
                    <span className="form-error text-danger fs-12">
                      {errors.numberOfEmployees}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group
                  as={Col}
                  controlId="formGridPhoneNumber"
                  className="mb-3 form-group phoneNumber"
                >
                  <FormLabel
                    className={activeLabelPhoneNumber ? "active" : "deactive"}
                  >
                    Phone Number
                  </FormLabel>
                  <PhoneInput
                    name="phoneNumber"
                    className="form-control"
                    defaultCountry={"BD"}
                    international
                    value={phone}
                    onChange={
                      (phone) => setFieldValue("phoneNumber", phone)
                      // handleFieldChange("phoneNumber", phone)
                    }
                    ref={phoneNumberInputRef}
                    onFocus={() =>
                      handleFocus(
                        phoneNumberInputRef,
                        setActiveLabelPhoneNumber
                      )
                    }
                    onBlur={handleBlur}
                  />
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <span className="form-error text-danger fs-12">
                      {errors.phoneNumber}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group
                  as={Col}
                  controlId="formGridRemarks"
                  className="mb-3 form-group"
                >
                  <FormLabel
                    className={activeLabelRemarks ? "active" : "deactive"}
                  >
                    Remarks
                  </FormLabel>
                  <Form.Control
                    type="text"
                    ref={remarks}
                    onFocus={() => handleFocus(remarks, setActiveLabelRemarks)}
                    onBlur={handleBlur}
                    name="remarks"
                    onChange={handleChange}
                    value={values.remarks}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Button
                variant="primary"
                  type="submit"
                  className={`w-100 ${loading ? "disabled" : ""}`}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                  {loading ? " Sending Message... " : "Send Message"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StartWithUs;
