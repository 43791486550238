import React from "react";
import CompanyCard from "./CompanyCard";



const AllCompany = ({data}) => {
  return (
    <div className="container mt-80 mb-80">
      <div className="row">
        {data?.map((item) => (
          <div key={item.id} className="col-12 col-md-6 col-lg-4 mb-3">
            <CompanyCard img={item.img} title={item.title} desc={item.desc} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCompany;
