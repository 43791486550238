import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { blogData } from "../assets/json/blogData";
import BreadCum from "../components/Blog/BreadCum";
import DynamicPost from "../components/Blog/DynamicPost";
import SingleBlogHero from "../components/Blog/SingleBlogHero";

const SingleBlog = ({ props }) => {
  const params = useParams();
  const [category, setCategory] = useState(blogData);


  let singleCategory =
    category.length > 0 &&
    category.find((item) => {
      return item.id == params.id;
    });

  return (
    <div>
      <BreadCum />
      <SingleBlogHero singleData={singleCategory}/>
      <DynamicPost singleData={singleCategory}/>
    </div>
  );
};

export default SingleBlog;
