import React from "react";
import { Link } from "react-router-dom";

const HeroWithSearch = () => {
  return (
    <div className="text-center hero-with-search mt-64 p-3">
      <p className="text-primary text-uppercase fw-700">Trending blogs</p>
      <h2 className="fw-700 fs-64 text-dark">News for every Penny</h2>
      <p className="mt-4 mb-5 fs-18 text-dark">
        Learn from thousands of professionals. Read their stories and develop
        your skills the most funniest way via weekly newsletters.
      </p>

      <form>
        <input className="form-control" placeholder="Search Blogs" />
        <Link className="btn btn-primary d-flex align-items-center justify-content-center gap-1">
          <span>
            <img
              src={require("./../../assets/images/blog/receiptsearch.png")}
              alt=""
            />
          </span>
          Search{" "}
        </Link>
      </form>
    </div>
  );
};

export default HeroWithSearch;
