const DomainCustomizationFeatureData=[
    {
        id: 0,
        title: "Lightning-fast search",
        desc: "Receive incentives for developing or improving products, processes and technologies.",
        img: require("./../images/feature/domain-customization/Lightning-fast search.png")
    },
    {
        id: 1,
        title: "Domain Privacy",
        desc: "Claim tax credits against employment taxes paid for employees retained during the pandemic.",
        img: require("./../images/feature/domain-customization/Domain Privacy.png")
    },
    {
        id: 2,
        title: "Geo-location domains",
        desc: "Benefit from growing, relocating, training or investing in facilities.",
        img: require("./../images/feature/domain-customization/Geo-location domains.png")
    },
];

export { DomainCustomizationFeatureData };

