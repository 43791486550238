import React from "react";

const MapSection = () => {
  return (
    <div className="container text-center">
      <img
        className="img-fluid mx-auto"
        src={require("../../assets/images/comp/Content.png")}
        alt="Error!"
      />
    </div>
  );
};

export default MapSection;
