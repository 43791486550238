import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const LeaveManagementControl = () => {
  return (
    <>
      <div className="payroll-manage leaveManagement-control">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <FullWidthHeading
                title={"Take control of Employee leave"}
                desc={
                  "Seamless solution to manage leave requests and holiday tracker, empowering employees to request holidays effortlessly, from anywhere anytime."
                }
              />

              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/leave-management/Take control of Employee leave.png")}
                  alt="Employee leave"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementControl;
