import Faqs from "../../components/Common/Faqs";
import BenefitOfSmartPeople from "../../components/Feature/CompanyManagement/BenefitOfSmartPeople";
import CompanyManagementHero from "../../components/Feature/CompanyManagement/CompanyManagementHero";
import FeaturesCompanyManagement from "../../components/Feature/CompanyManagement/FeaturesCompanyManagement";
import ManagementMultipleCompany from "../../components/Feature/CompanyManagement/ManagementMultipleCompany";
import RealTimeAttendanceTracking from "../../components/Feature/CompanyManagement/RealTimeAttendanceTracking";
import RealTimeTrackingOfEmployee from "../../components/Feature/CompanyManagement/RealTimeTrackingOfEmployee";
import "./../../assets/css/future.css";
const CompanyManagement = () => {
  return (
    <>
      <CompanyManagementHero />
      <RealTimeAttendanceTracking />
      <BenefitOfSmartPeople bg={"bg-shadow"} />
      <FeaturesCompanyManagement  />
      <ManagementMultipleCompany bg={"bg-shadow"} />
      <RealTimeTrackingOfEmployee />
      <Faqs />
      
    </>
  );
};

export default CompanyManagement;
