import React from "react";
import BannerText from "../../Banner/BannerText";

const ShiftManagementBlockchain = ({bg}) => {
  return (
    <>
      <section className={`feture-hero shiftManagement-blockchain ${bg}`}>
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/shift-lock.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <BannerText
                title="Enhance Your Shift 
Management system with 
Blockchain Technology
"
                desc="Experience enhanced security and reliability with our blockchain-powered notification system. Messages are securely stored in a decentralized, tamper-proof ledger, guaranteeing instant and secure delivery. The use of distributed ledgers ensures constant availability, while smart contracts automate the delivery process."
             
              />
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default ShiftManagementBlockchain;
