import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="details-section-6x6 mt-80">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <div className="img-content">
              <img
                className="img-fluid"
                src={require("../../assets/images/blog/blog_hero_image.png")}
                alt="error"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text-content">
              <p className="text-primary fs-16 fw-700 text-uppercase my-2">
                Decentralization
              </p>
              <h2 className="text-dark fw-800 fs-40">
              Top features to look for in a Human Resource Management System (HRMS).
              </h2>
              <div className="badge-group mt-4">
                <span className="badge text-primary radius-8 bg-primary-thin fw-400 p-2">
                  {" "}
                  Team News
                </span>
                <span className="badge text-primary radius-8 bg-primary-thin fw-400 p-2">
                  {" "}
                  Team News
                </span>
                <span className="badge text-primary radius-8 bg-primary-thin fw-400 p-2">
                  {" "}
                  Team News
                </span>
              </div>
              <p className="details fs-16 fw-400 mt-4 ">
              Human Resource Management System (HRMS) is essential for any organization that wants to manage its human resources efficiently. The software helps manage the HR department's day-to-day activities, such as employee data management, attendance, payroll, benefits, performance management, and other related tasks. The use of HRMS not only streamlines the HR processes but also enables organizations to make data-driven decisions that can improve employee engagement and productivity. Below mentioned are the top features to look for in an HRMS. 
              </p>
              <Link to={`/singleblog/${2}`} className="btn btn-primary radius-12 mt-3 text-white">
                Read Post
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
