const corehrData =[
    {
      id: 0,
      link:"/smart-people/payroll-management",
      icon: require("../images/overview/item-1.png"),
      title: "Automated Payroll Processing",
      desc: "Say goodbye to manual calculations! Our automated system effortlessly calculates and processes employee salaries, ensuring accurate and timely payments."
    },
    {
      id: 1,
      link:"/smart-people/payroll-management",
      icon: require("../images/overview/item-2.png"),
      title: "Error Free Salary Calculation",
      desc: "Eliminate human errors in payroll calculations,ensuring precise and error-free payments. Say goodbye to payroll discrepancies and foster trust among your employees."
    },
    {
      id: 2,
      link:"/smart-people/tax-management",
      icon: require("../images/overview/item-3.png"),
      title: "Tax Compliance with Confidence",
      desc: "Our tax configuration feature guarantees compliance with local tax regulations. Stay up-to-date with tax changes and configure tax and deductions with ease."
    },
    {
      id: 3,
      link:"/smart-people/blockchain-technology",
      icon: require("../images/overview/item-4.png"),
      title: "Data Security and Accessibility",
      desc: "Our platform prioritizes data security, ensuring sensitive payroll information remains protected. Securely access payroll data whenever and wherever you need it."
    }
  ]

  export { corehrData }

