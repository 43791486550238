import { Link } from "react-router-dom";
import "../../assets/css/common.css";
import BreadCum from "../Blog/BreadCum";
import RecentBlogs from "../Blog/RecentBlogs";

function AllComponent() {
  return (
    <section id="page_content">
      <Link to={"#"} className="btn btn-primary radius-16">
        TEsting
      </Link>
      <Link to={"#"} className="btn btn-light">
        Light
      </Link>

      <h2 className="mt-5 mb-5">###Info card component###</h2>
      <div className="info-card w-25 text-center card-border radius-16 card-shadow card p-3">
        <Link to={"#"} className="text-end d-block  loading-spin" href="">
          <img
            className="img-fluid "
            src={require("../../assets/images/comp/icons/ArrowUpRight.png")}
            alt="error"
          />
          <div className="circle-loading"></div>
        </Link>

        <div className="img-content">
          <svg
            width="153"
            height="153"
            viewBox="0 0 153 153"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_2001_44739)">
              <rect
                x="41.877"
                y="21"
                width="69.2444"
                height="69.2442"
                rx="13.8489"
                fill="white"
              />
              <path
                d="M88.752 42.9375H65.002C64.3461 42.9375 63.8145 43.4692 63.8145 44.125V67.875C63.8145 68.5308 64.3461 69.0625 65.002 69.0625H88.752C89.4078 69.0625 89.9395 68.5308 89.9395 67.875V44.125C89.9395 43.4692 89.4078 42.9375 88.752 42.9375Z"
                fill="#8601D7"
                fillOpacity="0.2"
                stroke="#8601D7"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M63.8145 50.0625H89.9395V44.125C89.9395 43.8101 89.8143 43.508 89.5916 43.2853C89.3689 43.0626 89.0669 42.9375 88.752 42.9375H65.002C64.687 42.9375 64.385 43.0626 64.1623 43.2853C63.9396 43.508 63.8145 43.8101 63.8145 44.125V50.0625Z"
                fill="#8601D7"
              />
              <path
                d="M84.002 40.5625V45.3125"
                stroke="#8601D7"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M69.752 40.5625V45.3125"
                stroke="#8601D7"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M63.8145 50.0625H89.9395"
                stroke="#8601D7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="42.3097"
                y="21.4328"
                width="68.3789"
                height="68.3787"
                rx="13.4161"
                stroke="#919EAB"
                stroke-opacity="0.24"
                strokeWidth="0.865554"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2001_44739"
                x="0.330341"
                y="0.226694"
                width="152.337"
                height="152.339"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="20.7733" />
                <feGaussianBlur stdDeviation="20.7733" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.16 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2001_44739"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2001_44739"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="text-content">
          <h3 className="fs-16">Manage Your Shifts with Ease</h3>
          <p className="fs-14 w-100 mx-auto">
            Create, manage and assign shifts quickly and easily with our
            intuitive shift management system.
          </p>
        </div>
      </div>

      <h2 className="mt-5 mb-5">###Blog card component###</h2>
      <div className="blog-card w-25 radius-12 overflow-hidden card">
        <div className="img-content">
          <img
            className="img-fluid w-100"
            src={require("../../assets/images/comp/blog_image.png")}
            alt=""
          />

          <div className="text-content">
            <div className="card-badge d-flex radius-16 bg-primary-thin ">
              <span className="badge-cat text-primary bg-light radius-16">
                Design
              </span>
              <span className=" text-primary "> 8 min read</span>
            </div>
            <h3 className="fs-20 mt-3">UX review presentations</h3>
            <p className="fs-12 mt-2">
              How do you create compelling presentations that wow your
              colleagues and impress your managers?
            </p>

            <div className="published-info text-primary">
              <span className="fs-14">
                20 Jan 2022 • <span>7 Views</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <h2 className="mt-5 mb-5">###Centered heading component###</h2>

      <div className="heading centered-heading  text-center">
        <div className="section-name text-primary fs-16">RECIPE LIBRARY</div>
        <h2 className="fs-48 text-center">Lets see what we integrate</h2>
        <p className="fs-12 text-center mt-3">
          We recently had to jump on 10+ different plugin across eight different
          countries to find the right owner and escalation process.
        </p>
      </div>

      <h2 className="mt-5 mb-5">###Left aligned heading component###</h2>

      <div className="heading left-heading  text-start w-25">
        <div className="section-name text-primary fs-16">RECIPE LIBRARY</div>
        <h2 className="fs-48 text-start">
          Start working faster with SmartOffice{" "}
        </h2>
        <p className="fs-12 text-start mt-3 mb-4">
          Choose from hundreds of pre-built workflow templates. Use them
          out-of-the-box or tweak them to meet your exact needs.
        </p>

        <div className="btn-group mt-5 d-flex justify-content-between gap-5">
          <Link to={"#"} className="btn btn-light radius-12">
            {" "}
            Request Demo
          </Link>
          <Link to={"http://apps.smartoffice.ai/register"} className="btn btn-primary radius-12">
            {" "}
            Free Signup
          </Link>
        </div>
      </div>

      <h2 className="mt-5 mb-5">###Info card component + learn more ###</h2>
      <div className="info-card w-25 text-center card-border radius-16 card-shadow card">
        <div className="img-content">
          <svg
            width="154"
            height="154"
            viewBox="0 0 154 154"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_2124_104769)">
              <rect
                x="42"
                y="21"
                width="70"
                height="70"
                rx="13.8489"
                fill="white"
              />
              <path
                opacity="0.24"
                d="M76.9998 75.3881C87.7077 75.3881 96.3882 66.7076 96.3882 55.9997C96.3882 45.2918 87.7077 36.6113 76.9998 36.6113C66.2918 36.6113 57.6113 45.2918 57.6113 55.9997C57.6113 66.7076 66.2918 75.3881 76.9998 75.3881Z"
                fill="#7B61FF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.1289 56.0015C69.1289 51.6557 72.6518 48.1328 76.9976 48.1328C79.0845 48.1328 81.0859 48.9618 82.5616 50.4375C84.0372 51.9132 84.8663 53.9146 84.8663 56.0015C84.8663 60.3472 81.3433 63.8702 76.9976 63.8702C72.6518 63.8702 69.1289 60.3472 69.1289 56.0015ZM76.9976 56.7884H80.1451C80.5796 56.7884 80.9319 56.4361 80.9319 56.0015C80.9319 55.5669 80.5796 55.2146 80.1451 55.2146H77.7844V52.854C77.7844 52.4194 77.4322 52.0672 76.9976 52.0672C76.563 52.0672 76.2107 52.4194 76.2107 52.854V56.0015C76.2107 56.4361 76.563 56.7884 76.9976 56.7884Z"
                fill="#7B61FF"
              />
              <rect
                x="42.4328"
                y="21.4328"
                width="69.1344"
                height="69.1344"
                rx="13.4161"
                stroke="#919EAB"
                stroke-opacity="0.24"
                strokeWidth="0.865554"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2124_104769"
                x="0.453388"
                y="0.226694"
                width="153.093"
                height="153.093"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="20.7733" />
                <feGaussianBlur stdDeviation="20.7733" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.16 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2124_104769"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2124_104769"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="text-content">
          <h3 className="fs-16">Manage Your Shifts with Ease</h3>
          <p className="fs-14 w-100 mx-auto">
            Create, manage and assign shifts quickly and easily with our
            intuitive shift management system.
          </p>
          <div className="mt-5 ">
            <Link className="text-primary " style={{ fontWeight: "bold" }}>
              {" "}
              Learn More
            </Link>
          </div>
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Newsletter section ###</h2>

      <section
        style={{
          backgroundImage: `url(${require("../../assets/images/comp/newsletter.png")})`
        }}
        className="newsletter text-center d-flex"
      >
        <div className="newsletter-content mx-auto d-flex flex-column justify-content-center align-items-center">
          <h2 className="text-dark fs-64 fw-800">
            Take advantage of the limited time offer to access all features
          </h2>

          <Link className="btn btn-primary w-50 mt-5" to={"http://apps.smartoffice.ai/register"}>
            Free Signup
          </Link>
        </div>
      </section>

      <h2 className="mt-5 mb-5">### Product card ###</h2>

      <div className="product-card w-25  card-border radius-16 card-shadow card p-3">
        <Link to={"#"} className="text-end d-block loading-spin" href="">
          <img
            className="img-fluid"
            src={require("../../assets/images/comp/icons/ArrowUpRight.png")}
            alt="error"
          />
          <div className="circle-loading"></div>
        </Link>
        <div className="img-content text-center ">
          <img
            src={require("../../assets/images/comp/product.png")}
            alt="error"
          />
        </div>
        <div className="text-content">
          <h3 className="fs-24 fw-700 mb-4">ZKTeco F18</h3>
          <ul>
            <li className="fs-16">Fingerprint capacity: 3000</li>
            <li className="fs-16">Card Capacity: 5000</li>
            <li className="fs-16">Transaction Capacity: 30000 Transactions</li>
            <li className="fs-16">Brand: ZKTeco</li>
            <li className="fs-16">Price: 10500 Tk</li>
          </ul>

          <div className="mt-5 text-center mb-4">
            <Link className="text-primary " style={{ fontWeight: "bold" }}>
              {" "}
              View Details
            </Link>
          </div>
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Statuscard component ###</h2>

      <div className="card stat-card card-shadow card-border w-25 p-3   radius-20">
        <div className="text-content">
          <h3 className="text-dark fw-700 fs-24">Tax penalty protection</h3>
          <p className="fs-14">
            With tax penalty protection we'll pay up to $25,000 if you receive a
            penalty.**
          </p>
        </div>
        <div className="img-content text-center ">
          <img
            className="mt-3 fs-14"
            src={require("../../assets/images/comp/stat.png")}
            alt="error"
          />
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Horizontal Statuscard component ###</h2>

      <div className="card stat-card card-shadow card-border w-25 p-3 d-flex flex-row gap-3 radius-20">
        <div className="text-content">
          <h3 className="text-dark fw-700 fs-24">Time tracking</h3>
          <p className="fs-14">
            Lorem ipsum dolor sit amet consectetur. Lacus hendrerit in id morbi
            eget sit fringilla amet tempus.{" "}
          </p>
        </div>
        <div className="img-content text-center ">
          <img
            className="mt-3 fs-14"
            src={require("../../assets/images/comp/stat2.png")}
            alt="error"
          />
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Schedule card component ###</h2>

      <div className="schedule-card card card-border w-25 p-3">
        <div className="schedule-card-heading mb-2 pb-2">
          <h3 className="fs-14 Pb-3 ">EMPLOYEE SCHEDULING SOFTWARE</h3>
        </div>

        <div className="img-content text-center mb-2">
          <img
            className="mt-4 img-fluid"
            src={require("../../assets/images/comp/schedule.png")}
            alt="error"
          />
        </div>

        <div className="text-content text-center">
          <h2 className="fs-24 fw-700">Schedule Faster</h2>
          <p className="fs-14 fw-400">
            Create the work schedule in minutes and share with your team.
          </p>
          <Link to={"#"} className="btn btn-light radius-16 mt-3">
            {" "}
            Explore Scheduling{" "}
          </Link>
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Content card component ###</h2>

      <div className="content-card  w-25 p-3">
        <div className="img-content text-center mb-2">
          <img
            className="mt-4 img-fluid"
            src={require("../../assets/images/comp/storage_1.png")}
            alt="error"
          />
        </div>

        <div className="text-content text-center">
          <h2 className="fs-20 fw-500">Secure storage</h2>
          <p className="fs-16 fw-400 mb-3">
            An all-in-one customer service platform that helps you balance
            everything your customers need to be happy.
          </p>
          <Link to={"#"} className="text-primary fs-16 fw-600 ">
            {" "}
            Learn More {"->"}{" "}
          </Link>
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Content card component ###</h2>

      <div className="details-section-6x6">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div className="img-content">
                <img
                  className="img-fluid"
                  src={require("../../assets/images/comp/layer.png")}
                  alt="error"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="text-content">
                <p className="text-primary fs-16 fw-700 text-uppercase">
                  Decentralization
                </p>
                <h2 className="text-dark fw-800 fs-40">
                  Decentralization boosts security by removing single points of
                  failure
                </h2>
                <p className="details fs-16 fw-400 mt-4">
                  Our notification system now incorporates blockchain technology
                  to provide a more secure, reliable, and instant notification
                  process. With blockchain, notifications are securely stored in
                  a decentralized and immutable ledger.
                </p>
                <Link to={"#"} className="btn btn-primary radius-12 mt-3">
                  {" "}
                  Explore Scheduling{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="mt-5 mb-5">### FAQ component ###</h2>

      <div className={"faq"}>
        <div className="container">
          <h2 className="text-dark fw-800 fs-48 text-center mb-5">
            Frequently Asked Questions
          </h2>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Accordion Item #1
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Nunc duis id aenean gravida tincidunt eu, tempor ullamcorper.
                  Viverra aliquam arcu, viverra et, cursus. Aliquet pretium
                  cursus adipiscing gravida et consequat lobortis arcu velit{" "}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Accordion Item #2
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong>This is the second item's accordion body.</strong> It
                  is hidden by default, until the collapse plugin adds the
                  appropriate classes that we use to style each element. These
                  classes control the overall appearance, as well as the showing
                  and hiding via CSS transitions. You can modify any of this
                  with custom CSS or overriding our default variables. It's also
                  worth noting that just about any HTML can go within the{" "}
                  <code>.accordion-body</code>, though the transition does limit
                  overflow.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Accordion Item #3
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong>This is the third item's accordion body.</strong> It
                  is hidden by default, until the collapse plugin adds the
                  appropriate classes that we use to style each element. These
                  classes control the overall appearance, as well as the showing
                  and hiding via CSS transitions. You can modify any of this
                  with custom CSS or overriding our default variables. It's also
                  worth noting that just about any HTML can go within the{" "}
                  <code>.accordion-body</code>, though the transition does limit
                  overflow.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="mt-5 mb-5">### Tab component ###</h2>
      <div className="hr-tab">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <ul
                className="nav nav-pills mb-3 flex-column"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active text-start p-4"
                    id="pills-onboarding-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-onboarding"
                    type="button"
                    role="tab"
                    aria-controls="pills-onboarding"
                    aria-selected="true"
                  >
                    <h4 className="text-primary text-dark fs-18">Onboarding</h4>
                    <p className="fs-14">
                      Lorem ipsum dolor sit amet consectetur. Ac tortor ut ipsum
                      posuere elementum aenean duis interdum.
                    </p>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link text-start p-4"
                    id="pills-transitions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-transitions"
                    type="button"
                    role="tab"
                    aria-controls="pills-transitions"
                    aria-selected="false"
                  >
                    <h4 className="text-dark fs-18">Transitions</h4>
                    <p className="fs-14">
                      Lorem ipsum dolor sit amet consectetur. Ac tortor ut ipsum
                      posuere elementum aenean duis interdum.
                    </p>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link text-start p-4"
                    id="pills-offboarding-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-offboarding"
                    type="button"
                    role="tab"
                    aria-controls="pills-offboarding"
                    aria-selected="false"
                  >
                    <h4 className="text-dark fs-18">Offboarding</h4>
                    <p className="fs-14">
                      Lorem ipsum dolor sit amet consectetur. Ac tortor ut ipsum
                      posuere elementum aenean duis interdum.
                    </p>
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-5 offset-0 offset-lg-2">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-onboarding"
                  role="tabpanel"
                  aria-labelledby="pills-onboarding-tab"
                  tabIndex="0"
                >
                  onboarding
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-transitions"
                  role="tabpanel"
                  aria-labelledby="pills-transitions-tab"
                  tabIndex="0"
                >
                  Transitions
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-offboarding"
                  role="tabpanel"
                  aria-labelledby="pills-offboarding-tab"
                  tabIndex="0"
                >
                  Offboarding
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecentBlogs />
      <BreadCum />
    </section>
  );
}

export default AllComponent;
