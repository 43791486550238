import React from "react";
import { Link } from "react-router-dom";

const BlogCard2 = ({ item }) => {
  return (
    <>
      <div className="d-flex gap-4">
        <div className="img-content">
          <img
            className="img-fluid"
            src={require(`../../assets/images/comp/${item.img}`)}
            alt={item.img}
          />
        </div>
        <div className="text-content">
          <Link to={`/singleblog/${item.id}`}>
            <h3
              style={{ lineHeight: "150%" }}
              className="fs-16 fw-500 text-dark text-wrap"
            >
              {item.title.slice(0, 50)}...
            </h3>
          </Link>
          <p className="text-primary fw-600 fs-14">{item.publishDate}</p>
        </div>
      </div>
    </>
  );
};

export default BlogCard2;
