import React from "react";
import { blogData } from "../../assets/json/blogData";
import BlogItem from "./BlogItem";

const Blog = () => {
  return (
    <div className="blog-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading text-center">
              <div className="section-name text-primary fs-16">BLOG</div>
              <h2 className="fs-40 text-center">From the blog</h2>
              <p className="fs-20 text-center mt-3 mb-64">
              Stay informed with our blog's expert content on standard operating procedures, best practices, cutting-edge technologies, and valuable resources for your organization.

              </p>
            </div>
          </div>
          <BlogItem data={blogData} />
          {/* <Link to={'#'} className="btn btn-primary radius-8 mt-64 mx-auto view-all">View all posts</Link> */}
        </div>
      </div>
    </div>
  );
};

export default Blog;
