import React from "react";
import Section6x6 from "../../Device/Section6x6";

const AttendanceManagementTimeBlockchain = ({bg}) => {
  return (
    <>
      <div className={`AttendanceManagement-blockchain ${bg}`}>
        <Section6x6
          title="Enhance Your Attendance Management 
System with Blockchain 
Technology
"
          desc="Revolutionize Your Attendance Management System with Blockchain Technology. Experience enhanced security, transparency, and immutability in attendance records, ensuring accurate and tamper-proof data. Embrace the future of attendance management with our cutting-edge blockchain solution."
          btnText="Learn More"
          btnName="btn-primary"
          direction={1}
          imgName="finger_print.png"
          btnLink={'/features/blockchain-technology'}
        />
      </div>
    </>
  );
};

export default AttendanceManagementTimeBlockchain;
