import { LiveChatWidget } from "@livechat/widget-react";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import MobileFooter from "../Footer/MobileFooter";
import Header from "../Header/Header";
import NewsLetter from "../NewsLetter/NewsLetter";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ToTopBtn from "../ToTopBtn/ToTopBtn";

const Layout = () => {
  const location = useLocation();
  const reload = location.pathname;


  const leaveSection = [
    "/faq",
    "/contact",
    "/terms-condition",
    "/privacy-policy",
    "/technical-support",
    "/billing",
    "/sales"
  ];

  return (
    <div className="main">
      <ScrollToTop>
        <Header />
        {/* <NavbarNav /> */}
        <Outlet />
        {!leaveSection.includes(reload) && <NewsLetter />}
        <Footer />
        <MobileFooter />
        <ToTopBtn />
        <LiveChatWidget license="12262128" />
      </ScrollToTop>
    </div>
  );
};

export default Layout;
