import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import OffCanvus from "../Common/OffCanvus";
import OffCanvus2 from "../Common/OffCanvus2";
import navData from "./../../assets/json/navbar.json";
import MobileDropdown from "./MobileDropdown";

const Header = () => {
  const {pathname} = useLocation();
  const exactPath = pathname.split('/')[1];
  const [scrollPosition, setPosition] = useState({ scrollY: 0 });
  const [btnId, setBtnId] = useState("Smart People");
  const [isNavOpened, setIsNavOpened] = useState(false);
  const [linkType, setLinkType] = useState("");
  const [level1MenuData, setLevel1MenuData] = useState([]);
  const [level2MenuData, setLevel2MenuData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(10000);
  const [nav, setNav] = useState(navData);
  const [filteredData, setFilteredData] = useState({});
  const [navHoverData, setNavHoverData] = useState();

  // console.log(level1MenuData);

  useEffect(() => {
    setNav(navData);
  }, []);

  // Is Butto hovered action for desktop meagamenu started
  const btnHovered = (id) => {
    setBtnId(id);
  };

  // Is Button hovered action for desktop meagamenu ended

  //Handeling mobile navbar menu to disapeear and for transfaring level menu data started
  //-----------------------------------------------------------------------------
  const handleNavLinkClick = (item) => {
    const windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
    item.options && true ? setLinkType("dropdown") : setLinkType("--");
    setLevel1MenuData(item);
    // handleMobNavExpand();
    if (item.hasDropdown === undefined) {
      const Nav = document.querySelector(".header_nav");
    }
    dismissMegaMenu();
  };

  const closeDropdown = (item) => {
    const mobileNav = document.querySelector("#navbarScroll");
    const dropdownNav = document.querySelector("#dropdown-toggle");
    const dropdownMenu = document.querySelector("#dropdown-menu");
    mobileNav.classList.remove("show");
    dropdownNav.classList.remove("show");
    dropdownMenu.classList.remove("show");
    // const mobileNav = document.querySelector("#navbarScroll");
    // mobileNav.classList.remove("show");
  };

  const handleSubMenuClick = (id) => {
    const filteredData = level1MenuData.find((item) => {
      return item.id === id;
    });
    // console.log(filteredData);
    setLevel2MenuData(filteredData);
  };

  // is link type is not dropdown then close mobile nav toggle started
  const handleMobNavExpand = () => {
    if (linkType !== "dropdown") {
      closeDropdown();
    }
  };

  // is link type is not dropdown then close mobile nav toogle ended

  const dismissMegaMenu = () => {
    const megamenu = document.querySelector(".megamenu");
    megamenu.classList.add("remover");
    setTimeout(() => {
      megamenu.classList.remove("remover");
    }, 100);
  };
  // console.log(btnId);
  useEffect(() => {
    const filter = nav.find((item) => btnId === item.name);
    setNavHoverData(filter);

    function updatePosition() {
      setPosition({ scrollY: window.scrollY });
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, [btnId, nav]);

  useEffect(() => {
    handleMobNavExpand();
    const windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  }, []);
  const navFeatures = nav[0].options;
  return (
    <>
      <section
        id="top_navbar"
        className={scrollPosition.scrollY > 300 ? "sticky" : ""}
      >
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <div className="mob-nav">
              <Link className="navbar-brand" to="/">
                <img
                  className="img-fluid"
                  src={require("./../../assets/images/logo/logo.png")}
                  alt=""
                />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <img
                  src={require("../../assets/images/comp/hamburger.png")}
                  alt="Error !"
                />
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item ">
                  <NavLink
                    type="button"
                    className={"nav-link d-flex justify-content-between"}
                    to="/"
                    onClick={closeDropdown}
                  >
                    <span>Home</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    type="button"
                    className={"nav-link d-flex justify-content-between"}
                    to="/about"
                    onClick={closeDropdown}
                  >
                    <span>About</span>
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <button
                    type="button"
                    className={
                      `nav-link d-flex justify-content-between dropdown-toggle ${exactPath === 'features' ? 'active' : ''}`
                    }
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="dropdown-toggle"
                  >
                    <span>Features</span>
                  </button>
                  <div className="megamenu dropdown-menu" id="dropdown-menu">
                    <div className="container mb-3">
                      <div className="row">
                        {nav.map((item) => (
                          <div key={item.id} className="col-3">
                            <div
                              className="megamenu-button level-1-button ps-0 pe-0"
                              onMouseEnter={() => setBtnId(item.name)}
                              onMouseLeave={() => setBtnId(item.name)}
                            >
                              <Link
                                className={`fs-20 fw-600`}
                                key={item.id}
                                to={item.link}
                                onClick={closeDropdown}
                              >
                                <span className="d-flex align-items-center gap-1">
                                  <img
                                    className="img-fluid icon"
                                    src={require("./../../assets/images/megamenu/smartPeople.png")}
                                    alt="Error"
                                  />
                                  {item.label}
                                </span>
                                <svg
                                  width="8"
                                  height="14"
                                  viewBox="0 0 8 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 0.929688L7.07 6.99969L1 13.0697"
                                    stroke="#93AAD2"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        ))}

                        <div className="cover-bar"></div>
                      </div>
                    </div>
                    <div className="level-1-menu-wrapper scrollbox">
                      <div className="container">
                        <div className="level-1-menu row">
                          {navFeatures.map((feature) => (
                            <div key={feature.id} className="col-3">
                              <div className="level-1-menu-card menu-item mb-3">
                                <Link
                                  onClick={closeDropdown}
                                  className="d-flex align-items-center justify-content-between gap-1 mb-0"
                                  to={`${feature.link}`}
                                >
                                  <span className="d-flex align-items-center gap-1">
                                    <img
                                      className="img-fluid"
                                      src={require(`./../../assets/images/megamenu/new-icon/${feature.img}`)}
                                      alt="Error"
                                    />
                                    <h5 className="fs-15 fw-600 mb-0">
                                      {feature.title}
                                    </h5>
                                  </span>
                                  <svg
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 0.929688L7.07 6.99969L1 13.0697"
                                      stroke="rgba(1, 63, 215, 1)"
                                      strokeWidth="1.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item ">
                  <NavLink
                    type="button"
                    className={"nav-link d-flex justify-content-between"}
                    to="/pricing"
                    onClick={closeDropdown}
                  >
                    <span>Pricing</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    type="button"
                    className={"nav-link d-flex justify-content-between"}
                    to="/customers"
                    onClick={closeDropdown}
                  >
                    <span>Customers</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    type="button"
                    className={"nav-link d-flex justify-content-between"}
                    to="/blog"
                    onClick={closeDropdown}
                  >
                    <span>Blog</span>
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex auth-btn">
                <Link
                  to="http://apps.smartoffice.ai/"
                  className="btn btn-light radius-8"
                >
                  Login
                </Link>
                <Link
                  to="http://apps.smartoffice.ai/register"
                  className="btn btn-primary radius-8"
                >
                  Free Signup
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </section>
      <OffCanvus
        handleSubMenuClick={handleSubMenuClick}
        level1MenuData={level1MenuData}
      />
      <OffCanvus2
        closeDropdown={closeDropdown}
        level2MenuData={level2MenuData}
        // filteredData={filteredData}
      />
      <MobileDropdown
        closeDropdown={closeDropdown}
        data={nav}
        handleNavLinkClick={handleNavLinkClick}
        windowWidth={windowWidth}
        btnHovered={btnHovered}
        btnId={btnId}
        
      />
    </>
  );
};

export default Header;
