import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const LeaveManagementWorkweeks = () => {
  return (
    <>
      <div className="payroll-revolutionize leaveManagement-Workweeks">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-7">
              <div className="img-content">
                <img
                  className="img-fluid w-100"
                  src={require("../../../assets/images/feature/leave-management/Holiday Calendar and Weekends.png")}
                  alt="error"
                />
              </div>
            </div>
            <div className="col-12 col-lg-5">
            <FullWidthHeading
                title={"Holiday Calendar and Weekends"}
//                 desc={`Streamline leave requests and approvals, saving time and reducing administrative burden.
// Give employees control over their leave, boosting morale and engagement.
// Stay compliant with leave regulations and track time off effortlessly.
// Utilize leave analytics to anticipate staffing needs and optimize workforce planning.
// Centralize public holidays for better time-off management.
// `}
                data={`
                <ul class="list_dots">
                <li>Streamline leave requests and approvals, saving time and reducing administrative burden.</li>
                <li>Give employees control over their leave, boosting morale and engagement.</li>
                <li>Stay compliant with leave regulations and track time off effortlessly.</li>
                <li>Utilize leave analytics to anticipate staffing needs and optimize workforce planning.</li>
                <li>Centralize public holidays for better time-off management.</li>
              </ul>
                <a href="#" class="btn btn-primary radius-8 mt-3"> Try it for free </a>`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementWorkweeks;
