import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useHref, useLocation } from "react-router-dom";
import productTrainingData from "../assets/json/productTrainingData";

const ProductTrainingSection = () => {
  const navigate = useLocation();
  const slug = useHref();
  const newSlug = slug.split("/");
  const filterSlug = navigate.pathname.split("/")[3];
  const [newData, setNewData] = useState(null); // Initial value is null or an empty object/array

  useEffect(() => {
    const filterData = productTrainingData?.find(
      (item) => item.slug == filterSlug
    );
    setNewData(filterData == null ? productTrainingData : filterData.section);
  }, [filterSlug]); // Pass filterSlug as a dependency

  return (
    <>
      <div className="training">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Resources </Breadcrumb.Item>
                <Breadcrumb.Item href="/">{newSlug[2]}</Breadcrumb.Item>
                <Breadcrumb.Item active>{newSlug[3]}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {newData?.map((item) => (
              <div key={item.id} className="col-lg-6">
                <div className="question-card">
                  <div className="img">
                    <img
                      src={require("./../assets/images/ProductTraining/folder.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h4>{item.title}</h4>
                    <ul>
                      <li>
                        <span>{item.article?.length} Articles</span>
                      </li>
                      {item.section && (
                        <li>
                          <span>{item.section?.length} Sections</span>
                        </li>
                      )}
                    </ul>
                    <p>{item.desc}</p>
                    <Link
                      to={`/product-training/single-articles/${item.title}`}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTrainingSection;