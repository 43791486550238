import React from "react";
import { DomainCustomizationPersonalConsultantData } from "../../../assets/json/DomainCustomizationPersonalConsultantData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const DomainCustomizationPersonalConsultant = () => {
  return (
    <>
      <div className="payroll-benifits AttendanceManagement-biometric domaincustomization-PersonalConsultant">
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle={"Personal consultant"}
                title={"Your direct line to tailored support."}
                desc={
                  "We provide mainly three types of attendace record solution"
                }
              />
            </div>
            {DomainCustomizationPersonalConsultantData.map((item, index) => (
              <div key={index} className="col-6 col-lg-4 mb-4">
                <InfoCard2 id={item.id} icon={item.icon} title={item.title} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainCustomizationPersonalConsultant;
