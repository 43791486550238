import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const PayrollWorks = () => {
  return (
    <>
      <div className="payroll-works">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <FullWidthHeading
                sectionTitle="HOW IT WORKS"
                title="Run your payroll in minutes"
                desc="Run payroll as many times as you need to each month — we don’t charge extra. Your team gets paid in just a few clicks. And you can even set it on AutoPilot™."
              />

              <div className="tab-section">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Pre-Build Tax Template
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      Dynamic Pay Grade
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    >
                      Dedicated Payroll Support
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <h2 className="text-start">
                          Introducing Pre-Built Tax Templates for Global
                          Compliance
                        </h2>

                        <p>
                          SmartPeople is proud to offer pre-built tax templates
                          tailored to each country's unique tax regulations and
                          compliance requirements. Our comprehensive tax
                          templates are designed to simplify tax processing for
                          businesses operating in different countries, ensuring
                          accurate and timely tax calculations.
                          <br />
                          <br />
                          With SmartPeople's pre-built tax templates, you can
                          effortlessly handle various tax scenarios specific to
                          each country, including income tax, social security
                          contributions, withholding tax, and more. Stay
                          compliant with local tax laws and regulations without
                          the stress of manual tax calculations.
                          <br />
                          <br />
                          Whether you're operating in the United States, the
                          United Kingdom, Canada, Australia, India, Pakistan,
                          Bangladesh, UEA or any other country, our tax
                          templates are optimized to meet your specific tax
                          needs. SmartPeople ensures that your payroll
                          operations remain seamless and compliant, so you can
                          focus on growing your business without worrying about
                          tax complexities. Experience hassle-free tax
                          processing with SmartPeople's pre-built tax templates
                          tailored for global compliance.
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../../assets/images/feature/payroll/Pre-Build Tax Template.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <h2 className="text-start">
                          Manage Pay Grade Dynamically with SmartPeople
                        </h2>

                        <p>
                          SmartPeople's Payroll Services offer a dynamic and
                          flexible pay grade management system to cater to your
                          organization's evolving needs. Say goodbye to rigid
                          pay structures and embrace a solution that adapts to
                          your business's growth and changing workforce.
                          <br />
                          <br />
                          With SmartPeople, you can easily customize pay grades
                          based on factors such as job roles, experience levels,
                          and performance. This dynamic approach allows you to
                          reward top performers appropriately and adjust
                          salaries to attract and retain top talent.
                          <br />
                          <br />
                          Our intuitive interface enables seamless pay grade
                          management, giving you complete control over salary
                          bands, increments, and bonuses. Whether you're
                          handling a small team or a large workforce,
                          SmartPeople ensures accurate and compliant pay grade
                          management.
                          <br />
                          <br />
                          Experience the power of SmartPeople's dynamic pay
                          grade management, empowering your organization to stay
                          competitive in the job market and foster a motivated
                          and engaged workforce. Let SmartPeople take the
                          complexity out of payroll and elevate your HR
                          operations to new heights.
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../../assets/images/feature/payroll/Dynamic Pay Grade.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabIndex="0"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <h2>
                          Get Expert Payroll Support for Seamless Operations
                        </h2>
                        <p>
                          At SmartPeople, we understand the importance of smooth
                          day-to-day activities for your business. That's why we
                          offer free technical support and access to our
                          dedicated team of IT experts. Whether you encounter a
                          configuration issue, minor glitch or a complex issue,
                          our efficient support team is here to assist you
                          promptly 24/7.
                          <br />
                          <br />
                          Rest assured, our skilled professionals are
                          well-equipped to tackle any scenario related to your
                          payroll or our software. With their expertise, you can
                          resolve any payroll-related concerns and ensure
                          uninterrupted operations.
                          <br />
                          <br />
                          Experience peace of mind with SmartPeople's reliable
                          Payroll Support. Focus on your core business, knowing
                          that our support team is just a call away to help you
                          overcome any challenges that come your way. Your
                          success is our priority, and we are committed to
                          providing top-notch support for your payroll needs.
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div className="img">
                          <img
                            src={require("./../../../assets/images/feature/payroll/Dedicated Payroll Support.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollWorks;
