import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InfoCard from "../InfoCard/InfoCard";
import SectionHeading from "../SectionHeading/SectionHeading";

const Overview = ({data}) => {
   return (
    <div className="overview-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <SectionHeading
            fs="fs-48"
              name="OVERVIEW"
              title="Save time & improve productivity with SmartPeople"
              desc="SmartPeople reinvents employee management with intelligent features that ensure your HR operations run seamlessly, 
empowering you to focus on what truly matters. 
"
              className="text-center"
            />
          </div>
          <div className="col-lg-6">
            <div className="row">
              {data.map(({id,icon, title, desc, link}) => (
                <div key={id} className="col-12 col-md-6 col-lg-6 mb-4 card-info-wrapper">
                  <InfoCard cardLogo="card-logo" icon={icon} title={title} desc={desc} link={link} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img">
            <LazyLoadImage
                    src={require("./../../assets/images/overview/plane.png")}
                    PlaceholderSrc={"./../../assets/images/overview/plane.png"}
                    alt={"plane"}
                    effect="blur"
                    loading="lazy"
                    className="img-fluid"
                  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
