import React from "react";
import { Link } from "react-router-dom";

const OffCanvus2 = ({ level2MenuData, closeDropdown }) => {
const menuSubData = level2MenuData.options;
  return (
    <div
      className="offcanvas offcanvas2 offcanvas-end"
      tabIndex="-1"
      id="offcanvasRight2"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header ">
        <div className="canvas-container d-flex justify-content-between align-items-center">
          <h5 className="offcanvas-title fs-14 fw-500" id="offcanvasRightLabel">
            <Link
              type="button"
              className="text-dark"
              data-bs-toggle={"offcanvas"}
              data-bs-target={"#offcanvasRight1"}
            >
              <img
                className="me-2"
                src={require("../../assets/images/comp/leftArrow.png")}
                alt="error"
              />
              Back
            </Link>
          </h5>
          <button data-bs-dismiss="offcanvas">
            <img
              src={require("../../assets/images/comp/cross.png")}
              alt="error"
            />
          </button>
        </div>
      </div>
      <div className="offcanvas-header ">
        <h5 className="fs-16 canvas-container">{level2MenuData.label}</h5>
      </div>
      <div className="offcanvas-body">
        <div className="canvas-container canvas-body">
          {menuSubData?.map((item) => (
              <Link
                key={item.id}
                onClick={closeDropdown}
                to={item.link}
                className="level-2-mob-menu-card  menu-item pt-2 "
              >
                <div className="d-flex gap-3  mb-2">
                  <img
                    src={require(`../../assets/images/megamenu/new-icon/${item.img}`)}
                    alt="Error"
                  />
                  <h6 className="fs-15 fw-600" data-bs-dismiss="offcanvas">
                    {item.title}
                  </h6>
                </div>
                <p className="fs-12">{item.desc}</p>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OffCanvus2;
