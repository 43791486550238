const NotificationFeatureData = [
    {
        id: 0,
        icon: require("./../images/feature/notification/icon- 1 Event Source Selection.png"),
        title: "Event Source Selection",
        desc: `Choose your event! Whether it be "Employee", "Leave", "Payroll", "Work Slot", or anything else. Get a notification alert for anything that is covered by SmartOffice.`,
    },
    {
        id: 1,
        icon: require("./../images/feature/notification/icon - 2 Action Customization.png"),
        title: "Action Customization",
        desc: `Specify precise actions! Such as "Deleted" or "Changed Password". This degree of granularity ensures that employees receive clear directives, minimizing confusion and promoting prompt responses.`,
    },
    {
        id: 2,
        icon: require("./../images/feature/notification/icon - 3 Target Group Delivery.png"),
        title: "Target Group Delivery",
        desc: `Who shall get this alert?! SmartPeople facilitates targeted communication by enabling users to select specific groups, teams, or departments to whom they want to send notifications.
        `,
    },
    {
        id: 3,
        icon: require("./../images/feature/notification/icon - 4 Diverse Delivery Channels.png"),
        title: "Diverse Delivery Channels",
        desc: `How shall employees get this alert?! SmartPeople allows users to send notifications through various combinations of delivery channels, including "System Notification" "Email" and "SMS"`,
    }
];

export { NotificationFeatureData };

