import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const reload = location.pathname;
  const browserName = navigator;
  const userAgent = navigator.userAgent;
  const element = document.querySelector("body");



   
  


  useEffect(() => {
    if (userAgent.includes("Firefox")) {
      element.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } 
    // if (!location.hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    // }
  }, [reload]);

  return children || null;
};

export default withRouter(ScrollToTop);
