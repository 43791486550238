import React from "react";

const CompanyBanner = () => {
  return (
    <div className="customer-banner">
      <div className="container mx-auto mt-80">
        <div className="customer-wrap">
          <div className="customer-bg">
            <img
              className="img-fluid w-100"
              src={require("../../assets/images/customer/svg.png")}
              alt="Error"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBanner;
