const productTrainingData = [
    {
      id: 0,
      title: "Onboarding",
      desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut.",
      article: [
        {
          id: 0,
          title: "Core Smartoffice feature",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 1,
          title: "Employee Self-Service",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 2,
          title: "Set up your team’s Workspace from scratch",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 3,
          title: "Set up personal workspace",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 4,
          title: "Universal search",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 5,
          title: "The hierarchy overview",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        }
      ],
      slug: "onboarding"
    },
    {
      id: 1,
      title: "Personal settings",
      desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut.",
      article: [
        {
          id: 0,
          title: "Core Smartoffice feature",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 1,
          title: "Employee Self-Service",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 2,
          title: "Set up your team’s Workspace from scratch",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 3,
          title: "Set up personal workspace",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 4,
          title: "Universal search",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 5,
          title: "The hierarchy overview",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        }
      ],
      slug: "personal-settings"
    },
    {
      id: 2,
      title: "Account settings",
      desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut.",
      article: [
        {
          id: 0,
          title: "Core Smartoffice feature",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 1,
          title: "Employee Self-Service",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 2,
          title: "Set up your team’s Workspace from scratch",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 3,
          title: "Set up personal workspace",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 4,
          title: "Universal search",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 5,
          title: "The hierarchy overview",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        }
      ],
      slug: "account-settings"
    },
    {
      id: 3,
      title: "Users and Control",
      desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut.",
      slug: "users-and-control",
      article: [
        {
          id: 0,
          title: "Core Smartoffice feature",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 1,
          title: "Employee Self-Service",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 2,
          title: "Set up your team’s Workspace from scratch",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 3,
          title: "Set up personal workspace",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 4,
          title: "Universal search",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 5,
          title: "The hierarchy overview",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        }
      ],
      section: [
        {
          id: 0,
          title: "Data Security Types",
          desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. ",
          article: [
            {
              id: 0,
              title: "Core Smartoffice feature",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 1,
              title: "Employee Self-Service",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 2,
              title: "Set up your team’s Workspace from scratch",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 3,
              title: "Set up personal workspace",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 4,
              title: "Universal search",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 5,
              title: "The hierarchy overview",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            }
          ]
        },
        {
          id: 1,
          title: "User Management",
          desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. ",
          article: [
            {
              id: 0,
              title: "Core Smartoffice feature",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 1,
              title: "Employee Self-Service",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 2,
              title: "Set up your team’s Workspace from scratch",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 3,
              title: "Set up personal workspace",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 4,
              title: "Universal search",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 5,
              title: "The hierarchy overview",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            }
          ]
        },
        {
          id: 2,
          title: "Role Management",
          desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. ",
          article: [
            {
              id: 0,
              title: "Core Smartoffice feature",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 1,
              title: "Employee Self-Service",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 2,
              title: "Set up your team’s Workspace from scratch",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 3,
              title: "Set up personal workspace",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 4,
              title: "Universal search",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 5,
              title: "The hierarchy overview",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            }
          ]
        },
        {
          id: 3,
          title: "Profile Management",
          desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. ",
          article: [
            {
              id: 0,
              title: "Core Smartoffice feature",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 1,
              title: "Employee Self-Service",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 2,
              title: "Set up your team’s Workspace from scratch",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 3,
              title: "Set up personal workspace",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 4,
              title: "Universal search",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 5,
              title: "The hierarchy overview",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            }
          ]
        },
        {
          id: 4,
          title: "Group Management",
          desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut. ",
          article: [
            {
              id: 0,
              title: "Core Smartoffice feature",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 1,
              title: "Employee Self-Service",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 2,
              title: "Set up your team’s Workspace from scratch",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 3,
              title: "Set up personal workspace",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 4,
              title: "Universal search",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            },
            {
              id: 5,
              title: "The hierarchy overview",
              desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
            }
          ]
        }
      ]
    },
    {
      id: 4,
      title: "Rewards",
      desc: "Lorem ipsum dolor sit amet consectetur. Pharetra phasellus mauris a blandit eu egestas facilisis ut.",
      article: [
        {
          id: 0,
          title: "Core Smartoffice feature",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 1,
          title: "Employee Self-Service",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 2,
          title: "Set up your team’s Workspace from scratch",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 3,
          title: "Set up personal workspace",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 4,
          title: "Universal search",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        },
        {
          id: 5,
          title: "The hierarchy overview",
          desc: "Brand new to Smartoffice? Make the most of our customizable platform by learning these co"
        }
      ],
      slug: "rewards"
    }
  ];
  
  export default productTrainingData;