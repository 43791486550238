import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecurityCommunicateEasilly = () => {
  return (
    <>
      <div className="details-section-6x6 blockSecurity-CommunicateEasilly">
        <div className="container">
          <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <FullWidthHeading sectionTitle={"GDPR COMPLIANCE "}
                title={"Meeting Global Standards"}
                desc={"SmartPeople takes data protection seriously, and blockchain technology aligns with the General Data Protection Regulation (GDPR) standards. By providing transparent user activity tracking with robust data security measures, SmartPeople offers peace of mind to organizations and their employees, ensuring compliance with global data protection regulations."}
            />
             
            </div>
            <div className="col-12 col-lg-6">
              <div className="img-content">
                <img
                  className="img-fluid"
                  src={require("../../../assets/images/feature/blockchain/GDPR COMPLIANCE.png")}
                  alt="error"
                />
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityCommunicateEasilly;
