import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const TaxManagementBlockchain = () => {
  return (
    <>
      <div className="TaxManagement-blockchain">
        <div className="container">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-lg-6">
              <FullWidthHeading 
              title="Payroll Tax Management: Harnessing the Power of Blockchain Technology" 
              desc="SmartPeople is proud to introduce its groundbreaking payroll tax calculation automation powered by blockchain technology. Say goodbye to manual processes and embrace a more secure, transparent, and efficient payroll management system. With the integration of blockchain, every activity performed to generate payroll is stored securely in a decentralized and immutable ledger, ensuring a tamper-proof record of all user activity performed to configure and set up taxes for payroll processing." 
              btn3='<a href="http://apps.smartoffice.ai/register" class="btn btn-primary radius-12 mt-3">Free Signup</a>'
              />
              </div>
              <div className="col-12 col-lg-6">
                <div className="img-content">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/feature/notice-blockchain.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxManagementBlockchain;
