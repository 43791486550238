import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const Article = ({ slug }) => {
  return (
    <>
      <div className="article">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-capitalize">{slug}</h2>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Resources</Breadcrumb.Item>
                <Breadcrumb.Item>Product Training</Breadcrumb.Item>
                <Breadcrumb.Item active>{slug}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="col-lg-6">
              <div className="article-card">
                <div className="content">
                  <h4>
                    
                      Core Smartoffice feature
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h4>
                  <p>
                    Brand new to{" "}
                    <Link to="https://smartoffice.ai/">Smartoffice?</Link> Make
                    the most of our customizable platform by learning these core
                    features! For an overview of everything Smartoffice offers,
                    take a look at our features page. To see a comparison of our
                    plans by feature, go to our pricing page.
                  </p>
                  <Link to={`/product-training/single-articles/core-smartOffice-feature`}>View Details</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="article-card">
                <div className="content">
                  <h4>
                    Employee Self-Service{" "}
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h4>
                  <p>
                    Brand new to Smartoffice? Make the most of our customizable
                    platform by learning these core features! For an overview of
                    everything Smartoffice offers, take a look at our features
                    page. To see a comparison of our plans by feature, go to our
                    pricing page.
                  </p>
                  <Link to="/product-training/single-articles/employee-self-service">View Details</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="article-card">
                <div className="content">
                  <h4>
                    Set up your team’s Workspace from scratch
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h4>
                  <p>
                    Brand new to Smartoffice? Make the most of our customizable
                    platform by learning these core features! For an overview of
                    everything Smartoffice offers, take a look at our features
                    page. To see a comparison of our plans by feature, go to our
                    pricing page.
                  </p>
                  <Link to="/product-training/single-articles/set-up-your-teams-Workspace-from-scratch">View Details</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="article-card">
                <div className="content">
                  <h4>
                    Set up personal workspace
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h4>
                  <p>
                    Brand new to Smartoffice? Make the most of our customizable
                    platform by learning these core features! For an overview of
                    everything Smartoffice offers, take a look at our features
                    page. To see a comparison of our plans by feature, go to our
                    pricing page.
                  </p>
                  <Link to="/product-training/single-articles/set-up-personal-workspace">View Details</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="article-card">
                <div className="content">
                  <h4>
                    Universal search
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h4>
                  <p>
                    Brand new to Smartoffice? Make the most of our customizable
                    platform by learning these core features! For an overview of
                    everything Smartoffice offers, take a look at our features
                    page. To see a comparison of our plans by feature, go to our
                    pricing page.
                  </p>
                  <Link to="/product-training/single-articles/universal-search">View Details</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="article-card">
                <div className="content">
                  <h4>
                    The hierarchy overview
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4252 9.13125C22.3313 8.83592 22.1504 8.57585 21.9061 8.38513C21.6619 8.19441 21.3657 8.08195 21.0564 8.0625L15.4877 7.67813L13.4252 2.475C13.3125 2.1885 13.1165 1.94241 12.8624 1.76858C12.6084 1.59475 12.308 1.50118 12.0002 1.5C11.6923 1.50118 11.3919 1.59475 11.1379 1.76858C10.8838 1.94241 10.6878 2.1885 10.5752 2.475L8.47516 7.70625L2.94391 8.0625C2.635 8.08321 2.33943 8.19614 2.09541 8.38668C1.85139 8.57722 1.67016 8.83659 1.57516 9.13125C1.4776 9.43043 1.4719 9.75197 1.55879 10.0544C1.64568 10.3569 1.82118 10.6263 2.06266 10.8281L6.31891 14.4281L5.05328 19.4062C4.96572 19.743 4.98147 20.0983 5.09851 20.426C5.21554 20.7537 5.42844 21.0386 5.70953 21.2438C5.98237 21.4396 6.30744 21.5495 6.64313 21.5596C6.97882 21.5696 7.30987 21.4792 7.59391 21.3L11.9908 18.5156H12.0095L16.7439 21.5062C16.9868 21.6641 17.2699 21.7487 17.5595 21.75C17.796 21.7481 18.0289 21.6921 18.2404 21.5861C18.4518 21.4802 18.6361 21.3272 18.7792 21.1389C18.9222 20.9505 19.0202 20.7319 19.0655 20.4998C19.1109 20.2677 19.1024 20.0283 19.0408 19.8L17.7002 14.3531L21.9377 10.8281C22.1791 10.6263 22.3546 10.3569 22.4415 10.0544C22.5284 9.75197 22.5227 9.43043 22.4252 9.13125Z"
                          fill="#ED8936"
                        />
                      </svg>
                    </span>
                  </h4>
                  <p>
                    Brand new to Smartoffice? Make the most of our customizable
                    platform by learning these core features! For an overview of
                    everything Smartoffice offers, take a look at our features
                    page. To see a comparison of our plans by feature, go to our
                    pricing page.
                  </p>
                  <Link to="/product-training/single-articles/the-hierarchy-overview">View Details</Link>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button className="btn">
                See all <span>46</span> articles
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;