import React, { useState } from "react";
import MarqueSlider from "../../components/Common/MarqueSlider";
import FetureHero from "../../components/Feature/FetureHero";
import HrProcess from "../../components/HrProcess/HrProcess";
import "./../../assets/css/future.css";
import { FeatureData } from "./../../assets/json/features";
// import { ENDPOINTS, api } from "../../api/apiConfig";

const Features = () => {
  const [hrProcess, setHrProcess] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   api
  //     .get(ENDPOINTS.APPS)
  //     .then((res) => {
  //       setHrProcess(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <div className="future">
      <FetureHero />
      <HrProcess loader={loading} data={FeatureData} />
      <MarqueSlider />
    </div>
  );
};

export default Features;
