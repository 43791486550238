import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const CompanyManagementHero = () => {
  return (
    <>
      <div className="company-management-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <FullWidthHeading
                  sectionTitle="Company Management"
                  title="Revolutionizing Multi-Company Oversight"
                  desc="SmartPeople revolutionizes organizational management with its Company Management feature, enabling users to effortlessly oversee multiple companies within a single, user-friendly panel."
                  btn="Free Signup"
                  btnLink="https://apps.smartoffice.ai/register"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/company-management/company-management-hero.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyManagementHero;
