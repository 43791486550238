const RealTimeAttendanceTrackingData = [
    {
      id: 0,
      icon: require("../images/feature/company-management/streamlined.png"),
      title: "Streamlined Oversight",
      desc: "Access helpful resources or talk to an HR advisor. Stay compliant with help from Mineral, Inc.",
    },
    {
      id: 1,
      icon: require("../images/feature/company-management/tailored.png"),
      title: "Tailored Customization",
      desc: "Access helpful resources or talk to an HR advisor. Stay compliant with help from Mineral, Inc.",
    },
    {
      id: 2,
      icon: require("../images/feature/company-management/oversight.png"),
      title: "Streamlined Oversight",
      desc: "Navigate through the organizational chart seamlessly.",
    },
  ];
  
  export { RealTimeAttendanceTrackingData };
  