import React from "react";
import Faqs from "../components/Common/Faqs";
import GetInTouchCard from "../components/Common/GetInTouchCard";
import FaqHero from "../components/Faq/FaqHero";

const Faq = () => {
  return (
    <div className="faq-page ">
      <FaqHero />
      <Faqs />
      <div>
        <GetInTouchCard />
      </div>
    </div>
  );
};

export default Faq;
