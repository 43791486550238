import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const TaxManagementFeature = () => {
  return (
    <>
      <div className="payroll-feature TaxManagement-feature">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                sectionTitle="TAX CALCULATION MADE EASIER"
                title="Experience the Power of Automated Payroll Tax Management with SmartPeople"
                desc="Say goodbye to payroll tax headaches and embrace the future of streamlined and accurate tax management. Join thousands of businesses that rely on SmartPeople to simplify their payroll processes and stay compliant with local tax regulations. Sign up for a free trial today and experience the benefits of automated payroll tax management firsthand."
              />
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-4   radius-20">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24 text-start">
                        Payroll Tax Calculation
                      </h3>
                      <p className="fs-14 text-start">
                        Our advanced payroll software automates tax
                        calculations, taking into account the latest tax laws
                        and regulations for each location. Say goodbye to manual
                        calculations and potential errors; our system ensures
                        precise deductions, making sure your employees are
                        accurately compensated.
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="img-content text-center">
                      <img
                        className="fs-14 img-fluid"
                        src={require("../../../assets/images/feature/tax-management/image34.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-4   radius-20">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24 text-start">
                        Time Savings
                      </h3>
                      <p className="fs-14 text-start">
                        Our automated tax management system saves you valuable
                        time by handling all the complex tax calculations. This
                        means no more manual data entry or lengthy tax filings,
                        allowing your HR and finance teams to focus on more
                        strategic tasks.
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="img-content text-center position-relative">
                      <img
                        className="mt-3 fs-14 video"
                        src={require("../../../assets/images/feature/tax-management/time-savings.png")}
                        alt="error"
                      />
                      {/* <div className="playBtn">
                        <img
                          src={require("../../../assets/images/feature/play-btn.png")}
                          alt=""
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card stat-card card-shadow card-border p-4   radius-20">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24 text-start">
                        Accuracy Guaranteed
                      </h3>
                      <p className="fs-14 text-start">
                        With our intelligent tax calculation algorithms, you can
                        be confident that your payroll taxes are calculated
                        accurately every time. Say goodbye to costly errors and
                        potential penalties.
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="img-content text-center position-relative">
                      <img
                        className="mt-3 fs-14 video"
                        src={require("../../../assets/images/feature/tax-management/accuracy-guaranteed.png")}
                        alt="error"
                      />
                      {/* <div className="playBtn">
                        <img
                          src={require("../../../assets/images/feature/play-btn.png")}
                          alt=""
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24 text-start">
                        Pre-built Tax Templates
                      </h3>
                      <p className="fs-14 text-start">
                        Dealing with diverse tax regulations across different
                        regions can be challenging. With our pre-built tax templates
                        tailored to each local regulatory authority, you can rest
                        assured that your payroll taxes are accurate and compliant.
                        Our templates cover a wide range of tax components, from
                        income tax to social security contributions and more.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="img-content text-center ">
                      <img
                        className="fs-14  img-fluid"
                        src={require("../../../assets/images/feature/tax-management/image39.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card stat-card card-shadow card-border p-3   radius-20">
                <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="img-content text-center ">
                      <img
                        className="fs-14  img-fluid"
                        src={require("../../../assets/images/feature/tax-management/image10.png")}
                        alt="error"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="text-content">
                      <h3 className="text-dark fw-700 fs-24 text-end">
                        Local Regulatory Compliance
                      </h3>
                      <p className="fs-14 text-end">
                        Staying compliant with ever-changing tax laws can be
                        overwhelming. Our system is constantly updated to reflect
                        the latest changes in tax regulations for each country or
                        region, ensuring your payroll taxes are always up to date.
                      </p>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxManagementFeature;
