import React from "react";
import BannerText from "../../Banner/BannerText";

const BlockSecurityHero = () => {
  return (
    <>
      <div className="AttendanceManagement-hero blockSecurity-hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <BannerText
                title="Leveraging Blockchain for 
Enhanced User Activity Monitoring 
and Data Integrity
"
                desc="We have revolutionized user activity tracking, ensuring transparency,
accuracy, and data integrity throughout the SmartPeople apps.
"
                btnText="Free Signup"
              />
            </div>
            <div className="col-lg-6">
              <div className="img">
                {/* <img
                  src={require("./../../../assets/images/feature/blockSecurity-hero.png")}
                  alt="attendance-hero"
                  className="img-fluid w-100"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityHero;
