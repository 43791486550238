import React from "react";
import { Helmet } from "react-helmet";
import { corehrData } from "../assets/json/corehr";
import Blog from "../components/Blog/Blog";
import CoreHR from "../components/CoreHR/CoreHR";
import Hero from "../components/Home/Hero";
import HomeHero from "../components/Home/HomeHero";
import Howtowork from "../components/Howtowork/Howtowork";
import Integrate from "../components/Integrate/Integrate";
import KeyFeature from "../components/KeyFeature/KeyFeature";
import Overview from "../components/Overview/Overview";
import RacipeLibrary from "../components/RacipeLibrary/RacipeLibrary";
import StartWithUs from "../components/StartWithUs/StartWithUs";
import WhySmartOffice from "../components/WhySmartOffice/WhySmartOffice";
import OptimizeTime from "../components/optimizeTime/OptimizeTime";
import "./../assets/css/home.css";
import heroIMG2 from "./../assets/images/blockchain/blockchain.png";
import { optimizeData } from "./../assets/json/optimizeData";
import { overviewData } from "./../assets/json/overviewData";

const Home = () => {

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smart-Office</title>
      </Helmet>
      <HomeHero
        className="hero"
        title="SmartOffice Future of Work!"
        desc="Experience the power of seamless HR management with SmartPeople App, 
simplifying people management, maximizing efficiency, and transforming your workplace
into a thriving hub of productivity and success.
"
        btn="Free Signup"
      />
      <Overview data={overviewData} />
      <KeyFeature />
      <Howtowork />
      <WhySmartOffice />
      <RacipeLibrary />
      <CoreHR rowclassName="align-items-center" data={corehrData} />
      <Hero
        className="blockChain"
        img={heroIMG2}
        name="BLOCKCHAIN TECHNOLOGY"
        title="Blockchain for ultimate transparency & track user activity"
        desc="Experience unparalleled transparency as blockchain ensures 
tamper-proof records of user activity. Say goodbye to doubts 
and uncertainties, and gain complete visibility into HR operations. 
With SmartPeople, your organization can trust in a system built 
on the principles of transparency and data integrity, paving the 
way for more efficient and secure workforce management. 
Harness the potential of blockchain with SmartPeople for a new 
era of HR excellence.
"
        data={`<a href="/smart-people/blockchain-technology" class="btn btn-primary radius-8 w-50 mt-5">Learn More</a>`}
      />
      <OptimizeTime rowclassName="align-items-center" data={optimizeData} />
      <StartWithUs />
      <Blog />
      <Integrate />
    </div>
  );
};

export default Home;
