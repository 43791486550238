import "../assets/css/device-page.css";
import DeviceHero from "../components/Device/DeviceHero";
import FullWidthHeading from "../components/Device/FullWidthHeading";
import ProductSection from "../components/Device/ProductSection";
import Section6x6 from "../components/Device/Section6x6";

const data1 = [
  {
    id: "0",
    title: "ZKTeco F18",
    desc: `<ul>
    <li class="fs-16">Fingerprint capacity: 3000</li>
    <li class="fs-16">Card Capacity: 5000</li>
    <li class="fs-16">Transaction Capacity: 30000 Transactions</li>
    <li class="fs-16">Brand: ZKTeco</li>
    <li class="fs-16">Price: 10500 Tk</li>
  </ul>`,

    imgName: "product.png"
  },
  {
    id: "1",
    title: "ZKTeco K40",
    desc: `<ul>
    <li class="fs-16">Fingerprint capacity: 3000</li>
    <li class="fs-16">Card Capacity: 3000 & User: 3000</li>
    <li class="fs-16">Specialty: Built-in Battery Backup</li>
    <li class="fs-16">Brand: ZKTeco</li>
    <li class="fs-16">Price: 7500 Tk</li>
  </ul>`,

    imgName: "product2.png"
  },
  {
    id: "2",
    title: "ZKTeco F22",
    desc: `  <ul>
    <li className="fs-16">Fingerprint capacity: 3000</li>
    <li className="fs-16">Card Capacity: 5000</li>
    <li className="fs-16">Transaction Capacity: 30000</li>
    <li className="fs-16">Specialty: BioID Sensor</li>
    <li className="fs-16">2.4-Inch liquid crystal display</li>
    <li className="fs-16">Brand: ZKTeco</li>
    <li className="fs-16">Price: 10500 Tk</li>
  </ul>`,
    imgName: "product3.png"
  },
  {
    id: "3",
    title: "MultiBio 700",
    desc: `  <ul>
    <li className="fs-16">Fingerprint capacity: 2000</li>
    <li className="fs-16">Card Capacity: 10000</li>
    <li className="fs-16">Face Capacity: 1500</li>
    <li className="fs-16">Specialty: 3.0 inches touch screen display</li>
    <li className="fs-16">High-Resolution Infrared Camera</li>
    <li className="fs-16">Brand: ZKTeco</li>
    <li className="fs-16">Price: 21500 Tk</li>
  </ul>`,
    imgName: "product1.png"
  }
];

const data = [
  {
    id: "0",
    title: "Why Fingerprint Attendance System",
    desc: "It help’s you track employee management in a cost-effective way. Besides, It also provides accurate data if you compare it with a manual system. Fingerprint attendance devices are the best Biometric attendance machine of the time. This biometric attendance system is connected with our payroll software. So, you don’t need to calculate overtime, working hours, etc. It will automatically calculate and generate salary.",
    imgName: "finger_option1.png"
  },
  {
    id: "1",
    title: "Attendance Management Machine",
    desc: "Our biometric attendance management machines help to track attendance by fingerprint, face tracking, card, or using a password. It keeps a database of all the employees, saves their clocking-in-out information. Out PiHR software doesn’t share biometric fingerprints with any other party. From small business to large business it’s highly recommended nowadays to use an attendance management machine. It helps both from financial and management way.",
    imgName: "finger_option1.png"
  },
  {
    id: "2",
    title: "Time & Attendance Tracking",
    desc: `Our biometric attendance management machines help to track attendance by fingerprint, face tracking, card, or using a password. It keeps a database of all the employees, saves their clocking-in-out information. Out PiHR software doesn’t share biometric fingerprints with any other party. From small business to large business it’s highly recommended nowadays to use an attendance management machine. It helps both from financial and management way.`,
    imgName: "finger_option1.png"
  }
];

const Devices = () => {
  return (
    <div className="device-page container">
      <DeviceHero
        sectionTitle=""
        title="Manage your attendance with Fingerprint!"
        desc="Fingerprint attendance machine helps to track employee attendance more efficiently way."
        bigImage="finger_print.png"
        btnText="Order Now"
      />
      <FullWidthHeading
        sectionTitle="Machines"
        title="Best Fingerprint Attendance Machines Price"
        desc="SmartOffice provides the best fingerprint attendance devices which help in attendance tracking and employee management with attendance management software. The attendance tracking machine helps to track employee attendance records and store the data. With the use of modern attendance machines, one company can really get beneficial in reducing labor as it will do their half task done. SmartOffice provides some popular models of attendance machines in Bangladesh including ZKTeco F18, ZKTeco K40, ZKTeco F22, ZKTeco MB700."
      />
      <ProductSection data={data1} />
      {data.map((item, index) => (
        <Section6x6
          key={item.id}
          direction={index}
          hasSectionname={""}
          title={item.title}
          desc={item.desc}
          btnText="Contact to Buy"
          btnWidth="w-50"
          btnName=""
          imgName={item.imgName}
          link="/devicedetails"
        />
      ))}
    </div>
  );
};

export default Devices;
