import React from "react";

import "../assets/css/blog.css";
import { blogData } from "../assets/json/blogData";
import BlogItem from "../components/Blog/BlogItem";
import Hero from "../components/Blog/Hero";
import RecentBlogs from "../components/Blog/RecentBlogs";
import TrendingBlogs from "../components/Blog/TrendingBlogs";

const Blog = () => {
  return (
    <div className="blog-page">
      <Hero />
      <div className="blog-section">
        <BlogItem data={blogData} />
      </div>
    
      <div className="blog-section">
        <TrendingBlogs title={"Trending Blogs"} btn={true} data={blogData}/>
      </div>
      <div className="blog-section">
        <TrendingBlogs title={"Popular blogs"} btn={true} data={blogData}/>
      </div>
      <RecentBlogs headeing={"RecentBlog"} />
    </div>
  );
};

export default Blog;
