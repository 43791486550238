import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const BlockSecurityDataProtection = ({bg}) => {
  return (
    <>
      <div className={`blockSecurity-secureIdentity blockSecurity-dataProtection ${bg}`}>
        <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-lg-6">
                <div className="img-content">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/feature/data-protection.png")}
                    alt="error"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <FullWidthHeading
                sectionTitle={"ZERO TOLERANCE"}
                sectionTitleClassName={'text-start'}
                  title={"Securing Against Upholding Data Authenticity and Reliability"}
                  desc={
                    "SmartPeople maintains a zero-tolerance policy for unwanted activity within the application. Blockchain's robust security features prevent any unauthorized changes to user activity records. This proactive approach ensures that the platform remains resilient against malicious attempts to manipulate important data."
                  }
                />
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default BlockSecurityDataProtection;
