import React from "react";

const InfoCard2 = ({ id, className, icon, title, desc, data }) => {
  return (
    <>
        <div className={`info-card text-center card-border radius-16 card-shadow card ${className}`}>
          <div className="img-content">
            <img src={icon} alt="" className="img-fluid" />
          </div>
          <div className="text-content">
            <h3 className="fs-16">{title}</h3>
            <p className="fs-14 w-100 mx-auto">{desc}</p>
            <div className="text hr-list-item" dangerouslySetInnerHTML={{__html: data}}/>
          </div>
        </div>
    </>
  );
};

export default InfoCard2;
