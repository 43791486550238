import React from "react";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  return (
    <div className="container">
      <form className="radius-16 auth-form">
        <div className="row">
          <h2 className="text-center">Have you forgotten your password?</h2>
          <p className="text-center">
            To reset your password, please enter your email address, and we will
            send you a link
          </p>

          <div className="col-12 mb-3">
            <input
              type="email"
              className="form-control"
              name=""
              id=""
              placeholder="Email address"
            />
          </div>

          <div className="col-12 mb-3">
            <Link className="btn btn-primary w-100" type="submit" to={"/auth/verify-password"}>
              Reset Password
            </Link>
          </div>
          <div className="col-12">
            <Link className="d-flex align-items-center justify-content-center gap-2 returnToSignIn" to="http://apps.smartoffice.ai/">
              <span className="">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 16.5L1 9L8.5 1.5"
                    stroke="#000F33"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Return to sign in
            </Link>
          </div>
         
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
