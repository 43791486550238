import React from "react";
import { Link } from "react-router-dom";

const BannerText = ({ className, title, desc, btnText, sectionTitle }) => {
  return (
    <div className={`hero-title ${className}`}>
      {sectionTitle && (
        <p className="text-primary fw-800 fs-24 section-title text-uppercase">{sectionTitle}</p>
      )}
      <h2 className="fs-64 fw-800 mb-4 CSK-900 title">{title}</h2>
      <p className="fs-20 fw-500 mb-3 desc">{desc}</p>

      {btnText && 
      <Link to="http://apps.smartoffice.ai/register" className="btn btn-primary radius-8 w-50 mt-5">
        {btnText}
      </Link>}
    </div>
  );
};

export default BannerText;
