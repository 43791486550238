import { Spinner } from 'react-bootstrap'

const Loader = () => {
  return (
    <div className="text-center my-5">
      <Spinner animation="border" variant="primary" />
    </div>
  )
}

export default Loader