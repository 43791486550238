import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const NoticeBoardBlockchain = ({bg}) => {
  return (
    <>
      <div className={`notification-blockchain NoticeBoard-blockchain ${bg}`}>
        <div className="container">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-lg-6">
                <FullWidthHeading
                  title={
                    "Enhance Your Notification System with Blockchain Technology"
                  }
                  desc={"At SmartPeople, we take data security and transparency to the next level with our cutting-edge blockchain technology. From the moment a notice is drafted to its final publication, every user activity is securely recorded and stored in the blockchain, ensuring tamper-proof data integrity. With our blockchain-powered system, users can trust that once data is stored, it becomes immutable, making it impossible for anyone, including system administrators, to alter or manipulate the information. This not only enhances the credibility of the data but also instills confidence in all users, knowing that the information they access is accurate and reliable. For top management and auditing purposes, the blockchain serves as a comprehensive and transparent record of all user activities performed within SmartPeople. This means that every action taken, from drafting and editing notices to their final publishing, is traceable and auditable, providing valuable insights into the system's usage and accountability."}
                  data={
                    ' <a href="#" class="btn btn-primary radius-12 mt-3"> Learn More</a>'
                  }
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="img-content">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/feature/notice-board.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBoardBlockchain;
