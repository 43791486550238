import React from "react";
import { Link } from "react-router-dom";

const GetInTouchCard = () => {
  return (
    <div className="container get-in-touch radius-10 flex-column align-items-center mt-64 mb-64">
      <div className="image-content">
        <img src={require("../../assets/images/blog/user.png")} alt="Error" />
        <img src={require("../../assets/images/blog/user.png")} alt="Error" />
        <img src={require("../../assets/images/blog/user.png")} alt="Error" />
      </div>
      <div className="text-content text-center mt-3">
        <h3 className="fs-24 fw-800">Still have questions?</h3>
        <p className="fs-16 fw-400">
          Can't find the answer you're looking for? Please chat with our
          friendly team.
        </p>
      </div>
      <Link className="btn btn-primary"> Get in touch </Link>
    </div>
  );
};

export default GetInTouchCard;
