import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";
import { RealTimeTrackingOfEmployeeData } from "../../../assets/json/RealTimeTrackingOfEmployeeData";

const RealTimeTrackingOfEmployee = () => {
  return (
    <>
      <div className="structure-understand-accessibility">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FullWidthHeading
                title={
                  "Real-time tracking of employee attendance of each company"
                }
              />
            </div>
            {RealTimeTrackingOfEmployeeData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-3">
                <div className="card stat-card card-shadow card-border p-3 box-content radius-20 real-time-tracking-of-employee">
                  {index % 2 === 0 ? (
                    <div className="top-direction">
                      <div className="text-content">
                        <h3 className="text-dark fw-700 fs-24">{item.title}</h3>
                        <p className="fs-14">{item.desc}</p>
                      </div>
                      <div className="img-content text-center">
                        <img
                          className="img-fluid fs-14"
                          src={item.img}
                          alt="error"
                        />
                      </div>
                    </div>
                  ) : (
                    <d iv className="bottom-direction">
                      <div className="img-content text-center">
                        <img
                          className="img-fluid fs-14"
                          src={item.img}
                          alt="error"
                        />
                      </div>
                      <div className="text-content">
                        <h3 className="text-dark fw-700 fs-24">{item.title}</h3>
                        <p className="fs-14">{item.desc}</p>
                      </div>
                    </d>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RealTimeTrackingOfEmployee;




