const DomainCustomizationPersonalConsultantData=[
    {
        id: 0,
        icon: require("./../images/feature/biometric1.png"),
        title: 'Support and coaching in one'
    },
    {
        id: 1,
        icon: require("./../images/feature/biometric2.png"),
        title: 'Available via chat, email'
    },
    {
        id: 2,
        icon: require("./../images/feature/biometric3.png"),
        title: 'Award-winning support'
    }
];


export { DomainCustomizationPersonalConsultantData };

