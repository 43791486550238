import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({title, desc, imgName}) => {
  return (
    <div className="product-card  card-border radius-16 card-shadow card p-3  h-100">
      <Link to={"/devicedetails"} className="text-end d-block loading-spin" href="">
        <img
          className="img-fluid"
          src={require("../../assets/images/comp/icons/ArrowUpRight.png")}
          alt="error"
        />
        <div className="circle-loading"></div>
      </Link>
      <div className="img-content text-center ">
        <img
          className="img-fluid"
          src={require(`../../assets/images/comp/${imgName}`)}
          alt="error"
        />
      </div>
      <div className="text-content">
        <h3 className="fs-24 fw-700 mb-4">{title}</h3>
        <div dangerouslySetInnerHTML={{__html: desc}}></div>
      

        <div className="mt-5 text-center mb-4">
          <Link to={"/devicedetails"} className="text-primary fs-16" style={{ fontWeight: "bold" }}>
            {" "}
            View Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
