const ScheduleData = [
  {
    id: 0,
    cardTitle: "EMPLOYEE SCHEDULING SOFTWARE    ",
    icon: require("../images/feature/schedule-faster.png"),
    title: "Schedule Faster",
    desc: "Create the work schedule in a minutes and Share with your team or department. ",
    link: "Explore Scheduling"
  },
  {
    id: 1,
    cardTitle: "EMPLOYEE SHIFT  SWAPPING & REQUEST    ",
    icon: require("../images/feature/track-time-easier.png"),
    title: "Shift Swapping    ",
    desc: "Enable employees to exchange shifts with colleagues, ensuring coverage and flexibility.",
    link: "Explore Attendance"
  },
  {
    id: 2,
    cardTitle: "EMPLOYEE SHIFT MONITORING    ",
    icon: require("../images/feature/communicate-better.png"),
    title: "Communicate Better",
    desc: "Monitor employee performance, address issues, and provide constructive feedback for improvement    ",
    link: "Explore Messaging"
  }
];


export { ScheduleData };

