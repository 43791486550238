import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";


const api = [
    {
      id: 1,
      title: "Getting Started",
      description: "Learn how to get started with Smartoffice",
      content: [
        "Get Started",
        "Authentication",
        "Postman collection",
        "Try the Smartoffice API",
        "Get code Examples",
        "Explore the Smartoffice Hierarchy",
      ],
    },
    {
      id: 2,
      title: "Comments Overview",
      description: "Overview of comments in Smartoffice",
      content: [
        "Add comments to documents",
        "View and manage comments",
        "Comments API",
        "Integration with third-party comment systems",
      ],
    },
    {
      id: 3,
      title: "Tasks Overview",
      description: "Overview of tasks in Smartoffice",
      content: [
        "Create and manage tasks",
        "Task assignments",
        "Task notifications",
        "Tasks API",
      ],
    },
    {
      id: 4,
      title: "Views Overview",
      description: "Overview of views in Smartoffice",
      content: [
        "Document views",
        "Customizing document views",
        "Sharing views",
        "Views API",
      ],
    },
    {
      id: 5,
      title: "Webhooks Overview",
      description: "Overview of webhooks in Smartoffice",
      content: [
        "Setting up webhooks",
        "Webhook events",
        "Webhooks security",
        "Webhooks API",
      ],
    },
    {
      id: 6,
      title: "Troubleshoot & FAQ",
      description: "Troubleshooting tips and frequently asked questions",
      content: [
        "Common issues and solutions",
        "FAQs",
        "Contact support",
      ],
    },
  ];
const Banner = () => {
    const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Fetch search results when searchText changes
    if (searchText.trim() !== "") {
      const filteredResults = api.filter(
        (result) =>
          result.title.toLowerCase().includes(searchText.toLowerCase()) ||
          result.description.toLowerCase().includes(searchText.toLowerCase()) ||
          result.content.some((item) =>
            item.toLowerCase().includes(searchText.toLowerCase())
          )
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [searchText]);

  const highlightKeyword = (text, keyword) => {
    const regex = new RegExp(`(${keyword})`, "gi");
    return text
      .split(regex)
      .map((part, index) =>
        regex.test(part) ? <mark key={index}>{part}</mark> : part
      );
  };
  return (
    <>
      <div className="api-doc-banner">
        <img
          src={require("./../../assets/images/api-docs/banner-bg.png")}
          className="img-fluid"
          alt=""
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h2>API Documentation</h2>

              <p>
                Lorem ipsum dolor sit amet consectetur. Pharetra phasellus
                mauris a blandit eu egestas facilisis ut.
              </p>
            </div>
            <div className="col-lg-5">
              <Form>
                <Form.Group>
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#667085"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  {/* <Form.Control type="text" placeholder="Search" /> */}
                  <div className="position-realtive">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    {/* {searchResults.length > 0 && (
                      <ul className="search_dropdown">
                        {searchResults.map((result) => (
                          <li key={result.id}>
                            <strong>{result.title}</strong>
                            <p>{result.description}</p>
                          </li>
                        ))}
                      </ul>
                    )} */}
                    {searchResults.length > 0 && (
                      <ul className="search_dropdown">
                        {searchResults.map((result) => (
                          <li key={result.id}>
                            <strong>
                              {highlightKeyword(result.title, searchText)}
                            </strong>
                            <p>
                              {highlightKeyword(result.description, searchText)}
                            </p>
                            <ul>
                              {result.content.map((item, index) => (
                                <li key={index}>
                                  {highlightKeyword(item, searchText)}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;