const aboutDriven =[
    {
      id: 0,
      icon: require("./../../assets/images/about/icon-principles-beliefs.png"),
      title: "Principles & Beliefs      ",
      desc: `SmartOffice.ai’s principles - honesty, integrity, and permissibility - are derived from Islamic principles. Honesty dictates individuals to be true to not only others but also to themselves. Integrity goes even further than that; it implies the purity of soul and intentions achieved through strong moral and ethical beliefs. The strength of faith, trust, and strong belief in the organization is expected to be emitted through the powerful character and noble manners of SmartOffice.ai's employees.

      Furthermore, the belief system based on the purpose, mission, vision, and core values of the organization, in its success and prosperity in the long term, should act as a constant source of motivation, confidence, and a driving force for the employees.
      
      All stakeholders and employees are expected to commit to these ideological constants with the highest level of sincerity and belief.
      `
    },
    {
      id: 1,
      icon: require("./../../assets/images/about/icon-exceptionalism-high-standards.png"),
      title: "Exceptionalism & High Standards      ",
      desc: `SmartOffice.ai adopts the term 'Exceptionalism' to mean being different from the norm, what is common and ordinary, in a superior way. This concept is finely in tune with the organization's mission to serve the world with exceptional products and services. We promote exceptionalism in every aspect of our business institution and maintain high standards in everything we do. The standard of something is relative, and high standards are ensured in relation to the particular industry, market, product, or service.

      In other words, whatever we do, we must do it differently and better than our peers or competitors.
      `
    },
    {
      id: 2,
      icon: require("./../../assets/images/about/icon-customer-experience-satisfaction.png"),
      title: "Customer Experience & Satisfaction",
      desc: `Enhancing customer satisfaction remains central to SmartOffice.ai's business approaches. Customer satisfaction is a constant effort leading to a never-ending pursuit of exceeding customers’ expectations. To enrich customer experience, the organization takes special care in improving every mode of communication and interaction with the customers. Our commitment to customers is reflected through providing them with exceptional products and services, and creating unparalleled impressions at all levels of their journey.
      `
    }
  ]

  export { aboutDriven }

