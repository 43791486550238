import gsap from "gsap";
import React, { useState } from "react";

const data = [
  {
    title: "Getting Started",
    content: ["Get Started", "Authentication", "Postman collection", "Try the Smartoffice API", "Get code Examples", "Explore the Smartoffice Hierarchy"]
  },
  {
    title: "Comments overview",
    content: ["Get Started", "Authentication", "Postman collection", "Try the Smartoffice API", "Get code Examples", "Explore the Smartoffice Hierarchy"]
  },
  {
    title: "Tasks overview",
    content: ["Get Started", "Authentication", "Postman collection", "Try the Smartoffice API", "Get code Examples", "Explore the Smartoffice Hierarchy"]
  },
  {
    title: "Views overview",
    content: ["Get Started", "Authentication", "Postman collection", "Try the Smartoffice API", "Get code Examples", "Explore the Smartoffice Hierarchy"]
  },
  {
    title: "Webhooks overview",
    content: ["Get Started", "Authentication", "Postman collection", "Try the Smartoffice API", "Get code Examples", "Explore the Smartoffice Hierarchy"]
  },
  {
    title: "Troubleshoot & FAQ",
    content: ["Get Started", "Authentication", "Postman collection", "Try the Smartoffice API", "Get code Examples", "Explore the Smartoffice Hierarchy"]
  },
];

const ApiDocsContent = () => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuClick = (event, index) => {
    const menu = event.currentTarget;
    const content = menu.nextElementSibling;

    setOpenMenu((prevIndex) => {
      if (prevIndex === index) {
        // If the clicked menu is the currently open one, close it
        gsap.to(content, {
          height: 0,
          overwrite: true,
          onComplete: () =>
            gsap.set(content.children, { y: -30, overwrite: true })
        });
        return null;
      } else {
        // If a different menu is clicked, close the previous and open the new one
        prevIndex !== null && handleClose(prevIndex);
        gsap.to(content, {
          height: "auto",
          duration: 1,
          ease: "elastic",
          overwrite: true
        });
        gsap.to(content.children, {
          y: 0,
          overwrite: true,
          duration: 1.5,
          stagger: 0.1,
          ease: "elastic"
        });
        return index;
      }
    });
  };

  const handleClose = (index) => {
    const content = document.querySelector(`#content-${index}`);
    gsap.to(content, {
      height: 0,
      overwrite: true,
      onComplete: () => gsap.set(content.children, { y: -30, overwrite: true })
    });
  };

  const renderIcon = (index) => {
    return index === openMenu ? "-" : "+";
  };

  console.log(data);
  return (
    <>
      <div className="ApiDocsContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="position-sticky">
                <div className="sidenav">
                  <h2>API Docs</h2>
                  <a
                    className="btn"
                    data-bs-toggle="collapse"
                    href="#collapseExample1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample2"
                  >
                    > User guides
                  </a>
                  <div className="collapse" id="collapseExample1">
                    <div className=" card-body">
                      <ul className="accordion">
                        {data.map((group, index) => (
                          <li key={index} className="accordion-group">
                            <div
                              className="accordion-menu"
                              onClick={(event) => handleMenuClick(event, index)}
                            >
                              {group.title} <span>{renderIcon(index)}</span>
                            </div>
                            <ul
                              id={`content-${index}`}
                              className="accordion-content"
                            >
                              {group.content.map((item, itemIndex) => (
                                <li key={itemIndex}>{item}</li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <hr/>

                  <h2>Reference</h2>
                  <a
                    className="btn"
                    data-bs-toggle="collapse"
                    href="#collapseExample2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample2"
                  >
                    > SmartOffice API Endpoints
                  </a>
                  <div className="collapse" id="collapseExample2">
                    <div className="card-body">
                    <ul className="accordion">
                        {data.map((group, index) => (
                          <li key={index} className="accordion-group">
                            <div
                              className="accordion-menu"
                              onClick={(event) => handleMenuClick(event, index)}
                            >
                              {group.title} <span>{renderIcon(index)}</span>
                            </div>
                            <ul
                              id={`content-${index}`}
                              className="accordion-content"
                            >
                              {group.content.map((item, itemIndex) => (
                                <li key={itemIndex}>{item}</li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <p>
                    Powered by{" "}
                    <span>
                      <svg
                        width="89"
                        height="13"
                        viewBox="0 0 89 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.27011 4.27038C6.25341 4.05867 6.18975 3.85334 6.08375 3.66932C5.97775 3.4853 5.83208 3.32721 5.65732 3.20654C5.29701 2.95319 4.83019 2.82651 4.25685 2.82651C3.90225 2.81734 3.54943 2.8795 3.21932 3.00929C2.95914 3.11102 2.73176 3.28201 2.56182 3.50373C2.40949 3.7112 2.32706 3.96171 2.32643 4.21909C2.32134 4.4227 2.37474 4.6235 2.48029 4.79769C2.58784 4.96548 2.73163 5.10702 2.90109 5.21191C3.08602 5.32869 3.28367 5.42398 3.49021 5.49595C3.70541 5.57316 3.92496 5.6377 4.1477 5.68926L5.14842 5.94042C5.54755 6.03288 5.93783 6.16004 6.31482 6.32046C6.6769 6.47382 7.01556 6.67746 7.32079 6.92536C7.61556 7.16607 7.85619 7.4663 8.02695 7.80641C8.20936 8.18356 8.2995 8.59867 8.28995 9.01752C8.30083 9.60355 8.13345 10.1791 7.80997 10.6678C7.4605 11.1634 6.97566 11.5478 6.41344 11.7751C5.71919 12.0565 4.97411 12.1908 4.22529 12.1696C3.48621 12.1862 2.75124 12.0553 2.06343 11.7843C1.48843 11.554 0.992105 11.1627 0.634031 10.6573C0.280339 10.1243 0.0876811 9.50067 0.0791016 8.86103H1.98322C1.99772 9.19837 2.11777 9.52265 2.32643 9.78811C2.53525 10.0398 2.8077 10.231 3.11543 10.3417C3.46582 10.4675 3.83594 10.5294 4.20819 10.5245C4.58727 10.5307 4.96403 10.4642 5.31805 10.3286C5.60953 10.2224 5.86769 10.0409 6.06629 9.80257C6.24744 9.5757 6.34496 9.29337 6.34244 9.00305C6.34848 8.7503 6.26143 8.50414 6.09785 8.31136C5.91127 8.11034 5.68214 7.95354 5.4272 7.85243C5.10026 7.71519 4.76302 7.60395 4.41859 7.51974L3.20354 7.20677C2.32687 6.98059 1.63211 6.63737 1.11926 6.17712C0.606416 5.71687 0.351744 5.10321 0.355251 4.33613C0.343165 3.74401 0.523593 3.16396 0.869415 2.68317C1.2287 2.19632 1.71246 1.81522 2.26989 1.57989C2.90388 1.30814 3.58821 1.1737 4.27789 1.18539C4.96357 1.17084 5.64422 1.30544 6.27274 1.57989C6.81147 1.81613 7.27695 2.1926 7.62061 2.67003C7.94857 3.1384 8.12524 3.69597 8.12689 4.26775L6.27011 4.27038Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M10.2803 12.0148V4.00384H12.0845V5.36486H12.1752C12.3271 4.92548 12.6179 4.54746 13.0036 4.28788C13.4146 4.02197 13.8964 3.88673 14.3857 3.89995C14.8741 3.88465 15.3551 4.02259 15.7612 4.29445C16.1338 4.55636 16.4108 4.93262 16.5502 5.36617H16.6343C16.8111 4.92286 17.1267 4.54871 17.5338 4.29971C17.9871 4.02681 18.5093 3.88988 19.0382 3.90521C19.7763 3.90521 20.3777 4.13797 20.8423 4.60348C21.307 5.06899 21.5402 5.74884 21.5419 6.64304V12.0201H19.6483V6.92971C19.6483 6.43264 19.5168 6.06839 19.2538 5.83695C18.9851 5.6039 18.6389 5.48002 18.2833 5.48978C18.0853 5.47879 17.8872 5.51123 17.703 5.58482C17.5188 5.65842 17.3529 5.7714 17.2169 5.91585C16.9535 6.22148 16.8167 6.61606 16.8342 7.01913V12.0161H14.9853V6.85212C14.994 6.67012 14.9653 6.48828 14.9011 6.31777C14.8369 6.14726 14.7384 5.99168 14.6119 5.86061C14.4835 5.73581 14.3309 5.63854 14.1636 5.57477C13.9963 5.511 13.8177 5.48208 13.6388 5.48978C13.3775 5.48751 13.1208 5.55907 12.8984 5.69624C12.6744 5.83844 12.4927 6.03829 12.3724 6.27484C12.2357 6.54375 12.1679 6.84245 12.1752 7.14405V12.0095L10.2803 12.0148Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M26.139 12.1776C25.6685 12.1863 25.2017 12.094 24.7701 11.9067C24.3779 11.7355 24.0443 11.4535 23.8101 11.0954C23.5642 10.6986 23.4416 10.2377 23.4577 9.77118C23.4429 9.382 23.529 8.9956 23.7075 8.64949C23.87 8.35783 24.1057 8.11354 24.3913 7.9407C24.6948 7.7565 25.0248 7.61991 25.3697 7.53568C25.7405 7.44202 26.1176 7.37522 26.498 7.3358C26.967 7.28671 27.3479 7.24288 27.6407 7.2043C27.8641 7.18555 28.0814 7.12205 28.2798 7.01758C28.3448 6.97518 28.3975 6.91639 28.4326 6.84713C28.4677 6.77788 28.4839 6.7006 28.4797 6.62308V6.59151C28.4899 6.42336 28.4641 6.25496 28.404 6.09758C28.3439 5.9402 28.2509 5.79746 28.1312 5.67891C27.8971 5.46325 27.5614 5.35542 27.1239 5.35542C26.6623 5.35542 26.2955 5.45667 26.0259 5.65787C25.7754 5.83489 25.5853 6.08461 25.4815 6.37323L23.7181 6.12206C23.842 5.66325 24.0789 5.24279 24.4071 4.89911C24.7362 4.56569 25.1375 4.31238 25.5801 4.15877C26.0733 3.98922 26.5919 3.9056 27.1134 3.91155C27.5016 3.91027 27.8887 3.9544 28.2666 4.04305C28.6406 4.12923 28.9966 4.28009 29.3186 4.48883C29.6402 4.70105 29.9029 4.99097 30.0826 5.33175C30.289 5.74363 30.3885 6.20081 30.3719 6.66121V12.0225H28.5572V10.9218H28.4941C28.2262 11.4301 27.7763 11.8185 27.2344 12.0093C26.8824 12.131 26.5113 12.188 26.139 12.1776ZM26.6281 10.7903C26.9709 10.7994 27.3103 10.7211 27.6144 10.5628C27.8801 10.4247 28.103 10.2164 28.2587 9.96054C28.4074 9.71476 28.4848 9.43248 28.4823 9.14524V8.19844C28.3913 8.26032 28.2896 8.3049 28.1825 8.32994C28.0274 8.37568 27.8698 8.41213 27.7104 8.43909C27.5368 8.47065 27.3645 8.49826 27.1949 8.52325L26.7518 8.58505C26.4931 8.61745 26.2393 8.68058 25.9956 8.7731C25.792 8.84686 25.6109 8.97185 25.4696 9.13603C25.3164 9.34657 25.2505 9.60814 25.2855 9.86615C25.3205 10.1242 25.4539 10.3587 25.6577 10.5207C25.9452 10.7094 26.2845 10.8036 26.6281 10.7903Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M32.6396 12.0148V4.00379H34.4701V5.33851H34.553C34.6779 4.90703 34.9436 4.52975 35.3078 4.26679C35.6654 4.01503 36.0935 3.88292 36.5307 3.88939C36.6346 3.88939 36.7516 3.88939 36.8818 3.90254C36.9913 3.90783 37.1003 3.92101 37.208 3.94199V5.67778C37.0847 5.64118 36.9584 5.61609 36.8305 5.60283C36.6653 5.58076 36.4989 5.56934 36.3322 5.56864C36.0115 5.56391 35.6948 5.64036 35.4117 5.79087C35.1446 5.932 34.9217 6.14404 34.7673 6.40366C34.6076 6.67614 34.5262 6.98736 34.5319 7.30312V12.0121L32.6396 12.0148Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M43.2646 4.00403V5.46236H38.6621V4.00403H43.2646ZM39.7917 2.08545H41.6853V9.60592C41.6733 9.80653 41.7137 10.0068 41.8023 10.1871C41.8701 10.3122 41.98 10.4092 42.1127 10.4607C42.2495 10.511 42.3943 10.5359 42.54 10.5343C42.6493 10.5348 42.7584 10.526 42.8662 10.508C42.9648 10.4909 43.0411 10.4751 43.0923 10.4607L43.4106 11.9374C43.3106 11.9716 43.166 12.0097 42.9806 12.0518C42.7564 12.0979 42.5283 12.1222 42.2994 12.1241C41.8632 12.1446 41.4278 12.0711 41.0225 11.9085C40.6554 11.7586 40.3419 11.5015 40.1231 11.1708C39.8909 10.7958 39.7772 10.3595 39.797 9.91889L39.7917 2.08545Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M54.8412 6.67442C54.8412 7.82548 54.6269 8.81041 54.1982 9.62922C53.8046 10.4103 53.1965 11.063 52.4453 11.511C51.6774 11.94 50.8125 12.1652 49.933 12.1652C49.0534 12.1652 48.1885 11.94 47.4207 11.511C46.67 11.0624 46.0621 10.4098 45.6678 9.62922C45.2382 8.81041 45.0234 7.82723 45.0234 6.67968C45.0234 5.53212 45.2382 4.54719 45.6678 3.72488C46.0608 2.94236 46.6689 2.28819 47.4207 1.83917C48.1885 1.41149 49.0528 1.18701 49.9317 1.18701C50.8105 1.18701 51.6749 1.41149 52.4427 1.83917C53.1937 2.28677 53.8017 2.93903 54.1955 3.71962C54.626 4.5393 54.8412 5.52423 54.8412 6.67442ZM52.8963 6.67442C52.9164 5.97124 52.7878 5.27175 52.5189 4.6217C52.3003 4.10504 51.9344 3.66417 51.4669 3.35405C51.003 3.07282 50.4709 2.92413 49.9284 2.92413C49.3859 2.92413 48.8538 3.07282 48.3898 3.35405C47.9226 3.66446 47.5568 4.10525 47.3378 4.6217C47.0854 5.17926 46.9596 5.8635 46.9604 6.67442C46.9613 7.48533 47.0871 8.16913 47.3378 8.72582C47.5565 9.24248 47.9223 9.68335 48.3898 9.99347C48.8538 10.2747 49.3859 10.4234 49.9284 10.4234C50.4709 10.4234 51.003 10.2747 51.4669 9.99347C51.9347 9.68364 52.3006 9.24268 52.5189 8.72582C52.788 8.07629 52.9166 7.37718 52.8963 6.67442Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M61.0172 4.0045V5.46284H56.2832V4.0045H61.0172ZM57.4667 12.0155V3.24838C57.4501 2.77672 57.5683 2.31009 57.8073 1.90314C58.023 1.55195 58.3378 1.27241 58.712 1.09967C59.1081 0.919595 59.5393 0.829764 59.9744 0.836674C60.2664 0.833737 60.558 0.859725 60.8449 0.914259C61.035 0.950771 61.2229 0.997745 61.4077 1.05496L61.033 2.51592C60.9528 2.49094 60.8515 2.46596 60.7305 2.43966C60.5963 2.41329 60.4597 2.40051 60.3229 2.40152C59.9722 2.40152 59.7246 2.48568 59.5799 2.654C59.4233 2.86078 59.3467 3.11716 59.3642 3.37593V12.0181L57.4667 12.0155Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M66.9234 4.0045V5.46284H62.1895V4.0045H66.9234ZM63.373 12.0155V3.24838C63.3552 2.7771 63.4719 2.3105 63.7096 1.90314C63.9253 1.55195 64.2401 1.27241 64.6143 1.09967C65.0104 0.919595 65.4416 0.829764 65.8767 0.836674C66.1687 0.833737 66.4603 0.859725 66.7472 0.914259C66.9373 0.950771 67.1252 0.997745 67.3101 1.05496L66.9353 2.51592C66.8551 2.49094 66.7538 2.46596 66.6328 2.43966C66.4986 2.41329 66.362 2.40051 66.2252 2.40152C65.8745 2.40152 65.6269 2.48568 65.4822 2.654C65.3262 2.86105 65.2496 3.11723 65.2665 3.37593V12.0181L63.373 12.0155Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M69.719 2.86859C69.4321 2.87344 69.1549 2.76528 68.9471 2.56746C68.8457 2.47621 68.7646 2.36467 68.7092 2.24007C68.6537 2.11548 68.625 1.98061 68.625 1.84421C68.625 1.70782 68.6537 1.57295 68.7092 1.44835C68.7646 1.32376 68.8457 1.21221 68.9471 1.12096C69.1584 0.928023 69.4342 0.821045 69.7203 0.821045C70.0064 0.821045 70.2822 0.928023 70.4935 1.12096C70.5942 1.21265 70.6746 1.32434 70.7296 1.44888C70.7846 1.57341 70.813 1.70806 70.813 1.84421C70.813 1.98036 70.7846 2.11501 70.7296 2.23955C70.6746 2.36409 70.5942 2.47577 70.4935 2.56746C70.2854 2.76642 70.0068 2.87471 69.719 2.86859ZM68.7696 12.0157V4.00475H70.6579V12.0157H68.7696Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M76.4606 12.1699C75.7388 12.1911 75.0256 12.0089 74.4026 11.6439C73.8338 11.2941 73.3774 10.7884 73.0876 10.1869C72.782 9.51061 72.6246 8.77679 72.626 8.03469C72.6273 7.29258 72.7874 6.55935 73.0955 5.88421C73.3893 5.28059 73.8497 4.77364 74.4223 4.42324C75.0373 4.06213 75.741 3.87996 76.454 3.89724C77.0498 3.88562 77.6406 4.00738 78.1832 4.25361C78.6738 4.47595 79.0935 4.82927 79.3963 5.27471C79.699 5.72015 79.8731 6.24045 79.8993 6.7784H78.0872C78.023 6.40852 77.8413 6.06916 77.5691 5.81057C77.2711 5.54366 76.8799 5.40478 76.4803 5.42396C76.1168 5.41731 75.7605 5.52595 75.4625 5.7343C75.1561 5.96009 74.9203 6.26841 74.7826 6.62324C74.6105 7.06502 74.5278 7.53657 74.5394 8.01056C74.5247 8.48971 74.6033 8.96714 74.7708 9.41629C74.9061 9.77496 75.1405 10.0878 75.4467 10.3184C75.7044 10.4933 76.0025 10.5995 76.3127 10.6269C76.6229 10.6543 76.935 10.602 77.2193 10.4749C77.4395 10.3691 77.6302 10.2106 77.7743 10.0133C77.9313 9.79482 78.0366 9.54344 78.082 9.27822H79.8993C79.8635 9.81422 79.691 10.3321 79.3983 10.7826C79.1034 11.2239 78.6944 11.577 78.2148 11.8043C77.6662 12.0601 77.0657 12.1853 76.4606 12.1699Z"
                          fill="#2D446C"
                        />
                        <path
                          d="M85.3612 12.1696C84.6358 12.1887 83.9183 12.0149 83.2821 11.6659C82.7028 11.3337 82.2354 10.8366 81.9395 10.2378C81.6293 9.55377 81.4688 8.81131 81.4688 8.06018C81.4688 7.30906 81.6293 6.5666 81.9395 5.88255C82.2334 5.28065 82.6916 4.77416 83.2611 4.42158C83.8643 4.06221 84.5566 3.87992 85.2586 3.89558C85.7356 3.89263 86.2095 3.97272 86.6591 4.13229C87.0969 4.28943 87.4957 4.53927 87.8281 4.86474C88.1828 5.2217 88.4556 5.65156 88.6276 6.12451C88.8345 6.70753 88.9334 7.32336 88.9195 7.94183V8.52175H82.3564V7.24883H87.1127C87.1156 6.91186 87.0352 6.57939 86.8787 6.28099C86.7305 6.00392 86.5095 5.77251 86.2396 5.61166C85.9516 5.44598 85.6236 5.36273 85.2915 5.37101C84.9328 5.3638 84.5798 5.46063 84.275 5.64979C83.9884 5.83035 83.7536 6.0823 83.5938 6.38093C83.431 6.68461 83.346 7.02392 83.3466 7.36849V8.47967C83.3353 8.89316 83.4227 9.30339 83.6017 9.67632C83.7594 9.99497 84.0078 10.2599 84.3157 10.4377C84.6446 10.6192 85.0159 10.71 85.3914 10.7007C85.6448 10.7038 85.897 10.6638 86.137 10.5823C86.352 10.509 86.5492 10.3911 86.7156 10.2365C86.8818 10.0784 87.0084 9.88331 87.0851 9.66711L88.8419 9.86699C88.7372 10.32 88.5191 10.739 88.2081 11.0847C87.8795 11.4423 87.4714 11.7175 87.0167 11.8881C86.4873 12.0845 85.9257 12.1799 85.3612 12.1696Z"
                          fill="#2D446C"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="content">
                <h1>SmartOffice 01.0</h1>
                <p>
                  Build your own custom integrations and ClickUp apps with our
                  public Application Programming Interface (API).
                </p>
                <h2>Explore SmartOffice</h2>
                <p>Explore what's possible with the API.</p>
                <ul>
                  <li>
                    Read our API docs in the left sidebar to learn about the
                    ClickUp API.
                  </li>
                  <li>
                    View and try individual endpoints and parameters by browsing
                    the Reference section in the left sidebar.
                  </li>
                </ul>
                <p>Try the ClickUp API in your web browser</p>
                <h2>Try using our API with account and Workspaces</h2>
                <p>
                  Try using our API with your ClickUp account and Workspaces, or
                  use a mock server to see generic information.
                </p>
                <h2>Authentication</h2>
                <p>
                  In order to use the ClickUp API, you'll need to authenticate
                  every request. If you're using the ClickUp API for personal
                  use, you can use your personal API token. If you're building
                  an app or integration for other people to use, you can use our
                  OAuth flow, which allows ClickUp users to authorize specific
                  Workspaces for your app
                </p>
                <h2>Download the Postman collection</h2>
                <ul>
                  <li>
                    Start working with the ClickUp API in just a few clicks!
                  </li>
                  <li>
                    You can view all of the ClickUp API endpoints using our
                    public collection available through Postman, which is an app
                    you can use to test and work with APIs.
                  </li>
                  <li>View our Postman Collection.</li>
                  <li>
                    Fork the collection to create a copy in your own Postman
                    workspace.
                  </li>
                  <li>Make your first request to the ClickUp API!</li>
                  <li>Rate limits</li>
                </ul>
                <h2>Rate limits</h2>
                <p>
                  The SmartOffice API enforces request rate limits per token.
                  Rate limits vary depending on your Workspace Plan.
                </p>
                <div className="dateTime">
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 18.75C15.7279 18.75 18.75 15.7279 18.75 12C18.75 8.27208 15.7279 5.25 12 5.25C8.27208 5.25 5.25 8.27208 5.25 12C5.25 15.7279 8.27208 18.75 12 18.75Z"
                        stroke="#2D446C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 8.25V12H15.75"
                        stroke="#2D446C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.25 6.38438L8.8875 2.86875C8.91839 2.69495 9.0096 2.53761 9.14507 2.42443C9.28054 2.31125 9.4516 2.24949 9.62812 2.25H14.3719C14.5484 2.24949 14.7195 2.31125 14.8549 2.42443C14.9904 2.53761 15.0816 2.69495 15.1125 2.86875L15.75 6.38438"
                        stroke="#2D446C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.25 17.6156L8.8875 21.1313C8.91839 21.3051 9.0096 21.4624 9.14507 21.5756C9.28054 21.6888 9.4516 21.7505 9.62812 21.75H14.3719C14.5484 21.7505 14.7195 21.6888 14.8549 21.5756C14.9904 21.4624 15.0816 21.3051 15.1125 21.1313L15.75 17.6156"
                        stroke="#2D446C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <p>Last update : 11 Jan 2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiDocsContent;