import React from "react";
import { pricing } from "../../assets/json/plan";
import PricingCard from "./PricingCard";

const Pricings = ({ multiplyer }) => {
  return (
    <div className="container">
      <div className="row">
        {pricing.map((item) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 max-auto">
            <PricingCard key={item.id} data={item} multiplyer={multiplyer} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricings;
