import React from "react";
import { EmployeeMonitoringIntegrateData } from "../../../assets/json/EmployeeMonitoringIntegrateData";
import InfoCard3 from "../../Common/InfoCard3";
import FullWidthHeading from "../../Device/FullWidthHeading";

const EmployeeMonitoringIntegrate = ({bg}) => {
  return (
    <>
      <div className={`payroll-benifits blockSecurity-integrate EmployeeMonitoring-integrate ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
              <FullWidthHeading
                sectionTitle="HR MANAGEMENT SOLUTION"
                title="Revolutionize Your Workforce with a comprehensive solution: SmartPeople"
                desc="Step into the realm of next-level HR Management with SmartPeople! Effortlessly manage and monitor your business needs with our cloud-based solution. From seamless onboarding to thoughtful promotions, our powerful features are designed to bring out the best in your employees. Experience the future of workforce management with SmartPeople - Your gateway to success!"
              />

              <div className="img">
                <img
                  src={require("../../../assets/images/feature/employee-monitoring/image83.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
            {EmployeeMonitoringIntegrateData.map((item, index) => (
              <div key={index} className="col-lg-4 mb-4">
                <InfoCard3
                  id={item.id}
                  icon={item.icon}
                  desc={item.desc}
                  title={item.title}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMonitoringIntegrate;
