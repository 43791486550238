const companyData2 = [
    {
      id: 0,
      img: "Aeroplanes.png",
      title: "Aeroplanes",
      desc: "Thanks to SmartOffice's central holiday calendar, we can easily manage our team's schedules and keep everyone on the same page      ",
    },
    {
      id: 1,
      img: "benazir-esita.png",
      title: "Benazir Esita",
      desc: "SmartPeople's blockchain technology provides unmatched transparency in our HR operations, ensuring data integrity and security.      ",
    },
    {
      id: 2,
      img: "max.png",
      title: "MAX",
      desc: " We love SmartOffice's performance management feature. Setting goals and tracking progress has never been so easy!",
    },
    {
      id: 3,
      img: "DesignerCollection.png",
      title: "DesignerCollection",
      desc: "SmartPeople's support team is top-notch! Quick responses and knowledgeable assistance have made our HR processes smoother.      ",
    },
    {
      id: 4,
      img: "Executiv.png",
      title: "Executiv",
      desc: "SmartOffice's dynamic payroll management system has made payday stress-free for our team, and the accuracy is exceptional!      ",
    },
    {
      id: 5,
      img: "Majestic-Logo.png",
      title: "Majestic",
      desc: "SmartPeople's automated tax management has saved us time and effort, making payroll processing a breeze!",
    },
  ];

  export default companyData2;