import React, { useState } from "react";
import FullWidthHeading from "../Device/FullWidthHeading";

const PricingHero = ({ checkBtn }) => {
  const [payPolicy, seltPayPolicy] = useState("monthly");

  const check = (id) => {
    id === 1 ? seltPayPolicy("monthly") : seltPayPolicy("annually");
    checkBtn(id);
  };

  return (
    <div className="mt-80 mb-80">
      <FullWidthHeading
        sectionTitle="PRICING PLAN"
        title="Compare our plans and find  yours"
        desc="Simple, Transparent pricing that grows with you. Try any plan free for 30 days."
      />
      <div className="pricing-switch p-2 ">
        <div className="btn-group d-flex gap-2">
          <button



            onClick={() => check(1)}
            className={`${
              payPolicy === "monthly" ? "active" : ""
            } btn radius-12 fs-16 fw-700`}
          >
            Monthly billing
          </button>
          <button
            onClick={() => check(2)}
            className={`${
              payPolicy === "annually" ? "active" : ""
            } btn radius-12 fs-16 fw-700`}
          >
            Annual billing
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingHero;
