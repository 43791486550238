import React from "react";
import SectionHeading from "../../SectionHeading/SectionHeading";

const EmployeeMonitoringScheduleTracking = ({bg}) => {
  return (
    <>
      <div className={`notification-coreHR blockSecurity-ScheduleTracking employee ${bg}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/employee-monitoring/image72.png")}
                  alt="Economies"
                  className="img-fluid w-auto d-flex mx-auto"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <SectionHeading
                fs="fs-40"
                title="Employee Movement
"
                desc="In the ever-evolving landscape of business, effective employee management is the cornerstone of success. SmartPeople, a visionary cloud-based HR Management solution, introduces its revolutionary Employee Movement feature. Seamlessly orchestrating transfers, promotions, and layoffs, this cutting-edge tool empowers you to optimize your workforce dynamically. Join us as we delve into the art of strategic employee movement with SmartPeople!
"
                data={`<ul>
                    <li class="fs-16"><div><b>Recognition and Growth:</b> Promotions are not just milestones; they are celebrations of achievement and potential. SmartPeople's Employee Movement feature empowers you to recognize the stars within your organization and elevate them to new heights. Witness the growth of your employees as they embrace new responsibilities, becoming catalysts of success.</div>
</li>
                    <li class="fs-16"><div><b>Agility Personified:</b> Business landscapes are unpredictable, demanding agile responses. With SmartPeople's Employee Movement feature, your organization embodies agility. Seamlessly adapt to market shifts, skill demands, and changing roles, ensuring your workforce remains dynamic and ready to embrace any challenge that comes their way.</div>
</li>
                   
                </ul>`}
              ></SectionHeading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMonitoringScheduleTracking;
