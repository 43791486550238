import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const EmployeeMonitoringBlockchain = ({bg}) => {
  return (
    <>
      <div className={`notification-blockchain TaxManagement-blockchain ${bg}`}>
        <div className="container">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-lg-6">
                <FullWidthHeading
                  title={"HR Management Tracking and Data Integrity through Blockchain Technology"}
                  desc={
                    "Our HR Management system now incorporates blockchain technology to provide a more secure, reliable, and insightful tracking process. With blockchain, all kinds of employee activity data are securely stored in a decentralized and immutable ledger, ensuring that records are securely stored and cannot be tampered with. The use of distributed ledgers ensures that data along with its relevant history is always available, and its integrity cannot be lost."
                  }
                  data={
                    '<a href="/smart-people/blockchain-technology" class="btn btn-primary radius-12 mt-3"> Learn More </a>'
                  }
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="img-content">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/feature/employee-blockchain.png")}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMonitoringBlockchain;
