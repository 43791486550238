import React from "react";
import "../assets/css/contact-page.css";
import ContactForm from "../components/Contact/ContactForm";
import ContactSection from "../components/Contact/ContactSection";
import MapSection from "../components/Contact/MapSection";
import VisitSection from "../components/Contact/VisitSection";
import FullWidthHeading from "../components/Device/FullWidthHeading";
const Contact = () => {
  // const [offsetHeight, setOffsetHeight] = useState(0);

  // let isFromSocialPlatform = window.location.href.includes('#go-to-contact');
  // if(isFromSocialPlatform){
  //   if (window.innerWidth < 769) {
  //     window.scrollTo({ top: 1750, behavior: 'smooth' });
  //   }else{
  //     window.scrollTo({ top: 1550, behavior: 'smooth' });

  //   }
  //   window.scrollTo(0, offsetHeight);
  // }

  // useEffect(() => {
  //   const div = document.getElementById("go-to-contact");
  //   console.log(div.innerHeight);
  //   setOffsetHeight(div.offsetHeight)
  // }, []);

  return (
    <div className="contact-page">
      <FullWidthHeading
        sectionTitle="CONTACT US"
        title="We’d love to hear from you"
        desc="We have offices and teams all around the world."
      />
      <MapSection />
      <ContactSection />
      <VisitSection />
      <ContactForm />
    </div>
  );
};

export default Contact;
