const RealTimeTrackingOfEmployeeData = [
    {
      id: 0,
      title: "Simplified Record-keeping",
      desc: "Our system employs clear visual indicators that make understanding reporting relationships intuitive.",
      img: require("./../images/feature/company-management/simlified-record.png"),
    },
    {
      id: 1,
      title: "Enhanced HR Efficiency",
      desc: "Experience an interactive user interface that goes beyond the ordinary. Our system ensures effortless navigation",
      img: require("./../images/feature/company-management/enhance-hr.png"),
    },
    {
      id: 2,
      title: "Informed Decision-Making",
      desc: "Collaboration is key. Our system includes collaborative features that promote effective teamwork. ",
      img: require("./../images/feature/company-management/infromed-decision.png"),
    }
   
  ];
  
  export { RealTimeTrackingOfEmployeeData };
  