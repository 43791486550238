import React from "react";
import FullWidthHeading from "../../Device/FullWidthHeading";

const TaxManagementHero = () => {
  return (
    <>
      <div className="TaxManagement-hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading text-center">
                <FullWidthHeading
                  sectionTitle="Tax Management "
                  title="Simplifying Payroll Tax Management and Calculation"
                  desc="we understand that managing payroll taxes can be complex and time-consuming. That's why we've developed a comprehensive suite of solutions to streamline the entire process, making it easy for businesses to stay compliant with local regulatory authorities and ensure accurate payroll tax calculations."
                  btn="Get started"
                />
              </div>
              <div className="img">
                <img
                  src={require("./../../../assets/images/feature/tax-management/tax-hero-old.png")}
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxManagementHero;
