import React from 'react';

const BlogCat = () => {
    return (
        <div className='d-flex justify-content-between align-items-center position-relative blog-cat'>
        <li className='fs-16 fw-500 w-100 py-3 px-3'> Category 1 </li>
        <span className='fs-12 fw-800 text-end '>16</span>
    </div>
    );
};

export default BlogCat;