import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import faqData from "../../assets/json/faqData";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState(0);

  const handleAccordionToggle = (itemKey) => {
    setActiveKey(itemKey === activeKey ? null : itemKey);
  };
  return (
    <div className="faq payroll-frequently-question">
      <div className="container">
        <h2 className="text-dark fw-800 fs-48 text-center mb-5">FAQ</h2>
        <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
          {faqData.map((item) => (
            <Accordion.Item key={item.id} eventKey={item.id} id={item.id}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>{item.desc}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {/* <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is SmartPeople, and what does it offer?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                SmartPeople is an App of SmartOffice.ai platform that deals with
                Human Resource Management System (HRMS), which streamlines HR
                processes, offering comprehensive solutions for employee
                management, payroll, leave tracking, attendance, and covering
                all the core activities of HR.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How does SmartPeople enhance HR efficiency?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                SmartPeople automates manual HR tasks, such as employee data
                management, leave approvals, and payroll processing, leading to
                increased efficiency, reduced errors, and improved productivity.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Is SmartPeople cloud-based?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, SmartPeople is a cloud-based people management solution,
                which means it is accessible anytime, anywhere, and does not
                require any hardware installations or maintenance.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Can SmartPeople handle employee data securely?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="collapseFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Absolutely. SmartPeople prioritizes data security and employs
                robust encryption protocols and access controls to ensure the
                confidentiality and protection of employee data. The platform
                uses Blockchain Technology for keeping user activity within the
                application secure, transparent, non editable and non tamperable
                for the Top Management.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                How can SmartPeople help in employee self-service?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="collapseFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                SmartPeople offers an employee self-service portal, empowering
                employees to view and update personal information, apply for
                leaves, view payroll details, download payslip and more,
                promoting a self-reliant work environment.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Is SmartPeople customizable to fit our organization's specific
                needs?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="collapseSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, SmartPeople is highly customizable. It can be tailored to
                align with your organization's unique requirements, including
                leave policies, attendance rules, and employee workflows.
                SmartOffice.ai also provides enterprise edition, which allows
                you to customize our core feature as per your business
                requirement.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                Can SmartPeople handle multi-location and multi-departmental
                operations?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="collapseSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Absolutely. SmartPeople is designed to cater to multi-location
                organizations with multi-departmental structures, making it
                efficient in managing diverse HR operations.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                How does SmartPeople ensure Tax compliance with labor laws?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="collapseEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                SmartPeople comes with built-in Tax customization features,
                ensuring adherence to local labor laws and regulations, helping
                you avoid penalties and legal complications.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingNine">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                What reporting and analytics capabilities does SmartPeople
                offer?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              aria-labelledby="collapseNine"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                SmartPeople provides comprehensive reporting and analytics
                tools, enabling HR managers to generate insights on employee
                performance, attendance trends, and HR data to make data-driven
                decisions.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTen">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                Is training and customer support available for SmartPeople
                users?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              aria-labelledby="collapseTen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, SmartPeople offers free of cost training and excellent
                customer support to help you and your team make the most out of
                the system and troubleshoot any issues that may arise.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEleven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                What types of businesses can benefit from SmartPeople?
              </button>
            </h2>
            <div
              id="collapseEleven"
              className="accordion-collapse collapse"
              aria-labelledby="collapseEleven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                SmartPeople is suitable for businesses of all sizes and
                industries looking to streamline their HR processes, enhance
                employee experiences, and achieve better HR management
                efficiency.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwelve">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="headingTwelve"
              >
                How can I get started with SmartPeople?
              </button>
            </h2>
            <div
              id="collapseTwelve"
              className="accordion-collapse collapse"
              aria-labelledby="collapseTwelve"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                To get started with SmartPeople, simply visit our website to
                register your business to get started or request a demo or reach
                out to our sales team. We will guide you through the
                implementation process and answer any questions you may have.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Faqs;
