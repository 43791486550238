import React from "react";
import { AttendanceBiometricData } from "../../../assets/json/AttendanceBiometricData";
import InfoCard2 from "../../Common/InfoCard2";
import FullWidthHeading from "../../Device/FullWidthHeading";

const AttendanceManagementBiometric = ({bg}) => {
  return (
    <>
      <div className={`payroll-benifits AttendanceManagement-biometric ${bg}`}>
        <div className="container">
          <div className="row">
            <div className="com-lg-12">
            <FullWidthHeading sectionTitle="Biometric" title="Biometric attendance solutions" desc="We provide mainly three types of attendace record solution"/>
             
            </div>
            {AttendanceBiometricData.map((item) => (
              <div className="col col-lg-4 mb-4">
                <InfoCard2 id={item.id} icon={item.icon} title={item.title} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceManagementBiometric;
