import React from "react";
import { Link } from "react-router-dom";

const ContactCard = ({ title, desc, mail, phone, link }) => {
  return (
    <div className="contact-card text-center radius-12 p-4 d-flex flex-column justify-content-center mb-3">
      <h3 className="fs-20 fw-700 text-uppercase">
        <Link to={link}>{title}</Link>
      </h3>
      <p className="fs-16 fw-400">{desc}</p>
      {mail && <Link to={`mailto:${mail}`}> {mail} </Link>}
      {phone && <Link to={`callto:${phone}`}> {phone} </Link>}
    </div>
  );
};

export default ContactCard;
