const attendanceBenifitData = [
    {
      id: 0,
      icon: require("../images/feature/attendance-management/Icon- Seamless Attendance Tracking.png"),
      title: "Seamless Attendance Tracking",
      desc: "Effortlessly manage employee time and attendance with advanced features. Stay compliant and gain insights with Mineral, Inc."
    },
    {
      id: 1,
      icon: require("../images/feature/attendance-management/Icon- Efficient Time Management.png"),
      title: "Efficient Time Management      ",
      desc: "Streamline timekeeping processes and optimize workforce productivity. Connect with an HR advisor for support and compliance guidance."
    },
    {
      id: 2,
      icon: require("../images/feature/attendance-management/Icon- Enhanced Communication.png"),
      title: "Enhanced Communication",
      desc: "Improve employee communication and engagement. Access HR resources and assistance from Mineral, Inc."
    },
    {
      id: 3,
      icon: require("../images/feature/attendance-management/Icon- Flexible Attendance Solutions.png"),
      title: "Flexible Attendance Solutions",
      desc: "Customize attendance policies and adapt to varying work scenarios. Explore AP Intego's coverage options for on-the-job incidents"
    }
  ];
  
  export { attendanceBenifitData };
  
  