const faqData = [
  {
    id: 0,
    title: "What is SmartPeople, and what does it offer?",
    desc: `SmartPeople is an App of SmartOffice.ai platform that deals with Human Resource Management System (HRMS), which streamlines HR processes, offering comprehensive solutions for employee management, payroll, leave tracking, attendance, and covering all the core activities of HR.`
  },
  {
    id: 1,
    title: "How does SmartPeople enhance HR efficiency?",
    desc: `SmartPeople automates manual HR tasks, such as employee data management, leave approvals, and payroll processing, leading to increased efficiency, reduced errors, and improved productivity.`
  },
  {
    id: 2,
    title: "Is SmartPeople cloud-based?",
    desc: `Yes, SmartPeople is a cloud-based people management solution, which means it is accessible anytime, anywhere, and does not require any hardware installations or maintenance.`
  },
  {
    id: 3,
    title: "Can SmartPeople handle employee data securely?",
    desc: `Absolutely. SmartPeople prioritizes data security and employs robust encryption protocols and access controls to ensure the confidentiality and protection of employee data. The platform uses Blockchain Technology for keeping user activity within the application secure, transparent, non editable and non tamperable for the Top Management.`
  },
  {
    id: 4,
    title: "How can SmartPeople help in employee self-service?",
    desc: `SmartPeople offers an employee self-service portal, empowering employees to view and update personal information, apply for leaves, view payroll details, download payslip and more, promoting a self-reliant work environment.`
  },
  {
    id: 5,
    title:
      "Is SmartPeople customizable to fit our organization's specific needs?",
    desc: `Yes, SmartPeople is highly customizable. It can be tailored to align with your organization's unique requirements, including leave policies, attendance rules, and employee workflows. SmartOffice.ai also provides enterprise edition, which allows you to customize our core feature as per your business requirement.`
  },
  {
    id: 6,
    title:
      "Can SmartPeople handle multi-location and multi-departmental operations?",
    desc: `Absolutely. SmartPeople is designed to cater to multi-location organizations with multi-departmental structures, making it efficient in managing diverse HR operations.`
  },
  {
    id: 7,
    title: "How does SmartPeople ensure Tax compliance with labor laws?",
    desc: `SmartPeople comes with built-in Tax customization features, ensuring adherence to local labor laws and regulations, helping you avoid penalties and legal complications.`
  },
  {
    id: 8,
    title: "What reporting and analytics capabilities does SmartPeople offer?",
    desc: `SmartPeople provides comprehensive reporting and analytics tools, enabling HR managers to generate insights on employee performance, attendance trends, and HR data to make data-driven decisions.`
  },
  {
    id: 9,
    title: "Is training and customer support available for SmartPeople users?",
    desc: `Yes, SmartPeople offers free of cost training and excellent customer support to help you and your team make the most out of the system and troubleshoot any issues that may arise.`
  },
  {
    id: 10,
    title: "What types of businesses can benefit from SmartPeople?",
    desc: `SmartPeople is suitable for businesses of all sizes and industries looking to streamline their HR processes, enhance employee experiences, and achieve better HR management efficiency.`
  },
  {
    id: 11,
    title: "How can I get started with SmartPeople?",
    desc: `To get started with SmartPeople, simply visit our website to register your business to get started or request a demo or reach out to our sales team. We will guide you through the implementation process and answer any questions you may have.`
  }
];

export default faqData;
