import React from "react";
import { Link } from "react-router-dom";
import MiniCard from "../Common/MiniCard";
import FullWidthHeading from "./FullWidthHeading";

const data = [
  {
  id: 0,
  icon: "mini_card1.png",
  name: "3000 Fingerprints"
},
  {
  id: 1,
  icon: "ic_avatar.png",
  name: "5000 Cards"
},
  {
  id: 2,
  icon: "ic_extra_form_validation.png",
  name: "30000 Log"
},
  {
  id: 3,
  icon: "ic_extra_chart.png",
  name: "10500 BDT"
},
]

const Feature = () => {
  return (
    <div>
      <FullWidthHeading
        sectionTitle="Feature"
        title="Our suite of smart products works seamlessly together to connect teams"
        desc="ZKTeco F18 is an access control biometric time attendance machine that is the most popular innovative fingerprint attendance machine in Bangladesh."
      />

      <div className="container mt-80 mb-80 text-center">
        <div className="row mini-card-wrapper">
          {data.map((item) => (
            <div className="col-6 col-lg-3" key={item.id}>
              <MiniCard icon={item.icon} title={item.name} />
            </div>
          ))}
        </div>
        <Link className="btn btn-light w-25 mt-64">View All Machines </Link>
      </div>
    </div>
  );
};

export default Feature;
